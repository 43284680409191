export const type = {
  refCS: "refCS",
  refP: "refP",
  refT: "refT",
  refS: "refS",
  refM: "refM",
  refCFD: "refCFD",
  SS: "SS",
  SRS: "SRS",
  sendNotification: "sendNotification",
};
