<template>
  <!-- <v-row justify="center">
    <v-dialog v-model="isShowReturn" max-width="800px" min-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline primary--text">{{
            __('Select Return Invoice')
          }}</span>
        </v-card-title>
        <v-container>
          <v-row class="mb-4">
            <v-text-field
              color="primary"
              :label="frappe._('Invoice ID')"
              background-color="white"
              hide-details
              v-model="dataSearch"
              dense
              clearable
              class="mx-4"
            ></v-text-field>
            <v-btn
              text
              class="ml-2"
              color="primary"
              dark
              @click="search_invoices"
              >{{ __('Search') }}</v-btn
            >
          </v-row>
          <v-row>
            <v-col cols="12" class="pa-1" v-if="data">
              <template>
                <v-data-table
                  :headers="headers"
                  :items="data"
                  item-key="name"
                  class="elevation-1"
                  :single-select="singleSelect"
                  show-select
                  v-model="selected"
                >
                  <template v-slot:item.grand_total="{ item }">{{
                    formtCurrency(item.grand_total)
                  }}</template>
                </v-data-table>
              </template>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="mt-4">
          <v-spacer></v-spacer>
          <v-btn color="error mx-2" dark @click="close_dialog">Close</v-btn>
          <v-btn
            v-if="selected.length"
            color="success"
            dark
            @click="submit_dialog"
            >{{ __('Select') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row> -->
  <ModalComponent
    title="Select Return Invoice"
    :isModalOpen="isShowReturn"
    :onToggle="() => (this.isShowReturn = false)"
    :width="768"
    :freeSpaceClose="true"
  >
    <div class="space-y-2">
      <div class="grid grid-cols-7 gap-4">
        <div class="col-span-6 relative w-full">
          <input
            type="text"
            v-model="dataSearch"
            @keydown.enter="onSearchInvoices"
            class="w-full px-2 py-[7px] border dark:text-gray-300 bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparente"
            placeholder="Search ID"
          />
          <div
            v-if="dataSearch != ''"
            @click="dataSearch = ''"
            class="absolute right-3 top-2.5 text-gray-500 dark:text-gray-400 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div class="col-span-1">
          <button
            type="button"
            @click="onSearchInvoices"
            class="inline-flex w-full items-center text-sm py-[10px] bg-secondary text-white font-medium rounded-md justify-center"
          >
            Search
          </button>
        </div>
      </div>
      <div class="inline-block relative overflow-x-scroll w-full max-h-[500px]">
        <TableComponent
          :columns="columns"
          :items="renderItems(data)"
          :onSelectSingleRow="(data) => (selected = data)"
          scrollX="35rem"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'grand_total'"
              ><span>{{ pos_profile.currency === "USD" ? "$" : "៛" }}</span
              >{{
                $n(
                  record.grand_total,
                  "decimal",
                  `${pos_profile.currency === "USD" ? "en" : "kh"}`
                )
              }}</template
            >
          </template>
        </TableComponent>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          @click="isShowReturn = false"
          class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
        >
          Cancel
        </button>
        <button
          type="button"
          @click="handleSelectItem"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Select
        </button>
      </div>
    </div>
  </ModalComponent>
</template>

<script>
const columns = [
  {
    key: "customer",
    label: "Customer",
    dataIndex: "customer",
    sort: true,
  },
  {
    key: "posting_date",
    label: "Date",
    dataIndex: "posting_date",
    sort: true,
  },
  {
    key: "invoice_no",
    label: "Invoice",
    dataIndex: "invoice_no",
    sort: true,
  },
  {
    key: "grand_total",
    label: "Amount",
    sort: true,
  },
];
import ModalComponent from "@/components/modal/ModalComponent.vue";
import TableComponent from "@/components/table/TableComponent.vue";
import { renderItems, formattedDateNow } from "@/utils/globleFunction";
import { dispatchNotification } from "@/components/notification";
import dataService from "@/services/data.service";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ModalComponent,
    TableComponent,
  },
  data: () => ({
    columns,
    renderItems,
    isShowReturn: false,
    selected: [],
    data: [],
    company: "",
    dataSearch: "",
  }),
  computed: {
    ...mapGetters("retail", {
      userProfile: "userProfile",
    }),
    pos_profile() {
      return this.userProfile.pos_profile;
    },
  },
  methods: {
    onSearchInvoices() {
      const vm = this;
      dataService
        .get(
          "search_invoices_for_return",
          `invoice_name=${vm.dataSearch}&company=${vm.company}`
        )
        .then((res) => {
          let data = res.data.message;
          if (data) {
            vm.data = data;
          }
        });
    },
    handleSelectItem() {
      if (this.selected) {
        const return_doc = this.selected;
        const invoice_doc = {};
        const items = [];
        return_doc.items.forEach((item) => {
          const new_item = { ...item };
          new_item.qty = item.qty * -1;
          new_item.stock_qty = item.stock_qty * -1;
          new_item.amount = item.amount * -1;
          items.push(new_item);
        });
        invoice_doc.items = items;
        invoice_doc.is_return = 1;
        invoice_doc.return_against = return_doc.name;
        invoice_doc.customer = return_doc.customer;
        const data = { invoice_doc, return_doc };
        this.emitter.emit("loadReturnInvoice", data);
        this.isShowReturn = false;
      } else
        dispatchNotification({
          content: "Please select item!",
          type: "warning",
        });
    },
    formtCurrency(value) {
      value = parseFloat(value);
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
  },
  created() {
    this.emitter.on("openReturns", (data) => {
      this.isShowReturn = true;
      this.company = data;
      this.dataSearch = "";
      this.data = [];
      this.selected = null;
    });
  },
};
</script>
