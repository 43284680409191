<template>
  <form class="px-5" name="form" @submit.prevent="handleSaveLoyalty">
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
          New Loyalty Program
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'loyalty' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >Loyalty Program</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'create-loyalty' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >New Loyalty Program</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        type="submit"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          class="h-5 w-5 mr-1"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
          />
          <polyline points="17 21 17 13 7 13 7 21" />
          <polyline points="7 3 7 8 15 8" />
        </svg>
        Save
      </button>
    </div>
    <div class="overflow-y-auto h-[calc(100vh_-_150px)]">
      <div class="col-span-2 grid grid-cols-1 sm:grid-cols-2 sm:gap-6">
        <InputComponent
          id="loyalty_program_name"
          label="Loyalty Program Name"
          v-model="dataForm.loyalty_program_name"
          required
        />
        <div>
          <label
            for="loyalty_program_type"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Loyalty Program Type</label
          >
          <div class="relative rounded-md shadow-sm">
            <multiselect
              id="loyalty_program_type"
              v-model="dataForm.loyalty_program_type"
              placeholder=""
              :options="['Single Tier Program', 'Multiple Tier Program']"
            />
          </div>
        </div>
        <div>
          <label
            for="from_date"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >From Date <span class="text-red-400">*</span></label
          >
          <div class="relative rounded-md shadow-sm">
            <vue-tailwind-datepicker
              v-model="dataForm.from_date"
              :formatter="{
                date: 'DD-MM-YYYY',
                month: 'MMM',
              }"
              as-single
            />
          </div>
        </div>
        <div>
          <label
            for="to_date"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >To Date</label
          >
          <div class="relative rounded-md shadow-sm">
            <vue-tailwind-datepicker
              v-model="dataForm.to_date"
              :formatter="{
                date: 'DD-MM-YYYY',
                month: 'MMM',
              }"
              as-single
            />
          </div>
        </div>
        <div class="space-y-4">
          <div class="flex items-center space-x-2">
            <input
              id="auto_opt_in"
              type="checkbox"
              v-model="dataForm.auto_opt_in"
              class="h-4 w-4 cursor-pointer accent-secondary"
            /><label
              for="auto_opt_in"
              class="text-sm font-medium text-gray-900 dark:text-gray-100"
            >
              Auto Opt In (For all customers)</label
            >
          </div>
        </div>
        <div class="col-span-2 space-y-4">
          <h1 class="text-sm font-bold text-gray-900 dark:text-gray-100">
            Collection Rules
          </h1>
          <div
            v-for="(field, index) in dataForm.collection_rules"
            class="flex space-x-4 items-end"
          >
            <div class="grid grid-cols-2 gap-6 w-full">
              <InputComponent
                id="tier_name"
                :label="index == 0 ? 'Item Name' : ''"
                v-model="dataForm.collection_rules[index].tier_name"
                required
              />

              <InputCurrencyComponent
                id="collection_factor"
                prefix="$"
                :label="index == 0 ? 'Collection Factor (=1 LP)' : ''"
                required
                v-model="dataForm.collection_rules[index].collection_factor"
              />
            </div>
            <button
              type="button"
              @click="dataForm.collection_rules.splice(index, 1)"
              class="m-2 dark:text-gray-100 hover:text-red-500 dark:hover:text-red-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>
          <div>
            <button
              type="button"
              @click="
                dataForm.collection_rules.push({
                  tier_name: '',
                  collection_factor: null,
                })
              "
              class="inline-flex items-center text-sm font-medium rounded-md justify-center text-gray-800 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v12m6-6H6"
                />
              </svg>

              Add Row
            </button>
          </div>
        </div>
        <InputComponent
          type="number"
          id="conversion_factor"
          label="Conversion Factor"
          v-model="dataForm.conversion_factor"
        />
        <InputComponent
          type="number"
          id="expiry_duration"
          label="Expiry Duration (in days)"
          v-model="dataForm.expiry_duration"
        />
      </div>
    </div>
  </form>
  <SpinComponent :show="loading" />
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import Loyalty from "@/models/loyalty";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import InputComponent from "@/components/input/InputComponent.vue";
import { formatDate } from "@/utils/globleFunction";
export default {
  name: "Create Purchase Invoice",
  components: {
    SpinComponent,
    InputCurrencyComponent,
    InputComponent,
  },
  data() {
    return {
      Tr,
      dataForm: new Loyalty(
        "",
        "Single Tier Program",
        formatDate(new Date()),
        "",
        "",
        false,
        [
          {
            tier_name: "",
            collection_factor: null,
          },
        ],
        "",
        ""
      ),
      dataSearchItem: "",
      loading: false,
    };
  },
  methods: {
    async handleSaveLoyalty() {
      this.loading = true;
      await dataService
        .post("create_loyalty", this.dataForm)
        .then((res) => {
          dispatchNotification({
            content: "Create loyalty program successfully",
            type: "success",
          });
          this.loading = false;
          this.$router.go(-1);
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.loading = false;
        });
    },
  },
};
</script>
