export default class Item {
  constructor(
    custom_item_code,
    item_name,
    item_group,
    uom,
    image,
    is_stock_item,
    rate,
    standard_rate,
    valuation_method,
    allow_negative_stock,
    is_item_bundle,
    items,
    disabled,
    uoms,
    barcodes
  ) {
    this.custom_item_code = custom_item_code;
    this.item_name = item_name;
    this.item_group = item_group;
    this.uom = uom;
    this.image = image;
    this.is_stock_item = is_stock_item;
    this.standard_rate = standard_rate;
    this.rate = rate;
    this.valuation_method = valuation_method;
    this.allow_negative_stock = allow_negative_stock;
    this.is_item_bundle = is_item_bundle;
    this.items = items;
    this.disabled = disabled;
    this.uoms = uoms;
    this.barcodes = barcodes;
  }
}
