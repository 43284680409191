export default class Customer {
    constructor(first_name, last_name, phone, email, live_id, token) {
      this.first_name = first_name;
      this.last_name = last_name;
      this.phone = phone;
      this.email = email;
      this.live_id = live_id;
      this.token = token;
    }
  }
  