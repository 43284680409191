import dataService from "@/services/data.service";
import { v4 as uuidv4 } from "uuid";
// state
const state = {
  loading: false,
  items: [],
};

// getters
const getters = {
  loadItem: (state) => {
    return state.loading;
  },
  getItems: (state) => state.items,
};

// actions
const actions = {
  async setItems({ state, commit }) {
    commit("setLoading", true);
    if (localStorage.items_res) {
      let items = JSON.parse(localStorage.getItem("items_res"));
      commit("setItems", items);
      commit("setLoading", false);
    }
    const newItems = await dataService
      .get("get_menu_items")
      .then((res) => res.data.message);
    let items = []
    if (newItems) {
      items = newItems.map((i) => {
        return {
          id: uuidv4(),
          ...i,
          qty: 1,
          discount: i.discount_percentage ? i.discount_percentage : 0,
        };
      });
      commit("setItems", items);
      commit("setLoading", false);
      localStorage.setItem("items_res", "");
      localStorage.setItem("items_res", JSON.stringify(items));
    }
  },
 setItemEmpty({ state, commit }, key) {
    let items = state.items_restaurant.filter((item) => item.key !== key);
    commit("setItems", items);
  },
};

// mutations
const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setItems(state, items) {
    state.items = items;
  },
  setLoading(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
