<template>
  <div
    class="bg-white dark:bg-slate-800 rounded-lg pointer-events-auto"
    style="
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    "
  >
    <div class="rounded-lg overflow-hidden">
      <div class="p-2">
        <div class="flex items-center space-x-1.5">
          <div class="flex-shrink-0" v-if="notification.type">
            <SuccessIcon v-if="notification.type === 'success'" />
            <InfoIcon v-else-if="notification.type === 'info'" />
            <WarningIcon v-else-if="notification.type === 'warning'" />
            <ErrorIcon v-else-if="notification.type === 'error'" />
          </div>
          <div>
            <p
              class="text-sm leading-5 text-gray-500 dark:text-gray-400"
              v-html="notification.content"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "./store";
import {
  ErrorIcon,
  InfoIcon,
  WarningIcon,
  SuccessIcon,
  CloseIcon,
} from "./icons";

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  components: {
    ErrorIcon,
    InfoIcon,
    WarningIcon,
    SuccessIcon,
    CloseIcon,
  },
  methods: {
    closeNotification(id) {
      store.actions.removeNotification(id);
    },
  },
};
</script>
