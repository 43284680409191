<template>
  <div class="w-96">
    <div class="h-36 flex justify-center mb-2">
      <img :src="baseURL + getLogo" alt="logo" class="w-36" />
    </div>
    <div class="text-center font-bold">
      <h1>{{ getDisplayName }}</h1>
    </div>
    <div>
      <p class="font-bold" v-show="getVATTin">
        លេខអត្តសញ្ញាណកម្ម អតប
        <span class="font-normal">(VAT TIN) {{ getVATTin }}</span>
      </p>
      <p class="font-bold">
        អាស័យដ្ឋាន:
        <span class="font-normal">{{
          getAddress.address_line1 + ", " + getAddress.city
        }}</span>
      </p>
      <p class="font-bold">
        លេខទំនាក់ទំនង:
        <span class="font-normal">{{ getAddress.phone }}</span>
      </p>
    </div>
    <div class="text-center font-bold">
      <h1>វិក្កយបត្រអាករ</h1>
      <h1>TAX INVOICE</h1>
    </div>
    <p>
      លេខរៀងវិក្កយបត្រ:
      <span class="font-bold">{{ invoiceNo }}</span>
    </p>
    <div class="flex justify-between items-center">
      <p>បោះពុម្ព: {{ dataTimeNow }}</p>
      <p>
        តុ:<span class="text-lg font-bold">{{ dataTable?.label }}</span>
      </p>
    </div>
    <div class="flex justify-between items-center">
      <p>បេឡាករ: {{ user?.username }}</p>
      <p>កាលបរិច្ឆេត: {{ dateCreation }}</p>
    </div>
    <table class="border-b border-gray-900 p-0 m-0 w-full">
      <thead>
        <tr class="border-b border-gray-900 font-bold">
          <th width="1%">
            <p>ល.រ</p>
            <p>No.</p>
          </th>
          <th width="29%">
            <p>ឈ្មោះ</p>
            <p>Name</p>
          </th>
          <th width="10%">
            <p>បរិមាណ</p>
            <p>Qty</p>
          </th>
          <th width="20%">
            <p>តម្លៃ</p>
            <p>Price</p>
          </th>
          <th width="20%">
            <p>ប.តម្លៃ</p>
            <p>Disc</p>
          </th>
          <th width="20%">
            <p>សរុប</p>
            <p>Total</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(item, index) in cartItems" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.item_name }}</td>
          <td>{{ item.qty }}</td>
          <td>
            {{
              item.currency_symbol +
              $n(
                item.price_list_rate,
                "decimal",
                `${item.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </td>
          <td>
            {{
              item.currency_symbol +
              $n(
                item.price_list_rate * item.qty * (item.discount / 100),
                "decimal",
                `${item.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </td>
          <td>
            {{
              item.currency_symbol +
              $n(
                item.price_list_rate * item.qty -
                  item.price_list_rate * item.qty * (item.discount / 100),
                "decimal",
                `${item.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex justify-between border-b border-gray-900">
      <div class="w-28">
        <p>សរុប</p>
        <p v-if="getTax != 0">អាករលើតម្លៃបន្ថែម</p>
        <p v-if="getCharge != 0">សេវាកម្ម</p>
        <p>បញ្ចុះតម្លៃ</p>
        <p>សរុបចុងក្រោយ</p>
      </div>
      <div class="flex">
        <div class="text-right mr-2">
          <p>SUB TOTAL</p>
          <p v-if="getTax != 0">TAX ({{ getTax }}%)</p>
          <p v-if="getCharge != 0">SERVICE CHARGE ({{ getCharge }}%)</p>
          <p>
            DISCOUNT ({{ getDiscount }}%
            {{
              getDiscountAmount != 0
                ? `+ $ ${$n(getDiscountAmount, "decimal", "en")}`
                : ""
            }})
          </p>
          <p>GRAND TOTAL</p>
          <p>GRAND TOTAL</p>
        </div>
        <div class="text-right">
          <p>$ {{ $n(cartSubTotal, "decimal", "en") }}</p>
          <p v-if="getTax != 0">
            $ {{ $n(getTotalAmountTaxCharge(getTax), "decimal", "en") }}
          </p>
          <p v-if="getCharge != 0">
            $ {{ $n(getTotalAmountTaxCharge(getCharge), "decimal", "en") }}
          </p>
          <p>$ {{ $n(getTotalDiscountAmount, "decimal", "en") }}</p>
          <p>$ {{ $n(total, "decimal", "en") }}</p>
          <p>៛ {{ $n(totalKHR, "decimal", "kh") }}</p>
        </div>
      </div>
    </div>
    <div v-show="getQRCode" class="flex justify-center h-48 p-1">
      <img :src="baseURL + getQRCode" />
    </div>
    <div class="text-center">
      <p>
        អត្រាប្តូរប្រាក់(Exchange Rate) : 1$ = ៛
        {{ $n(currencyExchange, "decimal", "kh") }}
      </p>
      <p v-show="getFooter1">{{ getFooter1 }}</p>
      <p v-show="getFooter2">{{ getFooter2 }}</p>
      <p v-show="getFooter3">{{ getFooter3 }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tr from "@/i18n/translation";
import store from "@/store";
import { formatDateTime } from "@/utils/globleFunction";
import { baseURL } from "@/utils/baseURL";

export default {
  data() {
    return {
      baseURL,
    };
  },
  computed: {
    ...mapGetters("restaurant", {
      cartItems: "getCartItems",
      cartSubTotal: "getCartSubTotal",
      getCartTotal: "getCartTotal",
      customer: "getCustomer",
      getDiscount: "getDiscount",
      getDiscountAmount: "getDiscountAmount",
      receive: "getReceive",
      getTotalAmountTaxCharge: "getTotalAmountTaxCharge",
      dataTable: "getDataTable",
      getTotalDiscountAmount: "getTotalDiscountAmount",
      dateCreation: "getDateCreation",
      invoiceNo: "getInvoiceNo",
    }),
    ...mapGetters("auth", {
      getTax: "getTax",
      getLogo: "getLogo",
      getCharge: "getCharge",
      currencyExchange: "getCurrencyExchange",
      getDisplayName: "getDisplayName",
      getAddress: "getAddress",
      getVATTin: "getVATTin",
      getFooter1: "getFooter1",
      getFooter2: "getFooter2",
      getFooter3: "getFooter3",
      getQRCode: "getQRCode",
    }),
    user() {
      return store.state.auth.user;
    },
    dataTimeNow() {
      const now = new Date();
      return formatDateTime(now);
    },
    total() {
      return this.getCartTotal(
        this.getTotalAmountTaxCharge(this.getTax),
        this.getTotalAmountTaxCharge(this.getCharge)
      );
    },
    totalKHR() {
      return Math.round((this.total * this.currencyExchange) / 100) * 100;
    },
  },
};
</script>
