<template>
  <div
    class="fixed z-30 inset-0 flex items-start justify-center pt-4 pointer-events-none"
  >
      <transition-group
        appear
        tag="div"
        :enter-active-class="getEnterActiveClass"
        enter-from-class="-translate-y-4 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        move-class="transition ease-in-out duration-500"
      >
        <Notification
          :key="notification.id"
          :notification="notification"
          :class="idx > 0 ? 'mt-4' : ''"
          v-for="(notification, idx) in getNotifications"
        />
      </transition-group> 
  </div>
  <slot></slot>
</template>

<script>
import Notification from "./Notification.vue";
import store from "./store";

export default {
  components: {
    Notification,
  },
  computed: {
    getNotifications() {
      return store.getters.getNotifications();
    },
    getNotificationsCount() {
      return store.getters.getNotificationsCount();
    },
    getEnterActiveClass() {
      return this.getNotificationsCount > 1
        ? "transform ease-out delay-300 duration-300 transition"
        : "transform ease-out duration-300 transition";
    },
  },
};
</script>

<style scoped>
.delay-300 {
  transition-delay: 300ms;
}
</style>
