<template>
  <div>
    <div class="w-full p-2 lg:p-4 grid grid-cols-1 sm:grid-cols-6">
      <div class="p-2 lg:p-4 col-span-4">
        <div>
          <div class="border-b-2 dark:border-slate-600">
            <div
              v-if="getBusinessType != 'Fast Food'"
              class="flex justify-between text-xs lg:text-base"
            >
              <div class="flex overflow-x-scroll no-scrollbar">
                <button
                  class="p-3 mr-3 bg-gray-200 rounded-md dark:bg-gray-900 dark:text-gray-200 text-xs lg:text-base"
                  v-for="item in dataInvoiceSplit"
                  :disabled="item.status == 'Receipt'"
                  :class="
                    $route.query?.sales_invoice != item.name
                      ? item.status == 'Receipt'
                        ? 'opacity-70 cursor-not-allowed line-through'
                        : 'opacity-70 cursor-pointer'
                      : 'font-bold'
                  "
                  @click="
                    () =>
                      $router.push({
                        ...Tr.i18nRoute({ name: 'checkout' }),
                        query: {
                          sales_invoice: item.name,
                        },
                      })
                  "
                >
                  #{{ item.name }}
                </button>
              </div>
              <div class="ml-4 flex space-x-4">
                <button
                  class="text-sm px-2 lg:px-3 h-10 bg-secondary text-white font-medium rounded-md justify-center"
                  @click="handleSplit"
                >
                  Split Bill
                </button>
                <button
                  class="text-sm px-2 lg:px-3 h-10 bg-red-700 text-white font-medium rounded-md justify-center"
                  @click="handleUnSplit"
                >
                  Revert Split
                </button>
              </div>
            </div>
            <div class="flex justify-between pt-3 pb-1">
              <div class="dark:text-gray-200">
                <p>#{{ getInvoiceName }}</p>
              </div>
              <div class="dark:text-gray-200">
                <p>
                  Customer:
                  <span
                    @click="
                      () => {
                        this.selectedCustomer = this.customer;
                        isModalOpenCutomer = true;
                      }
                    "
                    class="font-semibold cursor-pointer hover:text-secondary hover:dark:text-primary"
                    >{{ customer?.customer_name }}</span
                  >
                </p>
              </div>
              <div>
                <div
                  v-if="getBusinessType !== 'Fast Food'"
                  class="dark:text-gray-100 text-right"
                >
                  <p class="ml-1 w-48 ellipsis">
                    <span class="dark:text-gray-100">Table:</span>
                    <span
                      @click="handleGoToMenu"
                      class="underline cursor-pointer underline-offset-2 ml-2 font-semibold text-primary"
                      >{{ dataTable?.label }}</span
                    >
                  </p>
                </div>
                <div
                  v-else
                  class="cursor-pointer dark:text-gray-100"
                  @click="
                    () =>
                      $router.push({
                        ...Tr.i18nRoute({ name: 'menu' }),
                        query: {
                          sales_invoice: $route.query?.sales_invoice,
                        },
                      })
                  "
                >
                  Waiting Number:
                  <span
                    class="text-primary ml-1 ellipsis w-28 underline underline-offset-2"
                  >
                    {{ getWaitingNumber }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-full overflow-x-auto h-[calc(100vh_-_195px)] sm:h-[calc(100vh_-_184px)] lg:h-[calc(100vh_-_250px)]"
          >
            <div
              class="grid grid-cols-12 py-3 px-4 font-bold text-xs lg:text-base bg-gray-100 dark:bg-slate-600 dark:text-gray-200 text-gray-600"
            >
              <div class="col-span-5">Name</div>
              <div class="col-span-1 text-center">Qty</div>
              <div class="col-span-2 text-center">Price</div>
              <div class="col-span-2 text-center">Discount</div>
              <div class="col-span-2 text-center">Amount</div>
            </div>
            <article
              class="relative bg-white dark:bg-slate-700 border-b border-gray-200 dark:border-gray-800 text-gray-700 dark:text-gray-200"
              v-for="item in getCartItemsDiscount"
              :key="item.id"
            >
              <input
                type="checkbox"
                :value="item"
                @change="onSelectItem"
                class="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
              />
              <div
                class="grid grid-cols-12 py-1 px-4 items-center"
                :class="
                  dataSelectRows.find((i) => i.name == item.name) &&
                  'bg-slate-300 dark:bg-slate-900'
                "
              >
                <div class="col-span-5">{{ item.item_name }}</div>
                <div class="col-span-1 text-center">{{ item.qty }}</div>
                <div class="col-span-2 text-center">
                  {{
                    item.currency_symbol +
                    " " +
                    $n(
                      item.price_list_rate,
                      "decimal",
                      `${item.currency === "USD" ? "en" : "kh"}`
                    )
                  }}
                </div>
                <div class="col-span-2 flex items-center space-x-1 z-10">
                  <div class="relative">
                    <input
                      type="number"
                      :disabled="getStatusInvoice == 'Invoice'"
                      :value="item.discount"
                      @focus="$event.target.select()"
                      @input="
                        (e) =>
                          onChageDiscountItem({
                            value: e.target.value,
                            id: item.id,
                          })
                      "
                      class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                      :class="
                        getStatusInvoice == 'Invoice'
                          ? 'text-gray-500 dark:text-gray-400 '
                          : ''
                      "
                    />
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      %
                    </div>
                  </div>
                </div>
                <div class="col-span-2 text-center">
                  {{
                    item.currency_symbol +
                    " " +
                    $n(
                      item.currency === "USD" ? item.amount * item.qty : item.amount * item.qty * currencyExchange,
                      "decimal",
                      `${item.currency === "USD" ? "en" : "kh"}`
                    )
                  }}
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div
        class="col-span-2 overflow-auto h-[calc(100vh_-_96px)] hidden sm:block"
      >
        <div
          class="py-2 lg:py-4 flex space-x-4 w-full overflow-auto no-scrollbar"
        >
          <button
            v-for="item in getModeOfPayment"
            @click="this.selectedModeOfPayment = item.mode_of_payment"
            :disabled="
              getStatusInvoice != 'Invoice' && getBusinessType != 'Fast Food'
            "
            class="p-1 bg-gray-200 rounded-md dark:bg-gray-900 dark:text-gray-200 text-xs lg:text-base leading-5 lg:leading-8"
            :class="
              getStatusInvoice != 'Invoice' && getBusinessType != 'Fast Food'
                ? 'opacity-60 cursor-not-allowed '
                : selectedModeOfPayment !== item.mode_of_payment
                ? 'opacity-60 cursor-pointer '
                : 'font-semibold cursor-pointer '
            "
          >
            <p class="text-center w-20 lg:w-28 ellipsis">
              {{ item.mode_of_payment }}
            </p>
          </button>
        </div>

        <div
          class="p-2 lg:p-4 pt-0 border-dashed border-y dark:border-slate-600"
        >
          <label
            class="flex space-x-2 items-center dark:text-gray-200 text-xs lg:text-base leading-5 lg:leading-8"
            ><span>Discount</span>
            <div
              class="text-gray-400 hover:text-gray-600 cursor-pointer"
              @click="
                () => {
                  this.switchDis = !this.switchDis;
                  this.discountUSD = this.getDiscountAmount;
                  this.discountKHR = 0;
                }
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                />
              </svg>
            </div>
          </label>
          <div v-if="switchDis" class="grid grid-cols-2 gap-2 lg:gap-4">
            <div
              class="relative rounded-md shadow-sm"
              :class="
                getStatusInvoice == 'Invoice' && getBusinessType != 'Fast Food'
                  ? 'text-gray-400'
                  : 'text-gray-600  dark:text-gray-200'
              "
            >
              <InputCurrencyComponent
                prefix="$"
                v-model="discountUSD"
                :options="{
                  valueRange: { min: 0, max: getTotalAfterDiscountPer },
                }"
                :disabled="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                "
              />
              <button
                class="absolute inset-y-0 right-2 flex items-center text-gray-300"
                :class="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                    ? 'cursor-not-allowed text-gray-400 dark:text-gray-500'
                    : ''
                "
                :disabled="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                "
                @click="handleShowKeypad('discountUSD')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
                  />
                </svg>
              </button>
            </div>
            <div
              class="relative rounded-md shadow-sm"
              :class="
                getStatusInvoice == 'Invoice' && getBusinessType != 'Fast Food'
                  ? 'text-gray-400'
                  : 'text-gray-600 dark:text-gray-200'
              "
            >
              <InputCurrencyComponent
                prefix="៛"
                v-model="discountKHR"
                :options="{
                  valueRange: { min: 0, max: maxDiscountTotalKHR },
                }"
                :disabled="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                "
              />
              <button
                class="absolute inset-y-0 right-2 flex items-center text-gray-300"
                :class="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                    ? 'cursor-not-allowed text-gray-400 dark:text-gray-500'
                    : ''
                "
                :disabled="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                "
                @click="handleShowKeypad('discountKHR')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div v-else>
            <multiselect
              v-model="selectedDiscount"
              placeholder=""
              :disabled="
                getStatusInvoice == 'Invoice' && getBusinessType != 'Fast Food'
              "
              :options="getDiscountRate"
              label="value"
              :searchable="false"
              track-by="value"
            />
          </div>
        </div>
        <div>
          <div class="border-dashed border-b dark:border-slate-600">
            <div class="p-2 lg:p-4 text-xs lg:text-base leading-5 lg:leading-8">
              <div class="flex justify-between items-center dark:text-gray-200">
                <p>Subtotal</p>
                <p>
                  $ {{ $n(cartSubTotal, "decimal", "en") }} / ៛
                  {{ $n(cartSubTotal * currencyExchange, "decimal", "kh") }}
                </p>
              </div>
              <div
                v-if="true"
                class="flex justify-between items-center dark:text-gray-200"
              >
                <p>Tax {{ getTax }}%</p>
                <p>
                  $ {{ $n(getTotalAmountTaxCharge(getTax), "decimal", "en") }}
                </p>
              </div>
              <div
                v-if="true"
                class="flex justify-between items-center dark:text-gray-200"
              >
                <p>Service Charge {{ getCharge }}%</p>
                <p>
                  $
                  {{ $n(getTotalAmountTaxCharge(getCharge), "decimal", "en") }}
                </p>
              </div>
              <div class="flex justify-between items-center dark:text-gray-200">
                <p>
                  Discount {{ getStatusItemDiscount ? 0 : getDiscount }}%
                  {{
                    getDiscountAmount != 0
                      ? `+ $ ${$n(getDiscountAmount, "decimal", "en")}`
                      : ""
                  }}
                </p>
                <p>
                  $
                  {{
                    $n(
                      getStatusItemDiscount
                        ? getDiscountAmount
                        : getTotalDiscountAmount,
                      "decimal",
                      "en"
                    )
                  }}
                </p>
              </div>
            </div>
            <div class="border-dashed border-t w-full dark:border-slate-600">
              <div
                class="p-2 lg:p-4 flex justify-between items-center dark:text-gray-200 text-xs lg:text-lg leading-4 lg:leading-8"
              >
                <h1>Grand Total</h1>
                <p class="font-medium">
                  $ {{ $n(total, "decimal", "en") }} / ៛
                  {{ $n(totalKHR, "decimal", "kh") }}
                </p>
              </div>
            </div>
            <div
              class="p-2 lg:p-4 pt-0 border-dashed border-y dark:border-slate-600 bg-gray-100 dark:bg-slate-900"
            >
              <label
                for="receiveUSD"
                class="dark:text-gray-200 text-xs lg:text-base"
                >Receive</label
              >
              <div class="grid grid-cols-2 gap-2 lg:gap-4">
                <div
                  class="relative rounded-md shadow-sm"
                  :class="
                    getStatusInvoice != 'Invoice' &&
                    getBusinessType != 'Fast Food'
                      ? 'text-gray-400'
                      : 'text-gray-600 dark:text-gray-200'
                  "
                >
                  <InputCurrencyComponent
                    prefix="$"
                    v-model="receiveUSD"
                    :options="{
                      valueRange: { min: 0 },
                    }"
                    :disabled="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                    "
                  />

                  <button
                    class="absolute inset-y-0 right-2 flex items-center"
                    :class="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                        ? 'cursor-not-allowed text-gray-400 dark:text-gray-500'
                        : ''
                    "
                    @click="handleShowKeypad('receiveUSD')"
                    :disabled="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  class="relative rounded-md shadow-sm"
                  :class="
                    getStatusInvoice != 'Invoice' &&
                    getBusinessType != 'Fast Food'
                      ? 'text-gray-400'
                      : 'text-gray-600 dark:text-gray-200'
                  "
                >
                  <InputCurrencyComponent
                    prefix="៛"
                    v-model="receiveKHR"
                    :options="{
                      valueRange: { min: 0 },
                    }"
                    :disabled="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                    "
                  />
                  <button
                    class="absolute inset-y-0 right-2 flex items-center"
                    :class="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                        ? 'cursor-not-allowed text-gray-400 dark:text-gray-500'
                        : ''
                    "
                    @click="handleShowKeypad('receiveKHR')"
                    :disabled="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div
                class="p-2 lg:p-4 flex justify-between items-center text-xs lg:text-base leading-5 lg:leading-8"
              >
                <h1 class="dark:text-gray-200">Change</h1>
                <div
                  class="dark:text-gray-200 text-right font-medium text-xs lg:text-lg"
                >
                  <p>
                    $ {{ $n(change, "decimal", "en") }} / ៛
                    {{
                      $n(
                        Math.round((change * currencyExchange) / 100) * 100,
                        "decimal",
                        "kh"
                      )
                    }}
                  </p>
                  <p class="text-right">
                    $ {{ $n(changeUSD, "decimal", "en") }} + ៛
                    {{ $n(changeKHR, "decimal", "kh") }}
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="getStatusInvoice == 'Invoice'"
              class="text-xs lg:text-base p-2 border-dashed border-t dark:border-slate-600 flex justify-between"
            >
              <p
                @click="handleOpenRemarks"
                class="dark:text-gray-200 cursor-pointer hover:text-secondary hover:dark:text-primary"
              >
                + Remarks
              </p>
              <p class="dark:text-gray-200 ellipsis w-[77%] text-right">
                {{ remarks }}
              </p>
            </div>
          </div>
        </div>
        <div class="py-4">
          <div class="w-full flex space-x-2 lg:space-x-4">
            <button
              v-if="getBusinessType != 'Fast Food'"
              @click="submit(total, 'invoice')"
              class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white font-medium rounded-md w-full justify-center"
            >
              Invoice
            </button>
            <button
              v-else
              @click="
                $router.push({
                  ...Tr.i18nRoute({ name: 'menu' }),
                  query: {
                    sales_invoice: $route.query?.sales_invoice,
                  },
                })
              "
              class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white font-medium rounded-md w-1/2 justify-center"
            >
              Back</button
            ><button
              @click="submit(total, 'receipt')"
              :disabled="
                cartItems.length === 0 ||
                (getStatusInvoice != 'Invoice' &&
                  getBusinessType != 'Fast Food')
              "
              class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-primary text-white font-medium rounded-md w-full justify-center"
            >
              Receipt
            </button>
          </div>
        </div>
      </div>
      <KeypadDialogComponent
        :show="showKeypad"
        :handleKeypadDialogOk="handleKeypadDialogOk"
        :keyKeypad="keyKeypad"
        :onToggle="() => (this.showKeypad = false)"
      />
      <PrintComponent v-if="keySubmit == 'invoice'">
        <ContentInvoiceRestaurantComponent />
      </PrintComponent>
      <PrintComponent v-if="keySubmit == 'receipt'">
        <ContentReceiptComponent />
      </PrintComponent>
      <ModalComponent
        title="Enter remarks"
        :isModalOpen="isModalOpenRemarks"
        :onToggle="() => handleCloseRemarks()"
        :width="500"
      >
        <div class="w-full">
          <div class="relative rounded-md mt-1">
            <input
              v-model="textRemarks"
              type="text"
              class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
              placeholder="Enter remarks"
            />
          </div>
          <div class="flex justify-end pt-2 lg:pt-4">
            <button
              @click="handleSaveRemarks"
              class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
            >
              Save
            </button>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        title="Select customer"
        :isModalOpen="isModalOpenCutomer"
        :onToggle="() => (isModalOpenCutomer = false)"
        :width="500"
      >
        <div class="w-full">
          <multiselect
            v-model="selectedCustomer"
            :options="customers"
            placeholder=""
            label="name"
            track-by="name"
            :allow-empty="false"
            deselect-label="Can't remove this value"
          />
        </div>
      </ModalComponent>
      <ModalComponent
        title="How many quantities?"
        :isModalOpen="isModalOpenQuantity"
        :onToggle="
          () => {
            this.isModalOpenQuantity = false;
          }
        "
        :width="500"
      >
        <KeypadComponent :handleKeypadOk="handleKeypadOk" defaultValue="1" />
      </ModalComponent>
    </div>
    <div class="block sm:hidden">
      <button
        @click="isCheckout = true"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-primary text-white font-medium w-full justify-center"
      >
        Checkout
      </button>
    </div>
    <ModalComponent
      title="Checkout"
      :isModalOpen="isCheckout"
      :onToggle="
        () => {
          this.isCheckout = false;
        }
      "
      :width="500"
    >
      <div>
        <div
          class="py-2 lg:py-4 flex space-x-4 w-full overflow-auto no-scrollbar"
        >
          <button
            v-for="item in getModeOfPayment"
            @click="this.selectedModeOfPayment = item.mode_of_payment"
            :disabled="
              getStatusInvoice != 'Invoice' && getBusinessType != 'Fast Food'
            "
            class="p-1 bg-gray-200 rounded-md dark:bg-gray-900 dark:text-gray-200 text-xs lg:text-base leading-5 lg:leading-8"
            :class="
              getStatusInvoice != 'Invoice' && getBusinessType != 'Fast Food'
                ? 'opacity-60 cursor-not-allowed '
                : selectedModeOfPayment !== item.mode_of_payment
                ? 'opacity-60 cursor-pointer '
                : 'font-semibold cursor-pointer '
            "
          >
            <p class="text-center w-20 lg:w-28 ellipsis">
              {{ item.mode_of_payment }}
            </p>
          </button>
        </div>

        <div
          class="p-2 lg:p-4 pt-0 border-dashed border-y dark:border-slate-600"
        >
          <label
            class="flex space-x-2 items-center dark:text-gray-200 text-xs lg:text-base leading-5 lg:leading-8"
            ><span>Discount</span>
            <div
              class="text-gray-400 hover:text-gray-600 cursor-pointer"
              @click="
                () => {
                  this.switchDis = !this.switchDis;
                  this.discountUSD = this.getDiscountAmount;
                  this.discountKHR = 0;
                }
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                />
              </svg>
            </div>
          </label>
          <div v-if="switchDis" class="grid grid-cols-2 gap-2 lg:gap-4">
            <div
              class="relative rounded-md shadow-sm"
              :class="
                getStatusInvoice == 'Invoice' && getBusinessType != 'Fast Food'
                  ? 'text-gray-400'
                  : 'text-gray-600 dark:text-gray-200'
              "
            >
              <InputCurrencyComponent
                prefix="$"
                v-model="discountUSD"
                :options="{
                  valueRange: { min: 0, max: getTotalAfterDiscountPer },
                }"
                :disabled="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                "
              />

              <button
                class="absolute inset-y-0 right-2 flex items-center text-gray-300"
                :class="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                    ? 'cursor-not-allowed text-gray-400 dark:text-gray-500'
                    : ''
                "
                :disabled="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                "
                @click="handleShowKeypad('discountUSD')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
                  />
                </svg>
              </button>
            </div>
            <div
              class="relative rounded-md shadow-sm"
              :class="
                getStatusInvoice == 'Invoice' && getBusinessType != 'Fast Food'
                  ? 'text-gray-400'
                  : 'text-gray-600 dark:text-gray-200'
              "
            >
              <InputCurrencyComponent
                prefix="៛"
                v-model="discountKHR"
                :options="{
                  valueRange: { min: 0, max: maxDiscountTotalKHR },
                }"
                :disabled="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                "
              />

              <button
                class="absolute inset-y-0 right-2 flex items-center text-gray-300"
                :class="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                    ? 'cursor-not-allowed text-gray-400 dark:text-gray-500'
                    : ''
                "
                :disabled="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                "
                @click="handleShowKeypad('discountKHR')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div v-else>
            <div class="relative rounded-md shadow-sm">
              <Listbox
                :disabled="
                  getStatusInvoice == 'Invoice' &&
                  getBusinessType != 'Fast Food'
                "
              >
                <div class="relative w-full">
                  <ListboxButton
                    class="relative w-full cursor-default py-2 text-sm dark:text-gray-200 rounded-lg bg-white dark:bg-slate-700 pl-3 pr-10 text-left border dark:border-gray-700 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300l"
                    :class="
                      getStatusInvoice == 'Invoice' &&
                      getBusinessType != 'Fast Food'
                        ? 'cursor-not-allowed bg-gray-300/50 text-gray-400  dark:text-gray-500 dark:bg-slate-900/50 dark:border-slate-900/50 '
                        : ''
                    "
                  >
                    <span class="block truncate h-6 pt-0.5"
                      >{{ getDiscount }}%</span
                    >
                    <span
                      v-if="getDiscount != 0"
                      @click="
                        getStatusInvoice != 'Invoice' &&
                          getBusinessType != 'Fast Food' &&
                          setDiscount(0)
                      "
                      class="cursor-pointer absolute inset-y-0 flex items-center right-8"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.47 4.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 01-1.06 1.06L12 6.31 8.78 9.53a.75.75 0 01-1.06-1.06l3.75-3.75zm-3.75 9.75a.75.75 0 011.06 0L12 17.69l3.22-3.22a.75.75 0 111.06 1.06l-3.75 3.75a.75.75 0 01-1.06 0l-3.75-3.75a.75.75 0 010-1.06z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </ListboxButton>
                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-slate-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10"
                    >
                      <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="item in getDiscountRate"
                        :key="item.value"
                        :value="item.value"
                        as="template"
                      >
                        <li
                          @click="setDiscount(item.value)"
                          :class="[
                            selected || active
                              ? 'bg-gray-100 dark:bg-slate-800'
                              : 'dark:bg-slate-700',
                            'relative cursor-default select-none py-2 pl-4 pr-4 text-lg  dark:text-gray-200',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ item.value }}%</span
                          >
                          <span
                            v-if="selected"
                            class="absolute inset-y-0 right-1.5 flex items-center text-primary"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 12.75l6 6 9-13.5"
                              />
                            </svg>
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>
        </div>
        <div>
          <div class="border-dashed border-b dark:border-slate-600">
            <div class="p-2 lg:p-4 text-xs lg:text-base leading-5 lg:leading-8">
              <div class="flex justify-between items-center dark:text-gray-200">
                <p>Subtotal</p>
                <p>
                  $ {{ $n(cartSubTotal, "decimal", "en") }} / ៛
                  {{ $n(cartSubTotal * currencyExchange, "decimal", "kh") }}
                </p>
              </div>
              <div
                v-if="true"
                class="flex justify-between items-center dark:text-gray-200"
              >
                <p>Tax {{ getTax }}%</p>
                <p>
                  $ {{ $n(getTotalAmountTaxCharge(getTax), "decimal", "en") }}
                </p>
              </div>
              <div
                v-if="true"
                class="flex justify-between items-center dark:text-gray-200"
              >
                <p>Service Charge {{ getCharge }}%</p>
                <p>
                  $
                  {{ $n(getTotalAmountTaxCharge(getCharge), "decimal", "en") }}
                </p>
              </div>
              <div class="flex justify-between items-center dark:text-gray-200">
                <p>
                  Discount {{ getDiscount }}%
                  {{
                    getDiscountAmount != 0
                      ? `+ $ ${$n(getDiscountAmount, "decimal", "en")}`
                      : ""
                  }}
                </p>
                <p>$ {{ $n(getTotalDiscountAmount, "decimal", "en") }}</p>
              </div>
            </div>
            <div class="border-dashed border-t w-full dark:border-slate-600">
              <div
                class="p-2 lg:p-4 flex justify-between items-center dark:text-gray-200 text-xs lg:text-lg leading-4 lg:leading-8"
              >
                <h1>Grand Total</h1>
                <p class="font-medium">
                  $ {{ $n(total, "decimal", "en") }} / ៛
                  {{ $n(totalKHR, "decimal", "kh") }}
                </p>
              </div>
            </div>
            <div
              class="p-2 lg:p-4 pt-0 border-dashed border-y dark:border-slate-600 bg-gray-100 dark:bg-slate-900"
            >
              <label
                for="receiveUSD"
                class="dark:text-gray-200 text-xs lg:text-base"
                >Receive</label
              >
              <div class="grid grid-cols-2 gap-2 lg:gap-4">
                <div
                  class="relative rounded-md shadow-sm"
                  :class="
                    getStatusInvoice != 'Invoice' &&
                    getBusinessType != 'Fast Food'
                      ? 'text-gray-400'
                      : 'text-gray-600 dark:text-gray-200'
                  "
                >
                  <InputCurrencyComponent
                    prefix="$"
                    v-model="receiveUSD"
                    :options="{
                      valueRange: { min: 0 },
                    }"
                    :disabled="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                    "
                  />

                  <button
                    class="absolute inset-y-0 right-2 flex items-center"
                    :class="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                        ? 'cursor-not-allowed text-gray-400 dark:text-gray-500'
                        : ''
                    "
                    @click="handleShowKeypad('receiveUSD')"
                    :disabled="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  class="relative rounded-md shadow-sm"
                  :class="
                    getStatusInvoice != 'Invoice' &&
                    getBusinessType != 'Fast Food'
                      ? 'text-gray-400'
                      : 'text-gray-600 dark:text-gray-200'
                  "
                >
                  <InputCurrencyComponent
                    prefix="៛"
                    v-model="receiveKHR"
                    :options="{
                      valueRange: { min: 0 },
                    }"
                    :disabled="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                    "
                  />

                  <button
                    class="absolute inset-y-0 right-2 flex items-center"
                    :class="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                        ? 'cursor-not-allowed text-gray-400 dark:text-gray-500'
                        : ''
                    "
                    @click="handleShowKeypad('receiveKHR')"
                    :disabled="
                      getStatusInvoice != 'Invoice' &&
                      getBusinessType != 'Fast Food'
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div
                class="p-2 lg:p-4 flex justify-between items-center text-xs lg:text-base leading-5 lg:leading-8"
              >
                <h1 class="dark:text-gray-200">Change</h1>
                <div
                  class="dark:text-gray-200 text-right font-medium text-xs lg:text-lg"
                >
                  <p>
                    $ {{ $n(change, "decimal", "en") }} / ៛
                    {{
                      $n(
                        Math.round((change * currencyExchange) / 100) * 100,
                        "decimal",
                        "kh"
                      )
                    }}
                  </p>
                  <p class="text-right">
                    $ {{ $n(changeUSD, "decimal", "en") }} + ៛
                    {{ $n(changeKHR, "decimal", "kh") }}
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="getStatusInvoice == 'Invoice'"
              class="text-xs lg:text-base p-2 border-dashed border-t dark:border-slate-600 flex justify-between"
            >
              <p
                @click="handleOpenRemarks"
                class="dark:text-gray-200 cursor-pointer hover:text-secondary hover:dark:text-primary"
              >
                + Remarks
              </p>
              <p class="dark:text-gray-200 ellipsis w-[77%] text-right">
                {{ remarks }}
              </p>
            </div>
          </div>
        </div>
        <div class="py-4">
          <div class="w-full flex space-x-2 lg:space-x-4">
            <button
              v-if="getBusinessType != 'Fast Food'"
              @click="submit(total, 'invoice')"
              class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white font-medium rounded-md w-full justify-center"
            >
              Invoice
            </button>
            <button
              v-else
              @click="
                $router.push({
                  ...Tr.i18nRoute({ name: 'menu' }),
                  query: {
                    sales_invoice: $route.query?.sales_invoice,
                  },
                })
              "
              class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white font-medium rounded-md w-1/2 justify-center"
            >
              Back</button
            ><button
              @click="submit(total, 'receipt')"
              :disabled="
                cartItems.length === 0 ||
                (getStatusInvoice != 'Invoice' &&
                  getBusinessType != 'Fast Food')
              "
              class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-primary text-white font-medium rounded-md w-full justify-center"
            >
              Receipt
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
    <SpinComponent :show="getLoading || loading" />
  </div>
</template>

<script>
import ModalComponent from "@/components/modal/ModalComponent.vue";
import TableComponent from "@/components/table/TableComponent.vue";
import PrintComponent from "@/components/print/PrintComponent.vue";
import ContentInvoiceRestaurantComponent from "@/components/print/ContentInvoiceRestaurantComponent.vue";
import ContentReceiptComponent from "@/components/print/ContentReceiptComponent.vue";
import KeypadComponent from "@/components/keypad/KeypadComponent.vue";
import KeypadDialogComponent from "@/components/keypad/KeypadDialogComponent.vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import Tr from "@/i18n/translation";
import { socket } from "@/services/socketio.service.js";
import { mapActions, mapGetters } from "vuex";
import SpinComponent from "@/components/spin/SpinComponent.vue";
const columns = [
  {
    key: "item_name",
    label: "Name",
    dataIndex: "item_name",
  },
  {
    key: "qty",
    label: "Qty",
    dataIndex: "qty",
    align: "center",
    alignHeader: "center",
  },
  {
    key: "price_list_rate",
    label: "Price",
    align: "center",
    alignHeader: "center",
  },
];

const recentTransaction = [];
import dataService from "@/services/data.service";
import { dispatchNotification } from "@/components/notification";
import { type } from "@/services/socketio.type";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";

export default {
  name: "Checkout",
  components: {
    KeypadDialogComponent,
    TableComponent,
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ModalComponent,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    PrintComponent,
    ContentInvoiceRestaurantComponent,
    ContentReceiptComponent,
    SpinComponent,
    KeypadComponent,
    InputCurrencyComponent,
  },
  data() {
    return {
      Tr,
      customers: [],
      columns,
      recentTransaction,
      maxLength: 10,
      showKeypad: false,
      keyKeypad: "",
      switchDis: false,
      loading: false,
      isModalOpenRemarks: false,
      textRemarks: "",
      isModalOpenCutomer: false,
      selectedDiscount: { value: 0 },
      keySubmit: "",
      receiveUSD: 0,
      receiveKHR: 0,
      discountUSD: 0,
      discountKHR: 0,
      maxDiscountUSD: 0,
      selectedCustomer: null,
      selectedModeOfPayment: "",
      dataSelectRows: [],
      isModalOpenQuantity: false,
      itemQty: null,
      isCheckout: false,
    };
  },

  computed: {
    ...mapGetters("restaurant", {
      remarks: "getRemarks",
      customer: "getCustomer",
      getLoading: "getLoading",
      dataTable: "getDataTable",
      cartItems: "getCartItems",
      getDiscount: "getDiscount",
      getCartTotal: "getCartTotal",
      getReceiveKHR: "getReceiveKHR",
      getReceiveUSD: "getReceiveUSD",
      cartSubTotal: "getCartSubTotal",
      getInvoiceName: "getInvoiceName",
      getStatusInvoice: "getStatusInvoice",
      getWaitingNumber: "getWaitingNumber",
      getDiscountAmount: "getDiscountAmount",
      getCartItemsDiscount: "getCartItemsDiscount",
      getDiscountPerAmount: "getDiscountPerAmount",
      getStatusItemDiscount: "getStatusItemDiscount",
      getTotalDiscountAmount: "getTotalDiscountAmount",
      getTotalAmountTaxCharge: "getTotalAmountTaxCharge",
      getTotalAfterDiscountPer: "getTotalAfterDiscountPer",
    }),
    ...mapGetters("auth", {
      getTax: "getTax",
      getCharge: "getCharge",
      getTaxAndCharge: "getTaxAndCharge",
      currencyExchange: "getCurrencyExchange",
      getModeOfPayment: "getModeOfPayment",
      getPOSProfile: "getPOSProfile",
      getBusinessType: "getBusinessType",
      getDiscountRate: "getDiscountRate",
    }),
    change() {
      let change =
        this.getReceiveUSD +
        this.getReceiveKHR / this.currencyExchange -
        this.total;
      return change >= 0 ? change : 0;
    },
    changeUSD() {
      let changeUSD =
        this.change - (this.change > 10 ? this.change % 10 : this.change % 1);
      return changeUSD >= 0 ? changeUSD : 0;
    },
    changeKHR() {
      let changeKHR =
        Math.round(
          ((this.change > 10 ? this.change % 10 : this.change % 1) *
            this.currencyExchange) /
            100
        ) * 100;

      return changeKHR >= 0 ? changeKHR : 0;
    },
    total() {
      return this.getCartTotal(
        this.getTotalAmountTaxCharge(this.getTax),
        this.getTotalAmountTaxCharge(this.getCharge)
      );
    },
    totalKHR() {
      return Math.round((this.total * this.currencyExchange) / 100) * 100;
    },
    maxDiscountTotalKHR() {
      return (
        Math.round(
          ((this.cartSubTotal -
            (Number(this.discountUSD) + this.getDiscountPerAmount)) *
            this.currencyExchange) /
            100
        ) * 100
      );
    },
    dataInvoiceSplit() {
      return this.dataTable?.invoices_split;
    },
  },
  created() {
    this.clearCart();
    this.getDataSalesInvoice(`key=${this.$route.query?.sales_invoice}`);
    this.getDataCustomers();
    this.selectedModeOfPayment = this.getModeOfPayment?.find(
      (item) => item.default == 1
    ).mode_of_payment;
  },
  watch: {
    selectedDiscount(newDiscount) {
      if (!newDiscount) this.selectedDiscount = { value: 0 };
      this.setDiscount(this.selectedDiscount.value);
    },
    getTotalDiscountAmount() {
      socket.emit("send", {
        pos_profile: this.getPOSProfile,
        type: type.refCFD,
        data: this.cartItems,
      });
    },
    cartItems() {
      socket.emit("send", {
        pos_profile: this.getPOSProfile,
        type: type.refCFD,
        data: this.cartItems,
      });
    },
    "$route.query.sales_invoice"() {
      let exit = this.$route.query?.sales_invoice;
      this.keySubmit = "";
      this.receiveUSD = 0;
      this.receiveKHR = 0;
      this.clearCart();
      if (exit) this.getDataSalesInvoice(`key=${exit}`);
      this.dataSelectRows = [];
    },
    getModeOfPayment(newData) {
      this.selectedModeOfPayment = newData?.find(
        (item) => item.default == 1
      ).mode_of_payment;
    },
    receiveUSD(newData) {
      this.setReceiveUSD(Number(newData));
    },
    receiveKHR(newData) {
      this.setReceiveKHR(Number(newData));
    },
    discountUSD(newData) {
      if (Number(newData) <= this.getTotalAfterDiscountPer) {
        this.setDiscountAmount(Number(newData));
      } else {
        this.setDiscountAmount(this.getTotalAfterDiscountPer);
        this.discountUSD = this.getTotalAfterDiscountPer;
      }
      this.discountKHR = 0;
    },
    discountKHR(newData) {
      if (Number(newData) <= this.maxDiscountTotalKHR) {
        this.setDiscountAmount(
          Number(newData) / this.currencyExchange + this.discountUSD
        );
      } else
        this.setDiscountAmount(
          this.maxDiscountTotalKHR / this.currencyExchange + this.discountUSD
        );
    },
    selectedCustomer(newData) {
      if (newData) this.setCustomer(newData);
    },
  },
  mounted() {
    socket.on(type.refP, (message) => {
      if (
        message.pos_profile === this.getPOSProfile &&
        message.table === this.dataTable?.name
      ) {
        this.clearCart();
        this.getDataSalesInvoice(`key=${this.$route.query?.sales_invoice}`);
      }
    });
  },
  methods: {
    handleKeypadOk(value) {
      if (Number(value) != 0) {
        this.dataSelectRows.push({
          ...this.itemQty,
          qty:
            this.itemQty.qty >= Number(value)
              ? Number(value)
              : this.itemQty.qty,
        });
        this.itemQty = null;
        this.isModalOpenQuantity = false;
      } else
        dispatchNotification({
          content: "Quantities must be at least 1!",
          type: "warning",
        });
    },
    onSelectItem(event) {
      let value = event.target._value;
      let exit = this.dataSelectRows.find((item) => item.name == value.name);
      if (!exit) {
        if (value.qty > 1) {
          this.isModalOpenQuantity = true;
          this.itemQty = value;
        } else this.dataSelectRows.push(value);
      } else {
        this.dataSelectRows = this.dataSelectRows.filter(
          (item) => item.name != value.name
        );
      }
    },
    compareArrays(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i].qty !== arr2[i].qty) {
          return false;
        }
      }

      return true;
    },
    handleSplit() {
      this.loading = true;
      if (this.dataSelectRows.length != 0) {
        if (!this.compareArrays(this.cartItems, this.dataSelectRows)) {
          let data = {
            sales_invoice: this.$route.query?.sales_invoice,
            table: this.dataTable?.name,
            items: this.dataSelectRows.map((item) => {
              return {
                item_code: item.item_code,
                item_name: item.item_name,
                qty: item.qty,
                discount_percentage: item.discount,
                take_note: item.take_note,
                name: item.name,
                tag: item.tag,
                uom: item.uom,
              };
            }),
          };
          dataService.post("save_sales_invoice_split", data).then((res) => {
            this.clearCart();
            this.getDataSalesInvoice(`key=${this.$route.query?.sales_invoice}`);
            dispatchNotification({
              content: "Your action was successfully splited",
              type: "success",
            });
            this.dataSelectRows = [];
            this.loading = false;
          });
        } else {
          dispatchNotification({
            content: "Cannot split!",
            type: "warning",
          });
          this.loading = false;
        }
      } else {
        dispatchNotification({
          content: "Please select item!",
          type: "warning",
        });
        this.loading = false;
      }
    },
    handleUnSplit() {
      this.loading = true;
      if (this.dataInvoiceSplit?.length !== 1)
        dataService
          .post("unsplit_sales_invoice", {
            table: this.dataTable.name,
            invoice: this.dataTable.sales_invoice,
            invoices: this.dataTable.invoices_split.map(
              (invoice) => invoice.name
            ),
          })
          .then((res) => {
            this.clearCart();
            this.getDataSalesInvoice(`key=${res.data.message}`);
            this.$router.push({
              ...Tr.i18nRoute({ name: "checkout" }),
              query: {
                sales_invoice: res.data.message,
              },
            });
            dispatchNotification({
              content: "Your action was successfully splited",
              type: "success",
            });

            this.loading = false;
          });
      else {
        dispatchNotification({
          content: "Connot revert split!",
          type: "warning",
        });
        this.loading = false;
      }
    },
    handleOpenRemarks() {
      this.isModalOpenRemarks = true;
      this.textRemarks = this.remarks;
    },
    handleCloseRemarks() {
      this.isModalOpenRemarks = false;
      this.textRemarks = "";
    },
    handleSaveRemarks() {
      this.setRemarks(this.textRemarks);
      this.isModalOpenRemarks = false;
    },
    submit(total, key) {
      this.keySubmit = key;
      if (key == "receipt")
        if (
          this.getReceiveUSD + this.getReceiveKHR / this.currencyExchange >=
          total
        ) {
          this.submitCartItem({
            grandTotal: total,
            key: key,
            taxes: this.getTaxAndCharge,
            mode_of_payment: this.selectedModeOfPayment,
            pos_profile: this.getPOSProfile,
          });
          this.isCheckout = false;
        } else
          dispatchNotification({
            content: "Partially receive is not enough!",
            type: "warning",
          });
      else {
        this.submitCartItem({
          grandTotal: total,
          key: key,
          taxes: this.getTaxAndCharge,
          mode_of_payment: this.selectedModeOfPayment,
          pos_profile: this.getPOSProfile,
        });
        this.isCheckout = false;
      }
      this.dataSelectRows = [];
    },
    getDataCustomers() {
      dataService.get("get_customers").then((res) => {
        this.customers = res.data.message;
      });
    },
    ...mapActions("restaurant", {
      setRemarks: "setRemarks",
      setReceiveUSD: "setReceiveUSD",
      setReceiveKHR: "setReceiveKHR",
      setCustomer: "setCustomer",
      setDiscount: "setDiscount",
      setDiscountAmount: "setDiscountAmount",
      clearCustomer: "clearCustomer",
      submitCartItem: "submitCartItem",
      clearCart: "clearCart",
      onChageDiscountItem: "onChageDiscountItem",
      getDataSalesInvoice: "getDataSalesInvoice",
    }),

    handleGoToMenu() {
      if (this.getStatusInvoice != "Invoice")
        this.$router.push({
          ...Tr.i18nRoute({ name: "menu" }),
          query: {
            name: this.dataTable?.name,
            sales_invoice: this.$route.query?.sales_invoice,
          },
        });
    },
    handleShowKeypad(key) {
      this.keyKeypad = key;
      this.showKeypad = true;
    },
    handleKeypadDialogOk(value, key) {
      if (key == "receiveUSD") {
        this.receiveUSD = value;
      } else if (key == "receiveKHR") {
        this.receiveKHR = value;
      } else if (key == "discountUSD") {
        this.discountUSD = value;
      } else if (key == "discountKHR") {
        this.discountKHR = value;
      }
      this.showKeypad = false;
    },
  },
};
</script>
