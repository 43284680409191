<template>
  <div
    id="infinite-list"
    class="w-full px-5 overflow-x-auto h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]"
  >
    <div class="py-4 flex justify-between items-center w-full">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold  dark:text-gray-100">
          Live Comment
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'live-customer' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >Live Customer</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <div
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                >
                  {{ id }}
                </div>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="flex items-center space-x-2">
        <Menu as="div" class="relative">
          <div>
            <MenuButton
              v-if="dataSelectedItem.length > 0"
              class="flex items-center text-sm py-2 lg:py-[10px] pl-4 pr-3 bg-secondary text-white font-medium rounded-md justify-center"
            >
              Actions<svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 ml-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                />
              </svg>
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-gray-500 rounded-md bg-white dark:bg-slate-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <button
                    @click="handleDeleteItem"
                    class="text-left text-gray-800 dark:text-gray-100 block px-4 py-2 text-md font-bold w-full"
                  >
                    Delete
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
    <div
      class=" mb-3 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-2 my-3"
    >
      <div class="relative">
        <input
          type="text"
          @input="handleSearch"
          class="h-full w-full pl-8 py-1.5 lg:text-sm border pr-2 text-gray-900 dark:text-gray-100 bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-md focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
          placeholder="Search..."
        /><span class="absolute py-2.5 px-2 top-0 left-0 text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </span>
      </div>
      <multiselect
        v-model="dataSelectedUser"
        :options="userData"
        placeholder=""
        label="full_name"
        track-by="full_name"
      ></multiselect>
    </div>
    <div
      class="inline-block overflow-x-scroll w-full sm:w-[calc(100vw_-_78px)] lg:w-[calc(100vw_-_111px)]"
    >
      <TableComponent
        :columns="columns"
        :items="renderItems(items)"
        scrollX="35rem"
        :onClickRow="onClickShowOption"
        :onSelectRow="(data) => (dataSelectedItem = data)"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'created_time'">
            {{ $d(record.created_time, "long") }}
          </template>
          <template v-if="column.key === 'action'"> </template>
        </template>
      </TableComponent>
    </div>
    <ModalComponent
      :title="dataSelectedRow?.full_name + ': ' + dataSelectedRow?.message"
      :isModalOpen="isShowOption"
      :onToggle="handleCancelShowOption"
      :width="578"
      :freeSpaceClose="true"
    >
      <div>
        <div class="my-3">
          <div class="flex items-center justify-between">
            <h4
              class="text-sm lg:text-base font-medium text-gray-900 dark:text-gray-100"
            >
              Code
            </h4>
            <span
              class="ml-2 cursor-pointer text-secondary dark:text-primary"
              @click="isAddItemCode = true"
              >+ Add</span
            >
          </div>
          <RadioGroup v-model="selectedCode" class="mt-2">
            <RadioGroupLabel class="sr-only">Choose a code</RadioGroupLabel>
            <div class="grid grid-cols-5 gap-4">
              <RadioGroupOption
                as="template"
                v-for="code in dataCodeProduct"
                :key="code"
                :value="code"
                v-slot="{ active, checked }"
              >
                <div
                  class="cursor-pointer bg-white text-gray-900 dark:text-gray-100 dark:bg-slate-900/60"
                  :class="[
                    active ? 'ring-1 ring-secondary dark:ring-primary' : '',
                    'group relative flex items-center justify-center rounded-md border dark:border-transparent py-2 lg:py-[10px] text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1',
                  ]"
                >
                  <RadioGroupLabel as="span">{{ code }}</RadioGroupLabel>
                  <span
                    :class="[
                      active ? 'border' : 'border-2',
                      checked
                        ? 'border-secondary dark:border-primary'
                        : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-md',
                    ]"
                    aria-hidden="true"
                  />
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>
        <!-- <div class="my-3">
          <div class="flex items-center justify-between">
            <h4
              class="text-sm lg:text-base font-medium text-gray-900 dark:text-gray-100"
            >
              Size
            </h4>
          </div>
          <RadioGroup v-model="selectedSize" class="mt-2">
            <RadioGroupLabel class="sr-only">Choose a size</RadioGroupLabel>
            <div class="grid grid-cols-5 gap-4">
              <RadioGroupOption
                as="template"
                v-for="size in ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']"
                :key="size"
                :value="size"
                v-slot="{ active, checked }"
              >
                <div
                  class="cursor-pointer bg-white text-gray-900 dark:text-gray-100 dark:bg-slate-900/60"
                  :class="[
                    active ? 'ring-1 ring-secondary dark:ring-primary' : '',
                    'group relative flex items-center justify-center rounded-md border dark:border-transparent py-2 lg:py-[10px] text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1',
                  ]"
                >
                  <RadioGroupLabel as="span">{{ size }}</RadioGroupLabel>
                  <span
                    :class="[
                      active ? 'border' : 'border-2',
                      checked
                        ? 'border-secondary dark:border-primary'
                        : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-md',
                    ]"
                    aria-hidden="true"
                  />
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div> -->
        <div class="my-3">
          <div class="flex items-center justify-between">
            <h4
              class="text-sm lg:text-base font-medium text-gray-900 dark:text-gray-100"
            >
              Qty
            </h4>
          </div>
          <RadioGroup v-model="selectedQty" class="mt-2">
            <RadioGroupLabel class="sr-only">Choose a qty</RadioGroupLabel>
            <div class="grid grid-cols-5 gap-4">
              <RadioGroupOption
                as="template"
                v-for="qty in dataQty"
                :key="qty"
                :value="qty"
                v-slot="{ active, checked }"
              >
                <div
                  class="cursor-pointer bg-white text-gray-900 dark:text-gray-100 dark:bg-slate-900/60"
                  :class="[
                    active ? 'ring-1 ring-secondary dark:ring-primary' : '',
                    'group relative flex items-center justify-center rounded-md border dark:border-transparent py-2 lg:py-[10px] text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1',
                  ]"
                >
                  <RadioGroupLabel as="span">{{ qty }}</RadioGroupLabel>
                  <span
                    :class="[
                      active ? 'border' : 'border-2',
                      checked
                        ? 'border-secondary dark:border-primary'
                        : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-md',
                    ]"
                    aria-hidden="true"
                  />
                </div>
              </RadioGroupOption>
              <div class="col-span-3">
                <label>
                  <input
                    name="qty"
                    type="number"
                    v-model="selectedQty"
                    placeholder="Enter qty"
                    @focus="selectInput"
                    class="w-full px-4 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-900/60 dark:border-slate-900/60 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
                /></label>
              </div>
            </div>
          </RadioGroup>
        </div>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          @click="handleCancelShowOption"
          class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
        >
          Cancel
        </button>
        <button
          @click="handlePrint"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Print
        </button>
      </div>
    </ModalComponent>
    <ModalComponent
      title="Confirm"
      :isModalOpen="isDeleteMessage"
      :onToggle="() => (this.isDeleteMessage = false)"
      :width="378"
      :freeSpaceClose="true"
    >
      <div class="p-2">
        <h1 class="dark:text-gray-100">Do you want to delete customer?</h1>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          @click="isDeleteMessage = false"
          class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
        >
          Cancel
        </button>
        <button
          @click="handleYesDeleteItem"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Yes
        </button>
      </div>
    </ModalComponent>
    <ModalComponent
      title="New Code"
      :isModalOpen="isAddItemCode"
      :onToggle="handleCancelItemCode"
      :width="578"
      :freeSpaceClose="true"
    >
      <div class="p-2">
        <div>
          <label
            for="item_group"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Product Code</label
          >
          <div class="mt-1">
            <input
              name="item_group"
              type="text"
              v-model="itemCode"
              class="w-full px-4 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          @click="handleCancelItemCode"
          class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
        >
          Cancel
        </button>
        <button
          @click="handleSaveItemCode"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Save
        </button>
      </div>
    </ModalComponent>
    <PrintComponent>
      <div v-if="dataSelectedRow" class="w-72">
        <p class="font-semibold text-base">
          1 ┆ {{ dataSelectedRow.full_name }} ┆ {{ countQty }}/{{ selectedQty }}
        </p>
        <p>{{ dataSelectedRow.message }} ┆ {{ selectedCode }}</p>
        <p>{{ $d(dataSelectedRow.created_time, "long") }}</p>
      </div>
    </PrintComponent>
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
const columns = [
  {
    key: "no",
    label: "#",
    dataIndex: "no",
    width: 20,
    align: "center",
  },
  {
    key: "full_name",
    label: "Name",
    dataIndex: "full_name",
  },
  {
    key: "message",
    label: "Comments",
    dataIndex: "message",
  },
  {
    key: "created_time",
    label: "Date/Time",
    width: 220,
  },
];
const dataQty = [1, 2, 3, 4, 5, 6, 7];
import Multiselect from "vue-multiselect";
import TableComponent from "@/components/table/TableComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import PrintComponent from "@/components/print/PrintComponent.vue";
import dataService from "@/services/data.service";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";
import imageEmpty from "@/assets/image/empty.jpg";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { renderItems } from "@/utils/globleFunction";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import NavBarView from "../NavBarView.vue";
import { print } from "@/utils/globleFunction";

export default {
  name: "Live Comment",
  components: {
    TableComponent,
    SpinComponent,
    ModalComponent,
    PrintComponent,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Multiselect,
    NavBarView,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },
  data() {
    return {
      Tr,
      renderItems,
      columns,
      limit: 20,
      page: 1,
      items: [],
      imageEmpty,
      loading: false,
      dataSelectedItem: [],
      isDeleteMessage: false,
      dataCustomer: null,
      es: null,
      isShowOption: false,
      isLive: false,
      dataSelectedRow: null,
      selectedQty: 1,
      selectedSize: "",
      selectedCode: "",
      dataCodeProduct: [],
      dataQty,
      countQty: 0,
      isAddItemCode: false,
      itemCode: "",
      dataSelectedUser: null,
      userData: [],
    };
  },
  watch: {
    selectedQty(newQty) {
      if (newQty == "") this.selectedQty = 1;
    },
    dataSelectedUser(newUser, oldUser) {
      if (newUser) {
        this.items = [];
        this.getUserComment(newUser);
      } else {
        this.items = [];
        this.getItems();
      }
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   if (!this.isLive) {
  //     next();
  //   } else if (this.confirmLeave()) {
  //     this.es.close();
  //     next();}
  //   else {
  //     next(false);
  //   }
  // },
  created() {
    this.es?.close();
    this.getDataCustomer();
    this.getProductCode();
    this.getUserName();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", (e) => {
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        this.totalItems > this.items.length
      ) {
        this.page++;
        this.getItems();
      }
    });
  },

  methods: {
    nameWithLang({ name, language }) {
      return `${name} — [${language}]`;
    },
    getProductCode() {
      this.loading = false;
      dataService.get("get_product_code").then((res) => {
        this.dataCodeProduct = res.data.message;
        this.loading = false;
      });
    },
    handlePrint() {
      if (this.selectedCode) {
        print();
      } else {
        dispatchNotification({
          content: "Product code is not empty!",
          type: "warning",
        });
      }
    },
    // confirmLeave() {
    //   return window.confirm(
    //     "Are you sure you want to leave? Live will be turned off."
    //   );
    // },
    selectInput(event) {
      event.target.select();
    },
    handleCancelShowOption() {
      this.isShowOption = false;
      this.selectedCode = "";
      this.selectedQty = 1;
    },
    async onClickShowOption(e) {
      this.dataSelectedRow = await e;
      for (let i = 0; i < this.dataCodeProduct.length; i++) {
        const lowercasedText = this.dataSelectedRow.message.toLowerCase();
        const lowercasedWordToFind = this.dataCodeProduct[i].toLowerCase();
        const isWordFound = lowercasedText.includes(lowercasedWordToFind);
        if (isWordFound) {
          this.selectedCode = this.dataCodeProduct[i];
          this.selectedQty = this.findQtyNumber(
            this.cutWordFromString(lowercasedText, lowercasedWordToFind)
          );
        }
      }
      this.isShowOption = true;
    },
    cutWordFromString(inputString, wordToRemove) {
      var regex = new RegExp("\\b" + wordToRemove + "\\b", "g");
      var resultString = inputString.replace(regex, "");
      return resultString;
    },
    findQtyNumber(text) {
      for (let i = 0; i < this.dataQty.length; i++) {
        const lowercasedText = text.toLowerCase();
        const lowercasedWordToFind = this.dataQty[i].toString().toLowerCase();
        const isWordFound = lowercasedText.includes(lowercasedWordToFind);
        if (isWordFound) {
          return this.dataQty[i];
        }
      }
    },
    getComment() {
      this.es = new EventSource(
        `https://streaming-graph.facebook.com/${this.dataCustomer.live_id}/live_comments?access_token=${this.dataCustomer.token}&comment_rate=one_per_two_seconds&fields=from{name,id,first_name,last_name},message,created_time`
      );
      this.es.addEventListener("message", async (event) => {
        let data = JSON.parse(event.data);
        let newData = {
          full_name: data?.from?.name,
          message: data.message,
          created_time: data.created_time,
          customer: this.dataCustomer.name,
        };
        console.log(newData);
        if (data.message != "") {
          await dataService.post("create_live_comment", newData);
          this.items.splice(0, 0, newData);
          this.items = this.items.slice(0, this.limit);
          this.page = 1;
        }
      });
    },
    getDataCustomer() {
      this.loading = true;
      dataService
        .get("get_data_live_customer", `key=${this.id}`)
        .then((res) => {
          this.dataCustomer = res.data.message;
          this.getItems();
          this.getComment();
          this.loading = false;
        });
    },
    getItems() {
      this.loading = true;
      dataService
        .get(
          "get_data_live_comment",
          `limit=${this.limit}&&page=${this.page}&&customer=${this.dataCustomer.name}`
        )
        .then((res) => {
          let { items, total } = res.data.message;
          this.items.push(...items);
          this.totalItems = total;
          this.loading = false;
        });
    },
    handleDeleteItem() {
      this.isDeleteMessage = true;
    },
    handleYesDeleteItem() {
      this.loading = true;
      dataService
        .post("delete_data_live_comment", {
          items: this.dataSelectedItem.map((item) => item.name),
        })
        .then((res) => {
          dispatchNotification({
            content: "Detete customer successfully",
            type: "success",
          });
          this.isDeleteMessage = false;
          this.loading = false;
          this.dataSelectedItem = [];
          this.items = [];
          this.page = 1;
          this.getItems();
        });
    },
    handleCancelItemCode() {
      this.isAddItemCode = false;
      this.itemCode = "";
    },
    handleSaveItemCode() {
      dataService
        .post("create_product_code", {
          item_code: this.itemCode,
          live_customer: this.id,
        })
        .then((res) => {
          dispatchNotification({
            content: "Create Item Code successfully",
            type: "success",
          });
          this.getProductCode();
          this.handleCancelItemCode();
        });
    },
    getUserName() {
      this.loading = false;
      dataService.get("get_live_user_name").then((res) => {
        this.userData = res.data.message;
        this.loading = false;
      });
    },
    getUserComment(valeu) {
      this.loading = false;
      dataService
        .get("get_live_comment_by_user", `key=${valeu.full_name}`)
        .then((res) => {
          this.items = res.data.message;
          this.loading = false;
        });
    },
    handleSearch(event) {
      let query = event.target.value;
      if (query != "") {
        this.loading = true;
        dataService
          .get("get_live_comment_search", `query=${query}`)
          .then((res) => {
            this.items = [];
            this.items = res.data.message.items;
            this.loading = false;
          });
      } else {
        this.items = [];
        this.page = 1;
        this.getItems();
      }
    },
  },
};
</script>
