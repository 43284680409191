import { baseAPI, basePath } from "./baseAPI";

class dataService {
  get(url, param = "") {
    return baseAPI.get(basePath + url + "?" + param);
  }
  getAllPaginate(url, query, limit, page, status=null) {
    return baseAPI.get(basePath + url, {
      params: { query: query, limit: limit, page: page, status: status },
    });
  }
  getPaginate(url, key, query, limit, page) {
    return baseAPI.get(basePath + url, {
      params: { key: key, query: query, limit: limit, page: page },
    });
  }
  post(url, payload) {
    return baseAPI.post(basePath + url, payload);
  }
  postFile(url, payload) {
    return baseAPI.post(basePath + url, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  delete(url, payload) {
    return baseAPI.delete(basePath + url, {
      data: payload,
    });
  }
  getPaginatePublic(url, key, query, limit, page, pos_profile, secret) {
    return baseAPI.get(basePath + url, {
      params: {
        key: key,
        query: query,
        limit: limit,
        page: page,
        pos_profile: pos_profile,
        secret: secret,
      },
    });
  }
  getPublic(url, param = "") {
    return baseAPI.get(basePath + url + "?" + param);
  }
  postPublic(url, payload) {
    return baseAPI.post(basePath + url, payload);
  }
}

export default new dataService();
