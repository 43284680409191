<template>
  <div>
    <div>
      <div class="mb-2">
        <button
          class="inline-flex items-center py-1 lg:py-2 px-4 bg-secondary text-white text-1xs lg:text-sm font-medium rounded-md justify-center"
          @click="() => (this.isModalOpenTable = !this.isModalOpenTable)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6v12m6-6H6"
            />
          </svg>

          Add
        </button>
      </div>
      <TableComponent :columns="columnReserved" :items="itemReserved" />
    </div>
    <ModalComponent
      :isModalOpen="isModalOpenTable"
      title=""
      :onToggle="() => (this.isModalOpenTable = !this.isModalOpenTable)"
      :width="678"
    >
      <form>
        <div class="grid gap-x-6 gap-y-8 grid-cols-6">
          <div class="col-span-3">
            <label for="first-name" class="block leading-6 text-gray-900 dark:text-gray-200"
              >Table</label
            >
            <div class="mt-2">
              <div class="w-full">
                <Listbox v-model="selectedPerson">
                  <div class="relative mt-1">
                    <ListboxButton
                      class="relative w-full cursor-default py-2 text-sm dark:text-gray-200 rounded-lg bg-white dark:bg-slate-700 pl-3 pr-10 text-left border dark:border-gray-700 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300l"
                    >
                      <span class="block truncate h-6 pt-0.5">{{
                        selectedPerson?.name
                      }}</span>
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition duration-100 ease-in"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-slate-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10"
                      >
                        <ListboxOption
                          v-slot="{ active, selected }"
                          v-for="item in dataTables"
                          :key="item.name"
                          :value="item"
                          as="template"
                        >
                          <li
                          :class="[
                        active ? 'bg-gray-100 dark:bg-slate-800' : 'dark:bg-slate-700',
                        'relative cursor-default select-none py-2 pl-4 pr-4 text-lg dark:text-gray-200',
                      ]"
                          >
                            <span
                              :class="[
                                selected ? 'font-medium' : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ item.name }}</span
                            >
                            <span
                              v-if="selected"
                              class="absolute inset-y-0 right-1.5 flex items-center text-primary"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 12.75l6 6 9-13.5"
                                />
                              </svg>
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>
          <div class="co-span-3"></div>
          <div class="col-span-3">
            <label for="last-name" class="block leading-6 text-gray-900 dark:text-gray-200"
              >Customer Name</label
            >
            <div class="mt-2">
              <div class="mt-2">
                <input
                  type="text"
                  name="price"
                  id="price"
                  class="w-full px-4 py-2 text-gray-900 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
                />
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <label for="email" class="block leading-6 text-gray-900 dark:text-gray-200"
              >Contact Number</label
            >
            <div class="mt-2">
              <div class="mt-2">
                <input
                  type="text"
                  name="price"
                  id="price"
                  class="w-full px-4 py-2 text-gray-900 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
                />
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <label for="last-name" class="block leading-6 text-gray-900 dark:text-gray-200"
              >Deposit</label
            >
            <div class="mt-2">
              <div class="mt-2">
                <input
                  type="text"
                  name="price"
                  id="price"
                  class="w-full px-4 py-2 text-gray-900 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
                />
              </div>
            </div>
          </div>
          <div class="col-span-3">
            
          </div>
          <div class="col-span-6">
            <label for="last-name" class="block leading-6 text-gray-900 dark:text-gray-200"
              >Description</label
            >
            <div class="mt-2">
              <div class="mt-2">
                <textarea
                  type="text"
                  name="price"
                  id="price"
                  class="w-full px-4 py-2 text-gray-900 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-4">
          <div class="space-x-4">
            <button
              class="inline-flex items-center py-[10px] px-4 bg-secondary text-white rounded-md justify-center"
              @click="this.$router.push('/menu')"
            >
              Next Order
            </button>
            <button
              class="inline-flex items-center py-[10px] px-4 bg-primary text-white rounded-md justify-center"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </ModalComponent>
  </div>
</template>

<script>
const columnReserved = [
  { key: 'id', label: 'ID' },
  { key: 'table', label: 'Table' },
  { key: 'customer_name', label: 'Customer' },
  { key: 'contact_number', label: 'Contact' },
  { key: 'deposit', label: 'Deposit' },
  { key: 'description', label: 'Description' },
  { key: 'reserved_item', label: 'Time' },
  { key: 'actions', label: 'Actions', width: 100 },
]
const itemReserved = [
  {
    id: 1,
    table: 'TB001',
    customer_name: 'Customer',
    contact_number: '0987654321',
    deposit: '50$',
    description: 'This is Component A',
    reserved_item: '18/04/2023 9:00AM',
  },
]
const dataTables = [
  {
    name: 'A01',
    status: 'opened',
  },
  {
    name: 'A02',
    status: 'occupied',
  },
  {
    name: 'A03',
    status: 'invoice',
  },
  {
    name: 'A04',
    status: 'occupied',
  },
  {
    name: 'A05',
    status: 'Reserved',
  },
  {
    name: 'A05',
    status: 'reserved',
  },
  {
    name: 'A01',
    status: 'opened',
  },
  {
    name: 'A01',
    status: 'opened',
  },
  {
    name: 'A01',
    status: 'opened',
  },
  {
    name: 'A01',
    status: 'opened',
  },
  {
    name: 'A01',
    status: 'opened',
  },
  {
    name: 'A01',
    status: 'opened',
  },

  {
    name: 'A02',
    status: 'occupied',
  },
  {
    name: 'A03',
    status: 'invoice',
  },
  {
    name: 'A04',
    status: 'occupied',
  },
  {
    name: 'A05',
    status: 'Reserved',
  },
  {
    name: 'A05',
    status: 'reserved',
  },
  {
    name: 'A01',
    status: 'opened',
  },
  {
    name: 'A02',
    status: 'occupied',
  },
  {
    name: 'A03',
    status: 'invoice',
  },
  {
    name: 'A04',
    status: 'occupied',
  },
  {
    name: 'A05',
    status: 'Reserved',
  },
  {
    name: 'A05',
    status: 'reserved',
  },
  {
    name: 'A01',
    status: 'opened',
  },
  {
    name: 'A02',
    status: 'occupied',
  },
  {
    name: 'A03',
    status: 'invoice',
  },
  {
    name: 'A04',
    status: 'occupied',
  },
  {
    name: 'A05',
    status: 'Reserved',
  },
  {
    name: 'A05',
    status: 'reserved',
  },
]
import ModalComponent from '@/components/modal/ModalComponent.vue'
import TableComponent from '@/components/table/TableComponent.vue'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
export default {
  name: 'Reserved',
  components: {
    ModalComponent,
    TableComponent,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  },
  data() {
    return {
      date: null,
      columnReserved,
      dataTables,
      itemReserved,
      isModalOpenTable: false,
    }
  },
}
</script>
