<template>
  <div id="modal-print">
    <div id="print">
      <slot></slot>
    </div>
  </div>
</template>

<style>
@media screen {
  #print {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
  }
  #print {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
