import dataService from "@/services/data.service";
import { dispatchNotification } from "@/components/notification";
import { v4 as uuidv4 } from "uuid";
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";

// state
const state = {
  restaurant: {
    name: "",
    items: [],
    number_customer: 1,
    discount: 0,
    status_save: false,
  },
};

// getters
const getters = {
  getCartItems: (state, getters, rootState) => {
    return state.restaurant.items.map((item) => {
      return {
        ...item,
        amount:
          item.price_exchange - item.price_exchange * (item.discount / 100),
      };
    });
  },
  getStatusSave: (state) => {
    return state.restaurant.status_save;
  },
  getCartTotalQty: (state, getters) => {
    return getters.getCartItems.reduce((total, item) => {
      return total + item.qty;
    }, 0);
  },
  getCartSubTotal: (state, getters) => {
    return getters.getCartItems.reduce((total, item) => {
      return total + item.amount * item.qty;
    }, 0);
  },
  getNumberCustomer: (state, getters) => {
    return state.restaurant.number_customer;
  },
  getCartSubTotalOrg: (state, getters) => {
    return getters.getCartItems.reduce((total, item) => {
      return total + item.price_exchange * item.qty;
    }, 0);
  },
  getTotalAmountTaxCharge:
    (state, getters) =>
    (value = 0) => {
      let x = getters.getCartSubTotalOrg * (value / 100);
      return x >= 0 ? x : 0;
    },
  getCartTotal:
    (state, getters) =>
    (tax = 0, charge = 0) => {
      return (
        Number(getters.getCartSubTotal.toFixed(2)) +
        Number(tax.toFixed(2)) +
        Number(charge.toFixed(2))
      );
    },
};

// actions
const actions = {
  setOptionByCartItem({ state, commit, rootState }, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      commit("setOptionByCartItem", item);
    }
  },
  getDataSalesInvoice({ commit }, data) {
    dataService.getPublic("get_sales_invoice_customer", data).then(
      (res) => {
        const item = res.data.message.data;
        if (item) {
          item.items = item.items.map((i) => {
            return {
              ...i,
              id: uuidv4(),
            };
          });
          commit("setItemToCart", item);
        } else
          commit("setItemToCart", {
            name: "",
            items: [],
            number_customer: 1,
            discount: 0,
            status_save: false,
          });
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  setNumberCustomer({ state, commit }, numberCustomer) {
    commit("setNumberCustomer", numberCustomer);
  },
  setCartItem(
    { state, commit, getters },
    { table, total, pos_profile, secret }
  ) {
    commit("setSatusSave");
    let data = {
      name: state.restaurant.name,
      pos_profile: pos_profile,
      secret: secret,
      table: table,
      number_customer: getters.getNumberCustomer,
      total: total,
      total_qty: getters.getCartTotalQty,
      items: state.restaurant.items.map((item) => {
        return {
          item_code: item.item_code,
          item_name: item.item_name,
          qty: item.qty,
          discount_percentage: item.discount,
          take_note: item.take_note,
          name: item.name,
          tag: item.tag,
          uom: item.uom,
        };
      }),
    };
    dataService.postPublic("save_sales_invoice_customer", data).then(
     (res) => {
        const item = res.data.message;
        dispatchNotification({
          content: "Your order successfully!",
          type: "success",
        });

        let serviceAdd = item?.items_tag;
        socket.emit("send", {
          data: serviceAdd,
          type: type.SS,
          pos_profile: pos_profile,
        });

        socket.emit("send", {
          pos_profile: pos_profile,
          type: type.refM,
          table: table,
        });
        socket.emit("send", {
          pos_profile: pos_profile,
          type: type.refT,
        });
        socket.emit("send", {
          pos_profile: pos_profile,
          type: type.refCS,
          table: table,
        });
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  addItemToCart({ state, commit }, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (!cartItem) {
      commit("addItemToCart", item);
    } else {
      commit("incrementqtyByCartItem", item);
    }
    dispatchNotification({
      content: "Added to restaurant successfully!",
      type: "success",
    });
  },
  removeItemFromCart({ state, commit, rootState }, item) {
    commit("removeItemFromCart", item);
  },
  clearItemFromCart({ commit }) {
    commit("clearItemFromCart");
  },
  clearCart({ commit }) {
    commit("clearCart");
  },
  incrementqtyByCartItem({ state, commit, rootState }, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      commit("incrementqtyByCartItem", item);
    }
  },
  decrementqtyByCartItem({ state, commit, rootState }, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      commit("decrementqtyByCartItem", item);
    }
  },
};

// mutations
const mutations = {
  setSatusSave(state) {
    state.restaurant.status_save = true;
  },
  setItemToCart(state, data) {
    state.restaurant = data;
    state.restaurant.status_save = false;
  },
  setNumberCustomer(state, numberCustomer) {
    state.restaurant.number_customer = numberCustomer;
  },
  addItemToCart(state, item) {
    state.restaurant.items.splice(0, 0, {
      ...item,
      qty: 1,
      discount: 0,
      take_note: "",
    });
  },
  removeItemFromCart(state, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      state.restaurant.items.splice(state.restaurant.items.indexOf(cartItem), 1);
    }
  },
  clearCart(state) {
    state.restaurant = {
      name: "",
      table: null,
      items: [],
      number_customer: 0,
      customer: null,
      discount: 0,
      discount_amount: 0,
      creation: null,
      receive: 0,
      invoice_no: null,
    };
  },
  clearItemFromCart(state) {
    state.restaurant.items = [];
  },
  setOptionByCartItem(state, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      if (cartItem.take_note != item.take_note) cartItem.id = uuidv4();
      cartItem.take_note = item.take_note;
    }
  },
  incrementqtyByCartItem(state, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      cartItem.qty++;
    }
  },
  decrementqtyByCartItem(state, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      cartItem.qty--;
      if (cartItem.qty === 0) {
        state.restaurant.items.splice(state.restaurant.items.indexOf(cartItem), 1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
