<template>
  <div class="accordion">
    <transition
      name="accordion"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:after-enter="afterEnter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div class="body" :class="!isShow && 'overflow-hidden'" v-show="active">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      rerequired: true,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    afterEnter(el) {
      this.isShow = true;
    },
    beforeLeave(el) {
      this.isShow = false;
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
  },
};
</script>

<style scoped>
.accordion .body {
  transition: 150ms ease-out;
}
</style>
