<template>
  <div
    id="infinite-list"
    class="w-full px-5 overflow-x-auto h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]"
  >
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
          QR Menu
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'qr-menu' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >QR Menu</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        v-if="dataSelectedItem.length == 0"
        @click="handleAddTable"
        class="flex items-center text-sm py-2 lg:py-[10px] pl-3 pr-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5 mr-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v12m6-6H6"
          />
        </svg>
        Add QRCode
      </button>
      <Menu v-else as="div" class="relative">
        <div>
          <MenuButton
            class="flex items-center text-sm py-2 lg:py-[10px] pl-4 pr-3 bg-secondary text-white font-medium rounded-md justify-center"
          >
            Actions<svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 ml-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
              />
            </svg>
          </MenuButton>
        </div>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-gray-500 rounded-md bg-white dark:bg-slate-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <button
                  @click="handleDeleteItem"
                  class="text-left text-gray-800 dark:text-gray-100 block px-4 py-2 text-md font-bold w-full"
                >
                  Delete
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <div
      class="inline-block overflow-x-scroll w-full sm:w-[calc(100vw_-_78px)] lg:w-[calc(100vw_-_111px)]"
    >
      <TableComponent
        :columns="columns"
        :items="renderItems(items)"
        scrollX="35rem"
        :onClickRow="handleViewItem"
        :onSelectRow="(data) => (dataSelectedItem = data)"
        :onSearch="
          (value) => {
            page = 1;
            limit = 20;
            items = [];
            getItems(value);
          }
        "
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'table'">
            {{ record.table.label }}
          </template>
          <template v-if="column.key === 'short_url'">
            {{ baseURL + "/" + record.short_url }}
          </template>
        </template>
      </TableComponent>
    </div>
    <ModalComponent
      :title="titleModal"
      :isModalOpen="isAddView"
      :onToggle="() => (this.isAddView = false)"
      :width="578"
      :freeSpaceClose="true"
    >
      <div class="p-2 grid grid-cols-1 gap-y-2">
        <div>
          <label
            for="table"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Table <span class="text-red-400">*</span></label
          >
          <div class="mt-1">
            <multiselect
              id="parent_menu"
              v-model="dataForm.table"
              placeholder=""
              label="label"
              track-by="name"
              :options="dataTables"
              :allow-empty="false"
              deselect-label="Can't remove this value"
            />
          </div>
        </div>
        <div class="mt-1 flex justify-end">
          <button
            type="button"
            @click="handleSaveQRCode"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
          >
            Generate QRCode
          </button>
        </div>
        <div v-if="dataForm.short_url">
          <label
            for="short_url"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >URL</label
          >
          <div class="mt-1">
            <input
              id="short_url"
              :value="baseURL + '/' + dataForm.short_url"
              type="text"
              @focus="$event.target.select()"
              readonly
              class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
            />
          </div>
        </div>
        <div v-if="dataForm.qr_code">
          <div class="w-full flex justify-center mt-2">
            <img :src="dataForm.qr_code" />
          </div>
          <div class="flex justify-center mt-4">
            <button
              @click="print()"
              class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                />
              </svg>
              Print
            </button>
          </div>
        </div>
        <div class="flex justify-end pt-2 lg:pt-4 gap-4">
          <button
            type="button"
            @click="isAddView = false"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-gray-600 text-white font-medium rounded-md justify-center"
          >
            Close
          </button>
        </div>
      </div>
    </ModalComponent>
    <ModalComponent
      title="Confirm"
      :isModalOpen="isDeleteMessage"
      :onToggle="() => (this.isDeleteMessage = false)"
      :width="378"
      :freeSpaceClose="true"
    >
      <div class="p-2">
        <h1 class="dark:text-gray-100">Do you want to delete QR menu?</h1>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          @click="isDeleteMessage = false"
          class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
        >
          Cancel
        </button>
        <button
          @click="handleYesDeleteItem"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Yes
        </button>
      </div>
    </ModalComponent>
    <PrintComponent v-if="isAddView">
      <ContentQRMenuComponent
        :image="dataForm.qr_code"
        :table="dataForm.table?.label"
      />
    </PrintComponent>
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
const columns = [
  {
    key: "no",
    label: "#",
    dataIndex: "no",
    width: 20,
    align: "center",
    sort: true,
  },
  {
    key: "table",
    label: "Table",
    sort: true,
  },
  {
    key: "short_url",
    label: "Short URL",
    sort: true,
  },
];
import TableComponent from "@/components/table/TableComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import imageEmpty from "@/assets/image/empty.jpg";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { renderItems, print } from "@/utils/globleFunction";
import { baseURL } from "@/utils/baseURL";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import QRCode from "@/models/qrcode";
import { mapGetters } from "vuex";
import PrintComponent from "@/components/print/PrintComponent.vue";
import ContentQRMenuComponent from "@/components/print/ContentQRMenuComponent.vue";

export default {
  name: "QRCode",
  components: {
    TableComponent,
    SpinComponent,
    ModalComponent,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    PrintComponent,
    ContentQRMenuComponent,
  },
  data() {
    return {
      Tr,
      print,
      renderItems,
      dataForm: new QRCode(null, "", "", false),
      columns,
      limit: 20,
      page: 1,
      items: [],
      imageEmpty,
      loading: false,
      isAddView: false,
      dataSelectedItem: [],
      isDeleteMessage: false,
      titleModal: "",
      dataTables: [],
      baseURL,
    };
  },
  created() {
    this.getItems();
    this.getDataTabel();
  },
  mounted() {
    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", (e) => {
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        this.totalItems > this.items.length
      ) {
        this.page++;
        this.getItems();
      }
    });
  },
  computed: {
    ...mapGetters("auth", {
      getPOSProfile: "getPOSProfile",
    }),
  },
  watch: {
    "dataForm.table"(newData) {
      if (newData) {
        let url = `${window.location.origin}/frontend/en/customer-service?pos_profile=`;
        const qr_secret = function () {
          return (
            Date.now().toString(36) + Math.random().toString(36).substring(2)
          );
        };

        let store_secret = qr_secret();

        this.dataForm.long_url =
          url +
          this.getPOSProfile +
          "&table=" +
          newData.name +
          "&key=" +
          store_secret;
        this.dataForm.secret = store_secret;
      }
    },
  },
  methods: {
    getDataTabel() {
      this.loading = true;
      dataService.get("get_all_tables").then((res) => {
        this.dataTables = res.data.message;
        this.loading = false;
      });
    },
    handleAddTable() {
      this.titleModal = "Add QRCode";
      this.dataForm = new QRCode(null, "", "", false);
      this.isAddView = true;
    },
    handleViewItem(value) {
      this.dataForm = { ...value, disabled: Boolean(value.disabled) };
      this.titleModal = "View/Edit QRCode";
      this.isAddView = true;
    },
    getItems(query = "") {
      this.loading = true;
      dataService
        .getAllPaginate("get_list_qrcode", query, this.limit, this.page)
        .then((res) => {
          let { items, total } = res.data.message;
          this.items.push(...items);
          this.totalItems = total;
          this.loading = false;
        });
    },
    handleDeleteItem() {
      this.isDeleteMessage = true;
    },
    handleYesDeleteItem() {
      this.loading = true;
      dataService
        .post("delete_qrcode", {
          items: this.dataSelectedItem.map((item) => item.name),
        })
        .then((res) => {
          dispatchNotification({
            content: "Detete QRCode successfully",
            type: "success",
          });
          this.dataSelectedItem = [];
          this.isDeleteMessage = false;
          this.loading = false;
          this.page = 1;
          this.items = [];
          this.getItems();
          this.getDataTabel();
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.isDeleteMessage = false;
          this.loading = false;
        });
    },
    handleSaveQRCode() {
      this.loading = true;
      if (this.dataForm.table)
        if (!this.dataForm.name)
          dataService
            .post("create_qrcode", this.dataForm)
            .then((res) => {
              dispatchNotification({
                content: "Create QRCode successfully",
                type: "success",
              });
              this.dataForm = res.data.message.data;
              this.items = [];
              this.page = 1;
              this.getItems();
              this.getDataTabel();
              this.loading = false;
            })
            .catch((err) => {
              dispatchNotification({
                content: JSON.parse(
                  JSON.parse(err.response.data._server_messages)[0]
                ).message,
                type: "warning",
              });
              this.loading = false;
            });
        else {
          dataService
            .post("update_qrcode", this.dataForm)
            .then((res) => {
              dispatchNotification({
                content: "Update QRCode successfully",
                type: "success",
              });
              this.dataForm = res.data.message.data;
              this.items = [];
              this.page = 1;
              this.getItems();
              this.getDataTabel();
              this.loading = false;
            })
            .catch((err) => {
              dispatchNotification({
                content: JSON.parse(
                  JSON.parse(err.response.data._server_messages)[0]
                ).message,
                type: "warning",
              });
              this.loading = false;
            });
        }
      else {
        dispatchNotification({
          content: "Please select table!",
          type: "warning",
        });
        this.loading = false;
      }
    },
  },
};
</script>
