<template>
  <div
    class="accordion w-full  shadow-sm bg-white dark:bg-slate-900/20 text-gray-800"
  >
    <div
      class="flex justify-between items-center p-2 md:p-4 w-full cursor-pointer"
      @click="show = !show"
    >
      <div class="dark:text-gray-100" v-html="title"></div>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6 header-icon dark:text-gray-100"
          :class="{ rotate: show }"
        >
          <path
            fill-rule="evenodd"
            d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </div>
    <transition
      name="accordion"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:after-enter="afterEnter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div v-show="show" class="body" :class="!isShow && 'overflow-hidden'">
        <div class="px-2 pb-2 md:px-4 md:pb-4">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      show: true,
      isShow: true,
    };
  },
  methods: {
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    afterEnter(el) {
      this.isShow = this.show;
    },
    beforeLeave(el) {
      this.isShow = false;
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
  },
};
</script>

<style scoped>
.accordion .header-icon {
  transform: rotate(0deg);
  transition-duration: 0.3s;
}

.accordion .body {
  transition: 150ms ease-out;
}

.accordion .header-icon.rotate {
  transform: rotate(90deg);
  transition-duration: 0.3s;
}
</style>
