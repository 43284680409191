<template>
  <div
    id="infinite-list"
    class="w-full px-5 overflow-x-auto h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]"
  >
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
          UOM Conversion Factor
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'uom-conversion-factor' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white ellipsis-line line-clamp-1"
                  >UOM Conversion Factor</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        type="button"
        v-if="dataSelectedItem.length == 0"
        @click="handleAddUOMC"
        class="flex items-center text-sm py-2 lg:py-[10px] pl-3 pr-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5 mr-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v12m6-6H6"
          />
        </svg>
        <span class="ellipsis-line line-clamp-1">Add UOM Conversion Factor</span>
        
      </button>
      <Menu v-else as="div" class="relative">
        <div>
          <MenuButton
            class="flex items-center text-sm py-2 lg:py-[10px] pl-4 pr-3 bg-secondary text-white font-medium rounded-md justify-center"
          >
            Actions<svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 ml-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
              />
            </svg>
          </MenuButton>
        </div>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-gray-500 rounded-md bg-white dark:bg-slate-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <button
                  @click="handleDeleteItem"
                  class="text-left text-gray-800 dark:text-gray-100 block px-4 py-2 text-md font-bold w-full"
                >
                  Delete
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <div
      class="inline-block overflow-x-scroll w-full sm:w-[calc(100vw_-_78px)] lg:w-[calc(100vw_-_111px)]"
    >
      <TableComponent
        :columns="columns"
        :items="renderItems(items)"
        scrollX="35rem"
        :onClickRow="handleViewItem"
        :onSelectRow="(data) => (dataSelectedItem = data)"
        :onSearch="
          (value) => {
            page = 1;
            limit = 20;
            items = [];
            query = value;
            getItems();
          }
        "
      >
      </TableComponent>
    </div>
    <ModalComponent
      :title="titleModal"
      :isModalOpen="isAddView"
      :onToggle="() => (this.isAddView = false)"
      :width="578"
      :freeSpaceClose="true"
    >
      <form
        class="p-2 grid grid-cols-1 gap-y-2"
        name="form"
        @submit.prevent="handleSaveUOMC"
      >
        <div>
          <label
            for="category"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Category <span class="text-red-400">*</span></label
          >
          <div class="relative">
            <multiselect
              id="category"
              v-model="dataForm.category"
              placeholder=""
              :options="dataCategorys"
              :allow-empty="false"
              deselect-label="Can't remove this value"
              @input="onSearchCategory"
              @select="onSelectCategory"
              @open="() => getCategorys()"
            />
          </div>
        </div>
        <div>
          <label
            for="from_uom"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >From <span class="text-red-400">*</span></label
          >
          <div class="relative">
            <multiselect
              id="from_uom"
              v-model="dataForm.from_uom"
              placeholder=""
              :options="dataUOMs"
              :allow-empty="false"
              deselect-label="Can't remove this value"
              @input="onSearchUOM"
              @select="(value) => onSelectUOM(value, 1)"
              @open="() => getUOMs()"
            />
          </div>
        </div>
        <div>
          <label
            for="to_uom"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >To <span class="text-red-400">*</span></label
          >
          <div class="relative">
            <multiselect
              id="to_uom"
              v-model="dataForm.to_uom"
              placeholder=""
              :options="dataUOMs"
              :allow-empty="false"
              deselect-label="Can't remove this value"
              @input="onSearchUOM"
              @select="(value) => onSelectUOM(value, 2)"
              @open="() => getUOMs()"
            />
          </div>
        </div>
        <InputCurrencyComponent
          id="value"
          label="Value"
          v-model="dataForm.value"
          :options="{
            valueRange: { min: 0 },
          }"
          required
        />
        <div class="flex justify-end pt-2 lg:pt-4 gap-4">
          <button
            type="button"
            @click="isAddView = false"
            class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
          >
            Save
          </button>
        </div>
      </form>
    </ModalComponent>
    <ModalComponent
      title="Confirm"
      :isModalOpen="isDeleteMessage"
      :onToggle="() => (this.isDeleteMessage = false)"
      :width="378"
      :freeSpaceClose="true"
    >
      <div class="p-2">
        <h1 class="dark:text-gray-100">Do you want to delete uom?</h1>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          @click="isDeleteMessage = false"
          class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
        >
          Cancel
        </button>
        <button
          @click="handleYesDeleteItem"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Yes
        </button>
      </div>
    </ModalComponent>
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
const columns = [
  {
    key: "no",
    label: "#",
    dataIndex: "no",
    width: 20,
    align: "center",
    sort: true,
  },
  {
    key: "name",
    label: "ID",
    dataIndex: "name",
    sort: true,
  },
  {
    key: "category",
    label: "Category",
    dataIndex: "category",
    sort: true,
  },
  {
    key: "from_uom",
    label: "From",
    dataIndex: "from_uom",
    sort: true,
  },
  {
    key: "to_uom",
    label: "To",
    dataIndex: "to_uom",
    sort: true,
  },
  {
    key: "value",
    label: "Value",
    dataIndex: "value",
    sort: true,
  },
];
import TableComponent from "@/components/table/TableComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import imageEmpty from "@/assets/image/empty.jpg";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { renderItems } from "@/utils/globleFunction";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import UOMC from "@/models/uomc";
import { mapGetters } from "vuex";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";

export default {
  name: "UOM Conversion Factor",
  components: {
    TableComponent,
    SpinComponent,
    ModalComponent,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    InputCurrencyComponent,
  },
  data() {
    return {
      Tr,
      renderItems,
      dataForm: new UOMC(null, null, null, null),
      columns,
      limit: 20,
      page: 1,
      items: [],
      imageEmpty,
      loading: false,
      isAddView: false,
      dataSelectedItem: [],
      isDeleteMessage: false,
      titleModal: "",
      query: "",
      dataCategorys: [],
      dataUOMs: [],
    };
  },
  created() {
    this.getItems();
  },
  mounted() {
    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", (e) => {
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        this.totalItems > this.items.length
      ) {
        this.page++;
        this.getItems();
      }
    });
  },
  computed: {
    ...mapGetters("auth", {
      getFrappeVersion: "getFrappeVersion",
    }),
  },
  methods: {
    handleAddUOMC() {
      this.titleModal = "Add UOM Conversion Factor";
      this.isAddView = true;
      this.dataForm = new UOMC(null, null, null, null);
    },
    handleViewItem(value) {
      this.titleModal = "View/Edit UOM Conversion Factor";
      this.dataForm = value;
      this.isAddView = true;
    },
    getItems() {
      this.loading = true;
      dataService
        .getAllPaginate("get_list_uomc", this.query, this.limit, this.page)
        .then((res) => {
          let { items, total } = res.data.message;
          this.items.push(...items);
          this.totalItems = total;
          this.loading = false;
        });
    },
    handleDeleteItem() {
      this.isDeleteMessage = true;
    },
    handleYesDeleteItem() {
      this.loading = true;
      dataService
        .post("delete_uomc", {
          items: this.dataSelectedItem.map((item) => item.name),
        })
        .then((res) => {
          dispatchNotification({
            content: "Detete UOM Conversion Factor successfully",
            type: "success",
          });
          this.dataSelectedItem = [];
          this.isDeleteMessage = false;
          this.loading = false;
          this.page = 1;
          this.items = [];
          this.getItems();
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.isDeleteMessage = false;
          this.loading = false;
        });
    },
    handleSaveUOMC() {
      if (
        this.dataForm.category &&
        this.dataForm.from_uom &&
        this.dataForm.to_uom
      ) {
        this.loading = true;
        if (!this.dataForm.name)
          dataService
            .post("create_uomc", this.dataForm)
            .then((res) => {
              dispatchNotification({
                content: "Create UOM Conversion Factor successfully",
                type: "success",
              });
              this.items = [];
              this.page = 1;
              this.getItems();
              this.isAddView = false;
              this.loading = false;
            })
            .catch((err) => {
              dispatchNotification({
                content: JSON.parse(
                  JSON.parse(err.response.data._server_messages)[0]
                ).message,
                type: "warning",
              });
              this.loading = false;
            });
        else {
          dataService
            .post("update_uomc", this.dataForm)
            .then((res) => {
              dispatchNotification({
                content: "Update UOM Conversion Factor successfully",
                type: "success",
              });
              this.items = [];
              this.page = 1;
              this.getItems();
              this.isAddView = false;
              this.loading = false;
            })
            .catch((err) => {
              dispatchNotification({
                content: JSON.parse(
                  JSON.parse(err.response.data._server_messages)[0]
                ).message,
                type: "warning",
              });
              this.loading = false;
            });
        }
      }
    },
    onSearchCategory(event) {
      this.getCategorys(event.target.value);
    },
    getCategorys(query = "") {
      dataService.get("search_uomc", `query=${query}`).then((res) => {
        if (this.getFrappeVersion.split(".")[0] == "14")
          this.dataCategorys = res.data.results.map((item) => item.value);
        else this.dataCategorys = res.data.message.map((item) => item.value);
      });
    },
    onSelectCategory(value) {
      dataService.get("select_uomc", `name=${value}`).then((res) => {
        let data = res.data.message;
        this.dataCategorys = [];
        this.dataForm.category = data.name;
        this.getCategorys();
      });
    },
    onSearchUOM(event) {
      this.getUOMs(event.target.value);
    },
    getUOMs(query = "") {
      dataService.get("search_uom", `query=${query}`).then((res) => {
        if (this.getFrappeVersion.split(".")[0] == "14")
          this.dataUOMs = res.data.results.map((item) => item.value);
        else this.dataUOMs = res.data.message.map((item) => item.value);
      });
    },
    onSelectUOM(value, type) {
      dataService.get("select_uom", `name=${value}`).then((res) => {
        let data = res.data.message;
        this.dataUOMs = [];
        if (type == 1) this.dataForm.from_uom = data.name;
        else this.dataForm.to_uom = data.name;
        this.getUOMs();
      });
    },
  },
};
</script>
