export default class Loyalty {
  constructor(
    loyalty_program_name,
    loyalty_program_type,
    from_date,
    to_date,
    customer_group,
    auto_opt_in,
    collection_rules,
    conversion_factor,
    expiry_duration
  ) {
    this.loyalty_program_name = loyalty_program_name;
    this.loyalty_program_type = loyalty_program_type;
    this.from_date = from_date;
    this.to_date = to_date;
    this.customer_group = customer_group;
    this.auto_opt_in = auto_opt_in;
    this.collection_rules = collection_rules;
    this.conversion_factor = conversion_factor;
    this.expiry_duration = expiry_duration;
  }
}
