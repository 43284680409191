<template>
  <div class="w-96 leading-6 text-sm">
    <div class="grid grid-cols-6 gap-2 border-b border-gray-900 pb-2">
      <div class="col-span-2">
        <p>Start</p>
        <p>End</p>
        <p>Start Shift By</p>
        <p>End Shift By</p>
        <p>Work Hours</p>
        <p>Station</p>
        <p>Shift ID</p>
      </div>
      <div class="col-span-1">
        <p v-for="i in Array(7)">:</p>
      </div>
      <div class="col-span-3 text-right">
        <p>{{ formatDateTime(data?.start) }}</p>
        <p>{{ formatDateTime(data?.end) }}</p>
        <p>{{ data?.start_shift_by }}</p>
        <p>{{ data?.end_shift_by }}</p>
        <p>{{ data?.work_hours }}</p>
        <p>{{ data?.station }}</p>
        <p>{{ data?.shift_id }}</p>
      </div>
    </div>
    <h3 class="text-center py-2">{{ data?.warehouse }}</h3>
    <div class="grid grid-cols-12 gap-2">
      <p class="col-span-7 font-semibold">Itme</p>
      <p class="col-span-1 text-right font-semibold">Qty</p>
      <p class="col-span-4 text-right font-semibold">Amount</p>
      <p class="col-span-7">Default Item Category</p>
      <p class="col-span-1 text-right">{{ data?.total_qty }}</p>
      <p class="col-span-4 text-base text-right">
        ${{ formtCurrency(data?.net_total) }}
      </p>
    </div>
    <div class="border-b border-gray-900 pb-2">
      <p>Productline Name</p>
      <div v-for="item in data?.items">
        <p class="font-semibold">{{ item.item_group }}</p>
        <div class="grid grid-cols-12" v-for="i in item.items">
          <p class="col-span-7">{{ i.item_name }}</p>
          <p class="col-span-1 text-right">{{ i.qty }}</p>
          <p class="col-span-4 text-right">
            ${{ formtCurrency(i.base_rate * i.qty) }}
          </p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-2 pb-2">
      <p class="col-span-7"></p>
      <p class="col-span-1 text-right">{{ data?.total_qty }}</p>
      <p class="col-span-4 text-base text-right">
        ${{ formtCurrency(data?.net_total) }}
      </p>
    </div>
    <div class="grid grid-cols-12 gap-2 border-b border-gray-900 pb-2">
      <div class="col-span-7">
        <p>Number of Customers</p>
        <p>Invoice</p>
        <p>Average Transaction Value</p>
        <p v-if="data?.average_val_per_cus">Average Value Per Customer</p>
      </div>
      <div class="col-span-1 text-right">
        <p>:</p>
        <p>:</p>
        <p>:</p>
        <p v-if="data?.average_val_per_cus">:</p>
      </div>
      <div class="col-span-4 text-right">
        <p>{{ data?.number_of_customer }}</p>
        <p>{{ data?.number_of_transaction }}</p>
        <p>${{ formtCurrency(data?.average_tran_val) }}</p>
        <p v-if="data?.average_val_per_cus">
          ${{ formtCurrency(data?.average_val_per_cus) }}
        </p>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-2 border-b border-gray-900 pb-2">
      <div class="col-span-7 text-right">
        <p>Net Total</p>
        <p>Discount</p>
        <p>Service Charge</p>
        <p>Tax</p>
        <p>Grand Total</p>
        <p>Paid Amount</p>
        <p>Unpaid Amount</p>
      </div>
      <div class="col-span-1 text-right">
        <p v-for="i in Array(7)">:</p>
      </div>
      <div class="col-span-4 text-right">
        <p>${{ formtCurrency(data?.net_total) }}</p>
        <p>${{ formtCurrency(data?.discount_amount) }}</p>
        <p>${{ formtCurrency(data?.service_charge) }}</p>
        <p>${{ formtCurrency(data?.tax) }}</p>
        <p>${{ formtCurrency(data?.grand_total) }}</p>
        <p>${{ formtCurrency(data?.paid_amount) }}</p>
        <p>${{ formtCurrency(0) }}</p>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-2 border-b border-gray-900 pb-2 mb-2">
      <div class="col-span-7">
        <p>Number of Invoice Not Tender</p>
        <p>Amount of Invoice Not Tender</p>
        <p>Number of Void Invoice</p>
        <p>All Amount of Void Invoice</p>
      </div>
      <div class="col-span-1 text-right">
        <p v-for="i in Array(4)">:</p>
      </div>
      <div class="col-span-4 text-right">
        <p>{{ data?.number_tendor }}</p>
        <p>${{ formtCurrency(data?.amount_tendor) }}</p>
        <p>{{ data?.number_void }}</p>
        <p>${{ formtCurrency(data?.amount_void) }}</p>
      </div>
    </div>
    <p>Selling Cash By {{ data?.warehouse }}</p>
    <h3 class="text-center mt-1">{{ data?.warehouse }}</h3>
    <div>
      <div v-for="item in data?.mode_of_payment">
        <p>{{ item.mode_of_payment }}</p>
        <div class="grid grid-cols-12 gap-2 text-right">
          <p class="col-span-3">USD Dollar</p>
          <p class="col-span-3">${{ formtCurrency(item.expected) }}</p>
          <p class="col-span-3 text-base">
            ${{ formtCurrency(item.closing_usd) }}
          </p>
          <p class="col-span-3 text-base">
            ៛{{ formtCurrency(item.closing_khr) }}
          </p>
        </div>
      </div>
      <div class="grid grid-cols-12 text-right">
        <p class="col-span-6 pt-0.5">Total:</p>
        <p class="col-span-3 border-t border-gray-900 pt-0.5">
          $
          {{
            formtCurrency(
              data?.mode_of_payment.reduce(
                (sum, obj) => sum + obj.closing_usd,
                0
              )
            )
          }}
        </p>
        <p class="col-span-3 border-t border-gray-900 pt-0.5">
          ៛{{
            formtCurrency(
              data?.mode_of_payment.reduce(
                (sum, obj) => sum + obj.closing_khr,
                0
              )
            )
          }}
        </p>
      </div>
      <h3>Over/Short</h3>
      <div class="grid grid-cols-12 text-right text-base">
        <p class="col-span-9">
          {{
            overShort < 0 ? "Short:" : overShort > 0 ? "Over:" : "Over/Short:"
          }}
        </p>
        <p class="col-span-3">${{ formtCurrency(overShort) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import { formtCurrency, formatDateTime } from "@/utils/globleFunction";
import Tr from "@/i18n/translation";
import PrintComponent from "@/components/print/PrintComponent.vue";

export default {
  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: {
    SpinComponent,
    PrintComponent,
  },
  data() {
    return {
      formatDateTime,
      Tr,
      formtCurrency,
    };
  },
  computed: {
    overShort() {
      return this.data?.mode_of_payment.reduce(
        (sum, obj) => sum + obj.difference,
        0
      );
    },
  },
};
</script>
