<template>
  <div
    class="overflow-x-auto h-[calc(100vh_-_50px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]"
  >
    <div class="p-2 lg:p-5" v-if="dataProfitLoss">
      <div class="block md:flex items-start justify-between mb-4 space-y-4">
        <h1
          class="text-sm sm:text-xl font-semibold  dark:text-gray-100"
        >
          Profit and Loss
        </h1>
        <div class="w-full md:w-1/2 flex justify-end space-x-4">
          <div class="w-1/2">
            <vue-tailwind-datepicker
              :formatter="{
                date: 'DD/MM/YYYY',
                month: 'MMM',
              }"
              v-model="dateProfitLoss"
              as-single use-range
            />
          </div>
          <div class="w-1/2">
            <multiselect
              v-model="periodicity"
              :options="['Monthly', 'Quarterly', 'Half-Yearly', 'Yearly']"
              :searchable="false"
            />
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-11 h-24 items-center w-full bg-gray-100 dark:bg-slate-900/30"
      >
        <div class="text-center col-span-3">
          <h1 class="text-gray-600 text-xs sm:text-sm dark:text-gray-400">
            {{ dataProfitLoss.report_summary[0].label }}
          </h1>
          <count-component
            :startValue="0"
            :endValue="dataProfitLoss.report_summary[0].value"
            class="font-semibold text-xl dark:text-gray-200"
          />
        </div>
        <div class="col-span-1 flex justify-center">
          <div
            class="border dark:border-gray-700 h-8 w-8 flex items-center justify-center font-bold text-xl dark:text-gray-200"
          >
            -
          </div>
        </div>
        <div class="text-center col-span-3">
          <h1 class="text-gray-600 text-xs sm:text-sm dark:text-gray-400">
            {{ dataProfitLoss.report_summary[2].label }}
          </h1>
          <count-component
            :startValue="0"
            :endValue="dataProfitLoss?.report_summary[2].value"
            class="font-semibold text-xl dark:text-gray-200"
          />
        </div>
        <div class="col-span-1 flex justify-center">
          <div
            class="border dark:border-gray-700 h-8 w-8 flex items-center justify-center font-bold text-xl text-secondary dark:text-primary"
          >
            =
          </div>
        </div>
        <div class="text-center col-span-3">
          <h1 class="text-gray-600 text-xs sm:text-sm dark:text-gray-400">
            {{ dataProfitLoss.report_summary[4].label }}
          </h1>
          <count-component
            :startValue="0"
            :endValue="dataProfitLoss?.report_summary[4].value"
            class="font-semibold text-xl text-secondary dark:text-primary"
          />
        </div>
      </div>
    </div>
    <div v-if="dataProfitLoss?.chart?.data?.datasets?.length > 0">
      <v-frappe-chart
        type="bar"
        :labels="dataProfitLoss.chart.data.labels"
        :data="dataProfitLoss.chart.data.datasets"
      />
    </div>
    <div v-else class="relative h-64">
      <EmptyComponent :show="true" />
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 p-5 gap-5">
      <div>
        <div class="flex items-start justify-between">
          <h1
            class="text-sm sm:text-xl font-semibold  dark:text-gray-100"
          >
            Daily Sale
          </h1>
          <div class="w-72">
            <vue-tailwind-datepicker
              :formatter="{
                date: 'DD/MM/YYYY',
                month: 'MMM',
              }"
              v-model="dateDailySale"
              as-single use-range
            />
          </div>
        </div>
        <div
          v-if="dataDailySale && dataDailySale?.labels.length !== 0"
          class="w-full"
        >
          <v-frappe-chart
            type="line"
            :labels="dataDailySale?.labels"
            :data="dataDailySale?.data"
            :line-options="{
              regionFill: 1,
            }"
          />
        </div>
      </div>
      <div>
        <div>
          <h1
            class="text-sm sm:text-xl font-semibold  dark:text-gray-100"
          >
            Sale by Category
          </h1>
        </div>
        <div v-if="dataSalebyCategory" class="relative w-full">
          <v-frappe-chart
            type="pie"
            :labels="dataSalebyCategory[0].labels"
            :data="[{ values: dataSalebyCategory[1].data }]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import { data, options } from "./chartConfig.js";
import { VFrappeChart } from "vue-frappe-chart";
import dataService from "@/services/data.service";
import CountComponent from "@/components/count/CountComponent.vue";
import EmptyComponent from "@/components/empty/EmptyComponent.vue";
export default {
  components: {
    VFrappeChart,
    CountComponent,
    EmptyComponent,
  },
  data() {
    return {
      Tr,
      data,
      options,
      dataDailySale: null,
      dataProfitLoss: null,
      dateDailySale: [],
      dateProfitLoss: [],
      loading: false,
      periodicity: "Yearly",
      dataSalebyCategory: null,
    };
  },
  created() {
    this.getDefaultDateDailySale();
    this.getDefaultDateProfitLoss();
    this.getSalebyCategory();
  },
  watch: {
    dateDailySale(newData) {
      if (newData.length === 0) this.getDefaultDateDailySale();
      this.getDailySale();
    },
    dateProfitLoss(newData) {
      if (newData.length === 0) this.getDefaultDateProfitLoss();
      this.getProfitLoss();
    },
    periodicity() {
      this.getProfitLoss();
    },
  },
  computed: {},
  methods: {
    formatDate(date) {
      var day = date.getDate().toString().padStart(2, "0");
      var month = (date.getMonth() + 1).toString().padStart(2, "0");
      var year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    getDefaultDateProfitLoss() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const firstDayOfYear = new Date(currentYear, 0, 1);
      const lastDayOfYear = new Date(currentYear, 11, 31);
      const formattedFirstDay = this.formatDate(firstDayOfYear);
      const formattedLastDay = this.formatDate(lastDayOfYear);
      this.dateProfitLoss = [formattedFirstDay, formattedLastDay];
    },
    getDefaultDateDailySale() {
      var currentDate = new Date();
      var sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 7);
      var currentDateStr = this.formatDate(currentDate);
      var sevenDaysAgoStr = this.formatDate(sevenDaysAgo);
      this.dateDailySale = [sevenDaysAgoStr, currentDateStr];
    },
    getDailySale() {
      this.loading = true;
      dataService
        .get(
          "get_daily_sale",
          `start=${this.dateDailySale[0]}&end=${this.dateDailySale[1]}`
        )
        .then((res) => {
          this.dataDailySale = res.data.message;
          this.loading = false;
        });
    },
    getProfitLoss() {
      dataService
        .get(
          "get_profit_loss",
          `start=${this.dateProfitLoss[0]}&end=${this.dateProfitLoss[1]}&periodicity=${this.periodicity}`
        )
        .then((res) => {
          this.dataProfitLoss = res.data.message;
        });
    },
    getSalebyCategory() {
      dataService.get("get_sale_by_category").then((res) => {
        this.dataSalebyCategory = res.data.message;
      });
    },
  },
};
</script>
