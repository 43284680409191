<template>
  <div>
    <header-layout
      v-if="(userRoleCashier || userRoleService) && requiresLayout"
    />
    <div v-if="userRoleCashier && requiresLayout" class="flex flex-nowrap">
      <sidebar-layout />
      <slot></slot>
    </div>
    <div
      v-else-if="userRoleService && requiresLayout"
      class="flex flex-nowrap h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]"
    >
      <slot></slot>
    </div>
    <slot v-else-if="userRoleKitchen || !requiresLayout"></slot>
  </div>
</template>

<script>
import SidebarLayout from "./SidebarLayout.vue";
import HeaderLayout from "./HeaderLayout.vue";
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  components: {
    SidebarLayout,
    HeaderLayout,
  },
  computed: {
    loggedIn() {
      return store.state.auth.status.loggedIn;
    },
    requiresLayout() {
      return this.$route.meta.requiresLayout;
    },
    ...mapGetters("auth", {
      getUserRoles: "getUserRoles",
    }),
    userRoleCashier() {
      return this.getUserRoles?.includes("Cashier User");
    },
    userRoleService() {
      return this.getUserRoles?.includes("Service User");
    },
    userRoleKitchen() {
      return this.getUserRoles?.includes("Kitchen User");
    },
  },
};
</script>
