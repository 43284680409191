<template>
  <div>
    <div class="grid grid-cols-8 gap-4 p-2 sm:p-4">
      <div v-show="!cart" class="col-span-8 sm:col-span-3">
        <div v-show="!payment && !offers && !coupons">
          <ItemsSelectorComponent />
        </div>
        <div v-show="payment"><PaymentsComponent /></div>
        <div v-show="coupons"><CouponsComponent /></div>
      </div>
      <div class="col-span-5 hidden sm:block">
        <InvoiceComponent />
      </div>
      <div class="col-span-8" v-show="cart">
        <InvoiceComponent />
      </div>
    </div>
    <DraftsComponent />
    <ReturnsComponent />
    <div v-if="invoiceNo">
      <PrintComponent><ContentInvoiceRetailComponent /></PrintComponent>
    </div>
    <button
      v-show="!cart && !payment"
      type="button"
      @click="onShowCart"
      class="block sm:hidden fixed bg-secondary dark:bg-slate-300 text-white dark:text-black shadow-md z-10 bottom-10 right-10 p-2.5 rounded-full"
    >
      <div class="flex items-center justify-center rounded-md p-1 relative">
        <span class="sr-only">Close menu</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
          />
        </svg>
        <span
          v-show="totalQty > 0"
          class="absolute animate-bounce -top-1 -right-3 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full"
          >{{ totalQty > 100 ? "+99" : totalQty }}</span
        >
      </div>
    </button>
  </div>
</template>

<script>
import ItemsSelectorComponent from "./components/ItemsSelectorComponent.vue";
import CouponsComponent from "./components/CouponsComponent.vue";
import InvoiceComponent from "./components/InvoiceComponent.vue";
import dataService from "@/services/data.service";
import DraftsComponent from "./components/DraftsComponent.vue";
import ReturnsComponent from "./components/ReturnsComponent.vue";
import PaymentsComponent from "./components/PaymentsComponent.vue";
import { mapActions, mapGetters } from "vuex";
import ContentInvoiceRetailComponent from "@/components/print/ContentInvoiceRetailComponent.vue";
import PrintComponent from "@/components/print/PrintComponent.vue";

export default {
  data: function () {
    return {
      dialog: false,
      pos_opening_shift: "",
      payment: false,
      offers: false,
      coupons: false,
      cart: false,
      totalQty: 0,
    };
  },
  components: {
    ItemsSelectorComponent,
    InvoiceComponent,
    DraftsComponent,
    ReturnsComponent,
    PaymentsComponent,
    ContentInvoiceRetailComponent,
    PrintComponent,
    CouponsComponent,
  },
  created() {
    this.get_pos_setting();
    this.emitter.on("onShowPayment", (data) => {
      if (data) this.cart = false;
      this.payment = data;
      this.offers = false;
      this.coupons = false;
    });
    // this.emitter.on('show_offers', (data) => {
    //   this.offers = true ? data === 'true' : false;
    //   this.payment = false ? data === 'true' : false;
    //   this.coupons = false ? data === 'true' : false;
    // });
    this.emitter.on("onShowCoupons", (data) => {
      this.coupons = data;
      this.offers = false;
      this.payment = false;
      this.cart = false;
    });
    this.emitter.on("onShowCart", (data) => {
      this.cart = data;
      this.coupons = false;
      this.offers = false;
      this.payment = false;
    });
    this.emitter.on("setQty", (data) => {
      this.totalQty = data;
    });
  },
  computed: {
    ...mapGetters("retail", {
      invoiceNo: "getInvoiceNo",
    }),
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 640 && this.cart) this.cart = false;
    },
    onShowCart() {
      this.cart = true;
      this.payment = false;
      this.offers = false;
      this.coupons = false;
    },
    ...mapActions("retail", {}),
    create_opening_voucher() {
      this.dialog = true;
    },
    get_offers(pos_profile) {
      return dataService
        .post("get_offers", {
          profile: pos_profile,
        })
        .then((res) => {
          let data = res.data.message;
          if (data) {
            this.$emit("set_offers", data);
          }
        });
    },
    get_pos_setting() {
      //   dataService.get("get_pos_settings").then((res) => {
      //     this.$emit("set_pos_settings", res.data.message);
      //   });
    },
  },
};
</script>
