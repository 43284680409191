<template>
  <PrintComponent>
    <div class="w-96">
      <div class="flex justify-between items-center">
        <p>តុ:<span class="text-lg font-bold">1</span></p>
        <p>01:00</p>
      </div>
      <table class="border-y border-gray-500 p-0 m-0 w-full">
        <tbody>
          <tr class="leading-6">
            <td>
              <div class="flex items-center space-x-2 text-lg">
                <h1 class="text-xl">Item Name</h1>
                <p>(5)</p>
              </div>
              <p>Take note</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </PrintComponent>
  Working on
</template>

<script>
import PrintComponent from "@/components/print/PrintComponent.vue";
import { print, formatDate } from "@/utils/globleFunction";

export default {
  components: {
    PrintComponent,
  },
  data() {
    return {
      countdown: "15:00",
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    print() {
      print();
    },
    startCountdown() {
      let remainingTime = 10; // 15 minutes in seconds

      const timer = setInterval(() => {
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;

        this.countdown = `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;

        if (remainingTime <= 0) {
          clearInterval(timer);
          this.countdown = "00:00";
        } else {
          remainingTime--;
        }
      }, 1000);
    },
  },
};
</script>

<style>
/* Add your styles here */
</style>
