<template>
  <form class="px-5" name="form" @submit.prevent="handleSaveItem">
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
          New Kitchen/Drink Station
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'kitchen-drink' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >Kitchen/Drink Station</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'create-kitchen-drink' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >New Kitchen/Drink Station</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        type="submit"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          class="h-5 w-5 mr-1"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
          />
          <polyline points="17 21 17 13 7 13 7 21" />
          <polyline points="7 3 7 8 15 8" />
        </svg>
        Save
      </button>
    </div>
    <div class="overflow-y-auto h-[calc(100vh_-_150px)]">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div />
        <div class="col-span-1 space-y-6">
          <InputComponent
            id="kitchendrink_name"
            label="Name"
            v-model="dataForm.kitchendrink_name"
            required
          />
          <div v-if="isUseKDS">
            <label
              for="user"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >User</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <multiselect
                id="user"
                v-model="dataForm.user"
                placeholder=""
                label="full_name"
                track-by="name"
                :options="dataUsers"
              />
            </div>
          </div>
          <InputComponent
            v-else
            id="user"
            label="User"
            v-model="dataForm.user"
            disabled
          />
          <InputComponent
            id="printer_ip"
            label="Printer IP-address"
            v-model="dataForm.printer_ip"
            :disabled="!isUsePrinter"
          />
          <div class="flex items-center space-x-2">
            <input
              id="disabled"
              type="checkbox"
              v-model="dataForm.disabled"
              class="h-4 w-4 cursor-pointer accent-secondary"
            /><label
              for="disabled"
              class="text-sm font-medium text-gray-900 dark:text-gray-100"
              >Disabled</label
            >
          </div>
        </div>
      </div>
    </div>
  </form>
  <SpinComponent :show="loading" />
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import Kitchen from "@/models/kitchen";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import InputComponent from "@/components/input/InputComponent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Create Kitchen/Drink Station",
  components: {
    SpinComponent,
    InputComponent,
  },
  data() {
    return {
      Tr,
      dataForm: new Kitchen("", null, false, ""),
      loading: false,
      dataUsers: [],
    };
  },
  created() {
    this.getUsers();
  },
  computed: {
    ...mapGetters("auth", {
      isUseKDS: "isUseKDS",
      isUsePrinter: "isUsePrinter",
    }),
  },
  methods: {
    handleSaveItem() {
      this.loading = true;
      dataService
        .post("create_kitchen_drink", this.dataForm)
        .then((res) => {
          dispatchNotification({
            content: "Create Kitchen/Drink successfully",
            type: "success",
          });
          this.loading = false;
          this.$router.go(-1);
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.loading = false;
        });
    },
    getUsers() {
      dataService.get("get_users").then((res) => {
        this.dataUsers = res.data.message;
      });
    },
  },
};
</script>
<style>
.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}
</style>
