export default class Customer {
  constructor(
    customer_name,
    customer_group,
    mobile_no,
    address_line1,  
    city,
    country,
    disabled
  ) {
    this.customer_name = customer_name;
    this.customer_group = customer_group;
    this.mobile_no = mobile_no;
    this.address_line1 = address_line1;
    this.city = city;
    this.country = country;
    this.disabled = disabled;
  }
}
