export default class Offer {
  constructor(
    title,
    description,
    disable,
    apply_on,
    offer,
    item,
    item_group,
    brand,
    valid_from,
    valid_upto,
    min_qty,
    max_qty,
    min_amt,
    max_amt,
    apply_type,
    coupon_based,
    auto,
    replace_item,
    replace_cheapest_item,
    apply_item_code,
    apply_item_group,
    less_then,
    given_qty,
    discount_type,
    rate,
    discount_amount,
    discount_percentage,
    loyalty_program,
    loyalty_points
  ) {
    this.title = title;
    this.description = description;
    this.disable = disable;
    this.apply_on = apply_on;
    this.offer = offer;
    this.item = item;
    this.item_group = item_group;
    this.brand = brand;
    this.valid_from = valid_from;
    this.valid_upto = valid_upto;
    this.min_qty = min_qty;
    this.max_qty = max_qty;
    this.min_amt = min_amt;
    this.max_amt = max_amt;
    this.apply_type = apply_type;
    this.coupon_based = coupon_based;
    this.auto = auto;
    this.replace_item = replace_item;
    this.replace_cheapest_item = replace_cheapest_item;
    this.apply_item_code = apply_item_code;
    this.apply_item_group = apply_item_group;
    this.less_then = less_then;
    this.given_qty = given_qty;
    this.discount_type = discount_type;
    this.rate = rate;
    this.discount_amount = discount_amount;
    this.discount_percentage = discount_percentage;
    this.loyalty_program = loyalty_program;
    this.loyalty_points = loyalty_points;
  }
}
