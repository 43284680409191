<template>
  <div
    class="h-screen w-full flex justify-center bg-white dark:bg-slate-800 overflow-auto"
  >
    <div class="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <div class="w-full flex justify-center">
          <img src="@/assets/image/Logo.png" alt="logo-company" class="w-24" />
        </div>
      </div>
      <h2 class="text-center text-2xl text-gray-900 dark:text-gray-100 mt-4">
        Register
      </h2>
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <form class="space-y-4" name="form" @submit.prevent="handleSubmit">
          <div class="grid grid-cols-2 gap-4">
            <InputComponent
              id="first_name"
              label="First Name"
              v-model="dataForm.first_name"
              required
            />
            <InputComponent
              id="last_name"
              label="Last Name"
              v-model="dataForm.last_name"
            />
          </div>
          <InputComponent
            id="email"
            type="email"
            label="Email"
            v-model="dataForm.email"
            required
          />
          <InputComponent
            id="phone"
            label="Phone"
            type="number"
            v-model="dataForm.phone"
            required
          />
          <InputComponent
            id="company_name"
            label="Company Name"
            v-model="dataForm.company_name"
            required
          />
          <div>
            <label
              for="custom_business_categories"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Business Categories <span class="text-red-400">*</span>
            </label>
            <div>
              <multiselect
                id="custom_business_categories"
                v-model="dataForm.custom_business_categories"
                :searchable="false"
                placeholder=""
                :options="['Restaurant', 'Retail', 'Pharma']"
                :allow-empty="false"
                deselect-label="Can't remove this value"
              />
            </div>
          </div>
          <div class="relative">
            <div v-if="pwdStrength" class="absolute top-1 right-0 w-1/4">
              <div class="flex items-center space-x-2">
                <span class="text-[13px]" :style="`color: ${progColor}`">{{
                  progText
                }}</span>
                <div
                  class="bg-gray-100 dark:bg-slate-600 relative h-1 w-full rounded-2xl"
                >
                  <div
                    class="absolute bg-black top-0 left-0 h-full w-1/2 rounded-2xl"
                    :style="`width: ${progPercent}%; background-color: ${progColor}`"
                  ></div>
                </div>
              </div>
            </div>
            <InputComponent
              id="password"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              v-model="password"
              required
            />
            <div class="absolute bottom-3 right-4 cursor-pointer">
              <svg
                class="h-4 text-gray-700 dark:text-gray-100"
                fill="none"
                @click="showPassword = !showPassword"
                :class="!showPassword ? 'block' : 'hidden'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                ></path>
              </svg>
              <svg
                class="h-4 text-gray-700 dark:text-gray-100"
                fill="none"
                @click="showPassword = !showPassword"
                :class="showPassword ? 'block' : 'hidden'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="currentColor"
                  d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                ></path>
              </svg>
            </div>
          </div>
          <span
            v-if="pwdStrength && score < 4"
            class="text-[14px] text-gray-500 dark:text-gray-400"
            >Include symbols, numbers and capital letters in the password</span
          >
          <div class="relative">
            <InputComponent
              id="confirm_password"
              :type="showConfirmPassword ? 'text' : 'password'"
              label="Confirm Password"
              v-model="dataForm.confirm_password"
              required
            />
            <div class="absolute bottom-3 right-4 cursor-pointer">
              <svg
                class="h-4 text-gray-700 dark:text-gray-100"
                fill="none"
                @click="showConfirmPassword = !showConfirmPassword"
                :class="!showConfirmPassword ? 'block' : 'hidden'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                ></path>
              </svg>
              <svg
                class="h-4 text-gray-700 dark:text-gray-100"
                fill="none"
                @click="showConfirmPassword = !showConfirmPassword"
                :class="showConfirmPassword ? 'block' : 'hidden'"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="currentColor"
                  d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                ></path>
              </svg>
            </div>
          </div>
          <span
            v-if="message != ''"
            class="flex justify-center text-sm text-red-500 mt-1"
            >{{ message }}</span
          >
          <div class="py-2">
            <button
              type="submit"
              :disabled="loading"
              class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-primary text-white font-medium rounded-md w-full justify-center"
            >
              <div v-if="loading" class="inline-flex items-center">
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline mr-2 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  ></path>
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span>Loading...</span>
              </div>
              <span v-else> Register </span>
            </button>
          </div>
          <p class="text-center text-sm text-gray-500 w-full">
            Have an account?
            <router-link :to="Tr.i18nRoute({ name: 'login' })" class="underline"
              >Login Here</router-link
            >
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Register from "@/models/register";
import Tr from "@/i18n/translation";
import { updateDarkMode } from "@/utils/globleFunction";
import InputComponent from "@/components/input/InputComponent.vue";
import { dispatchNotification } from "@/components/notification";
import dataService from "@/services/data.service";
import _ from "lodash";

export default {
  name: "Register",
  components: {
    InputComponent,
  },
  data() {
    return {
      Tr,
      dataForm: new Register("", "", "", "", "", "Restaurant", "", ""),
      loading: false,
      message: "",
      isDarkMode: false,
      showPassword: false,
      showConfirmPassword: false,
      pwdStrength: null,
      score: 0,
      progText: "",
      progColor: "",
      progPercent: "",
    };
  },
  mounted() {
    setInterval(() => {
      this.date = new Date();
    }, 1000);
    const storedDarkMode = localStorage.getItem("darkMode");
    this.isDarkMode = storedDarkMode === "true";
    updateDarkMode(this.isDarkMode);
  },
  computed: {
    password: {
      get() {
        return this.dataForm.password;
      },
      set: _.debounce(function (newValue) {
        this.dataForm.password = newValue;
        this.onChangePwd(newValue);
      }, 200),
    },
  },
  methods: {
    onChangePwd(val) {
      dataService
        .postPublic("test_pwd_strength", { pwd: val })
        .then((res) => {
          let data = res.data.message;
          this.pwdStrength = data;
          if (data) {
            let score = data.score;
            this.score = score;
            var strength = {
              red: ["Weak", "#ff0e0e", 25],
              orange: ["Average", "#ffcc00", 50],
              blue: ["Strong", "#007bff", 75],
              green: ["Excellent", "#28a745", 100],
            };
            var indicators = ["red", "red", "orange", "blue", "green"];
            this.progText = strength[indicators[score]][0];
            this.progColor = strength[indicators[score]][1];
            this.progPercent = strength[indicators[score]][2];
          }
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
        });
    },
    handleSubmit() {
      this.loading = true;
      if (this.dataForm.password === this.dataForm.confirm_password) {
        dataService
          .postPublic("register", this.dataForm)
          .then((res) => {
            dispatchNotification({
              content: "Register successfully",
              type: "success",
            });
            this.loading = false;
            this.$router.go(-1);
          })
          .catch((err) => {
            dispatchNotification({
              content: JSON.parse(
                JSON.parse(err.response.data._server_messages)[0]
              ).message,
              type: "warning",
            });
            this.loading = false;
          });
      } else {
        dispatchNotification({
          content: "Password not match!",
          type: "warning",
        });
        this.loading = false;
      }
    },
  },
};
</script>
