import store from "@/store";
import Trans from "../i18n/translation";
import { createRouter, createWebHistory } from "vue-router";
import TableView from "@/view/app/restaurant/table/TableView.vue";
import LoginView from "@/view/auth/LoginView.vue";
import RegisterView from "@/view/auth/RegisterView.vue";
import MenuView from "@/view/app/restaurant/menu/MenuView.vue";
import RetailView from "@/view/app/retail/RetailView.vue";
import MenuDelivery from "@/view/app/restaurant/delivery/MenuDeliveryView.vue";
import CheckoutView from "@/view/app/restaurant/checkout/CheckoutView.vue";
import ProfileView from "@/view/app/restaurant/profile/ProfileView.vue";
import DeleteItemView from "@/view/app/report/DeleteItemView.vue";
import CloseShiftView from "@/view/app/report/close/CloseShiftView.vue";
import EndDayView from "@/view/app/report/day/EndDayView.vue";
import CloseShiftDetailView from "@/view/app/report/close/CloseShiftDetailView.vue";
import EndDayDetailView from "@/view/app/report/day/EndDayDetailView.vue";
import TotalStockSummaryView from "@/view/app/report/TotalStockSummaryView.vue";
import ForbiddenView from "@/view/app/403/403View.vue";
import NotFoundView from "@/view/app/404/404View.vue";
import OpenShiftView from "@/view/app/shift/OpenShiftView.vue";
import KitchenDrinkView from "@/view/app/restaurant/services/KitchenDrinkView.vue";
import CustomerServiceView from "@/view/app/restaurant/services/CustomerServiceView.vue";
import CustomerFacingDisplayView from "@/view/app/restaurant/services/CustomerFacingDisplayView.vue";
import CustomerFacingDisplayRetailView from "@/view/app/retail/services/CustomerFacingDisplayView.vue";
import LiveCustomerView from "@/view/app/live/customer/LiveCustomerView.vue";
import CreateLiveCustomerView from "@/view/app/live/customer/CreateLiveCustomerView.vue";
import ViewLiveCustomerView from "@/view/app/live/customer/ViewLiveCustomerView.vue";
import LiveCommentsView from "@/view/app/live/comment/LiveCommentsView.vue";
import LiveCommentView from "@/view/app/live/comment/LiveCommentView.vue";
import RouteMiddleware from "./routeMiddleware";
import ItemView from "@/view/app/items/item/ItemView.vue";
import CreateItemView from "@/view/app/items/item/CreateItemView.vue";
import EditItemView from "@/view/app/items/item/EditItemView.vue";
import KitchenView from "@/view/app/items/kitchen/KitchenView.vue";
import CreateKitchenView from "@/view/app/items/kitchen/CreateKitchenView.vue";
import EditKitchenView from "@/view/app/items/kitchen/EditKitchenView.vue";
import MenuGroupView from "@/view/app/items/menuGroup/MenuGroupView.vue";
import ItemGroupView from "@/view/app/items/itemGroup/ItemGroupView.vue";
import ModifierView from "@/view/app/items/modifier/ModifierView.vue";
import UserView from "@/view/app/users/user/UserView.vue";
import CreateUserView from "@/view/app/users/user/CreateUserView.vue";
import EditUserView from "@/view/app/users/user/EditUserView.vue";
import DialogDeleteView from "@/view/app/users/user/DialogDeleteView.vue";
import CustomerView from "@/view/app/users/customer/CustomerView.vue";
import CreateCustomerView from "@/view/app/users/customer/CreateCustomerView.vue";
import EditCustomerView from "@/view/app/users/customer/EditCustomerView.vue";
import CustomerGroupView from "@/view/app/users/customerGroup/CustomerGroupView.vue";
import SupplierView from "@/view/app/stock/supplier/SupplierView.vue";
import CreateSupplierView from "@/view/app/stock/supplier/CreateSupplierView.vue";
import EditSupplierView from "@/view/app/stock/supplier/EditSupplierView.vue";
import SupplierGroupView from "@/view/app/stock/supplierGroup/SupplierGroupView.vue";
import QRMenuView from "@/view/app/floors/qr/QRMenuView.vue";
import FloorView from "@/view/app/floors/floor/FloorView.vue";
import CouponView from "@/view/app/promotion/coupon/CouponView.vue";
import OfferView from "@/view/app/promotion/offer/OfferView.vue";
import CreateOfferView from "@/view/app/promotion/offer/CreateOfferView.vue";
import EditOfferView from "@/view/app/promotion/offer/EditOfferView.vue";
import LoyaltyView from "@/view/app/promotion/loyalty/LoyaltyView.vue";
import CreateLoyaltyView from "@/view/app/promotion/loyalty/CreateLoyaltyView.vue";
import EditLoyaltyView from "@/view/app/promotion/loyalty/EditLoyaltyView.vue";
import SetupTableView from "@/view/app/floors/table/TableView.vue";
import SettingView from "@/view/app/settings/setting/SettingView.vue";
import PaymentView from "@/view/app/settings/payment/PaymentView.vue";
import DashboardView from "@/view/app/dashboard/DashboardView.vue";
import SaleTransactionsView from "@/view/app/report/SaleTransactionsView.vue";
import UOMConversionView from "@/view/app/stock/uom/UOMConversionView.vue";
import UOMView from "@/view/app/stock/uom/UOMView.vue";
import PurchaseInvoiceView from "@/view/app/stock/purchase/PurchaseInvoiceView.vue";
import CreatePurchaseInvoiceView from "@/view/app/stock/purchase/CreatePurchaseInvoiceView.vue";
import EditPurchaseInvoiceView from "@/view/app/stock/purchase/EditPurchaseInvoiceView.vue";
import QtyOnHandView from "@/view/app/report/QtyOnHandView.vue";

const router = createRouter({
  history: createWebHistory("/frontend"),
  routes: [
    {
      path: "/:locale?",
      beforeEnter: RouteMiddleware,
      children: [
        {
          path: "",
          name: "dashboard",
          meta: {
            title: "Dashboard",
            requiresLayout: true,
            requiresAuth: true,
          },

          component: DashboardView,
          beforeEnter: async (to, from, next) => {
            let roles = await store.getters["auth/getUserRoles"];
            if (
              roles.includes("Cashier User") ||
              roles.includes("Admin User") ||
              roles.includes("Admin User")
            ) {
              next();
            } else {
              next(Trans.i18nRoute({ name: "403" }));
            }
          },
        },
        {
          path: "login",
          name: "login",
          meta: {
            title: "Login",
            requiresLayout: false,
            requiresAuth: false,
          },
          component: LoginView,
          beforeEnter: async (to, from, next) => {
            const loggedIn = await store.state.auth.status.loggedIn;
            if (!loggedIn) {
              next();
            } else {
              next(Trans.i18nRoute({ name: "dashboard" }));
            }
          },
        },
        {
          path: "register",
          name: "register",
          meta: {
            title: "Register",
            requiresLayout: false,
            requiresAuth: false,
          },
          component: RegisterView,
          beforeEnter: async (to, from, next) => {
            const loggedIn = await store.state.auth.status.loggedIn;
            const isHideSignup = await store.state.auth.status.isHideSignup;
            if (!loggedIn) {
              if (!isHideSignup) next();
              else next(Trans.i18nRoute({ name: "login" }));
            } else {
              next(Trans.i18nRoute({ name: "dashboard" }));
            }
          },
        },
        {
          path: "open-shift",
          name: "open-shift",
          meta: {
            title: "Open Shift",
            requiresLayout: true,
            requiresAuth: true,
          },

          component: OpenShiftView,
          beforeEnter: async (to, from, next) => {
            let roles = await store.getters["auth/getUserRoles"];
            let getIsOpened = await store.getters["auth/getIsOpened"];
            if (
              roles.includes("Cashier User") ||
              (roles.includes("Admin User") && !getIsOpened)
            ) {
              next();
            } else {
              next(Trans.i18nRoute({ name: "403" }));
            }
          },
        },
        {
          path: "restaurant",
          name: "restaurant",
          beforeEnter: async (to, from, next) => {
            let roles = await store.getters["auth/getUserRoles"];
            let getIsOpened = await store.getters["auth/getIsOpened"];
            if (
              roles.includes("Cashier User") ||
              roles.includes("Admin User")
            ) {
              if (getIsOpened) next();
              else next(Trans.i18nRoute({ name: "open-shift" }));
            } else if (roles.includes("Service User")) next();
            else {
              next(Trans.i18nRoute({ name: "403" }));
            }
          },
          children: [
            {
              path: "table",
              name: "table",
              meta: {
                title: "Table",
                requiresLayout: true,
                requiresAuth: true,
              },

              component: TableView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                let getBusinessType = await store.getters[
                  "auth/getBusinessType"
                ];
                if (getBusinessType === "Fast Food") {
                  next(Trans.i18nRoute({ name: "menu" }));
                } else if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User") ||
                  roles.includes("Service User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "menu",
              name: "menu",
              meta: {
                title: "Menu",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: MenuView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User") ||
                  roles.includes("Service User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "delivery",
              name: "delivery",
              meta: {
                title: "Delivery",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: MenuDelivery,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "checkout",
              name: "checkout",
              meta: {
                title: "Checkout",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: CheckoutView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "customer-facing-display",
              name: "customer-facing-display",
              meta: {
                title: "Customer Facing Display",
                requiresLayout: false,
                requiresAuth: true,
              },
              component: CustomerFacingDisplayView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
          ],
        },
        {
          path: "retail",
          beforeEnter: async (to, from, next) => {
            let roles = await store.getters["auth/getUserRoles"];
            let getIsOpened = await store.getters["auth/getIsOpened"];
            if (
              roles.includes("Cashier User") ||
              roles.includes("Admin User")
            ) {
              if (getIsOpened) next();
              else next(Trans.i18nRoute({ name: "open-shift" }));
            } else if (roles.includes("Service User")) next();
            else {
              next(Trans.i18nRoute({ name: "403" }));
            }
          },
          children: [
            {
              path: "",
              name: "retail",
              meta: {
                title: "Retail",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: RetailView,
              beforeEnter: async (to, from, next) => {
                await store.dispatch("retail/getUserProfile");
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User") ||
                  roles.includes("Service User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "customer-facing-display-retail",
              name: "customer-facing-display-retail",
              meta: {
                title: "Customer Facing Display",
                requiresLayout: false,
                requiresAuth: true,
              },
              component: CustomerFacingDisplayRetailView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
          ],
        },
        {
          path: "floors",
          children: [
            {
              path: "",
              name: "floor",
              meta: {
                title: "Floor",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: FloorView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "table",
              name: "setup-table",
              meta: {
                title: "Table",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: SetupTableView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "qr-menu",
              name: "qr-menu",
              meta: {
                title: "QR Menu",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: QRMenuView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
          ],
        },
        {
          path: "menus",
          children: [
            {
              path: "",
              name: "setup-menu",
              meta: {
                title: "Menu Group",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: MenuGroupView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "item-group",
              name: "item-group",
              meta: {
                title: "Item Group",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: ItemGroupView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "modifier",
              name: "modifier",
              meta: {
                title: "Modifier",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: ModifierView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "item",
              children: [
                {
                  path: "",
                  name: "item",
                  meta: {
                    title: "Item",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: ItemView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: "create",
                  name: "create-item",
                  meta: {
                    title: "New Item",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CreateItemView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: ":id",
                  name: "view-item",
                  meta: {
                    title: "Item",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: EditItemView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
              ],
            },
            {
              path: "kitchen-drink",
              children: [
                {
                  path: "",
                  name: "kitchen-drink",
                  meta: {
                    title: "Kitchen/Drink Station",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: KitchenView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: "create",
                  name: "create-kitchen-drink",
                  meta: {
                    title: "New Kitchen/Drink Station",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CreateKitchenView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: ":id",
                  name: "view-kitchen-drink",
                  meta: {
                    title: "Kitchen/Drink Station",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: EditKitchenView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
              ],
            },
          ],
        },
        {
          path: "stock",
          children: [
            {
              path: "purchase-invoice",
              children: [
                {
                  path: "",
                  name: "purchase-invoice",
                  meta: {
                    title: "Purchase Invoice",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: PurchaseInvoiceView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: "create",
                  name: "create-purchase-invoice",
                  meta: {
                    title: "New Purchase Invoice",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CreatePurchaseInvoiceView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: ":id",
                  name: "view-purchase-invoice",
                  meta: {
                    title: "Purchase Invoice",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: EditPurchaseInvoiceView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
              ],
            },
            {
              path: "uom",
              name: "uom",
              meta: {
                title: "UOM",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: UOMView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  // roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "uom-conversion-factor",
              name: "uom-conversion-factor",
              meta: {
                title: "UOM Conversion Factor",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: UOMConversionView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  // roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "supplier",
              children: [
                {
                  path: "",
                  name: "supplier",
                  meta: {
                    title: "Supplier",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: SupplierView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: "create",
                  name: "create-supplier",
                  meta: {
                    title: "New Supplier",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CreateSupplierView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: ":id",
                  name: "view-supplier",
                  meta: {
                    title: "Supplier",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: EditSupplierView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
              ],
            },
            {
              path: "supplier-group",
              name: "supplier-group",
              meta: {
                title: "supplier Groups",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: SupplierGroupView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  // roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
          ],
        },
        {
          path: "users",
          children: [
            {
              path: "",
              children: [
                {
                  path: "",
                  name: "user",
                  meta: {
                    title: "Users",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: UserView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: "create",
                  name: "create-user",
                  meta: {
                    title: "New User",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CreateUserView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    let allowCreateUser = await store.getters[
                      "auth/getAllowCreateUser"
                    ];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User") &&
                      allowCreateUser
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: ":id",
                  name: "view-user",
                  meta: {
                    title: "User",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: EditUserView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: "delete-user",
                  name: "delete-user",
                  meta: {
                    title: "Reques Delete User",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: DialogDeleteView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
              ],
            },
            {
              path: "customer",
              children: [
                {
                  path: "",
                  name: "customers",
                  meta: {
                    title: "Customers",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CustomerView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: "create",
                  name: "create-customer",
                  meta: {
                    title: "New Customer",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CreateCustomerView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: ":id",
                  name: "view-customer",
                  meta: {
                    title: "Customer",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: EditCustomerView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
              ],
            },
            {
              path: "customer-group",
              name: "customer-group",
              meta: {
                title: "Customer Groups",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: CustomerGroupView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  // roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
          ],
        },
        {
          path: "promotions",
          children: [
            {
              path: "",
              name: "coupon",
              meta: {
                title: "Coupon",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: CouponView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  // roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "offer",
              children: [
                {
                  path: "",
                  name: "offer",
                  meta: {
                    title: "Offer",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: OfferView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (roles.includes("Admin User")) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: "create",
                  name: "create-offer",
                  meta: {
                    title: "New Offer",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CreateOfferView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (roles.includes("Admin User")) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: ":id",
                  name: "view-offer",
                  meta: {
                    title: "Offer",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: EditOfferView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
              ],
            },
            {
              path: "loyalty",
              children: [
                {
                  path: "",
                  name: "loyalty",
                  meta: {
                    title: "Loyalty Program",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: LoyaltyView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (roles.includes("Admin User")) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: "create",
                  name: "create-loyalty",
                  meta: {
                    title: "New Loyalty Program",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CreateLoyaltyView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (roles.includes("Admin User")) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: ":id",
                  name: "view-loyalty",
                  meta: {
                    title: "Loyalty Program",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: EditLoyaltyView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      // roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
              ],
            },
          ],
        },
        {
          path: "settings",
          children: [
            {
              path: "",
              name: "setting",
              meta: {
                title: "Setting",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: SettingView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  // roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "payments",
              name: "payment",
              meta: {
                title: "Payment",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: PaymentView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  // roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
          ],
        },
        {
          path: "live",
          children: [
            {
              path: "live-comments",
              name: "live-comments",
              meta: {
                title: "Comments",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: LiveCommentsView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },

            {
              path: "live-customer",
              name: "live-customer",
              meta: {
                title: "Customer",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: LiveCustomerView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "create",
              name: "create-live-customer",
              meta: {
                title: "New Customer",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: CreateLiveCustomerView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "view-live-customer/:id",
              name: "view-live-customer",
              meta: {
                title: "Customer",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: ViewLiveCustomerView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "live-comment/:id",
              name: "live-comment",
              meta: {
                title: "Comment",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: LiveCommentView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
          ],
        },
        {
          path: "profile",
          name: "profile",
          meta: {
            title: "Profile",
            requiresLayout: true,
            requiresAuth: true,
          },
          component: ProfileView,
          beforeEnter: async (to, from, next) => {
            let roles = await store.getters["auth/getUserRoles"];
            if (
              roles.includes("Cashier User") ||
              roles.includes("Admin User")
            ) {
              next();
            } else {
              next(Trans.i18nRoute({ name: "403" }));
            }
          },
        },
        {
          path: "report",
          meta: {
            title: "Report",
            requiresLayout: true,
            requiresAuth: true,
          },
          children: [
            {
              path: "close-shift",
              meta: {
                title: "Close Shift",
                requiresLayout: true,
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  name: "close-shift",
                  meta: {
                    title: "Close Shift",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CloseShiftView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: ":id",
                  name: "close-shift-detail",
                  meta: {
                    title: "Close Shift",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: CloseShiftDetailView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
              ],
            },
            {
              path: "delete-item",
              name: "delete-item",
              meta: {
                title: "Delete Item",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: DeleteItemView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "sale-transactions",
              name: "sale-transactions",
              meta: {
                title: "Sale Transactions",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: SaleTransactionsView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "total-stock-summary",
              name: "total-stock-summary",
              meta: {
                title: "Total Stock Summary",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: TotalStockSummaryView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "qty-on-hand",
              name: "qty-on-hand",
              meta: {
                title: "Qty On Hand",
                requiresLayout: true,
                requiresAuth: true,
              },
              component: QtyOnHandView,
              beforeEnter: async (to, from, next) => {
                let roles = await store.getters["auth/getUserRoles"];
                if (
                  roles.includes("Cashier User") ||
                  roles.includes("Admin User")
                ) {
                  next();
                } else {
                  next(Trans.i18nRoute({ name: "403" }));
                }
              },
            },
            {
              path: "end-day",
              meta: {
                title: "End of Day",
                requiresLayout: true,
                requiresAuth: true,
              },
              children: [
                {
                  path: "",
                  name: "end-day",
                  meta: {
                    title: "End of Day Detail",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: EndDayView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
                {
                  path: ":id",
                  name: "end-day-detail",
                  meta: {
                    title: "End of Day",
                    requiresLayout: true,
                    requiresAuth: true,
                  },
                  component: EndDayDetailView,
                  beforeEnter: async (to, from, next) => {
                    let roles = await store.getters["auth/getUserRoles"];
                    if (
                      roles.includes("Cashier User") ||
                      roles.includes("Admin User")
                    ) {
                      next();
                    } else {
                      next(Trans.i18nRoute({ name: "403" }));
                    }
                  },
                },
              ],
            },
          ],
        },
        {
          path: "service-kitchen-drink",
          name: "service-kitchen-drink",
          meta: {
            title: "Bar Kitchen",
            requiresLayout: true,
            requiresAuth: true,
          },
          component: KitchenDrinkView,
          beforeEnter: async (to, from, next) => {
            let roles = await store.getters["auth/getUserRoles"];
            if (
              roles.includes("Cashier User") ||
              roles.includes("Admin User") ||
              roles.includes("Kitchen User")
            ) {
              next();
            } else {
              next(Trans.i18nRoute({ name: "403" }));
            }
          },
        },
        {
          path: "customer-service",
          name: "customer-service",
          meta: {
            title: "Customer Service",
            requiresLayout: false,
            requiresAuth: false,
          },
          component: CustomerServiceView,
        },
        {
          path: "403",
          name: "403",
          meta: {
            title: "403",
            requiresLayout: false,
            requiresAuth: true,
          },
          component: ForbiddenView,
        },
        {
          path: "404",
          name: "404",
          meta: {
            title: "404",
            requiresLayout: false,
            requiresAuth: true,
          },
          component: NotFoundView,
        },
      ],
    },
  ],
});

export default router;

router.beforeEach(async (to, from, next) => {
  if (to.matched.length === 0) next(Trans.i18nRoute({ name: "404" }));
  else next();
  document.title = `Ditech | ${to.meta.title}` || "Ditech";
});
