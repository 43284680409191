<template>
  <div
    id="infinite-list"
    class="w-full px-5 overflow-x-auto h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]"
  >
    <NavBarView />
    <div class="py-4 flex justify-between items-center w-full">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold  dark:text-gray-100">
          Live Comments
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'live-comments' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >Live Comments</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <Menu as="div" class="relative">
        <div>
          <MenuButton
            v-if="dataSelectedItem.length > 0"
            class="flex items-center text-sm py-2 lg:py-[10px] pl-4 pr-3 bg-secondary text-white font-medium rounded-md justify-center"
          >
            Actions<svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 ml-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
              />
            </svg>
          </MenuButton>
        </div>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-gray-500 rounded-md bg-white dark:bg-slate-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <button
                  @click="handleDeleteItem"
                  class="text-left text-gray-800 dark:text-gray-100 block px-4 py-2 text-md font-bold w-full"
                >
                  Delete
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <div class="w-96 mb-3">
      <multiselect
        v-model="dataSelectedCustomer"
        :options="dataCustomer"
        placeholder=""
        label="full_name"
        track-by="name"
        :allow-empty="false"
        deselect-label="Can't remove this value"
      ></multiselect>
    </div>
    <div
      class="inline-block overflow-x-scroll w-full sm:w-[calc(100vw_-_78px)] lg:w-[calc(100vw_-_111px)]"
    >
      <TableComponent
        :columns="columns"
        :items="renderItems(items)"
        scrollX="35rem"
        :onClickRow="
          (e) =>
            $router.push(
              Tr.i18nRoute({
                ...{ name: '' },
                params: { id: e.name },
              })
            )
        "
        :onSelectRow="(data) => (dataSelectedItem = data)"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'created_time'">
            {{ $d(record.created_time, "long") }}
          </template>
        </template>
      </TableComponent>
    </div>
    <ModalComponent
      title="Confirm"
      :isModalOpen="isDeleteMessage"
      :onToggle="() => (this.isDeleteMessage = false)"
      :width="378"
      :freeSpaceClose="true"
    >
      <div class="p-2">
        <h1 class="dark:text-gray-100">Do you want to delete customer?</h1>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          @click="isDeleteMessage = false"
          class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
        >
          Cancel
        </button>
        <button
          @click="handleYesDeleteItem"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Yes
        </button>
      </div>
    </ModalComponent>
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
const columns = [
  {
    key: "no",
    label: "#",
    dataIndex: "no",
    width: 20,
    align: "center",
  },
  {
    key: "full_name",
    label: "Name",
    dataIndex: "full_name",
  },
  {
    key: "message",
    label: "Comments",
    dataIndex: "message",
  },
  {
    key: "created_time",
    label: "Date/Time",
  },
];
import Multiselect from "vue-multiselect";
import TableComponent from "@/components/table/TableComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import imageEmpty from "@/assets/image/empty.jpg";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { renderItems } from "@/utils/globleFunction";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import NavBarView from "../NavBarView.vue";

export default {
  name: "Bar",
  components: {
    TableComponent,
    SpinComponent,
    ModalComponent,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Multiselect,
    NavBarView,
  },
  data() {
    return {
      Tr,
      renderItems,
      columns,
      limit: 20,
      page: 1,
      items: [],
      imageEmpty,
      loading: false,
      dataSelectedItem: [],
      isDeleteMessage: false,
      dataCustomer: [],
      dataSelectedCustomer: null,
      es: null,
    };
  },
  created() {
    this.getDataCustomer();
  },
  watch: {
    dataSelectedCustomer(newCustomer, oldCustomer) {
      if (newCustomer) {
        this.items = [];
        this.getItems();
      }
    },
  },

  mounted() {
    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", (e) => {
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        this.totalItems > this.items.length
      ) {
        this.page++;
        this.getItems();
      }
    });
  },
  methods: {
    nameWithLang({ name, language }) {
      return `${name} — [${language}]`;
    },
    getDataCustomer() {
      this.loading = true;
      dataService.get("get_data_live_customers_select").then((res) => {
        this.dataCustomer = res.data.message;
        this.loading = false;
      });
    },
    getItems() {
      this.loading = true;
      dataService
        .get(
          "get_data_live_comment",
          `limit=${this.limit}&&page=${this.page}&&customer=${this.dataSelectedCustomer.name}`
        )
        .then((res) => {
          let { items, total } = res.data.message;
          this.items.push(...items);
          this.totalItems = total;
          this.loading = false;
        });
    },
    handleDeleteItem() {
      this.isDeleteMessage = true;
    },
    handleYesDeleteItem() {
      this.loading = true;
      dataService
        .post("delete_data_live_comment", {
          items: this.dataSelectedItem.map((item) => item.name),
        })
        .then((res) => {
          dispatchNotification({
            content: "Detete customer successfully",
            type: "success",
          });
          this.isDeleteMessage = false;
          this.loading = false;
          this.dataSelectedItem = [];
          this.items = [];
          this.getItems();
        });
    },
  },
};
</script>
