<template>
  <div class="w-full">
    <label
      v-show="label != ''"
      :for="id"
      class="block text-sm font-medium leading-6 dark:text-gray-100"
      :class="
        disabled
          ? ' text-gray-500 dark:text-gray-400 '
          : 'text-gray-900 dark:text-gray-200'
      "
      >{{ label }} <span v-if="isRequired" class="text-red-400">*</span></label
    >
    <div class="relative">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 dark:text-gray-200"
        :class="
          disabled ? ' text-gray-500 dark:text-gray-400 ' : 'dark:text-gray-200'
        "
      >
        {{ prefix }}
      </div>
      <input
        :type="type"
        :id="id"
        :required="isRequired"
        :disabled="isDisabled"
        :readonly="isReadonly"
        :value="modelValue"
        @input="onChange"
        class="w-full px-3 py-[7px] border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
        :class="[
          disabled
            ? 'cursor-not-allowed text-gray-500 dark:text-gray-400 '
            : 'dark:text-gray-200',
          prefix == '' ? 'px-2' : 'px-6 ',
        ]"
      />
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 dark:text-gray-200"
        :class="
          disabled ? ' text-gray-500 dark:text-gray-400 ' : 'dark:text-gray-200'
        "
      >
        {{ suffix }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    label: {
      type: String,
      rerequired: false,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {},
    required: {
      type: Boolean,
      rerequired: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      rerequired: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      rerequired: false,
      default: false,
    },
    change: {
      type: Function,
      rerequired: false,
    },
    prefix: {
      type: String,
      rerequired: false,
      default: "",
    },
    suffix: {
      type: String,
      rerequired: false,
      default: "",
    },
  },
  computed: {
    isRequired() {
      return this.required ? "required" : null;
    },
    isDisabled() {
      return this.disabled ? "disabled" : null;
    },
    isReadonly() {
      return this.readonly ? "readonly" : null;
    },
  },
  methods: {
    onChange(event) {
      this.$emit("update:modelValue", event.target.value);
      if (this.change) this.change(event);
    },
  },
};
</script>
