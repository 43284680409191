import { createApp } from "vue/dist/vue.esm-bundler";
import App from "./App.vue";
import "./assets/style/main.scss";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueNumberFormat from "@coders-tm/vue-number-format";
import Multiselect from "vue-multiselect";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import Chart from "vue-frappe-chart";
import mitt from 'mitt'

const emitter = mitt()
const app = createApp(App)

app.config.globalProperties.emitter = emitter
app.use(store);
app.use(Chart);
app.use(VueTailwindDatepicker);
app.use(VueNumberFormat, {
  decimal: ".",
  separator: ",",
  prefix: "",
  suffix: "",
  precision: 2,
  nullValue: "",
  masked: false,
  minimumFractionDigits: "",
  reverseFill: false,
  prefill: false,
});
app.component("multiselect", Multiselect);
app.use(router);
app.use(i18n);
app.mount("#app");
