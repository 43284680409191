<template>
    <svg class="hidden">
        <symbol id="light" viewBox="0 0 24 24">
            <g stroke="currentColor" stroke-width="2" stroke-linecap="round">
                <line x1="12" y1="17" x2="12" y2="20" transform="rotate(0,12,12)" />
                <line x1="12" y1="17" x2="12" y2="20" transform="rotate(45,12,12)" />
                <line x1="12" y1="17" x2="12" y2="20" transform="rotate(90,12,12)" />
                <line x1="12" y1="17" x2="12" y2="20" transform="rotate(135,12,12)" />
                <line x1="12" y1="17" x2="12" y2="20" transform="rotate(180,12,12)" />
                <line x1="12" y1="17" x2="12" y2="20" transform="rotate(225,12,12)" />
                <line x1="12" y1="17" x2="12" y2="20" transform="rotate(270,12,12)" />
                <line x1="12" y1="17" x2="12" y2="20" transform="rotate(315,12,12)" />
            </g>
            <circle fill="currentColor" cx="12" cy="12" r="5" />
        </symbol>
        <symbol id="dark" viewBox="0 0 24 24">
            <path fill="currentColor"
                d="M15.1,14.9c-3-0.5-5.5-3-6-6C8.8,7.1,9.1,5.4,9.9,4c0.4-0.8-0.4-1.7-1.2-1.4C4.6,4,1.8,7.9,2,12.5c0.2,5.1,4.4,9.3,9.5,9.5c4.5,0.2,8.5-2.6,9.9-6.6c0.3-0.8-0.6-1.7-1.4-1.2C18.6,14.9,16.9,15.2,15.1,14.9z" />
        </symbol>
    </svg>
    <label class="switch cursor-pointer">
        <input class="switch__input" type="checkbox" role="switch" name="dark" v-model="isDarkMode" @change="updateDarkMode(isDarkMode)" />
        <svg class="switch__icon" width="24px" height="24px" aria-hidden="true">
            <use href="#light" />
        </svg>
        <svg class="switch__icon" width="24px" height="24px" aria-hidden="true">
            <use href="#dark" />
        </svg>
        <span class="switch__inner"></span>
        <span class="switch__inner-icons">
            <svg class="switch__icon" width="24px" height="24px" aria-hidden="true">
                <use href="#light" />
            </svg>
            <svg class="switch__icon" width="24px" height="24px" aria-hidden="true">
                <use href="#dark" />
            </svg>
        </span>
        <span class="switch__sr">Dark Mode</span>
    </label>
</template>

<script>
import { updateDarkMode } from "@/utils/globleFunction";

export default {
    data() {
        return {
            isDarkMode: false,
            updateDarkMode,
        };
    },
    mounted() {
        const storedDarkMode = localStorage.getItem("darkMode");
        this.isDarkMode = storedDarkMode === "true";
    },
};
</script>

<style >
.switch,
.switch__input {
    display: block;
    -webkit-tap-highlight-color: transparent;
}

.switch {
    margin: auto;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.switch__icon {
    color: hsla(223, 10%, 80%);
    pointer-events: none;
    position: absolute;
    top: 0.375em;
    left: 0.375em;
    width: 0.75em;
    height: 0.75em;
    transition:
        color 0.6s,
        transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}

.switch__icon:nth-of-type(2) {
    right: 0.375em;
    left: auto;
}

.switch__inner,
.switch__inner-icons {
    border-radius: 0.5em;
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0.25em;
    left: 0.25em;
    width: 2.25em;
    height: 1em;
}

.switch__inner:before,
.switch__inner-icons {
    transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateX(-1.25em);
}

.switch__inner:before {
    background-color: #023E7D;
    border-radius: inherit;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
}

.switch__inner-icons {
    pointer-events: none;
}

.switch__inner-icons .switch__icon {
    color: hsl(0, 0%, 100%);
    top: 0.125em;
    left: 0.125em;
    transform: translateX(1.25em);
}

.switch__inner-icons .switch__icon:nth-child(2) {
    right: 0.125em;
    left: auto;
}

.switch__input {
    background-color: #f1f0f0;
    border-radius: 0.75em;
    outline: transparent;
    width: 2.75em;
    height: 1.5em;
    -webkit-appearance: none;
    appearance: none;
    transition:
        background-color 0.6s,
        box-shadow 0.6s;
}

.switch__input:checked {
    background-color: hsl(223, 10%, 10%);
}

.switch__input:checked~.switch__icon {
    color: hsla(223, 10%, 40%);
}

.switch__input:checked~.switch__inner:before,
.switch__input:checked~.switch__inner-icons {
    transform: translateX(1.25em);
}

.switch__input:not(:checked)~.switch__icon:first-of-type,
.switch__input:checked~.switch__icon:nth-of-type(2) {
    transform: rotate(360deg);
}

.switch__input:checked~.switch__inner-icons .switch__icon:first-of-type {
    transform: translateX(-1.25em) rotate(-360deg);
}

.switch__input:checked~.switch__inner-icons .switch__icon:nth-of-type(2) {
    transform: translateX(-1.25em) rotate(360deg);
}

.switch__input:focus-visible {
    box-shadow:
        0 0 0 0.0625em hsla(223, 90%, 50%, 1),
        0 0.125em 0.5em hsla(223, 10%, 10%, 0.1);
}

.switch__sr {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}
</style>