<template>
  <div class="w-full p-2 lg:p-4 text-xs sm:text-sm">
    <div v-show="dataFloor.length > 0" class="flex justify-center mb-4 lg:mb-5">
      <div
        class="w-auto max-w-[460px] lg:max-w-[750px] overflow-x-auto no-scrollbar text-gray-600 bg-gray-200 dark:text-gray-200 dark:bg-slate-700 rounded-full"
      >
        <ul class="grid grid-flow-col text-center">
          <li v-for="item in dataFloor" :key="item.name">
            <span
              @click="() => handleSelectFLoor(item.name)"
              class="flex justify-center cursor-pointer py-1 lg:py-2 w-24 lg:w-40"
              :class="[
                item.name === selectMenuFloor &&
                  'bg-primary rounded-full shadow text-white',
              ]"
              >{{ item.label }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="flex justify-center w-full">
      <div
        class="overflow-x-auto no-scrollbar text-gray-600 bg-gray-200 dark:text-gray-200 dark:bg-slate-700 rounded-full"
      >
        <ul class="grid grid-flow-col text-center">
          <li v-for="item in menuTable" :key="item.key">
            <span
              @click="() => handleSelectMenuTable(item.key)"
              class="flex justify-center cursor-pointer py-1 lg:py-2 w-24 lg:w-40"
              :class="[
                item.key === selectMenuTable &&
                  'bg-secondary rounded-full shadow text-white',
              ]"
              >{{ item.title }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="relative">
      <SpinComponent :show="loadingGetTable" />
      <EmptyComponent :show="dataTables.length == 0 && !loadingGetTable" />
      <div
        id="infinite-list"
        class="mt-4 overflow-auto h-[calc(100vh_-_148px)] sm:h-[calc(100vh_-_144px)] lg:h-[calc(100vh_-_206px)] dir-rtl pr-2"
      >
        <DeliveryView v-if="showDelivery" />
        <ReservedView v-else-if="showReserved" />
        <div v-else>
          <div
            class="grid gap-2 lg:gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 text-white"
          >
            <div
              v-for="item in dataTables"
              :key="item.name"
              class="flex items-center justify-center text-center w-full h-10 lg:h-16 cursor-pointer rounded-lg relative"
              :class="
                item.status === 'Occupied'
                  ? 'bg-primary'
                  : item.status === 'Invoice'
                  ? 'bg-stone-400'
                  : 'bg-secondary'
              "
              @click="handleTable(item)"
            >
              <span
                v-if="item.status === 'reserved'"
                class="absolute top-1 right-1 text-[11px] font-bold leading-none text-white"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="h-4 w-4 lg:w-5 lg:h-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <p class="ellipsis">{{ item.label }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalComponent
      :isModalOpen="isModalOpen"
      :subtitle="dataTable?.label"
      title="Choose an Action For Table:"
      :onToggle="() => (this.isModalOpen = false)"
      :width="400"
    >
      <div class="grid grid-row gap-4">
        <div v-if="dataTable.status != 'Invoice'">
          <div
            class="inline-flex cursor-pointer items-center py-2 lg:py-[10px] bg-primary text-white text-sm font-medium rounded-md w-full justify-center"
            @click="handleAddView"
          >
            <svg
              class="h-5 w-5 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4v16m8-8H4"
              />
            </svg>
            Add / View
          </div>
        </div>
        <div>
          <div
            @click="handleMove"
            class="inline-flex cursor-pointer items-center py-2 lg:py-[10px] bg-secondary text-white text-sm font-medium rounded-md w-full justify-center"
          >
            <svg
              class="h-5 w-5 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
              />
            </svg>

            Move
          </div>
        </div>
        <div>
          <div
            @click="handleMerge"
            class="inline-flex cursor-pointer items-center py-2 lg:py-[10px] bg-secondary text-white text-sm font-medium rounded-md w-full justify-center"
          >
            <svg
              class="h-5 w-5 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
              />
            </svg>
            Merge
          </div>
        </div>
        <div v-if="this.dataTable?.merge.split(',').length > 1">
          <div
            @click="handleUnMerge"
            class="inline-flex cursor-pointer items-center py-2 lg:py-[10px] bg-secondary text-white text-sm font-medium rounded-md w-full justify-center"
          >
            <svg
              class="h-5 w-5 mr-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <path
                d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
              />
              <path d="M7 12h14l-3 -3m0 6l3 -3" />
            </svg>
            Unmerge
          </div>
        </div>
        <div v-if="userRoleCashier">
          <div
            @click="handlePayment"
            class="inline-flex cursor-pointer items-center py-2 lg:py-[10px] bg-primary text-white text-sm font-medium rounded-md w-full justify-center"
          >
            <svg
              class="h-5 w-5 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Payment
          </div>
        </div>
      </div>
    </ModalComponent>
    <ModalComponent
      :isModalOpen="isModalOpenMove"
      title="Move or Transfer Items"
      :onToggle="() => this.setIsModalOpenMove(false)"
      :width="1200"
    >
      <div class="py-2">
        <div class="grid grid-cols-11">
          <div class="col-span-5 px-4">
            <label
              class="text-4x flex space-x-2 items-center dark:text-gray-200"
              >Table
            </label>
            <div class="relative rounded-md shadow-sm">
              <multiselect
                v-model="selectedTableTransferOne"
                track-by="name"
                label="label"
                placeholder=""
                :options="dataTablesDifferentInvoice"
              />
            </div>
          </div>
          <div></div>
          <div class="col-span-5 px-4">
            <label
              class="text-4x flex space-x-2 items-center dark:text-gray-200"
              >Table
            </label>
            <div class="relative rounded-md shadow-sm">
              <multiselect
                v-model="selectedTableTransferTwo"
                track-by="name"
                label="label"
                placeholder=""
                :options="dataTablesDifferent"
              />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-11 mt-4 items-center">
          <div class="col-span-5">
            <div
              class="relative divide-y divide-gray-100 dark:divide-gray-500 h-[calc(100vh_-_468px)] overflow-y-auto"
            >
              <EmptyComponent v-show="dataItemCartOne.length == 0" />
              <article
                class="relative border-b border-gray-200 dark:border-gray-800 text-gray-700 dark:text-gray-200"
                v-for="item in dataItemCartOne"
                :key="item.id"
              >
                <input
                  type="checkbox"
                  :value="item"
                  @change="handleSelectedItemTransferOne"
                  class="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
                <div
                  class="grid grid-cols-12 p-4 items-center"
                  :class="
                    selectedItemTransferOne.find((i) => i.name == item.name) &&
                    'bg-slate-300 dark:bg-slate-900'
                  "
                >
                  <div class="col-span-7">
                    {{ item.item_name }} ({{ item.qty }})
                  </div>
                  <div class="col-span-2 text-right">
                    {{
                      item.currency_symbol +
                      " " +
                      $n(
                        item.price_list_rate,
                        "decimal",
                        `${item.currency === "USD" ? "en" : "kh"}`
                      )
                    }}
                  </div>
                  <div class="col-span-3 text-right">
                    {{
                      item.currency_symbol +
                      " " +
                      $n(
                        item.amount * item.qty,
                        "decimal",
                        `${item.currency === "USD" ? "en" : "kh"}`
                      )
                    }}
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div class="space-y-4">
            <div class="flex justify-center">
              <div class="space-y-4">
                <button
                  type="button"
                  class="dark:text-gray-200 border p-2 rounded-xl block"
                  @click="handleMoveItemToCartTwo"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  class="dark:text-gray-200 border p-2 rounded-xl block"
                  @click="moveAllItemsToCart(1)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  class="dark:text-gray-200 border p-2 rounded-xl block"
                  @click="moveAllItemsToCart(2)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  class="dark:text-gray-200 border p-2 rounded-xl block"
                  @click="handleMoveItemToCartOne"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="col-span-5">
            <div
              class="relative divide-y divide-gray-100 dark:divide-gray-500 h-[calc(100vh_-_468px)] overflow-y-auto"
            >
              <EmptyComponent v-show="dataItemCartTwo.length == 0" />
              <article
                class="relative border-b border-gray-200 dark:border-gray-800 text-gray-700 dark:text-gray-200"
                v-for="item in dataItemCartTwo"
                :key="item.id"
              >
                <input
                  type="checkbox"
                  :value="item"
                  @change="handleSelectedItemTransferTwo"
                  class="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                />
                <div
                  class="grid grid-cols-12 p-4 items-center"
                  :class="
                    selectedItemTransferTwo.find((i) => i.name == item.name) &&
                    'bg-slate-300 dark:bg-slate-900'
                  "
                >
                  <div class="col-span-7">
                    {{ item.item_name }} ({{ item.qty }})
                  </div>
                  <div class="col-span-2 text-right">
                    {{
                      item.currency_symbol +
                      " " +
                      $n(
                        item.price_list_rate,
                        "decimal",
                        `${item.currency === "USD" ? "en" : "kh"}`
                      )
                    }}
                  </div>
                  <div class="col-span-3 text-right">
                    {{
                      item.currency_symbol +
                      " " +
                      $n(
                        item.amount * item.qty,
                        "decimal",
                        `${item.currency === "USD" ? "en" : "kh"}`
                      )
                    }}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between pt-2 lg:pt-4 items-end">
        <button
          @click="handleResetItemsMoved"
          class="flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-primary font-medium rounded-md underline underline-offset-2"
        >
          Reset
        </button>
        <button
          @click="handleSaveMove"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Save
        </button>
      </div>
    </ModalComponent>
    <ModalComponent
      :isModalOpen="isModalOpenMerge"
      title="Merge Table"
      :onToggle="() => (this.isModalOpenMerge = false)"
      :width="600"
    >
      <div class="py-2">
        <div class="px-4 mb-8">
          <label class="text-4x flex space-x-2 items-center dark:text-gray-200"
            >Table
          </label>
          <div class="relative rounded-md shadow-sm">
            <multiselect
              v-model="selectedTableMergeOne"
              track-by="name"
              label="label"
              placeholder=""
              :options="dataTablesOccupied"
            />
          </div>
        </div>
        <div class="px-4">
          <label class="text-4x flex space-x-2 items-center dark:text-gray-200"
            >Tables
          </label>
          <div class="relative rounded-md shadow-sm">
            <multiselect
              v-model="selectedTableMergeTwo"
              placeholder=""
              track-by="name"
              label="label"
              :options="dataTablesDifferentOccupied"
              :multiple="true"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-between pt-2 lg:pt-4 items-end">
        <button
          @click="selectedTableMergeOne = null"
          class="flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-primary font-medium rounded-md underline underline-offset-2"
        >
          Reset
        </button>
        <button
          @click="handleSaveMerge"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Save
        </button>
      </div>
    </ModalComponent>
    <ModalComponent
      title="How many quantities?"
      :isModalOpen="isModalOpenQty"
      :onToggle="
        () => {
          this.isModalOpenQty = false;
        }
      "
      :width="500"
    >
      <KeypadComponent :handleKeypadOk="handleKeypadOk" defaultValue="1" />
    </ModalComponent>
    <SpinComponent :show="loading" />
    <SpinComponent :show="getIsLoading" />
  </div>
</template>

<script>
const menuTable = [
  {
    key: "all",
    title: "All",
  },
  {
    key: "Opened",
    title: "Open",
  },
  {
    key: "Occupied",
    title: "Occupied",
  },
  {
    key: "Invoice",
    title: "Invoiced",
  },
  // {
  //   key: "delivery",
  //   title: "Delivery",
  // },
  // {
  //   key: "reserved",
  //   title: "Reserved",
  // },
];

import ModalComponent from "@/components/modal/ModalComponent.vue";
import TableComponent from "@/components/table/TableComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import DeliveryView from "./DeliveryView.vue";
import ReservedView from "./ReservedView.vue";
import EmptyComponent from "@/components/empty/EmptyComponent.vue";
import KeypadComponent from "@/components/keypad/KeypadComponent.vue";
import dataService from "@/services/data.service";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import Tr from "@/i18n/translation";
import { mapActions, mapGetters } from "vuex";
import { dispatchNotification } from "@/components/notification";
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";

export default {
  name: "Home",
  components: {
    ModalComponent,
    TableComponent,
    DeliveryView,
    ReservedView,
    SpinComponent,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    EmptyComponent,
    KeypadComponent,
  },
  data() {
    return {
      dataFloor: [],
      dataTables: [],
      selectMenuFloor: null,
      menuTable,
      selectMenuTable: "all",
      isModalOpen: false,
      dataTable: null,
      isModalOpenMerge: false,
      showDelivery: false,
      showReserved: false,
      loading: false,
      loadingGetTable: false,
      selectedItemTransferOne: [],
      selectedItemTransferTwo: null,
      dataTableOne: null,
      selectedTableTransferOne: null,
      selectedTableTransferTwo: null,
      dataTablesDifferent: [],
      dataTablesDifferentInvoice: [],
      dataTablesOccupied: [],
      dataTablesDifferentOccupied: [],
      selectedTableMergeOne: null,
      selectedTableMergeTwo: null,
      limit: 50,
      page: 1,
      scrollTop: 0,
      totalItems: 0,
      itemQty: null,
      isModalOpenQty: false,
      isItem: 0,
    };
  },
  watch: {
    selectedTableTransferOne(newData) {
      this.getDataItemCartOne(`key=${newData?.sales_invoice}`);
      this.getDataItemCartTwo("key=");
      this.getDataTablesDifferent(newData?.name);
      this.selectedTableTransferTwo = null;
      this.setDataTableOne(newData);
      this.setDataTableTwo(null);
      this.selectedItemTransferTwo = [];
      this.selectedItemTransferOne = [];
    },
    selectedTableTransferTwo(newData) {
      this.getDataItemCartTwo(`key=${newData?.sales_invoice}`);
      this.setDataTableTwo(newData);
      this.getDataItemCartOne(
        `key=${this.selectedTableTransferOne?.sales_invoice}`
      );
      this.selectedItemTransferOne = [];
      this.selectedItemTransferTwo = [];
    },
    selectedTableMergeOne(newData) {
      this.getDataTablesDifferentOccupied(newData?.name);
      this.selectedTableMergeTwo = null;
    },
  },
  created() {
    this.getDataFloor();
    this.getDataTable();
  },
  mounted() {
    socket.on(type.refT, (message) => {
      if (
        message.pos_profile === this.getPOSProfile &&
        this.dataTables.length <= this.limit
      ) {
        dataService
          .get(
            "get_tables",
            `floor=${
              this.selectMenuFloor ? this.selectMenuFloor : null
            }&&status=${this.selectMenuTable}&&limit=${this.limit}&&page=1`
          )
          .then((res) => {
            let { items, total } = res.data.message;
            this.dataTables = items;
            this.totalItems = total;
          })
          .catch((err) => {
            let error = err.response.data._server_messages;
            if (error)
              dispatchNotification({
                content: JSON.parse(JSON.parse(error)[0]).message,
                type: "warning",
              });
          });
      }
    });

    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", (e) => {
      this.scrollTop = listElm.scrollTop;
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        this.totalItems != this.dataTables.length
      ) {
        this.page++;
        this.getDataTable();
      }
    });
  },
  computed: {
    ...mapGetters("transfer", {
      getIsLoading: "getIsLoading",
      dataItemCartOne: "getCartItemsOne",
      dataItemCartTwo: "getCartItemsTwo",
      getCartTotalOne: "getCartTotalOne",
      getCartTotalTwo: "getCartTotalTwo",
      isModalOpenMove: "getIsModalOpenMove",
      getTotalAmountTaxChargeOne: "getTotalAmountTaxChargeOne",
      getTotalAmountTaxChargeTwo: "getTotalAmountTaxChargeTwo",
    }),
    ...mapGetters("auth", {
      getTax: "getTax",
      getCharge: "getCharge",
      getPOSProfile: "getPOSProfile",
      getUserRoles: "getUserRoles",
    }),
    userRoleCashier() {
      return this.getUserRoles?.includes("Cashier User");
    },
  },
  methods: {
    handleKeypadOk(value) {
      if (Number(value) != 0) {
        if (this.isItem == 1)
          this.selectedItemTransferOne.push({
            ...this.itemQty,
            qty:
              this.itemQty.qty >= Number(value)
                ? Number(value)
                : this.itemQty.qty,
          });
        else
          this.selectedItemTransferTwo.push({
            ...this.itemQty,
            qty:
              this.itemQty.qty >= Number(value)
                ? Number(value)
                : this.itemQty.qty,
          });
        this.itemQty = null;
        this.isModalOpenQty = false;
        this.isItem == 0;
      } else
        dispatchNotification({
          content: "Quantities must be at least 1!",
          type: "warning",
        });
    },
    handleSaveMove() {
      if (
        this.selectedTableTransferOne != null &&
        this.selectedTableTransferTwo != null
      ) {
        this.saveItemsMoved({
          totalCartOne: this.getCartTotalOne(
            this.getTotalAmountTaxChargeOne(this.getTax),
            this.getTotalAmountTaxChargeOne(this.getCharge)
          ),
          totalCartTwo: this.getCartTotalTwo(
            this.getTotalAmountTaxChargeTwo(this.getTax),
            this.getTotalAmountTaxChargeTwo(this.getCharge)
          ),
          pos_profile: this.getPOSProfile,
        });
      } else {
        dispatchNotification({
          content: "Please Select a Table!",
          type: "warning",
        });
      }
    },
    handleSaveMerge() {
      this.loading = true;
      if (
        this.selectedTableMergeOne != null &&
        this.selectedTableMergeTwo != null
      ) {
        const data = {
          tables: [this.selectedTableMergeOne, ...this.selectedTableMergeTwo],
        };
        dataService
          .post("merge_sales_invoice", data)
          .then((res) => {
            socket.emit("send", {
              pos_profile: this.getPOSProfile,
              type: type.refT,
            });
            socket.emit("send", {
              pos_profile: this.getPOSProfile,
              type: type.refCS,
            });
            socket.emit("send", {
              pos_profile: this.getPOSProfile,
              type: type.refS,
            });
            dispatchNotification({
              content: `Your action was successfully!`,
              type: "success",
            });
            this.loading = false;
            this.isModalOpenMerge = false;
          })
          .catch((err) => {
            let error = err.response.data._server_messages;
            if (error)
              dispatchNotification({
                content: JSON.parse(JSON.parse(error)[0]).message,
                type: "warning",
              });
          });
      } else {
        this.loading = false;
        dispatchNotification({
          content: "Please Select a Table!",
          type: "warning",
        });
      }
    },
    handleMerge() {
      if (this.dataTable.invoices_split.length == 1) {
        this.isModalOpenMerge = true;
        this.isModalOpen = false;
        this.selectedTableMergeOne = this.dataTable;
        this.getDataTablesDifferentOccupied(this.dataTable?.name);
        this.getDataTablesOccupied();
      } else
        dispatchNotification({
          content: "This table cannot be merged.",
          type: "warning",
        });
    },
    handleUnMerge() {
      this.loading = true;
      dataService
        .post("unmerge_sales_invoice", { name: this.dataTable.name })
        .then((res) => {
          socket.emit("send", {
            pos_profile: this.getPOSProfile,
            type: type.refT,
          });
          socket.emit("send", {
            pos_profile: this.getPOSProfile,
            type: type.refCS,
          });
          socket.emit("send", {
            pos_profile: this.getPOSProfile,
            type: type.refS,
          });
          dispatchNotification({
            content: `Your action was successfully!`,
            type: "success",
          });
          this.loading = false;
          this.isModalOpen = false;
        })
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
        });
    },
    handleSelectedItemTransferOne(event) {
      let value = event.target._value;
      let exit = this.selectedItemTransferOne.find(
        (item) => item.name == value.name
      );
      if (!exit) {
        if (value.qty > 1) {
          this.isModalOpenQty = true;
          this.isItem = 1;
          this.itemQty = value;
        } else this.selectedItemTransferOne.push(value);
      } else {
        this.selectedItemTransferOne = this.selectedItemTransferOne.filter(
          (item) => item.name != value.name
        );
      }
    },
    handleSelectedItemTransferTwo(event) {
      let value = event.target._value;
      let exit = this.selectedItemTransferTwo.find(
        (item) => item.name == value.name
      );
      if (!exit) {
        if (value.qty > 1) {
          this.isModalOpenQty = true;
          this.isItem = 2;
          this.itemQty = value;
        } else this.selectedItemTransferTwo.push(value);
      } else {
        this.selectedItemTransferTwo = this.selectedItemTransferTwo.filter(
          (item) => item.name != value.name
        );
      }
    },
    handleResetItemsMoved() {
      this.getDataItemCartOne(`key=${this.dataTable?.sales_invoice}`);
      this.getDataItemCartTwo("key=");
      this.getDataTablesDifferent(this.dataTable?.name);
      this.selectedTableTransferTwo = null;
      this.setDataTableOne(this.dataTable);
      this.setDataTableTwo(null);
    },
    handleMoveItemToCartTwo() {
      this.selectedItemTransferTwo = [];
      if (this.selectedItemTransferOne.length > 0)
        if (this.selectedTableTransferTwo != null) {
          this.moveItemToCartTwo(this.selectedItemTransferOne);
          this.selectedItemTransferOne = [];
        } else {
          dispatchNotification({
            content: "Please Select a Table!",
            type: "warning",
          });
        }
      else
        dispatchNotification({
          content: "Please select item!",
          type: "warning",
        });
    },
    handleMoveItemToCartOne() {
      this.selectedItemTransferOne = [];
      if (this.selectedItemTransferTwo.length > 0)
        if (this.selectedTableTransferOne != null) {
          this.moveItemToCartOne(this.selectedItemTransferTwo);
          this.selectedItemTransferTwo = [];
        } else {
          dispatchNotification({
            content: "Please Select a Table!",
            type: "warning",
          });
        }
      else
        dispatchNotification({
          content: "Please select item!",
          type: "warning",
        });
    },
    getDataTablesOccupied() {
      dataService
        .get("get_tables_occupied")
        .then((res) => {
          this.dataTablesOccupied = res.data.message;
        })
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
        });
    },
    getDataTablesDifferentOccupied(key) {
      dataService
        .get("get_tables_different_occupied", `key=${key}`)
        .then((res) => {
          this.dataTablesDifferentOccupied = res.data.message;
        })
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
        });
    },
    getDataTablesDifferent(key) {
      dataService
        .get("get_tables_different", `key=${key}`)
        .then((res) => {
          this.dataTablesDifferent = res.data.message;
        })
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
        });
    },
    getDataTablesDifferentInvoice() {
      dataService
        .get("get_tables_different", `key=null`)
        .then((res) => {
          this.dataTablesDifferentInvoice = res.data.message;
        })
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
        });
    },
    ...mapActions("transfer", {
      setDataTableOne: "setDataTableOne",
      setDataTableTwo: "setDataTableTwo",
      getDataItemCartOne: "getDataItemCartOne",
      getDataItemCartTwo: "getDataItemCartTwo",
      moveItemToCartTwo: "moveItemToCartTwo",
      moveItemToCartOne: "moveItemToCartOne",
      moveAllItemsToCart: "moveAllItemsToCart",
      saveItemsMoved: "saveItemsMoved",
      setIsModalOpenMove: "setIsModalOpenMove",
    }),
    handleMove() {
      if (this.dataTable.invoices_split.length == 1) {
        this.setIsModalOpenMove(true);
        this.isModalOpen = false;
        this.selectedTableTransferOne = this.dataTable;
        this.getDataItemCartOne(`key=${this.dataTable?.sales_invoice}`);
        this.getDataTablesDifferent(this.dataTable?.name);
        this.getDataTablesDifferentInvoice();
      } else
        dispatchNotification({
          content: "This table cannot be moved items.",
          type: "warning",
        });
    },
    handlePayment() {
      this.$router.push({
        ...Tr.i18nRoute({ name: "checkout" }),
        query: {
          sales_invoice: this.dataTable.invoices_split.find(
            (item) => item.status != "Receipt"
          )?.name,
        },
      });
    },
    handleAddView() {
      this.$router.push({
        ...Tr.i18nRoute({ name: "menu" }),
        query: {
          name: this.dataTable?.name,
        },
      });
    },
    getDataFloor() {
      dataService
        .get("get_floors")
        .then((res) => {
          this.dataFloor = res.data.message;
          this.selectMenuFloor = this.dataFloor[0]?.name;
        })
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
        });
    },
    getDataTable() {
      this.loadingGetTable = true;
      dataService
        .get(
          "get_tables",
          `floor=${
            this.selectMenuFloor ? this.selectMenuFloor : null
          }&&status=${this.selectMenuTable}&&limit=${this.limit}&&page=${
            this.page
          }`
        )
        .then((res) => {
          let { items, total } = res.data.message;
          this.dataTables.push(...items);
          this.totalItems = total;
          this.loadingGetTable = false;
        })
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
        });
    },
    handleSelectFLoor(key) {
      this.selectMenuFloor = key;
      this.dataTables = [];
      if (this.scrollTop > 0) this.page = 0;
      else this.page = 1;
      this.getDataTable();
    },
    handleSelectMenuTable(key) {
      this.selectMenuTable = key;
      this.dataTables = [];
      if (this.scrollTop > 0) this.page = 0;
      else this.page = 1;
      this.getDataTable();

      if (key === "delivery") {
        this.showReserved = false;
        this.showDelivery = true;
      } else if (key === "reserved") {
        this.showDelivery = false;
        this.showReserved = true;
      } else {
        this.showDelivery = false;
        this.showReserved = false;
      }
    },
    handleTable(item) {
      if (item.status === "Opened")
        this.$router.push({
          ...Tr.i18nRoute({ name: "menu" }),
          query: { name: item.name },
        });
      else {
        this.isModalOpen = true;
        this.dataTable = item;
      }
    },
  },
};
</script>
