export default class Floor {
  constructor(
    label,
    dpos_floor,
    no_of_seats,
    minimum_seating,
    status,
    index_no,
    disabled,
  ) {
    this.label = label;
    this.dpos_floor = dpos_floor;
    this.no_of_seats = no_of_seats;
    this.minimum_seating = minimum_seating;
    this.status = status;
    this.index_no = index_no;
    this.disabled = disabled;
  }
}
