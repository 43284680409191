<template>
  <div class="w-full px-5 overflow-x-auto h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]">
      <div class="mt-4 mb-6 leading-9">
      <h1 class="text-sm sm:text-xl font-semibold  dark:text-gray-100">
        Close Shift
      </h1>

      <nav class="flex text-gray-700" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <router-link :to="Tr.i18nRoute({ name: 'dashboard' })"
              class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center  dark:text-gray-400 dark:hover:text-white">
              <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z">
                </path>
              </svg>
              Home
            </router-link>
          </li>
          <li>
            <div class="flex items-center">
              <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
              <router-link :to="Tr.i18nRoute({ name: 'close-shift' })"
                class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium  dark:text-gray-400 dark:hover:text-white">Close
                Shift</router-link>
            </div>
          </li>
        </ol>
      </nav>
    </div>
    <div class="my-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
      <vue-tailwind-datepicker :formatter="{
        date: 'DD/MM/YYYY',
        month: 'MMM',
      }" v-model="dateValue" 
       as-single use-range/>
    </div>
    <div class="inline-block overflow-x-scroll w-full sm:w-[calc(100vw_-_78px)] lg:w-[calc(100vw_-_104px)]">
      <TableComponent :columns="columns" :items="renderItems(items)" id="infinite-list" scrollX="60rem" :onClickRow="(e) =>
          $router.push(
            Tr.i18nRoute({
              ...{ name: 'close-shift-detail' },
              params: { id: e.name },
            })
          )
        ">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'close_shift'">
            {{
              new Date($d(record.period_start_date, "long")).toLocaleString(
                "en-US",
                {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                  month: "numeric",
                  day: "numeric",
                  year: "numeric",
                }
              )
            }}
            - {{
              new Date($d(record.period_end_date, "long")).toLocaleString(
                "en-US",
                {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                  month: "numeric",
                  day: "numeric",
                  year: "numeric",
                }
              )
            }}
          </template>
          <template v-if="column.key === 'grand_total'">
            $ {{ $n(record.grand_total, "decimal", "en") }}
          </template>
          <template v-if="column.key === 'net_total'">
            $ {{ $n(record.net_total, "decimal", "en") }}
          </template>
        </template>
      </TableComponent>
    </div>
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
const columns = [
  { key: "name", label: "Id", dataIndex: "name" },
  { key: "close_shift", label: "Close Shift" },
  {
    key: "num_tran",
    label: "Total Transaction",
    dataIndex: "num_tran",
  },
  { key: "net_total", label: "Net Total" },
  { key: "grand_total", label: "Grand Total" },
];
import TableComponent from "@/components/table/TableComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { renderItems} from "@/utils/globleFunction";
import Tr from "@/i18n/translation";

export default {
  name: "Bar",
  components: {
    TableComponent,
    SpinComponent,
    ModalComponent,
},
  data() {
    return {
      Tr,
      columns,
      items: [],
      loading: false,
      loading: false,
      totalItems: 0,
      limit: 50,
      page: 1,
      renderItems,
      dateValue: [],
    };
  },
  created() {
    this.getDefaultDateValue();
  },
  watch: {
    dateValue(newData) {
      if (newData.length === 0) {
        this.getDefaultDateValue();
      } else {
        this.items = [];
        this.getClosingList();
      }
    },
  },
  methods: {
    formatDate(date) {
      var day = date.getDate().toString().padStart(2, "0");
      var month = (date.getMonth() + 1).toString().padStart(2, "0");
      var year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    getDefaultDateValue() {
      var currentDate = new Date();
      var sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 7);
      var currentDateStr = this.formatDate(currentDate);
      var sevenDaysAgoStr = this.formatDate(sevenDaysAgo);
      this.dateValue = [sevenDaysAgoStr, currentDateStr];
    },
    getClosingList() {
      this.loading = true;
      dataService
        .get(
          "get_closing_list",
          `limit=${this.limit}&&page=${this.page}&&start=${this.dateValue[0]}&end=${this.dateValue[1]}`
        )
        .then((res) => {
          let { items, total } = res.data.message;
          this.items.push(...items);
          this.totalItems = total;
          this.loading = false;
        });
    },
  },
};
</script>