<script setup>
import { RouterView } from "vue-router";
import Applayout from "./layout/AppLayout.vue";
import { NotificationProvider } from "@/components/notification";
import { onMounted, onBeforeUnmount } from "vue";
import { updateDarkMode } from "@/utils/globleFunction";
import "tabulator-tables/dist/css/tabulator.min.css";

onMounted(() => {
  const storedDarkMode = localStorage.getItem("darkMode");
  updateDarkMode(storedDarkMode === "true");
});
</script>

<template>
  <NotificationProvider>
    <Applayout>
      <div class="bg-gray-100/50 dark:bg-slate-800 w-full">
        <RouterView />
      </div>
    </Applayout>
  </NotificationProvider>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
