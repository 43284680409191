export const data = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "Data One",
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)",
        "rgba(54, 162, 235, 0.5)",
        "rgba(255, 206, 86, 0.5)",
        "rgba(75, 192, 192, 0.5)",
        "rgba(153, 102, 255, 0.5)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)", // Red
        "rgba(54, 162, 235, 1)", // Blue
        "rgba(255, 205, 86, 1)", // Yellow
        "rgba(75, 192, 192, 1)", // Green
        "rgba(153, 102, 255, 1)", // Purple
      ],
      borderWidth: 1,
      data: [40, 39, 10, 40, 39, 80, 40],
    },
  ],
};

export const options = {
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: "rgba(255, 255, 255, 0.1)", // Gridlines color
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "white", // Legend text color
      },
    },
  },
  elements: {
    point: {
      backgroundColor: "rgba(255, 255, 255, 1)", // Point color
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};
