export default class Register {
  constructor(
    first_name,
    last_name,
    email,
    phone,
    company_name,
    custom_business_categories,
    password,
    confirm_password
  ) {
    this.password = password;
    this.confirm_password = confirm_password;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.phone = phone;
    this.company_name = company_name;
    this.custom_business_categories = custom_business_categories;
  }
}
