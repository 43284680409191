<template>
  <section class="flex items-center h-[100vh] p-16  dark:bg-gray-900">
    <div
      class="container flex flex-col items-center justify-center px-5 mx-auto my-8"
    >
      <div class="max-w-md text-center">
        <h2
          class="mb-4 font-extrabold text-9xl text-gray-300  dark:text-gray-600"
        >
          <span class="sr-only">Error</span>404
        </h2>
        <p
          class="text-2xl font-semibold md:text-3xl text-gray-600  dark:text-gray-400"
        >
          Sorry, we couldn't find this page.
        </p>
        <p class="mt-2 text-gray-500  dark:text-gray-500">
          The page you are looking for doesn't exist. Here is helpful links:
        </p>
        <button
          @click="$router.go(-1)"
          class="text-sm mt-4 p-2 lg:p-[10px]  bg-secondary text-white font-medium rounded-md justify-center"
        >
          Go to Back
        </button>
      </div>
    </div>
  </section>
</template>
