<template>
  <div>
    <ModalComponent
      v-if="getCurrencyExchange > 0"
      title="Cash Register"
      :isModalOpen="!opened"
      :onToggle="handleCloseOpen"
      :width="578"
      :freeSpaceClose="false"
    >
      <div class="p-2 lg:p-4">
        <div class="flex justify-center">
          <div class="text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-20 h-20 text-gray-400"
            >
              <path
                fill-rule="evenodd"
                d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                clip-rule="evenodd"
              />
            </svg>
            <h1 class="dark:text-gray-100">{{ fullName }}</h1>
          </div>
        </div>
        <div class="mt-8 dark:text-gray-100">
          <!-- <h1 class="text-center font-bold">Mode of Payment</h1> -->
          <div v-for="(item, index) in modeOfPayment">
            <div class="mt-2" v-if="item.default === 1">
              <h1 class="font-medium">{{ item.mode_of_payment }}</h1>
              <div class="grid grid-cols-2 gap-6">
                <div class="mt-1 relative">
                  <div
                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  >
                    $
                  </div>
                  <VueNumber
                    v-number="{ min: 0 }"
                    v-model="openingAmountUSD[index]"
                    class="w-full px-6 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
                  />
                </div>
                <div class="mt-1 relative">
                  <div
                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  >
                    ៛
                  </div>
                  <VueNumber
                    v-number="{ min: 0 }"
                    v-model="openingAmountKHR[index]"
                    class="w-full px-6 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4">
        <button
          @click="handleSaveOpening"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Save
        </button>
      </div>
    </ModalComponent>
    <ModalComponent
      v-else
      title="Not yet set up settings!"
      :isModalOpen="true"
      :onToggle="handleCloseOpen"
      :width="578"
      :freeSpaceClose="false"
    >
      <div class="text-gray-500 dark:text-gray-300">
        Please set up settings before execute.
        <router-link
          :to="Tr.i18nRoute({ name: 'setting' })"
          class="text-secondary dark:text-primary underline underline-offset-2"
          >click here go to settings</router-link
        >
      </div>
    </ModalComponent>
    <!-- <ModalComponent
      title="The payment type is not empty!"
      :isModalOpen="!opened"
      :onToggle="handleCloseOpen"
      :width="578"
      :freeSpaceClose="false"
    >
      <div class="text-gray-500 dark:text-gray-300">
        Please check your payment type.
        <router-link
          :to="Tr.i18nRoute({ name: 'payment' })"
          class="text-secondary dark:text-primary underline underline-offset-2"
          >click here go to payment type</router-link
        >
      </div>
    </ModalComponent> -->
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";

export default {
  name: "Login",
  components: {
    ModalComponent,
    SpinComponent,
  },
  data() {
    return {
      Tr,
      loading: false,
      openingAmountUSD: [],
      openingAmountKHR: [],
    };
  },
  created() {
    this.clearAmount();
  },
  computed: {
    ...mapGetters("auth", {
      opened: "getIsOpened",
      modeOfPayment: "getModeOfPayment",
      getCurrencyExchange: "getCurrencyExchange",
    }),
    users() {
      return store.state?.auth?.user;
    },
    fullName() {
      return this.users?.full_name?.replace(/\b\w/g, (match) =>
        match?.toUpperCase()
      );
    },
  },
  watch: {
    modeOfPayment(newData) {
      this.openingAmountUSD = newData?.map((item) => "");
      this.openingAmountKHR = newData?.map((item) => "");
    },
  },
  methods: {
    ...mapActions("auth", {
      saveOpening: "saveOpening",
    }),
    handleCloseOpen() {
      this.$router.go(-1);
    },
    handleSaveOpening() {
      this.loading = true;
      const arr1 = this.modeOfPayment;
      const arr2 = [this.openingAmountUSD, this.openingAmountKHR];

      const data = arr1.map((item, index) => {
        return {
          mode_of_payment: item.mode_of_payment,
          opening_amount_usd: arr2[0][index] != "" ? arr2[0][index] : 0,
          opening_amount_khr: arr2[1][index] != "" ? arr2[1][index] : 0,
        };
      });
      this.saveOpening({ mode_of_payment: data }).then(
        () => (this.loading = false)
      );
    },
    clearAmount() {
      this.openingAmountUSD = this.modeOfPayment?.map((item) => "");
      this.openingAmountKHR = this.modeOfPayment?.map((item) => "");
    },
  },
};
</script>
