<template>
  <!-- <v-card
      class="selection mx-auto grey lighten-5 pa-1"
      style="max-height: 76vh; height: 76vh"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="info"
      ></v-progress-linear>
      <div class="overflow-y-auto px-2 pt-2" style="max-height: 75vh">
        <v-row v-if="invoice_doc" class="px-1 py-0">
          <v-col cols="7">
            <v-text-field
              outlined
              color="primary"
              :label="frappe._('Paid Amount')"
              background-color="white"
              hide-details
              :value="formtCurrency(total_payments)"
              :prefix="invoice_doc.currency"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field
              outlined
              color="primary"
              :label="frappe._(diff_lable)"
              background-color="white"
              hide-details
              :value="formtCurrency(diff_payment)"
              disabled
              :prefix="invoice_doc.currency"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="7" v-if="diff_payment < 0 && !invoice_doc.is_return">
            <v-text-field
              outlined
              color="primary"
              :label="frappe._('Paid Change')"
              background-color="white"
              v-model="paid_change"
              @input="setPaidChange()"
              :prefix="invoice_doc.currency"
              :rules="paid_change_rules"
              dense
              type="number"
            ></v-text-field>
          </v-col>

          <v-col cols="5" v-if="diff_payment < 0 && !invoice_doc.is_return">
            <v-text-field
              outlined
              color="primary"
              :label="frappe._('Credit Change')"
              background-color="white"
              hide-details
              :value="formtCurrency(credit_change)"
              disabled
              :prefix="invoice_doc.currency"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <div v-if="is_cashback">
          <v-row
            class="pyments px-1 py-0"
            v-for="payment in invoice_doc.payments"
            :key="payment.name"
          >
            <v-col cols="6" v-if="!is_mpesa_c2b_payment(payment)">
              <v-text-field
                dense
                outlined
                color="primary"
                :label="frappe._(payment.mode_of_payment)"
                background-color="white"
                hide-details
                v-model="payment.amount"
                type="number"
                :prefix="invoice_doc.currency"
                @focus="setRestAmount(payment.idx)"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="!is_mpesa_c2b_payment(payment)"
              :cols="
                6
                  ? (payment.type != 'Phone' ||
                      payment.amount == 0 ||
                      !request_payment_field) &&
                    !is_mpesa_c2b_payment(payment)
                  : 3
              "
            >
              <v-btn
                block
                class=""
                color="primary"
                dark
                @click="setFullAmount(payment.idx)"
                >{{ payment.mode_of_payment }}</v-btn
              >
            </v-col>
            <v-col v-if="is_mpesa_c2b_payment(payment)" :cols="12" class="pl-3">
              <v-btn
                block
                class=""
                color="success"
                dark
                @click="mpesa_c2b_dialg(payment)"
              >
                {{ __(`Get Payments ${payment.mode_of_payment}`) }}
              </v-btn>
            </v-col>
            <v-col
              v-if="
                payment.type == 'Phone' &&
                payment.amount > 0 &&
                request_payment_field
              "
              :cols="3"
              class="pl-1"
            >
              <v-btn
                block
                class=""
                color="success"
                dark
                :disabled="payment.amount == 0"
                @click="phone_dialog = true"
              >
                {{ __("Request") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-row
          class="pyments px-1 py-0"
          v-if="
            invoice_doc &&
            available_pioints_amount > 0 &&
            !invoice_doc.is_return
          "
        >
          <v-col cols="7">
            <v-text-field
              dense
              outlined
              color="primary"
              :label="frappe._('Redeem Loyalty Points')"
              background-color="white"
              hide-details
              v-model="loyalty_amount"
              type="number"
              :prefix="invoice_doc.currency"
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field
              dense
              outlined
              color="primary"
              :label="frappe._('You can redeem upto')"
              background-color="white"
              hide-details
              :value="formtFloat(available_pioints_amount)"
              :prefix="invoice_doc.currency"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          class="pyments px-1 py-0"
          v-if="
            invoice_doc &&
            available_customer_credit > 0 &&
            !invoice_doc.is_return &&
            redeem_customer_credit
          "
        >
          <v-col cols="7">
            <v-text-field
              dense
              outlined
              disabled
              color="primary"
              :label="frappe._('Redeemed Customer Credit')"
              background-color="white"
              hide-details
              v-model="redeemed_customer_credit"
              type="number"
              :prefix="invoice_doc.currency"
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field
              dense
              outlined
              color="primary"
              :label="frappe._('You can redeem credit upto')"
              background-color="white"
              hide-details
              :value="formtCurrency(available_customer_credit)"
              :prefix="invoice_doc.currency"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="px-1 py-0">
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              color="primary"
              :label="frappe._('Net Total')"
              background-color="white"
              hide-details
              :value="formtCurrency(invoice_doc.net_total)"
              disabled
              :prefix="invoice_doc.currency"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              color="primary"
              :label="frappe._('Tax and Charges')"
              background-color="white"
              hide-details
              :value="formtCurrency(invoice_doc.total_taxes_and_charges)"
              disabled
              :prefix="invoice_doc.currency"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              color="primary"
              :label="frappe._('Total Amount')"
              background-color="white"
              hide-details
              :value="formtCurrency(invoice_doc.total)"
              disabled
              :prefix="invoice_doc.currency"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              color="primary"
              :label="frappe._('Discount Amount')"
              background-color="white"
              hide-details
              :value="formtCurrency(invoice_doc.discount_amount)"
              disabled
              :prefix="invoice_doc.currency"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              color="primary"
              :label="frappe._('Grand Total')"
              background-color="white"
              hide-details
              :value="formtCurrency(invoice_doc.grand_total)"
              disabled
              :prefix="invoice_doc.currency"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            v-if="pos_profile.custom_allow_create_sales_order && invoiceType == 'Order'"
          >
            <v-menu
              ref="order_delivery_date"
              v-model="order_delivery_date"
              :close-on-content-click="false"
              transition="scale-transition"
              dense
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="invoice_doc.posa_delivery_date"
                  :label="frappe._('Delivery Date')"
                  outlined
                  dense
                  background-color="white"
                  clearable
                  color="primary"
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="invoice_doc.posa_delivery_date"
                no-title
                scrollable
                color="primary"
                :min="frappe.datetime.now_date()"
                @input="order_delivery_date = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" v-if="invoice_doc.posa_delivery_date">
            <v-autocomplete
              dense
              clearable
              auto-select-first
              outlined
              color="primary"
              :label="frappe._('Address')"
              v-model="invoice_doc.shipping_address_name"
              :items="addresses"
              item-text="address_title"
              item-value="name"
              background-color="white"
              no-data-text="Address not found"
              hide-details
              :filter="addressFilter"
              append-icon="mdi-plus"
              @click:append="new_address"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text subtitle-1"
                      v-html="data.item.address_title"
                    ></v-list-item-title>
                    <v-list-item-title
                      v-html="data.item.address_line1"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="data.item.custoaddress_line2mer_name"
                      v-html="data.item.address_line2"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="data.item.city"
                      v-html="data.item.city"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="data.item.state"
                      v-html="data.item.state"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="data.item.country"
                      v-html="data.item.mobile_no"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-if="data.item.address_type"
                      v-html="data.item.address_type"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" v-if="pos_profile.custom_display_additional_notes">
            <v-textarea
              class="pa-0"
              outlined
              dense
              background-color="white"
              clearable
              color="primary"
              auto-grow
              rows="2"
              :label="frappe._('Receive Phone Number')"
              v-model="invoice_doc.take_note"
              :value="invoice_doc.take_note"
            ></v-textarea>
          </v-col>
          <v-col cols="12" v-if="pos_profile.custom_display_additional_notes">
            <v-textarea
              class="pa-0"
              outlined
              dense
              background-color="white"
              clearable
              color="primary"
              auto-grow
              rows="2"
              :label="frappe._('Remarks')"
              v-model="invoice_doc.remarks"
              :value="invoice_doc.remarks"
            ></v-textarea>
          </v-col>
        </v-row>

        <div v-if="pos_profile.posa_allow_customer_purchase_order">
          <v-divider></v-divider>
          <v-row class="px-1 py-0" justify="center" align="start">
            <v-col cols="6">
              <v-text-field
                v-model="invoice_doc.po_no"
                :label="frappe._('Purchase Order')"
                outlined
                dense
                background-color="white"
                clearable
                color="primary"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="po_date_menu"
                v-model="po_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="invoice_doc.po_date"
                    :label="frappe._('Purchase Order Date')"
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="invoice_doc.po_date"
                  no-title
                  scrollable
                  color="primary"
                  @input="po_date_menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-row class="px-1 py-0" align="start" no-gutters>
          <v-col
            cols="6"
            v-if="
              pos_profile.custom_allow_write_off_change &&
              diff_payment > 0 &&
              !invoice_doc.is_return
            "
          >
            <v-switch
              class="my-0 py-0"
              v-model="is_write_off_change"
              flat
              :label="frappe._('Write Off Difference Amount')"
            ></v-switch>
          </v-col>
          <v-col
            cols="6"
            v-if="pos_profile.custom_allow_credit_sale && !invoice_doc.is_return"
          >
            <v-switch
              v-model="is_credit_sale"
              flat
              :label="frappe._('Is Credit Sale')"
              class="my-0 py-0"
            ></v-switch>
          </v-col>
          <v-col
            cols="6"
            v-if="invoice_doc.is_return && pos_profile.custom_use_cashback"
          >
            <v-switch
              v-model="is_cashback"
              flat
              :label="frappe._('Is Cashback')"
              class="my-0 py-0"
            ></v-switch>
          </v-col>
          <v-col cols="6" v-if="is_credit_sale">
            <v-menu
              ref="date_menu"
              v-model="date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="invoice_doc.due_date"
                  :label="frappe._('Due Date')"
                  outlined
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="invoice_doc.due_date"
                no-title
                scrollable
                color="primary"
                :min="frappe.datetime.now_date()"
                @input="date_menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="6"
            v-if="!invoice_doc.is_return && pos_profile.custom_use_customer_credit"
          >
            <v-switch
              v-model="redeem_customer_credit"
              flat
              :label="frappe._('Use Customer Credit')"
              class="my-0 py-0"
              @change="get_available_credit($event)"
            ></v-switch>
          </v-col>
        </v-row>
        <div
          v-if="
            invoice_doc &&
            available_customer_credit > 0 &&
            !invoice_doc.is_return &&
            redeem_customer_credit
          "
        >
          <v-row v-for="(row, idx) in customer_credit_dict" :key="idx">
            <v-col cols="4">
              <div class="pa-2 py-3">{{ row.credit_origin }}</div>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                color="primary"
                :label="frappe._('Available Credit')"
                background-color="white"
                hide-details
                :value="formtCurrency(row.total_credit)"
                disabled
                :prefix="invoice_doc.currency"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                color="primary"
                :label="frappe._('Redeem Credit')"
                background-color="white"
                hide-details
                type="number"
                v-model="row.credit_to_redeem"
                :prefix="invoice_doc.currency"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>

        <v-row class="pb-0 mb-2" align="start">
          <v-col cols="12">
            <v-autocomplete
              dense
              clearable
              auto-select-first
              outlined
              color="primary"
              :label="frappe._('Sales Person')"
              v-model="sales_person"
              :items="sales_persons"
              item-text="sales_person_name"
              item-value="name"
              background-color="white"
              :no-data-text="__('Sales Person not found')"
              hide-details
              :filter="salesPersonFilter"
              :disabled="readonly"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text subtitle-1"
                      v-html="data.item.sales_person_name"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="data.item.sales_person_name != data.item.name"
                      v-html="`ID: ${data.item.name}`"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </div>
    </v-card> -->

  <!-- <v-card flat class="cards mb-0 mt-3 py-0">
      <v-row align="start" no-gutters>
        <v-col cols="12">
          <v-btn
            block
            class="pa-1"
            large
            color="error"
            dark
            @click="onBacktoInvoice"
            >{{ __("Back") }}</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn
            block
            class="mt-2"
            large
            color="success"
            dark
            @click="submit"
            :disabled="vaildatPayment"
            >{{ __("Submit Payments") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card> -->
  <!-- <div>
      <v-dialog v-model="phone_dialog" max-width="400px">
        <v-card>
          <v-card-title>
            <span class="headline primary--text">{{
              __("Confirm Mobile Number")
            }}</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container>
              <v-text-field
                dense
                outlined
                color="primary"
                :label="frappe._('Mobile Number')"
                background-color="white"
                hide-details
                v-model="invoice_doc.contact_mobile"
                type="number"
              ></v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" dark @click="phone_dialog = false">{{
              __("Close")
            }}</v-btn>
            <v-btn color="primary" dark @click="request_payment">{{
              __("Request")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div> -->
  <div
    v-if="invoice_doc"
    class="w-full text-xs lg:text-base rounded-md dark:text-gray-200"
  >
    <div
      class="overflow-x-auto h-[calc(100vh_-_113px)] sm:h-[calc(100vh_-_123px)] lg:h-[calc(100vh_-_153px)]"
    >
      <div
        v-if="is_cashback"
        class="pb-2 lg:pb-4 flex space-x-4 w-full overflow-auto no-scrollbar"
      >
        <button
          type="button"
          v-for="payment in invoice_doc.payments"
          @click="setFullAmount(payment.idx)"
          class="p-1 bg-gray-200 rounded-md dark:bg-gray-900 dark:text-gray-200 text-xs lg:text-base leading-5 lg:leading-8"
          :class="
            selectedModeIdx !== payment.idx
              ? 'opacity-60 cursor-pointer '
              : 'font-semibold cursor-pointer '
          "
        >
          <p class="text-center w-20 lg:w-28 ellipsis">
            {{ payment.mode_of_payment }}
          </p>
        </button>
      </div>
      <div
        class="p-2 lg:p-4 space-y-2 border-dashed border-y dark:border-slate-600"
      >
        <div class="flex justify-between items-center">
          <p>Net Total</p>
          <p>
            <span>{{ pos_profile.currency === "USD" ? "$" : "៛" }}</span
            >{{
              $n(
                invoice_doc.net_total,
                "decimal",
                `${pos_profile.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </p>
        </div>
        <div class="flex justify-between items-center">
          <p>Tax and Charges</p>
          <p class="font-bold">
            <span>{{ pos_profile.currency === "USD" ? "$" : "៛" }}</span
            >{{
              $n(
                invoice_doc.total_taxes_and_charges,
                "decimal",
                `${pos_profile.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </p>
        </div>
        <div class="flex justify-between items-center">
          <p>Total Amount</p>
          <p class="font-bold">
            <span>{{ pos_profile.currency === "USD" ? "$" : "៛" }}</span
            >{{
              $n(
                invoice_doc.total,
                "decimal",
                `${pos_profile.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </p>
        </div>
        <div class="flex justify-between items-center">
          <p>Discount Amount</p>
          <p class="font-bold">
            <span>{{ pos_profile.currency === "USD" ? "$" : "៛" }}</span
            >{{
              $n(
                invoice_doc.discount_amount,
                "decimal",
                `${pos_profile.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </p>
        </div>
      </div>
      <div class="border-dashed border-t w-full dark:border-slate-600">
        <div
          class="p-4 flex justify-between items-center dark:text-gray-200 text-lg lg:text-xl"
        >
          <h1>Grand Total</h1>
          <p class="font-bold">
            <span>{{ pos_profile.currency === "USD" ? "$" : "៛" }}</span
            >{{
              $n(
                invoice_doc.grand_total,
                "decimal",
                `${pos_profile.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </p>
        </div>
      </div>
      <div
        v-if="is_cashback"
        class="p-2 lg:px-4 border-dashed border-t dark:border-slate-600 bg-gray-100 dark:bg-slate-900"
      >
        <div v-for="payment in invoice_doc.payments" :key="payment.name">
          <div v-if="!is_mpesa_c2b_payment(payment)">
            <div
              v-show="selectedModeIdx == payment.idx"
              class="dark:text-gray-200 text-lg space-y-1"
            >
              <div>Receive</div>
              <div class="grid grid-cols-2 gap-6 items-center pb-2">
                <InputCurrencyComponent
                  :id="`USD${payment.mode_of_payment}`"
                  prefix="$"
                  v-model="receiveUSD"
                />
                <InputCurrencyComponent
                  :id="`KHR${payment.mode_of_payment}`"
                  prefix="៛"
                  v-model="receiveKHR"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full p-2 lg:px-4 flex justify-between items-center text-xs lg:text-base leading-5 lg:leading-8"
      >
        <h1 class="dark:text-gray-200">Change</h1>
        <div
          class="dark:text-gray-200 text-right font-medium text-xs lg:text-lg"
        >
          <p>
            <span>{{ pos_profile.currency === "USD" ? "$" : "៛" }}</span
            >{{ formtCurrency(-diff_payment) }}
          </p>
        </div>
      </div>
      <div
        v-if="diff_payment < 0 && !invoice_doc.is_return"
        class="p-2 lg:p-4 border-dashed border-t dark:border-slate-600"
      >
        <div class="grid grid-cols-2 gap-6 dark:text-gray-200 items-center">
          <div>Paid Change</div>
          <InputCurrencyComponent
            prefix="$"
            v-model="paid_change"
            :change="setPaidChange()"
            :options="{
              valueRange: { min: 0 },
            }"
          />
        </div>
      </div>
      <div
        v-if="diff_payment < 0 && !invoice_doc.is_return"
        class="w-full px-2 lg:px-4 pb-2 flex justify-between items-center text-xs lg:text-base leading-5 lg:leading-8"
      >
        <h1 class="dark:text-gray-200">Credit Change</h1>
        <div
          class="dark:text-gray-200 text-right font-medium text-xs lg:text-lg"
        >
          <p>
            <span>{{ pos_profile.currency === "USD" ? "$" : "៛" }}</span
            >{{ formtCurrency(credit_change) }}
          </p>
        </div>
      </div>
      <div
        v-if="pos_profile.custom_display_additional_notes"
        class="p-2 lg:px-4 border-dashed border-t dark:border-slate-600"
      >
        <InputComponent
          id="take_note"
          label="Receive Phone Number"
          v-model="invoice_doc.take_note"
        ></InputComponent>
      </div>
      <div
        v-if="pos_profile.custom_display_additional_notes"
        class="p-2 lg:px-4"
      >
        <InputComponent
          id="remarks"
          label="Remarks"
          v-model="invoice_doc.remarks"
        ></InputComponent>
      </div>
      <div
        v-if="pos_profile.posa_allow_customer_purchase_order"
        class="p-2 lg:px-4 border-dashed border-t dark:border-slate-600"
      >
        <div class="grid grid-cols-2 gap-4">
          <InputComponent
            id="po_no"
            v-model="invoice_doc.po_no"
            label="Purchase Order"
          />
          <div>
            <label
              for="po_date"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Delivery Date</label
            >
            <vue-tailwind-datepicker
              id="po_date"
              v-model="invoice_doc.po_date"
              :formatter="{
                date: 'YYYY-MM-DD',
                month: 'MMM',
              }"
              as-single
            />
          </div>
        </div>
      </div>
      <div class="p-2 lg:px-4 border-dashed border-t dark:border-slate-600">
        <div class="grid grid-cols-2 gap-4 items-center">
          <div
            v-if="
              pos_profile.custom_allow_write_off_change &&
              diff_payment > 0 &&
              !invoice_doc.is_return
            "
            class="flex items-center space-x-2"
          >
            <Switch
              v-model="is_write_off_change"
              :class="is_write_off_change ? 'bg-teal-700' : 'bg-teal-900'"
              class="relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
            >
              <span
                aria-hidden="true"
                :class="is_write_off_change ? 'translate-x-4' : 'translate-x-0'"
                class="pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
            <span
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Write Off Difference Amount</span
            >
          </div>
          <div
            v-if="
              Boolean(pos_profile.custom_allow_credit_sale) &&
              !invoice_doc.is_return
            "
            class="flex items-center space-x-2"
          >
            <Switch
              v-model="is_credit_sale"
              :class="is_credit_sale ? 'bg-teal-700' : 'bg-teal-900'"
              class="relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
            >
              <span
                aria-hidden="true"
                :class="is_credit_sale ? 'translate-x-4' : 'translate-x-0'"
                class="pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
            <span
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Is Credit Sale</span
            >
          </div>
          <div
            v-if="invoice_doc.is_return && pos_profile.custom_use_cashback"
            class="flex items-center space-x-2"
          >
            <Switch
              v-model="is_cashback"
              :class="is_cashback ? 'bg-teal-700' : 'bg-teal-900'"
              class="relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
            >
              <span
                aria-hidden="true"
                :class="is_cashback ? 'translate-x-4' : 'translate-x-0'"
                class="pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
            <span
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Is Cashback</span
            >
          </div>
          <div v-if="is_credit_sale">
            <label
              for="due_date"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Due Date</label
            >
            <vue-tailwind-datepicker
              id="due_date"
              v-model="invoice_doc.due_date"
              :formatter="{
                date: 'YYYY-MM-DD',
                month: 'MMM',
              }"
              as-single
            />
          </div>
          <div
            v-if="
              !invoice_doc.is_return &&
              Boolean(pos_profile.custom_use_customer_credit)
            "
            class="flex items-center space-x-2"
          >
            <Switch
              v-model="redeem_customer_credit"
              :class="redeem_customer_credit ? 'bg-teal-700' : 'bg-teal-900'"
              class="relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
            >
              <span
                aria-hidden="true"
                :class="
                  redeem_customer_credit ? 'translate-x-4' : 'translate-x-0'
                "
                class="pointer-event+s-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
            <span
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Use Customer Credit</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="py-4 border-dashed border-t w-full dark:border-slate-600">
        <label
          for="sales_person"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >Sales Person</label
        >
        <multiselect
          v-model="sales_person"
          :options="sales_persons"
          label="sales_person_name"
          track-by="name"
          placeholder=""
          :allow-empty="false"
          deselect-label="Can't remove this value"
        />
      </div> -->
    </div>
    <div
      class="pt-4 flex space-x-2 lg:space-x-4 border-dashed border-t w-full dark:border-slate-600"
    >
      <button
        type="button"
        @click="onBackeCart"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white rounded-md w-1/2 justify-center font-bold sm:hidden"
      >
        Back
      </button>
      <button
        type="button"
        @click="onBacktoInvoice"
        class="items-center text-sm py-2 lg:py-[10px] bg-secondary text-white rounded-md w-1/2 justify-center font-bold hidden sm:block"
      >
        Back
      </button>
      <button
        type="button"
        @click="submit"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-primary text-white rounded-md w-full justify-center font-bold"
      >
        Submit Payments
      </button>
    </div>
  </div>
  <SpinComponent v-show="loading" />
</template>

<script>
import InputComponent from "@/components/input/InputComponent.vue";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import { mapActions, mapGetters } from "vuex";
import dataService from "@/services/data.service";
import { Switch } from "@headlessui/vue";
import { dispatchNotification } from "@/components/notification";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import {
  renderItems,
  formattedDateNow,
  formtCurrency,
} from "@/utils/globleFunction";
export default {
  components: {
    InputCurrencyComponent,
    InputComponent,
    Switch,
    SpinComponent,
  },
  data: () => ({
    formtCurrency,
    loading: false,
    invoice_doc: null,
    loyalty_amount: 0,
    is_credit_sale: false,
    is_write_off_change: false,
    date_menu: false,
    po_date_menu: false,
    addresses: [],
    sales_persons: [],
    sales_person: "",
    paid_change: 0,
    order_delivery_date: false,
    paid_change_rules: [],
    is_return: false,
    is_cashback: true,
    redeem_customer_credit: false,
    customer_credit_dict: [],
    phone_dialog: false,
    invoiceType: "Invoice",
    pos_settings: "",
    customer_info: "",
    mpesa_modes: [],
    float_precision: 2,
    currency_precision: 2,
    selectedModeIdx: 1,
    dataInvoice: {},
    receiveUSD: null,
    receiveKHR: null,
  }),
  computed: {
    ...mapGetters("retail", {
      userProfile: "userProfile",
      allItems: "allItems",
    }),
    ...mapGetters("auth", {
      getCurrencyExchange: "getCurrencyExchange",
    }),
    pos_profile() {
      return this.userProfile.pos_profile;
    },
    total_payments() {
      let total = parseFloat(this.invoice_doc.loyalty_amount);
      if (this.invoice_doc && this.invoice_doc.payments) {
        this.invoice_doc.payments.forEach((payment) => {
          total += parseFloat(payment.amount);
        });
      }

      total += parseFloat(this.redeemed_customer_credit);

      if (!this.is_cashback) total = 0;
      return total.toFixed(this.currency_precision);
    },
    diff_payment() {
      let diff_payment = this.invoice_doc.grand_total - this.total_payments;
      this.paid_change = -diff_payment;
      return diff_payment;
    },
    credit_change() {
      let change = -this.diff_payment;
      if (this.paid_change > change) return 0;
      return (this.paid_change - change).toFixed(this.currency_precision);
    },
    available_pioints_amount() {
      let amount = 0;
      if (this.customer_info.loyalty_points) {
        amount =
          this.customer_info.loyalty_points *
          this.customer_info.conversion_factor;
      }
      return amount;
    },
    available_customer_credit() {
      let total = 0;
      this.customer_credit_dict.map((row) => {
        total += row.total_credit;
      });

      return total;
    },
    redeemed_customer_credit() {
      let total = 0;
      this.customer_credit_dict.map((row) => {
        if (row.credit_to_redeem) total += parseFloat(row.credit_to_redeem);
        else row.credit_to_redeem = 0;
      });

      return total;
    },
    vaildatPayment() {
      if (this.pos_profile.custom_allow_create_sales_order) {
        if (
          this.invoiceType == "Order" &&
          !this.invoice_doc.posa_delivery_date
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    request_payment_field() {
      let res = false;
      if (!this.pos_settings || this.pos_settings.invoice_fields.length == 0) {
        res = false;
      } else {
        this.pos_settings.invoice_fields.forEach((el) => {
          if (
            el.fieldtype == "Button" &&
            el.fieldname == "request_for_payment"
          ) {
            res = true;
          }
        });
      }
      return res;
    },
  },
  watch: {
    receiveUSD(newValue) {
      this.setReceiveUSD(Number(newValue));
      this.invoice_doc.payments.forEach((payment) => {
        payment.amount =
          payment.idx == this.selectedModeIdx
            ? newValue + this.receiveKHR / this.getCurrencyExchange
            : 0;
      });
    },
    receiveKHR(newValue) {
      this.setReceiveKHR(Number(newValue));
      this.invoice_doc.payments.forEach((payment) => {
        payment.amount =
          payment.idx == this.selectedModeIdx
            ? newValue / this.getCurrencyExchange + this.receiveUSD
            : 0;
      });
    },
    redeem_customer_credit(newValue) {
      this.getAvailableCredit(newValue);
    },
    loyalty_amount(value) {
      if (value > this.available_pioints_amount) {
        this.invoice_doc.loyalty_amount = 0;
        this.invoice_doc.redeem_loyalty_points = 0;
        this.invoice_doc.loyalty_points = 0;
        this.emitter.emit("show_mesage", {
          text: `Loyalty Amount can not be more then ${this.available_pioints_amount}`,
          color: "error",
        });
      } else {
        this.invoice_doc.loyalty_amount = Number(this.loyalty_amount);
        this.invoice_doc.redeem_loyalty_points = 1;
        this.invoice_doc.loyalty_points =
          Number(this.loyalty_amount) / this.customer_info.conversion_factor;
      }
    },
    is_credit_sale(value) {
      if (value) {
        this.invoice_doc.payments.forEach((payment) => {
          payment.amount = 0;
          payment.base_amount = 0;
        });
      }
    },
    is_write_off_change(value) {
      if (value) {
        this.invoice_doc.write_off_amount = this.diff_payment;
        this.invoice_doc.write_off_outstanding_amount_automatically = 1;
      } else {
        this.invoice_doc.write_off_amount = 0;
        this.invoice_doc.write_off_outstanding_amount_automatically = 0;
      }
    },
    redeemed_customer_credit(value) {
      if (value > this.available_customer_credit) {
        this.emitter.emit("show_mesage", {
          text: `You can redeem customer credit upto ${this.available_customer_credit}`,
          color: "error",
        });
      }
    },
    sales_person() {
      if (this.sales_person) {
        this.invoice_doc.sales_team = [
          {
            sales_person: this.sales_person,
            allocated_percentage: 100,
          },
        ];
      } else {
        this.invoice_doc.sales_team = [];
      }
    },
  },
  methods: {
    onBackeCart() {
      this.emitter.emit("onShowCart", true);
    },
    onBacktoInvoice() {
      this.emitter.emit("onShowPayment", false);
      this.emitter.emit("setCustomerReadonly", false);
    },
    ...mapActions("retail", {
      submitInvoice: "submitInvoice",
      setReceiveUSD: "setReceiveUSD",
      setReceiveKHR: "setReceiveKHR",
    }),
    submit() {
      if (!this.invoice_doc.is_return && this.total_payments < 0) {
        dispatchNotification({
          content: "Payments not correct!",
          type: "warning",
        });
        return;
      }

      if (
        !this.pos_profile.custom_allow_partial_payment &&
        (this.total_payments < this.invoice_doc.grand_total ||
          this.total_payments >
            this.receiveUSD + this.receiveKHR / this.getCurrencyExchange)
      ) {
        dispatchNotification({
          content: "The amount paid is not complete!",
          type: "warning",
        });
        return;
      }

      if (
        this.pos_profile.custom_allow_partial_payment &&
        !this.pos_profile.custom_allow_credit_sale &&
        this.total_payments == 0
      ) {
        dispatchNotification({
          content: "Please enter the amount paid!",
          type: "warning",
        });
        return;
      }

      if (!this.paid_change) this.paid_change = 0;

      if (this.paid_change > -this.diff_payment) {
        dispatchNotification({
          content: "Paid change can not be greater than total change!",
          type: "warning",
        });
        return;
      }

      let total_change = Number(
        Number(this.paid_change) + Number(-this.credit_change)
      );

      if (this.is_cashback && total_change != -this.diff_payment) {
        dispatchNotification({
          content: "Error in change calculations!",
          type: "warning",
        });
        return;
      }

      let credit_calc_check = this.customer_credit_dict.filter((row) => {
        if (row.credit_to_redeem)
          return row.credit_to_redeem > row.total_credit;
        else return false;
      });

      if (credit_calc_check.length > 0) {
        dispatchNotification({
          content: "Redeamed credit can not greater than its total.",
          type: "warning",
        });
        return;
      }

      if (
        !this.invoice_doc.is_return &&
        this.redeemed_customer_credit > this.invoice_doc.grand_total
      ) {
        dispatchNotification({
          content: "Can not redeam customer credit more than invoice total.",
          type: "warning",
        });
        return;
      }

      let data = {};
      data["total_change"] = -this.diff_payment;
      data["paid_change"] = this.paid_change;
      data["credit_change"] = -this.credit_change;
      data["redeemed_customer_credit"] = this.redeemed_customer_credit;
      data["customer_credit_dict"] = this.customer_credit_dict;
      data["is_cashback"] = this.is_cashback;

      this.submitInvoice({
        data: data,
        invoice: this.invoice_doc,
      });
      this.customer_credit_dict = [];
      this.redeem_customer_credit = false;
      this.is_cashback = true;
      this.sales_person = "";

      this.emitter.emit("newInvoice");
      this.onBacktoInvoice();
    },
    setFullAmount(idx) {
      this.selectedModeIdx = idx;
      this.invoice_doc.payments.forEach((payment) => {
        payment.amount = 0;
      });
      this.receiveKHR = 0;
      this.receiveUSD = 0;
    },
    setRestAmount(idx) {
      this.invoice_doc.payments.forEach((payment) => {
        if (
          payment.idx == idx &&
          payment.amount == 0 &&
          this.diff_payment > 0
        ) {
          payment.amount = this.diff_payment;
        }
      });
    },
    clear_all_amounts() {
      this.invoice_doc.payments.forEach((payment) => {
        payment.amount = 0;
      });
    },
    validate_due_date() {
      const today = frappe.datetime.now_date();
      const parse_today = Date.parse(today);
      const new_date = Date.parse(this.invoice_doc.due_date);
      if (new_date < parse_today) {
        setTimeout(() => {
          this.invoice_doc.due_date = today;
        }, 0);
      }
    },
    formtFloat(value) {
      value = parseFloat(value);
      return value
        .toFixed(this.float_precision)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    shortPay(e) {
      if (e.key === "x" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.submit();
      }
    },
    setPaidChange() {
      if (!this.paid_change) this.paid_change = 0;

      this.paid_change_rules = [];
      let change = -this.diff_payment;
      if (this.paid_change > change) {
        this.paid_change_rules = [
          "Paid change can not be greater than total change!",
        ];
        this.credit_change = 0;
      }
    },
    getAvailableCredit(e) {
      this.clear_all_amounts();
      if (e) {
        dataService
          .get(
            "get_available_credit",
            `customer=${this.invoice_doc.customer}&&company=${this.pos_profile.company}`
          )
          .then((res) => {
            const data = res.data.message;
            if (data.length) {
              this.customer_credit_dict = data;
            } else {
              this.customer_credit_dict = [];
            }
          });
      } else {
        this.customer_credit_dict = [];
      }
    },
    get_addresses() {
      const vm = this;
      if (!vm.invoice_doc) {
        return;
      }
      dataService
        .get("get_customer_addresses", `customer=${vm.invoice_doc.customer}`)
        .then((res) => {
          let data = res.data.message;
          if (!data) {
            vm.addresses = data;
          } else {
            vm.addresses = [];
          }
        });
    },
    addressFilter(item, queryText, itemText) {
      const textOne = item.address_title
        ? item.address_title.toLowerCase()
        : "";
      const textTwo = item.address_line1
        ? item.address_line1.toLowerCase()
        : "";
      const textThree = item.address_line2
        ? item.address_line2.toLowerCase()
        : "";
      const textFour = item.city ? item.city.toLowerCase() : "";
      const textFifth = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1 ||
        textFour.indexOf(searchText) > -1 ||
        textFifth.indexOf(searchText) > -1
      );
    },
    new_address() {
      this.emitter.emit("open_new_address", this.invoice_doc.customer);
    },
    get_sales_person_names() {
      const vm = this; //declare
      if (
        vm.pos_profile.custom_use_browser_local_storage &&
        localStorage.sales_persons_storage
      ) {
        vm.sales_persons = JSON.parse(
          localStorage.getItem("sales_persons_storage")
        );
      }
      dataService.get("get_sales_person_names").then((res) => {
        let data = res.data.message;
        if (data) {
          vm.sales_persons = data;
          if (vm.pos_profile.custom_use_browser_local_storage) {
            localStorage.setItem("sales_persons_storage", "");
            localStorage.setItem("sales_persons_storage", JSON.stringify(data));
          }
        }
      });
    },
    salesPersonFilter(item, queryText, itemText) {
      const textOne = item.sales_person_name
        ? item.sales_person_name.toLowerCase()
        : "";
      const textTwo = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    request_payment() {
      this.phone_dialog = false;
      const vm = this;
      if (!this.invoice_doc.contact_mobile) {
        this.emitter.emit("show_mesage", {
          text: __(`Pleas Set Customer Mobile Number`),
          color: "error",
        });
        this.emitter.emit("open_edit_customer");
        this.onBacktoInvoice();
        return;
      }
      this.emitter.emit("freeze", {
        title: __(`Waiting for payment... `),
      });

      let formData = this.invoice_doc;
      formData["total_change"] = -this.diff_payment;
      formData["paid_change"] = this.paid_change;
      formData["credit_change"] = -this.credit_change;
      formData["redeemed_customer_credit"] = this.redeemed_customer_credit;
      formData["customer_credit_dict"] = this.customer_credit_dict;
      formData["is_cashback"] = this.is_cashback;

      frappe
        .call({
          method: "posawesome.posawesome.api.posapp.update_invoice",
          args: {
            data: formData,
          },
          async: false,
          callback: function (r) {
            if (r.message) {
              vm.invoice_doc = r.message;
            }
          },
        })
        .then(() => {
          frappe
            .call({
              method: "posawesome.posawesome.api.posapp.create_payment_request",
              args: {
                doc: vm.invoice_doc,
              },
            })
            .fail(() => {
              this.emitter.emit("unfreeze");
              this.emitter.emit("show_mesage", {
                text: __(`Payment request failed`),
                color: "error",
              });
            })
            .then(({ message }) => {
              const payment_request_name = message.name;
              setTimeout(() => {
                frappe.db
                  .get_value("Payment Request", payment_request_name, [
                    "status",
                    "grand_total",
                  ])
                  .then(({ message }) => {
                    if (message.status != "Paid") {
                      this.emitter.emit("unfreeze");
                      this.emitter.emit("show_mesage", {
                        text: __(
                          `Payment Request took too long to respond. Please try requesting for payment again`
                        ),
                        color: "error",
                      });
                    } else {
                      this.emitter.emit("unfreeze");
                      this.emitter.emit("show_mesage", {
                        text: __("Payment of {0} received successfully.", [
                          formtCurrency(
                            message.grand_total,
                            vm.invoice_doc.currency,
                            0
                          ),
                        ]),
                        color: "success",
                      });
                      frappe.db
                        .get_doc("Sales Invoice", vm.invoice_doc.name)
                        .then((doc) => {
                          vm.invoice_doc = doc;
                          vm.submit();
                        });
                    }
                  });
              }, 30000);
            });
        });
    },
    get_mpesa_modes() {
      const vm = this;
      dataService
        .get("get_mpesa_mode_of_payment", `company=${vm.pos_profile.company}`)
        .then((res) => {
          let data = res.data.message;
          if (!data) {
            vm.mpesa_modes = data;
          } else {
            vm.mpesa_modes = [];
          }
        });
    },
    is_mpesa_c2b_payment(payment) {
      if (
        this.mpesa_modes.includes(payment.mode_of_payment) &&
        payment.type == "Bank"
      ) {
        payment.amount = 0;
        return true;
      } else {
        return false;
      }
    },
    mpesa_c2b_dialg(payment) {
      const data = {
        company: this.pos_profile.company,
        mode_of_payment: payment.mode_of_payment,
        customer: this.invoice_doc.customer,
      };
      this.emitter.emit("open_mpesa_payments", data);
    },
    set_mpesa_payment(payment) {
      this.pos_profile.custom_use_customer_credit = 1;
      this.redeem_customer_credit = true;
      const advance = {
        type: "Advance",
        credit_origin: payment.name,
        total_credit: payment.unallocated_amount,
        credit_to_redeem: payment.unallocated_amount,
      };
      this.clear_all_amounts();
      this.customer_credit_dict.push(advance);
    },
  },

  created: function () {
    this.$nextTick(function () {
      this.emitter.on("sendInvoiceDocPayment", async (invoice_doc) => {
        this.loading = true;
        this.invoice_doc = await invoice_doc;
        const default_payment = this.invoice_doc.payments.find(
          (payment) => payment.default == 1
        );
        this.is_credit_sale = false;
        this.is_write_off_change = false;
        if (default_payment) {
          default_payment.amount = this.invoice_doc.grand_total;
        }
        this.loyalty_amount = 0;
        this.get_addresses();
        this.get_sales_person_names();
        this.receiveUSD = 0;
        this.receiveKHR = 0;
        this.loading = false;
      });
      // this.get_mpesa_modes();
      this.emitter.on("add_the_new_address", (data) => {
        this.addresses.push(data);
        this.$forceUpdate();
      });
      this.emitter.on("update_invoice_type", (data) => {
        this.invoiceType = data;
        if (this.invoice_doc && data != "Order") {
          this.invoice_doc.posa_delivery_date = null;
          this.invoice_doc.take_note = null;
          this.invoice_doc.remarks = null;
          this.invoice_doc.shipping_address_name = null;
        }
      });
    });
    this.emitter.on("update_customer", (customer) => {
      if (this.customer != customer) {
        this.customer_credit_dict = [];
        this.redeem_customer_credit = false;
        this.is_cashback = true;
      }
    });
    this.emitter.on("set_pos_settings", (data) => {
      this.pos_settings = data;
    });
    this.emitter.on("set_customer_info_to_edit", (data) => {
      this.customer_info = data;
    });
    this.emitter.on("set_mpesa_payment", (data) => {
      this.set_mpesa_payment(data);
    });
    document.addEventListener("keydown", this.shortPay.bind(this));
  },

  destroyed() {
    document.removeEventListener("keydown", this.shortPay);
  },
};
</script>
