<template>
  <div class="p-1 pr-4  bg-white dark:bg-slate-950 flex justify-center items-center h-12 lg:h-16">
    <div class="w-5/6 flex items-center space-x-2 z-20" v-show="isSearch">
      <div class="w-5/6 relative">
        <input type="text" v-model="query" @input="handleSearch"
          class="w-full px-4 py-1.5 text-sm border pr-2  text-gray-900 dark:text-gray-100 bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-full focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
          placeholder="Search..." />
        <span v-if="query != ''" @click="handleSearchClear"
          class="absolute top-[5px] right-3 lg:top-[7.5px]  text-gray-500 dark:text-gray-400 cursor-pointer"><svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
              clip-rule="evenodd" />
          </svg>
        </span>
      </div>
      <button class="text-gray-400 md:hidden" @click="handleCloseSearch">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div class="text-sm lg:text-base absolute w-full flex justify-center z-10">
      <p class="w-48 text-center ellipsis text-primary">
        <span class="lg:text-[20px] ml-1 ">
          {{ table?.label }}
        </span>
      </p>
    </div>
    <div v-show="!isSearch" class="w-full relative flex items-center justify-between z-20">
      <router-link :to="Tr.i18nRoute({ name: 'table' })">
        <img src="@/assets/image/Logo.png" alt="Logo" class="cursor-pointer h-[38px] lg:h-14" />
      </router-link>

      <div class="w-1/2 md:w-1/2 hidden md:block">
        <input type="text" v-model="query" @input="handleSearch"
          class="w-full px-4 py-1.5 text-sm border pr-2  text-gray-900 dark:text-gray-100 bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-full focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
          placeholder="Search..." />
        <span v-if="query != ''" @click="handleSearchClear"
          class="absolute top-[5px] right-3 lg:top-[7.5px]   text-gray-500 dark:text-gray-400 cursor-pointer"><svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
              clip-rule="evenodd" />
          </svg>
        </span>
      </div>
      <div class="flex items-center space-x-2">
        <button class="text-gray-400 md:hidden" @click="this.isSearch = true">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>
        </button>
        <SwitchComponet />
        <!-- <button @click="() => (this.onDrawerOpenOrder = !this.onDrawerOpenOrder)" type="button"
          class="md:hidden flex items-center justify-center rounded-md p-1 text-gray-400 relative">
          <span class="sr-only">Close menu</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
          </svg>
          <span
            class="absolute top-1 right-1 inline-flex items-center justify-center px-1.5 py-0.5 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full"
            v-show="getCartTotalQty > 0">{{ getCartTotalQty > 100 ? '+99' : getCartTotalQty }}</span>
        </button> -->
      </div>
    </div>
  </div>
  <div class="w-full">
    <div class=" grid grid-cols-1 md:grid-cols-6">
      <div class="col-span-4 w-full">
        <div class="flex items-center justify-between space-x-2 w-full p-2">
          <div class="flex items-center space-x-2 w-full">
            <span v-if="menuChild.child.length !== 0" @click="handleCloseMenuChild"
              class="bg-primary shadow rounded-full p-0.5 text-white cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>
            <nav
              class="grid grid-flow-col text-center max-w-full md:max-w-[75%] overflow-x-auto no-scrollbar  text-gray-600 bg-gray-200 dark:text-gray-100 dark:bg-slate-700 rounded-full">
              <a v-if="menuChild.child.length === 0" v-for="(item, index) in menu" :key="item.name">
                <span @click="() => handleSelectMenu(item, index)"
                  class="flex justify-center cursor-pointer text-sm lg py-1 lg:py-2 w-24 lg:w-40" :class="[
                    item.name === selectMenu &&
                    'bg-primary rounded-full shadow text-white',
                  ]">{{ item.label }}</span>
              </a>
              <a v-else>
                <span @click="() => handleSelectMenu(menuChild, 0)"
                  class="flex justify-center cursor-pointer text-sm lg py-1 lg:py-2 w-24 lg:w-40" :class="[
                    menuChild.name === selectMenu &&
                    'bg-secondary rounded-full shadow text-white',
                  ]">{{ menuChild.label }}</span>
              </a>
              <a v-if="menuChild.child.length !== 0" v-for="(itemChild, index) in menuChild.child" :key="itemChild.name">
                <span @click="() => handleSelectMenu(itemChild, index)"
                  class="flex justify-center cursor-pointer text-sm lg py-1 lg:py-2 w-24 lg:w-40" :class="[
                    itemChild.name === selectMenu &&
                    'bg-secondary rounded-full shadow text-white',
                  ]">{{ itemChild.label }}</span>
              </a>
            </nav>
          </div>
        </div>
        <div class="relative">
          <SpinComponent :show="loadingGetItem" />
          <EmptyComponent :show="getItems?.items?.length == 0 && !loadingGetItem" />
          <div id="infinite-list"
            class="relative mt-2 p-2 overflow-auto h-[calc(100vh_-_100px)] lg:h-[calc(100vh_-_124px)] dir-rtl pr-2">
            <div class="grid gap-2 lg:gap-4 grid-cols-3 sm:grid-cols-4 lg:grid-cols-5">
              <div v-for="item in getItems?.items" :key="item.name" class="group cursor-pointer" @click="
                getNumberCustomer != 0
                  ? addItemToCart(item)
                  : handleBeforeGuests(item)
                ">
                <div
                  class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 lg:aspect-h-8 lg:aspect-w-7">
                  <img :src="item.image ? baseURL + item.image : imageEmpty" :alt="item?.imageAlt"
                    class="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3 class="mt-3 text-sm  text-gray-700 dark:text-gray-100 ellipsis-line line-clamp-2">
                  {{ item.item_name }}
                </h3>
                <p class="mt-1 text-sm font-medium  text-gray-900 dark:text-gray-100">
                  {{
                    item.currency_symbol +
                    $n(
                      item.price_list_rate,
                      "decimal",
                      `${item.currency === "USD" ? "en" : "kh"}`
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="w-full block md:hidden"
            style="box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;">
            <div class="overflow-hidden relative">
              <EmptyComponent :show="getCartItems.length == 0" />
              <ul role="list"
                class="divide-y  divide-gray-100 dark:divide-gray-600 flex flex-col overflow-auto pr-2 rounded-lg h-[214px]">
                <li v-for="item in getCartItems" :key="item.name" class="flex py-2 lg:py-4">
                  <div class="flex flex-1 flex-col px-2" @click="handleOpenOption(item)">
                    <div>
                      <div class="flex justify-between   text-gray-900 dark:text-gray-100 items-start">
                        <div>
                          <div class="text-sm font-medium flex items-center space-x-1">
                            <h1 class="ellipsis-line line-clamp-1">{{ item.item_name }}</h1>
                          </div>
                          <p v-if="item.take_note != ''" class="text-sm text-gray-600 dark:text-gray-400">
                            {{ item.take_note }}
                          </p>
                        </div>
                        <div class="text-right">
                          <p class="text-sm lg:text-base">
                            {{
                              item.currency_symbol +
                              $n(
                                item.price_list_rate,
                                "decimal",
                                `${item.currency === "USD" ? "en" : "kh"}`
                              )
                            }}
                          </p>
                          <p v-if="item.discount != 0" class="text-sm lg:text-base text-gray-500  dark:text-gray-400">
                            {{ item.discount }}% off
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-1 items-end justify-between text-sm">
                      <span v-if="item.parent" class=" dark:text-gray-100">Qty: {{ item.qty }}</span>
                      <div v-else
                        class="flex items-end bg-gray-100 rounded-md border border-gray-100 mb-1  dark:bg-slate-700 dark:border-gray-700">
                        <button class="p-[2px] bg-white text-gray-600  dark:bg-slate-800 dark:text-white" :class="item.qty == 1
                          ? 'cursor-not-allowed opacity-50'
                          : 'cursor-pointer'
                          " :disabled="item.qty == 1" @click.stop="decrementqtyByCartItem(item)">
                          <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                            stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                          </svg>
                        </button>
                        <span class="mx-2  dark:text-gray-100">{{ item.qty }}</span>
                        <button class="p-[2px] bg-white text-gray-600  dark:bg-slate-800 dark:text-white"
                          @click.stop="incrementqtyByCartItem(item)">
                          <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                            stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                          </svg>
                        </button>
                      </div>
                      <div class="font-medium text-sm">
                        <div v-if="item.parent" class="text-primary">{{ item.dpos_status !== 'Done' ? 'Ordered' : 'Done'
                        }}
                        </div>
                        <button v-else type="button" @click.stop="removeItemFromCart(item)" class="text-red-700">
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <div class="border-dashed border rounded-lg  dark:text-white dark:border-slate-600">
                <div class="p-2 lg:p-4 pb-2 text-sm lg:text-base">
                  <div class="flex justify-between leading-6 lg:leading-10 items-center">
                    <p>Subtotal</p>
                    <p class="font-medium">
                      ${{ $n(getCartSubTotal, "decimal", "en") }}
                    </p>
                  </div>
                  <div v-if="true" class="flex justify-between leading-6 lg:leading-10 items-center">
                    <p>Tax {{ getTax }}%</p>
                    <p class="font-medium">
                      ${{ $n(this.getTotalAmountTaxCharge(this.getTax), "decimal", "en") }}
                    </p>
                  </div>
                </div>
                <div class="border-dotted border-t h-1 w-full  dark:border-slate-600"></div>
                <div class="p-2 lg:p-4 flex justify-between leading-6 lg:leading-10 items-center">
                  <h1 class="text-[16px] lg:text-[20px]">TOTAL</h1>
                  <p class="text-sm lg:text-[20px] font-medium">
                    ${{ $n(total, "decimal", "en") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <button @click="saveCartItem"
                class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white font-medium w-full justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-5 h-5 mr-2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                </svg>
                Order
              </button>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-span-3 md:col-span-2 hidden md:block">
        <div class="p-2 leading-4 lg:leading-10">
          <div
            class="flex justify-between border-b-2  dark:border-slate-600 text-gray-900 dark:text-gray-100">
            <div class="flex items-center text-sm lg:text-base space-x-1" @click="() => (this.isModalOpenGuests = true)">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="hidden lg:block w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>
              </div>
              <p class="flex items-center space-x-1">
              <p>Guests:</p>
              <p class="text-primary lg:text-[20px]">{{ getNumberCustomer }}</p>
              </p>
            </div>
            <div class="text-sm lg:text-base cursor-pointer">
              <p class="w-48 text-right ellipsis text-primary">
                <span class=" text-slate-900 dark:text-gray-50 relative"><svg
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="hidden lg:block w-6 h-6 absolute -bottom-[3px] left-0">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                  </svg>
                  <span class="ml-[26px]">Table:</span>
                </span>
                <span class="lg:text-[20px] ml-1">
                  {{ table?.label }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="pl-2 lg:pl-4 pr-0 overflow-hidden relative">
          <EmptyComponent :show="getCartItems.length == 0" />
          <ul role="list"
            class="divide-y  divide-gray-100 dark:divide-gray-600 flex flex-col overflow-auto pr-2 rounded-lg h-[calc(100vh_-_288px)] lg:h-[calc(100vh_-_410px)]">
            <li v-for="item in getCartItems" :key="item.name" class="flex py-2 lg:py-4">
              <div
                class="h-16 lg:h-24 w-16 lg:w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 bg-white"
                :class="!item.parent && 'cursor-pointer'" @click="handleOpenOption(item)">
                <img :src="item.image ? baseURL + item.image : imageEmpty" :alt="item?.imageAlt"
                  class="h-full w-full object-cover object-center" />
              </div>

              <div class="ml-4 flex flex-1 flex-col">
                <div>
                  <div class="flex justify-between  text-gray-900 dark:text-gray-100 items-start">
                    <div>
                      <div class="text-sm font-medium flex items-center space-x-1">
                        <h1 class="ellipsis-line line-clamp-1">{{ item.item_name }}</h1>
                      </div>
                      <p v-if="item.take_note != ''" class="text-sm  text-gray-600 dark:text-gray-400">
                        {{ item.take_note }}
                      </p>
                    </div>
                    <div class="text-right">
                      <p class="text-sm lg:text-base">
                        {{
                          item.currency_symbol +
                          $n(
                            item.price_list_rate,
                            "decimal",
                            `${item.currency === "USD" ? "en" : "kh"}`
                          )
                        }}
                      </p>
                      <p v-if="item.discount != 0"
                        class="text-sm lg:text-base  text-gray-500 dark:text-gray-400">
                        {{ item.discount }}% off
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex flex-1 items-end justify-between text-sm">
                  <span v-if="item.parent" class=" dark:text-gray-100">Qty: {{ item.qty }}</span>
                  <div v-else
                    class="flex items-end bg-gray-100 rounded-md  border border-gray-100 mb-1 dark:bg-slate-700 dark:border-gray-700">
                    <button class="p-[2px]  bg-white text-gray-600 dark:bg-slate-800 dark:text-white" :class="item.qty == 1
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                      " :disabled="item.qty == 1" @click="decrementqtyByCartItem(item)">
                      <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                      </svg>
                    </button>
                    <span class="mx-2  dark:text-gray-100">{{ item.qty }}</span>
                    <button class="p-[2px]  bg-white text-gray-600 dark:bg-slate-800 dark:text-white"
                      @click="incrementqtyByCartItem(item)">
                      <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                      </svg>
                    </button>
                  </div>
                  <div class="font-medium text-sm">
                    <div v-if="item.parent" class="text-primary">{{ item.dpos_status !== 'Done' ? 'Ordered' : 'Done' }}
                    </div>

                    <button v-else type="button" @click="removeItemFromCart(item)" class="text-red-700">
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="p-2 lg:pl-4">
          <div class="border-dashed border rounded-lg  dark:text-white dark:border-slate-600">
            <div class="p-2 lg:p-4 pb-2 text-sm lg:text-base">
              <div class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Subtotal</p>
                <p class="font-medium">
                  ${{ $n(getCartSubTotal, "decimal", "en") }}
                </p>
              </div>
              <div v-if="true" class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Tax {{ getTax }}%</p>
                <p class="font-medium">
                  ${{ $n(this.getTotalAmountTaxCharge(this.getTax), "decimal", "en") }}
                </p>
              </div>
              <div v-if="true" class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Service Charge {{ getCharge }}%</p>
                <p class="font-medium">
                  ${{ $n(this.getTotalAmountTaxCharge(this.getCharge), "decimal", "en") }}
                </p>
              </div>
            </div>
            <div class="border-dotted border-t h-1 w-full  dark:border-slate-600"></div>
            <div class="p-2 lg:p-4 flex justify-between leading-6 lg:leading-10 items-center">
              <h1 class="text-[16px] lg:text-[20px]">TOTAL</h1>
              <p class="text-sm lg:text-[20px] font-medium">
                ${{ $n(total, "decimal", "en") }}
              </p>
            </div>
          </div>
        </div>
        <div class="pr-2 pl-2 lg:pl-4">
          <div class="grid grid-cols-2 gap-1.5 lg:gap-5">
            <div class="col-span-1">

            </div>
            <div class="col-span-1">
              <button @click="saveCartItem"
                class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white font-medium rounded-md w-full justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-5 h-5 mr-2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                </svg>
                Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DrawerComponent :isDrawerOpen="onDrawerOpenOrder" :isDrawerClose="() => this.onDrawerOpenOrder = false">
      <div class="w-full">
        <div class="p-2 leading-4 lg:leading-10">
          <div
            class="flex justify-between  border-b-2 dark:border-slate-600 text-gray-900 dark:text-gray-100">
            <div class="flex items-center text-sm lg:text-base space-x-1" @click="() => (this.isModalOpenGuests = true)">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="hidden lg:block w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>
              </div>
              <p class="flex items-center space-x-1">
              <p>Guests:</p>
              <p class="text-primary lg:text-[20px]">{{ getNumberCustomer }}</p>
              </p>
            </div>
            <div class="text-sm lg:text-base cursor-pointer">
              <p class="w-48 text-right ellipsis text-primary">
                <span class=" text-slate-900 dark:text-gray-50 relative"><svg
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="hidden lg:block w-6 h-6 absolute -bottom-[3px] left-0">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                  </svg>
                  <span class="ml-[26px]">Table:</span>
                </span>
                <span class="lg:text-[20px] ml-1">
                  {{ table?.label }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="pl-2 lg:pl-4 pr-0 overflow-hidden relative">
          <EmptyComponent :show="getCartItems.length == 0" />
          <ul role="list"
            class="divide-y  divide-gray-100 dark:divide-gray-600 flex flex-col overflow-auto pr-2 rounded-lg h-[calc(100vh_-_288px)] lg:h-[calc(100vh_-_410px)]">
            <li v-for="item in getCartItems" :key="item.name" class="flex py-2 lg:py-4">
              <div
                class="h-16 lg:h-24 w-16 lg:w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 bg-white"
                :class="!item.parent && 'cursor-pointer'" @click="handleOpenOption(item)">
                <img :src="item.image ? baseURL + item.image : imageEmpty" :alt="item?.imageAlt"
                  class="h-full w-full object-cover object-center" />
              </div>

              <div class="ml-4 flex flex-1 flex-col">
                <div>
                  <div class="flex justify-between  text-gray-900 dark:text-gray-100 items-start">
                    <div>
                      <div class="text-sm font-medium flex items-center space-x-1">
                        <h1 class="ellipsis-line line-clamp-1">{{ item.item_name }}</h1>
                      </div>
                      <p v-if="item.take_note != ''" class="text-sm  text-gray-600 dark:text-gray-400">
                        {{ item.take_note }}
                      </p>
                    </div>
                    <div class="text-right">
                      <p class="text-sm lg:text-base">
                        {{
                          item.currency_symbol +
                          $n(
                            item.price_list_rate,
                            "decimal",
                            `${item.currency === "USD" ? "en" : "kh"}`
                          )
                        }}
                      </p>
                      <p v-if="item.discount != 0"
                        class="text-sm lg:text-base  text-gray-500 dark:text-gray-400">
                        {{ item.discount }}% off
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex flex-1 items-end justify-between text-sm">
                  <span v-if="item.parent" class=" dark:text-gray-100">Qty: {{ item.qty }}</span>
                  <div v-else
                    class="flex items-end bg-gray-100 rounded-md border border-gray-100 mb-1  dark:bg-slate-700 dark:border-gray-700">
                    <button class="p-[2px]  bg-white text-gray-600 dark:bg-slate-800 dark:text-white" :class="item.qty == 1
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                      " :disabled="item.qty == 1" @click="decrementqtyByCartItem(item)">
                      <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                      </svg>
                    </button>
                    <span class="mx-2  dark:text-gray-100">{{ item.qty }}</span>
                    <button class="p-[2px]  bg-white text-gray-600 dark:bg-slate-800 dark:text-white"
                      @click="incrementqtyByCartItem(item)">
                      <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                      </svg>
                    </button>
                  </div>
                  <div class="font-medium text-sm">
                    <div v-if="item.parent" class="text-primary">{{ item.dpos_status !== 'Done' ? 'Ordered' : 'Done' }}
                    </div>
                    <button v-else type="button" @click="removeItemFromCart(item)" class="text-red-700">
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="p-2 lg:pl-4">
          <div class="border-dashed border rounded-lg  dark:text-white dark:border-slate-600">
            <div class="p-2 lg:p-4 pb-2 text-sm lg:text-base">
              <div class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Subtotal</p>
                <p class="font-medium">
                  ${{ $n(getCartSubTotal, "decimal", "en") }}
                </p>
              </div>
              <div v-if="true" class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Tax {{ getTax }}%</p>
                <p class="font-medium">
                  ${{ $n(this.getTotalAmountTaxCharge(this.getTax), "decimal", "en") }}
                </p>
              </div>
              <div v-if="true" class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Service Charge {{ getCharge }}%</p>
                <p class="font-medium">
                  ${{ $n(this.getTotalAmountTaxCharge(this.getCharge), "decimal", "en") }}
                </p>
              </div>
            </div>
            <div class="border-dotted border-t h-1 w-full  dark:border-slate-600"></div>
            <div class="p-2 lg:p-4 flex justify-between leading-6 lg:leading-10 items-center">
              <h1 class="text-[16px] lg:text-[20px]">TOTAL</h1>
              <p class="text-sm lg:text-[20px] font-medium">
                ${{ $n(total, "decimal", "en") }}
              </p>
            </div>
          </div>
        </div>
        <div class="px-2 lg:pl-4">
          <button @click="saveCartItem"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white font-medium rounded-md w-full justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-5 h-5 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
            </svg>
            Order
          </button>
        </div>
      </div>
    </DrawerComponent>
    <ModalComponent title="How many guests?" :isModalOpen="isModalOpenGuests" :onToggle="() => {
      this.isModalOpenGuests = false;
      this.itemBeforeGuests = null;
    }
      " :width="500">
      <KeypadComponent :handleKeypadOk="handleKeypadOk" defaultValue="1" />
    </ModalComponent>
    <KeypadDialogComponent :show="showKeypad" :handleKeypadDialogOk="handleKeypadDialogOk" :keyKeypad="keyKeypad"
      :onToggle="() => (this.showKeypad = false)" />
    <ModalComponent title="Confirmation" :isModalOpen="isModalOpenConfOrder" :onToggle="() => {
      this.isModalOpenConfOrder = false;
    }
      " :width="500">
      <div class="w-full">
        <div>
          <p class="dark:text-gray-200">
            Are you sure want to order?
          </p>
        </div>
        <div class="flex justify-end pt-2 lg:pt-4">
          <div class="flex space-x-4">
            <button @click="
              this.isModalOpenConfOrder = false;
            "
              class="flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium">
              Cancel
            </button>
            <button @click="handleYesConfOrder"
              class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center">
              Yes
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
    <ModalComponent :title="selectItemCart?.item_name" :isModalOpen="isModalOpenOption" :onToggle="handleCloseOption"
      :width="578">
      <div class="pb-2">
        <label class="text-sm lg:text-sm flex space-x-2 items-center dark:text-gray-200"><span>Take Note</span>
        </label>
        <div v-if="!isAddTakeNote" class="flex space-x-2 lg:space-x-4 items-center">
          <div class="w-full ">
            <multiselect v-model="selectedTakeNote" placeholder="" label="description" track-by="description"
              :options="dataTakeNote" :multiple="true" />
          </div>
          <div>
            <button
              class="dark:text-gray-200 underline underline-offset-2 inline-flex items-center text-sm py-2 lg:py-[10px] bg-transparent text-gray-600 hover:border-secondary/80 hover:text-secondary font-medium rounded-md justify-center whitespace-nowrap"
              @click="this.isAddTakeNote = true">
              + New
            </button>
          </div>
        </div>
        <div v-else class="flex space-x-2 lg:space-x-4 items-center">
          <input v-model="takeNote" type="text"
            class="w-full px-4 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
            placeholder="Enter note" />
          <div class="text-gray-600 cursor-pointer dark:text-gray-200" @click="this.isAddTakeNote = !this.isAddTakeNote">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4">
        <div class="flex space-x-4">
          <button @click="handleCloseOption"
            class="flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-white font-medium rounded-md">
            Cancel
          </button>
          <button @click="handleSaveOption"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center">
            Save
          </button>
        </div>
      </div>
    </ModalComponent>
    <button v-show="getCartTotalQty > 0" @click="() => (this.onDrawerOpenOrder = !this.onDrawerOpenOrder)" type="button"
      class="block md:hidden fixed bg-secondary  dark:bg-slate-300 text-white dark:text-black  shadow-md z-10 bottom-10 right-10 p-2.5 rounded-full">
      <div class=" flex items-center justify-center rounded-md p-1  relative">
        <span class="sr-only">Close menu</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
        </svg>
        <span
          class="absolute animate-bounce -top-1 -right-3 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full"
          v-show="getCartTotalQty > 0">{{ getCartTotalQty > 100 ? '+99' : getCartTotalQty }}</span>
      </div>
    </button>
    <SpinComponent :show="getStatusSave" />
  </div>
</template>
  
<script>
import ModalComponent from "@/components/modal/ModalComponent.vue";
import KeypadDialogComponent from "@/components/keypad/KeypadDialogComponent.vue";
import KeypadComponent from "@/components/keypad/KeypadComponent.vue";
import DrawerComponent from "@/components/drawer/DrawerComponent.vue";
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import dataService from "@/services/data.service";
import imageEmpty from "@/assets/image/empty.jpg";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import EmptyComponent from "@/components/empty/EmptyComponent.vue";
import SwitchComponet from "@/components/switch/SwitchComponet.vue";
import { mapActions, mapGetters } from "vuex";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";
import { baseURL } from "@/utils/baseURL";

export default {
  name: "Menu Customer",
  components: {
    ModalComponent,
    KeypadDialogComponent,
    KeypadComponent,
    DrawerComponent,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    EmptyComponent,
    SpinComponent,
    SwitchComponet
  },
  data() {
    return {
      Tr,
      imageEmpty,
      menu: [{ label: "All", name: "all", index_no: 0, child: [] }],
      menuChild: { child: [] },
      selectMenu: "all",
      showKeypad: false,
      keyKeypad: "",
      isModalOpenGuests: false,
      onDrawerOpenOrder: false,
      items: [],
      loading: false,
      isSearch: false,
      itemBeforeGuests: null,
      qty: "",
      query: "",
      limit: 20,
      page: 1,
      scrollTop: 0,
      baseURL,
      table: null,
      pos_profile: null,
      isModalOpenConfOrder: false,
      selectItemCart: null,
      selectedTakeNote: null,
      isModalOpenOption: false,
      isAddTakeNote: false,
      dataTakeNote: null,
      takeNote: ''
    };
  },
  computed: {
    ...mapGetters("itemsCustomer", {
      getItemsByKey: "getItemsByKey",
      loadingGetItem: "loadingGetItem",
    }),
    ...mapGetters("restaurantCustomer", {
      getCartItems: "getCartItems",
      getCartTotal: "getCartTotal",
      getCartSubTotal: "getCartSubTotal",
      getCartTotalQty: "getCartTotalQty",
      getNumberCustomer: "getNumberCustomer",
      getTotalAmountTaxCharge: "getTotalAmountTaxCharge",
      getStatusSave: "getStatusSave"
    }),
    getItems() {
      return this.getItemsByKey(this.selectMenu);
    },
    getQuery() {
      return this.$route.query
    },
    getTax() {
      let charge = this.pos_profile?.taxes_and_charges?.find(
        (e) => e.dpos_type === "Tax"
      )?.dpos_rate;
      return charge ? charge : 0;
    },
    getCharge() {
      let charge = this.pos_profile?.taxes_and_charges?.find(
        (e) => e.dpos_type === "Service Charge"
      )?.dpos_rate;
      return charge ? charge : 0;
    },
    total() {
      return this.getCartTotal(
        this.getTotalAmountTaxCharge(this.getTax),
        this.getTotalAmountTaxCharge(this.getCharge)
      );
    },
  },
  mounted() {
    socket.on(type.refCS, (message) => {
      if (message.pos_profile === this.getQuery.pos_profile && message.table === this.table.name) {
        this.getDataTable()
      }
    });
    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", (e) => {
      this.scrollTop = listElm.scrollTop;
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        this.getItems?.total > this.getItems?.items.length
      ) {
        this.page++;
        this.getDataMenuItems();
      }
    });
  },
  created() {
    this.getCheckKey()
    this.getDataPosProfile()
    this.getDataMenu();
    this.getDataMenuItems();
    this.getDataTable()
    this.getDataTakeNote()
  },
  methods: {
    handleCloseSearch() {
      this.isSearch = false
      this.handleSearchClear()
    },
    handleSaveOption() {
      this.setOptionByCartItem({
        ...this.selectItemCart,
        take_note: this.isAddTakeNote ? this.takeNote :
          this.selectedTakeNote.length == 0
            ? ""
            : this.selectedTakeNote
              ?.map((item) => item.description)
              .join(", "),
      });
      this.isModalOpenOption = false;
      this.isAddTakeNote = false;
    },
    handleCloseOption() {
      this.isModalOpenOption = false;
      this.isAddTakeNote = false
    },
    getDataTakeNote() {
      dataService.getPublic("get_take_note_customer", `pos_profile=${this.getQuery.pos_profile}&secret=${this.getQuery.key}`).then((res) => {
        this.dataTakeNote = res.data.message;
      });
    },
    handleOpenOption(item) {
      if (!item.parent) {
        this.selectItemCart = item;
        this.selectedTakeNote =
          item.take_note != ""
            ? item.take_note?.split(", ").map((description, index) => ({
              name: description.trim(),
              description: description.trim(),
            }))
            : [];
        this.isModalOpenOption = true;
      }
    },
    handleYesConfOrder() {
      this.setCartItem({
        table: this.getQuery.table,
        total: this.total,
        pos_profile: this.getQuery.pos_profile,
        secret: this.getQuery.key
      });
      this.isModalOpenConfOrder = false
    },
    getCheckKey() {
      dataService.getPublic("check_key", `key=${this.getQuery.key}`).then(res => {
        let check = res.data.message;
        if (!check) this.$router.replace(
          Tr.i18nRoute({ name: "403" })
        );
      }
      )
    },
    saveCartItem() {
      if (this.getCartItems.length != 0)
        this.isModalOpenConfOrder = true
      else {
        dispatchNotification({
          content: "Please choose items!",
          type: "warning",
        });
      }
    },
    getDataTable() {
      dataService.getPublic("get_table_customer", `key=${this.getQuery.table}&pos_profile=${this.getQuery.pos_profile}&secret=${this.getQuery.key}`).then((res) => {
        let table = res.data.message.data;
        this.table = table;
        if (!table) {
          this.$router.replace(
            Tr.i18nRoute({ name: "404" })
          );
        }
        this.getDataSalesInvoice(`key=${table?.sales_invoice}&secret=${this.getQuery.key}`);
      });
    },
    getDataPosProfile() {
      dataService.getPublic("get_pos_profile_customer", `name=${this.getQuery.pos_profile}&secret=${this.getQuery.key}`).then((res) => {
        this.pos_profile = res.data.message;
      });
    },
    handleBeforeGuests(item) {
      this.isModalOpenGuests = true;
      this.itemBeforeGuests = item;
    },
    ...mapActions("itemsCustomer", {
      setItems: "setItems",
      setItemEmpty: "setItemEmpty",
      setItemsSearch: "setItemsSearch",
    }),
    ...mapActions("restaurantCustomer", {
      setCartItem: "setCartItem",
      addItemToCart: "addItemToCart",
      setNumberCustomer: "setNumberCustomer",
      decrementqtyByCartItem: "decrementqtyByCartItem",
      incrementqtyByCartItem: "incrementqtyByCartItem",
      setOptionByCartItem: "setOptionByCartItem",
      getDataSalesInvoice: "getDataSalesInvoice",
      removeItemFromCart: "removeItemFromCart",
    }),
    handleSearch(event) {
      this.page = 1;
      this.query = event.target.value;
      this.setItemEmpty(this.selectMenu);
      if (this.query === "") this.getDataMenuItems();
      else this.getDataMenuItemsSearch();
    },
    handleSearchClear() {
      this.query = "";
      this.page = 1;
      this.setItemEmpty(this.selectMenu);
      this.getDataMenuItems();
    },
    getDataMenu() {
      dataService.getPublic("get_menus_customer", `pos_profile=${this.getQuery.pos_profile}&secret=${this.getQuery.key}`).then((res) => {
        this.menu = [...this.menu, ...res.data.message];
        this.selectMenu = this.menu[0].name;
      });
    },
    getDataMenuItems() {
      this.setItems({
        key: this.selectMenu,
        limit: this.limit,
        page: this.page,
        pos_profile: this.getQuery.pos_profile,
        secret: this.getQuery.key
      });
    },
    getDataMenuItemsSearch() {
      this.setItemsSearch({
        key: this.selectMenu,
        query: this.query,
        page: this.page,
        limit: this.limit,
        pos_profile: this.getQuery.pos_profile,
        secret: this.getQuery.key
      });
    },
    handleSelectMenu(item, index) {
      const nav = document.querySelector('nav');
      const link = document.querySelectorAll('nav a')[index];

      const scrollPosition = link.offsetLeft - (nav.offsetWidth - link.offsetWidth) / 2;

      nav.scroll({
        left: scrollPosition,
        behavior: 'smooth',
      });
      if (!this.loadingGetItem) {
        this.selectMenu = item.name;
        this.page = this.getItems?.page ? this.getItems?.page : 1;
        this.query = this.getItems?.query ? this.getItems?.query : "";
        this.getDataMenuItems();
      }
    },
    handleCloseMenuChild() {
      if (this.scrollTop > 0) this.page = 0;
      else this.page = 1;
      this.menuChild = { child: [] };
      this.selectMenu = "all";
    },
    handleShowKeypad(key) {
      this.keyKeypad = key;
      this.showKeypad = true;
    },
    handleKeypadDialogOk(value, key) {
      if (value != 0)
        if (key == "qty") {
          this.qty = value;
        }
      this.showKeypad = false;
    },
    handleKeypadOk(value) {
      if (value != 0) {
        this.setNumberCustomer(Number(value));
        if (this.itemBeforeGuests != null) {
          this.addItemToCart(this.itemBeforeGuests);
          this.itemBeforeGuests = null;
        }
        this.isModalOpenGuests = false;
      } else
        dispatchNotification({
          content: "Guests must be at least 1!",
          type: "warning",
        });
    },
  },
};
</script>