<template>
  <div class="w-full">
    <div
      class="mt-1 w-full flex justify-center rounded-lg border border-dashed border-gray-900/25 dark:border-gray-500/25"
      :class="{ highlight: isDragging }"
      @dragover.prevent="handleDragOver"
      @dragleave="handleDragLeave"
      @drop.prevent="handleDrop"
    >
      <div class="text-center relative py-2">
        <div v-if="newImageSrc != '' || (src != '' && src)" class="w-full h-44">
          <img
            :src="
              newImageSrc != ''
                ? newImageSrc
                : src != '' && src
                ? baseURL + src
                : ''
            "
            alt=""
            class="h-full"
          />
        </div>
        <div v-else class="px-6 py-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="mx-auto h-12 w-12 text-gray-300"
          >
            <path
              fill-rule="evenodd"
              d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
              clip-rule="evenodd"
            />
          </svg>

          <div
            class="mt-4 flex text-sm leading-6 text-gray-600 dark:text-gray-400"
          >
            <label
              for="file-upload"
              class="relative cursor-pointer rounded-md font-semibold text-secondary dark:text-primary"
            >
              <span>Upload a file</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                @change="handleFileChange"
                accept="image/*"
                class="sr-only"
              />
            </label>
            <p class="pl-1">or drag and drop</p>
          </div>
          <p class="text-xs leading-5 text-gray-600 dark:text-gray-400">
            PNG, JPG, GIF up to 10MB
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="afterCropImage || (src != '' && src)"
      @click="handleRemoveImage"
      class="flex justify-between items-center mt-2 text-gray-600 dark:text-gray-300 hover:text-red-500 dark:hover:text-red-500 cursor-pointer"
    >
      <p class="ellipsis">
        {{ afterCropImage?.name ? afterCropImage?.name : getImageName }}
      </p>
      <span
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
          />
        </svg>
      </span>
    </div>
  </div>
  <ModalComponent
    title="Edit Image"
    :isModalOpen="isEditImage"
    :onToggle="handleCancelEditImage"
    :width="578"
    :freeSpaceClose="false"
  >
    <div class="h-96">
      <vue-cropper
        class="h-full"
        ref="cropper"
        :aspect-ratio="9 / 9"
        :src="imageSrc"
      />
    </div>
    <div class="flex justify-end pt-4 space-x-4">
      <button
        @click="handleCancelEditImage"
        class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
      >
        Cancel
      </button>
      <button
        @click="handleSaveEditImage"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-primary text-white font-medium rounded-md justify-center"
      >
        Save
      </button>
    </div>
  </ModalComponent>
  <SpinComponent :show="loading" />
</template>
<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import imageEmpty from "@/assets/image/empty.jpg";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { baseURL } from "@/utils/baseURL";
export default {
  name: "Component Upload Image",
  components: {
    ModalComponent,
    SpinComponent,
    VueCropper,
  },
  props: {
    src: {
      rerequired: false,
      default: "",
    },
    dataImage: {
      type: Function,
      rerequired: false,
    },
  },
  data() {
    return {
      imageEmpty,
      isEditImage: false,
      imageForm: null,
      imageSrc: "",
      newImageSrc: "",
      formImage: null,
      afterCropImage: null,
      loading: false,
      baseURL,
      isDragging: false,
    };
  },
  computed: {
    getImageName() {
      if (this.src != "" && this.src) {
        const newData = this.src.split("/");
        return newData[newData.length - 1];
      } else return "";
    },
  },
  methods: {
    handleSaveEditImage() {
      this.loading = true;
      this.newImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const file = new File([blob], this.formImage.name, {
          type: "image/jpeg",
        });
        const formData = new FormData();
        formData.append("file", file);
        this.dataImage(formData);
        this.isEditImage = false;
        this.imageSrc = "";
        this.afterCropImage = this.formImage;
        this.loading = false;
      });
    },
    handleDragOver() {
      this.isDragging = true;
    },
    handleDragLeave() {
      this.isDragging = false;
    },
    handleDrop(event) {
      this.isDragging = false;
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      this.isEditImage = true;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imageSrc = e.target.result;
        this.$refs.cropper.replace(e.target.result);
      };
      reader.readAsDataURL(file);
      this.formImage = file;
    },
    handleCancelEditImage() {
      this.isEditImage = false;
    },
    handleRemoveImage() {
      this.newImageSrc = "";
      this.afterCropImage = null;
      this.dataImage(null);
    },
  },
};
</script>
