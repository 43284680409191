<template>
  <ModalComponent
    title="Select Hold Invoice"
    :isModalOpen="isShowHeld"
    :onToggle="() => (this.isShowHeld = false)"
    :width="768"
    :freeSpaceClose="true"
  >
    <div class="inline-block relative overflow-x-scroll w-full max-h-[500px]">
      <TableComponent
        :columns="columns"
        :items="renderItems(data)"
        :onSelectSingleRow="(data) => (selected = data)"
        scrollX="35rem"
        ><template #bodyCell="{ column, record, index }">
          <template v-if="column.key === 'grand_total'"
            ><span>{{ pos_profile.currency === "USD" ? "$" : "៛" }}</span
            >{{
              $n(
                record.grand_total,
                "decimal",
                `${pos_profile.currency === "USD" ? "en" : "kh"}`
              )
            }}</template
          >
        </template>
      </TableComponent>
    </div>
    <div class="flex justify-end pt-2 lg:pt-4 gap-4">
      <button
        @click="isShowHeld = false"
        class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
      >
        Cancel
      </button>
      <button
        type="button"
        @click="handleSelectItem"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        Select
      </button>
    </div>
  </ModalComponent>
</template>

<script>
const columns = [
  {
    key: "customer",
    label: "Customer",
    dataIndex: "customer",
    sort: true,
  },
  {
    key: "posting_date",
    label: "Date",
    dataIndex: "posting_date",
    sort: true,
  },
  // {
  //   key: "name",
  //   label: "Invoice",
  //   dataIndex: "name",
  //   sort: true,
  // },
  {
    key: "grand_total",
    label: "Amount",
    sort: true,
  },
];
import ModalComponent from "@/components/modal/ModalComponent.vue";
import TableComponent from "@/components/table/TableComponent.vue";
import { renderItems, formattedDateNow } from "@/utils/globleFunction";
import { dispatchNotification } from "@/components/notification";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ModalComponent,
    TableComponent,
  },
  data: () => ({
    columns,
    renderItems,
    isShowHeld: false,
    selected: null,
    data: [],
  }),
  computed: {
    ...mapGetters("retail", {
      userProfile: "userProfile",
    }),
    pos_profile() {
      return this.userProfile.pos_profile;
    },
  },
  methods: {
    handleSelectItem() {
      if (this.selected) {
        this.emitter.emit("loadInvoice", this.selected);
        this.isShowHeld = false;
      } else
        dispatchNotification({
          content: "Please select item!",
          type: "warning",
        });
    },
  },
  created() {
    this.emitter.on("openHeld", (data) => {
      this.isShowHeld = true;
      this.data = data;
    });
  },
};
</script>
