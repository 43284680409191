import dataService from "@/services/data.service";
import { v4 as uuidv4 } from "uuid";
// state
const state = {
  loadingGetItem: false,
  items: [],
};

// getters
const getters = {
  getItemsByKey:
    (state) =>
    (key = "all") => {
      return state.items.find((item) => item.key === key);
    },
  loadingGetItem: (state) => {
    return state.loadingGetItem;
  },
};

// actions
const actions = {
  async setItems(
    { state, commit, getters },
    { limit, page, key, pos_profile, secret }
  ) {
    let items = state.items;
    let exit = items.find((item) => item.key === key);

    if (!exit) {
      commit("setLoadingGetItem", true);
      const data = await dataService
        .getPaginatePublic(
          "get_menu_items_customer",
          key,
          "",
          limit,
          1,
          pos_profile,
          secret
        )
        .then((res) => res.data.message)
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
          commit("setLoadingGetItem", false);

          return
        });
      if (data) {
        items.push({
          key: key,
          ...data,
          query: "",
          page: 1,
          items: data.items?.map((i) => {
            return {
              id: uuidv4(),
              ...i,
              qty: 1,
              discount: i.discount_percentage ? i.discount_percentage : 0,
            };
          }),
        });
        commit("setLoadingGetItem", false);
        commit("setItems", items);
      }
    } else if (page > exit.page) {
      commit("setLoadingGetItem", true);
      const data = await dataService
        .getPaginatePublic(
          "get_menu_items_customer",
          key,
          "",
          limit,
          page,
          pos_profile,
          secret
        )
        .then((res) => res.data.message)
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
        });
      if (data) {
        exit.page = page;
        exit.items.push(
          ...data.items.map((i) => {
            return {
              id: uuidv4(),
              ...i,
              qty: 1,
              discount: i.discount_percentage ? i.discount_percentage : 0,
            };
          })
        );
        commit("setLoadingGetItem", false);
        commit("setItems", items);
      }
    }
  },
  async setItemsSearch(
    { state, commit },
    { query, limit, page, key, pos_profile, secret }
  ) {
    let items = state.items;
    commit("setLoadingGetItem", true);
    const data = await dataService
      .getPaginatePublic(
        "get_menu_items_customer",
        key,
        query,
        limit,
        page,
        pos_profile,
        secret
      )
      .then((res) => res.data.message)
      .catch((err) => {
        let error = err.response.data._server_messages;
        if (error)
          dispatchNotification({
            content: JSON.parse(JSON.parse(error)[0]).message,
            type: "warning",
          });
        commit("setLoadingGetItem", false);
      });
    if (data) {
      items.push({
        key: key,
        ...data,
        query: query,
        page: 1,
        items: data.items.map((i) => {
          return {
            id: uuidv4(),
            ...i,
            qty: 1,
            discount: i.discount_percentage ? i.discount_percentage : 0,
          };
        }),
      });
      commit("setLoadingGetItem", false);
      commit("setItems", items);
    }
  },
  setItemEmpty({ state, commit }, key) {
    let items = state.items.filter((item) => item.key !== key);
    commit("setItems", items);
  },
};

// mutations
const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setLoadingGetItem(state, value) {
    state.loadingGetItem = value;
  },

  incrementInventoryByItem(state, { pid }) {
    const item = state.allItems.find((item) => item.pid === pid);
    if (item) {
      item.inventory++;
    }
  },

  decrementInventoryByItem(state, { pid }) {
    const item = state.allItems.find((item) => item.pid === pid);
    if (item) {
      item.inventory--;
    }
  },

  restockInventoryByItem(state, { pid, qty }) {
    const item = state.allItems.find((item) => item.pid === pid);
    if (item) {
      item.inventory += qty;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
