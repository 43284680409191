<template>
  <!-- <div class="bg-white w-96 p-4">
    <div class="text-5xl text-center font-bold uppercase leading-[55px]">
      <h1>Sacan to</h1>
      <h1>view</h1>
      <h1>our menu</h1>
    </div>
    <div class="w-full flex justify-center mt-4">
      <img :src="image" />
    </div>
    <div class="w-full flex justify-center mt-8">
      <img :src="baseURL + getLogo" class="w-40" />
    </div>
  </div> -->
  <!-- <div class="w-screen bg-white h-screen relative">
    <div
      class="bg-[#EDD279] h-1/2 w-full leading-10 py-5 flex justify-center rounded-md"
    >
      <div class="w-52 text-center">
        <h3 class="text-gray-600">Table</h3>
        <h1 class="font-bold text-3xl leading-10">{{ table }}</h1>
      </div>
    </div>
    <div class="h-1/2 w-full relative">
      <div
        class="leading-10 text-center absolute bottom-16 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <h1 class="font-semibold text-xl">Order here</h1>
        <p class="text-gray-600">Scan the QR code with mobile phone</p>
      </div>
    </div>
    <div class="absolute w-full border-t py-2 flex justify-center bottom-0">
      <div class="flex items-center">
        <h1>Powered by:</h1>
        <img src="../../assets/image/Logo.png" class="w-14" />
      </div>
    </div>
    <div
      class="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <div class="flex justify-center w-full">
        <img :src="image" class="w-2/3" />
      </div>
    </div>
  </div> -->
  <div class="w-screen flex justify-center bg-white">
    <div class="w-96 text-center space-y-3">
      <h1 class="text-5xl font-bold font-sans leading-[60px] uppercase">
        {{ getDisplayName }}
      </h1>
      <h1 class="text-4xl font-bold font-sans leading-[60px] text-red-600">
        {{ table }}
      </h1>
      <div class="flex justify-center w-full">
        <div class="border-b-4 border-black w-1/2 p-1" />
      </div>
      <div class="flex justify-center w-full px-4">
        <img :src="image" class="w-full" />
      </div>

      <div class="flex justify-center w-full">
        <div class="border-t-4 border-black w-1/2 p-1" />
      </div>
      <div class="flex justify-center w-full">
        <svg
          class="h-12 w-12"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M4 7v-1a2 2 0 0 1 2 -2h2" />
          <path d="M4 17v1a2 2 0 0 0 2 2h2" />
          <path d="M16 4h2a2 2 0 0 1 2 2v1" />
          <path d="M16 20h2a2 2 0 0 0 2 -2v-1" />
          <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
      </div>
      <p class="font-sans font-bold uppercase">
        Scan the QR Code to order <br />
        with mobile phone
      </p>

      <div class="flex justify-center w-full">
        <img :src="baseURL + getLogo" class="w-40" />
      </div>
      <p class="font-sans pt-4">
        Powered By: DiPOS
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { baseURL } from "@/utils/baseURL";
export default {
  props: {
    image: {
      type: String,
      required: true,
      default: "",
    },
    table: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      baseURL,
    };
  },
  computed: {
    ...mapGetters("auth", {
      getDisplayName: "getDisplayName",
      getLogo: "getLogo",
    }),
  },
};
</script>
