<template>
  <div v-show="show" class="fixed z-50 top-0 left-0 w-full h-full">
    <div class="flex justify-center items-center w-full h-full">
      <div
        class="flex justify-center items-center bg-slate-600 rounded-lg text-white p-2 dark:bg-slate-900"
      >
        <span class="loader"></span>
        Loading...
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      rerequired: true,
    },
  },
};
</script>
<style scoped>
.loader {
  margin-right: 4px;
  width: 24px;
  height: 24px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
