<template>
  <div class="w-96">
    <div class="h-36 flex justify-center mb-2">
      <img :src="baseURL + getLogo" alt="logo" class="w-36" />
    </div>
    <div class="text-center font-bold">
      <h1>{{ getDisplayName }}</h1>
    </div>
    <div>
      <p class="font-bold" v-show="getVATTin">
        លេខអត្តសញ្ញាណកម្ម អតប
        <span class="font-normal">(VAT TIN) {{ getVATTin }}</span>
      </p>
      <p class="font-bold">
        អាស័យដ្ឋាន:
        <span class="font-normal">{{
          getAddress.address_line1 + ", " + getAddress.city
        }}</span>
      </p>
      <p class="font-bold">
        លេខទំនាក់ទំនង:
        <span class="font-normal">{{ getAddress.phone }}</span>
      </p>
    </div>
    <div class="text-center font-bold">
      <h1>វិក្កយបត្រអាករ</h1>
      <h1>TAX INVOICE</h1>
    </div>
    <p>
      លេខរៀងវិក្កយបត្រ:
      <span class="font-bold">{{ invoiceNo }}</span>
    </p>
    <div class="flex justify-between items-center">
      <p>បោះពុម្ព: {{ now }}</p>
    </div>
    <div class="flex justify-between items-center">
      <p>បេឡាករ: {{ user?.username }}</p>
      <p>កាលបរិច្ឆេត: {{ createtion }}</p>
    </div>
    <table v-if="cartItems?.length > 0" class="p-0 m-0 w-full">
      <thead>
        <tr class="border-b border-gray-900 font-bold">
          <th width="1%">
            <p>ល.រ</p>
            <p>No.</p>
          </th>
          <th width="29%">
            <p>ឈ្មោះ</p>
            <p>Name</p>
          </th>
          <th width="10%">
            <p>បរិមាណ</p>
            <p>Qty</p>
          </th>
          <th width="20%">
            <p>តម្លៃ</p>
            <p>Price</p>
          </th>
          <th width="20%">
            <p>ប.តម្លៃ</p>
            <p>Disc</p>
          </th>
          <th width="20%">
            <p>សរុប</p>
            <p>Total</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(item, index) in cartItems" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.item_name }}</td>
          <td>{{ item.qty }}</td>
          <td>
            {{
              item.currency_symbol +
              $n(
                item.price_list_rate,
                "decimal",
                `${item.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </td>
          <td>
            {{
              item.currency_symbol +
              $n(
                item.price_list_rate * item.qty * (item.discount / 100),
                "decimal",
                `${item.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </td>
          <td>
            {{
              item.currency_symbol +
              $n(
                item.price_list_rate * item.qty -
                  item.price_list_rate * item.qty * (item.discount / 100),
                "decimal",
                `${item.currency === "USD" ? "en" : "kh"}`
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="invoiceNo" class="flex justify-between border-t border-gray-900 w-full">
      <div class="w-28">
        <p>សរុប</p>
        <p v-if="getTaxes">អាករលើតម្លៃ</p>
        <p v-if="getCharge != 0">សេវាកម្ម</p>
        <p>បញ្ចុះតម្លៃ</p>
        <p>សរុបចុងក្រោយ</p>
      </div>
      <div class="flex">
        <div class="text-right mr-2">
          <p>SUB TOTAL</p>
          <p v-if="getTaxes">TAX ({{ getTaxes?.rate }}%)</p>
          <!-- <p v-if="getCharge != 0">SERVICE CHARGE ({{ getCharge }}%)</p> -->
           <p>
            DISCOUNT 
          </p>
          <p>GRAND TOTAL</p>
          <p>GRAND TOTAL</p>
        </div>
        <div class="text-right">
          <p>$ {{ $n(getTotal, "decimal", "en") }}</p>
          <p v-if="getTaxes">$ {{ $n(getTaxes.amount, "decimal", "en") }}</p>
          <!-- <p v-if="getCharge != 0">
            $ {{ $n(getTotalAmountTaxCharge(getCharge), "decimal", "en") }}
          </p> -->
          <p>$ {{ formtCurrency(getDiscountAmount) }}</p> 
          <p>$ {{ $n(getGrandTotal, "decimal", "en") }}</p>
          <p>៛ {{ $n(getGrandTotalKHR, "decimal", "kh") }}</p>
        </div>
      </div>
    </div>
    <div class="flex justify-between border border-gray-900 px-2 py-1">
      <div>
        <p>ប្រាក់ទទួល</p>
      </div>
      <div class="flex">
        <div class="text-right mr-2">
          <p>RECEIVED</p>
          <p>RECEIVED</p>
        </div>
        <div class="text-right">
          <p>$ {{ $n(receiveUSD, "decimal", "en") }}</p>
          <p>៛ {{ $n(receiveKHR, "decimal", "kh") }}</p>
        </div>
      </div>
    </div>
    <div class="flex justify-between py-1">
      <div>
        <p>ប្រាក់អាប់</p>
      </div>
      <div class="flex">
        <div class="text-right mr-2">
          <p>CHANGE</p>
          <p>CHANGE</p>
        </div>
        <div class="text-right">
          <p>
            $
            {{
              formtCurrency(
                receiveUSD + receiveKHR / currencyExchange - getGrandTotal
              )
            }}
          </p>
          <p>៛ {{ formtCurrency(changeKHR) }}</p>
        </div>
      </div>
    </div>
    <div v-show="getQRCode" class="flex justify-center h-48 p-1">
      <img :src="baseURL + getQRCode" />
    </div>
    <div class="text-center">
      <p>
        អត្រាប្តូរប្រាក់(Exchange Rate) : 1$ = ៛
        {{ $n(currencyExchange, "decimal", "kh") }}
      </p>
      <p v-show="getFooter1">{{ getFooter1 }}</p>
      <p v-show="getFooter2">{{ getFooter2 }}</p>
      <p v-show="getFooter3">{{ getFooter3 }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tr from "@/i18n/translation";
import store from "@/store";
import { formatDateTime, formtCurrency } from "@/utils/globleFunction";
import { baseURL } from "@/utils/baseURL";
export default {
  data() {
    return {
      formtCurrency,
      baseURL,
    };
  },
  computed: {
    ...mapGetters("retail", {
      cartItems: "getCartItems",
      postingDate: "getPostingDate",
      invoiceNo: "getInvoiceNo",
      dateTimeNow: "dateTimeNow",
      getTaxes: "getTaxes",
      getTotal: "getTotal",
      getGrandTotal: "getGrandTotal",
      getProfile: "profile",
      receiveUSD: "getReceiveUSD",
      receiveKHR: "getReceiveKHR",
      getDiscountAmount: "getDiscountAmount",
    }),
    ...mapGetters("auth", {
      getTax: "getTax",
      getLogo: "getLogo",
      getCharge: "getCharge",
      currencyExchange: "getCurrencyExchange",
      getDisplayName: "getDisplayName",
      getAddress: "getAddress",
      getVATTin: "getVATTin",
      getFooter1: "getFooter1",
      getFooter2: "getFooter2",
      getFooter3: "getFooter3",
      getQRCode: "getQRCode",
    }),
    user() {
      return store.state.auth.user;
    },
    now() {
      return formatDateTime(this.dateTimeNow);
    },
    createtion() {
      return formatDateTime(this.postingDate);
    },
    getGrandTotalKHR() {
      return (
        Math.round((this.getGrandTotal * this.currencyExchange) / 100) * 100
      );
    },
    changeKHR() {
      return (
        Math.round(
          (this.receiveUSD * this.currencyExchange +
            this.receiveKHR -
            this.getGrandTotalKHR) /
            100
        ) * 100
      );
    },
  },
};
</script>
