<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="relative z-20" @click="onToggle">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full justify-center text-center items-end">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-0"
            enter-to="opacity-100 translate-y-100 scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 scale-100" leave-to="opacity-0 translate-y-0">
            <DialogPanel class="flex w-full transform text-left text-base transition">
              <div class="w-full mx-auto my-auto rounded-xl shadow-lg  bg-white dark:bg-slate-800 relative">

                <div class=" bg-white dark:bg-slate-700">
                  <div class="w-full">
                    <div class="p-2 flex">
                      <input v-if="keyKeypad == 'qty'" :value="valueKeypad" min="0" type="number" @input="onchange"
                        class="w-full px-4 py-2 text-[16px] lg:text-[20px] border border-gray-200 rounded-lg hover:border-gray-300  dark:text-gray-200 dark:bg-slate-700 dark:border-slate-500 focus:outline-none focus:border-primary focus:ring-transparent"
                        placeholder="0" />
                      <VueNumber v-else v-model="valueKeypad"
                        class="w-full px-4 py-2 text-[16px] lg:text-[20px] border border-gray-200 rounded-lg hover:border-gray-300 dark:text-gray-200 dark:bg-slate-700 dark:border-slate-500 focus:outline-none focus:border-primary focus:ring-transparent transition-colors" />
                      <button
                        class="inline-flex items-center mx-2 py-[10px] px-4 bg-secondary text-white text-sm font-medium rounded-md justify-center"
                        @click="handleKeypadDialogOk(valueKeypad, keyKeypad)">
                        Ok
                      </button>
                      <button
                        class="inline-flex items-center py-[10px] px-4 bg-gray-500 text-white text-sm font-medium rounded-md justify-center"
                        @click="onToggle">
                        Close
                      </button>
                    </div>
                  </div>
                  <div class="grid grid-cols-3">
                    <div v-for="n in 12" :key="n">
                      <div
                        class="w-full h-full text-center text-gray-500 bg-gray-100 border border-gray-300 p-4 text-lg  dark:text-gray-200 dark:bg-slate-900/80 dark:border-slate-500"
                        v-if="n == 10 && onReset" @click="onReset">
                        C
                      </div>
                      <div
                        class="w-full h-full text-center text-gray-500 bg-gray-100 border border-gray-300 p-4 text-lg  dark:text-gray-200 dark:bg-slate-900/80 dark:border-slate-500"
                        :ripple="true" v-if="n != 10 && n != 12" @click="onInput(n === 11 ? 0 : n)">
                        <div v-if="n < 10">{{ n }}</div>
                        <div v-if="n == 11">0</div>
                      </div>
                      <div
                        class="w-full h-full text-centerborder border-gray-300 p-4 text-lg text-gray-200 bg-gray-400  dark:bg-gray-600 flex justify-center items-center"
                        v-if="n == 12 && onDelete" @click="onDelete(n)">
                        <div v-if="n == 12">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    show: { type: Boolean, default: false, required: true },
    keyKeypad: { type: String, required: false },
    handleKeypadDialogOk: { type: Function, required: false },
    onToggle: { type: Function, required: true },
  },
  data: () => ({
    n: 0,
    valueKeypad: "",
  }),
  methods: {
    onchange(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.valueKeypad = value;
      }
      this.$forceUpdate();
    },
    onInput(key) {
      if (this.keyKeypad == "qty") {
        if (String(this.valueKeypad).length < 3)
          this.valueKeypad = (this.valueKeypad + key).slice(0, this.maxLength);
      } else
        this.valueKeypad = (this.valueKeypad + key).slice(0, this.maxLength);
    },
    onDelete() {
      this.valueKeypad = this.valueKeypad.slice(0, this.valueKeypad.length - 1);
    },
    onReset() {
      this.valueKeypad = "";
    },
  },
};
</script>