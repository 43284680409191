<template>
  <div
    v-show="show"
    class="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center opacity-30 text-gray-500 dark:text-gray-200"
  >
    <span class="text-center">
      <svg viewBox="0 0 78 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="13"
          y="12"
          width="64"
          height="72"
          rx="7"
          stroke="#A6B1B9"
          stroke-width="2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 2H58C61.3137 2 64 4.68629 64 8V9H66V8C66 3.58172 62.4183 0 58 0H8C3.58172 0 0 3.58172 0 8V66C0 70.4183 3.58172 74 8 74H10V72H8C4.68629 72 2 69.3137 2 66V8C2 4.68629 4.68629 2 8 2Z"
          fill="#A6B1B9"
        />
        <path
          d="M42 31H66"
          stroke="#A6B1B9"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M42 51H66"
          stroke="#A6B1B9"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M42 25H55"
          stroke="#A6B1B9"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M42 45H55"
          stroke="#A6B1B9"
          stroke-width="2"
          stroke-linecap="round"
        />
        <rect
          x="24"
          y="23"
          width="10"
          height="10"
          rx="2"
          stroke="#E9874D"
          stroke-width="2"
        />
        <rect
          x="24"
          y="43"
          width="10"
          height="10"
          rx="2"
          stroke="#E9874D"
          stroke-width="2"
        />
      </svg>
      No data
    </span>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      rerequired: true,
    },
  },
};
</script>
