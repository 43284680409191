import store from "@/store";
import Trans from "../i18n/translation";

const routeMiddleware = async (to, _from, next) => {
  const paramLocale = to.params.locale;

  if (!Trans.isLocaleSupported(paramLocale)) {
    return next(Trans.guessDefaultLocale());
  }
  await Trans.switchLanguage(paramLocale);
  await store.dispatch("auth/verifyToken");
  const loggedIn = await store.state.auth.status.loggedIn;
  if (loggedIn && to.matched.some((record) => record.meta.requiresAuth))
    await store.dispatch("auth/getUserProfile");
  
  if (!loggedIn && to.matched.some((record) => record.meta.requiresAuth)) {
    next(Trans.i18nRoute({ name: "login" }));
  } else {
    next();
  }
};

export default routeMiddleware;
