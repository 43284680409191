<template>
  <div class="space-y-3 min-h-[400px]">
    <div
      v-if="onSearch"
      class="grid grid-cols-2 md:grid-cols-3 gap-4 items-center"
    >
      <div class="relative">
        <input
          type="text"
          v-model="dataSearch"
          class="w-full px-9 py-[7px] border dark:text-gray-300 bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparente"
          placeholder="Search..."
        />
        <div class="absolute py-[9px] px-3 top-0 left-0 text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </div>
        <div
          v-if="dataSearch != ''"
          @click="dataSearch = ''"
          class="absolute right-3 top-2.5 text-gray-500 dark:text-gray-400 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
      <slot name="filter"></slot>
    </div>
    <table class="w-full" :style="`min-width: ${scrollX}`">
      <thead>
        <tr>
          <th
            v-if="expandedRow"
            class="text-center bg-gray-100 dark:bg-slate-600 w-9"
          />
          <th v-if="onSelectRow" class="bg-gray-100 dark:bg-slate-600 w-9">
            <input
              type="checkbox"
              v-model="isAllSelected"
              @click="toggleSelectAll"
              class="cursor-pointer accent-secondary"
            />
          </th>
          <template v-for="column in columns" :key="column.key">
            <th
              v-if="column.sort"
              @click="setSort(column.key)"
              class="py-3 px-4 cursor-pointer font-bold text-xs lg:text-base bg-gray-100 dark:bg-slate-600 dark:text-gray-200 text-gray-600"
            >
              <span
                class="flex items-center group center"
                :class="
                  column.alignHeader
                    ? `justify-${column.alignHeader}`
                    : 'justify-start'
                "
              >
                <span class="whitespace-nowrap">{{ column.label }}</span>
                <span
                  :class="
                    sortBy === column.key
                      ? 'text-gray-600 dark:text-gray-100'
                      : 'text-transparent group-hover:text-gray-400'
                  "
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    class="w-4 h-4 duration-100"
                    :class="sortDesc ? 'rotate-0' : 'rotate-180'"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 14a.75.75 0 0 0 .75-.75V4.56l1.22 1.22a.75.75 0 1 0 1.06-1.06l-2.5-2.5a.75.75 0 0 0-1.06 0l-2.5 2.5a.75.75 0 0 0 1.06 1.06l1.22-1.22v8.69c0 .414.336.75.75.75Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </span>
            </th>
            <th
              v-else
              class="py-3 px-4 font-bold text-xs lg:text-base bg-gray-100 dark:bg-slate-600 dark:text-gray-200 text-gray-600"
              :style="[
                column.alignHeader
                  ? { textAlign: `${column.alignHeader}` }
                  : { textAlign: 'left' },
              ]"
            >
              {{ column.label }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-if="items.length == 0" class="h-80 relative">
          <EmptyComponent v-show="true" />
        </tr>
        <template v-else v-for="(record, index) in sort ? dataItems : items">
          <tr
            class="text-gray-700 dark:text-gray-200"
            :class="[
              !record.active &&
                'border-b border-gray-200  dark:border-gray-600',
              dataSelected?.no == record.no &&
                onSelectSingleRow &&
                'bg-gray-300 dark:bg-gray-700',
            ]"
          >
            <td v-if="expandedRow" class="text-center w-9">
              <button
                type="button"
                @click="toggleExpandable(record)"
                class="relative p-2 text-gray-900 dark:text-gray-100 border border-gray-300 dark:border-gray-200/20 rounded-md"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-3 h-3 absolute top-0.5 left-0.5 transform transition duration-200"
                  :class="!record.active ? 'rotate-0' : 'rotate-90'"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    :d="
                      !record.active
                        ? 'M12 4.5v15m7.5-7.5h-15'
                        : 'M12 4.5v15m7.5-7'
                    "
                  />
                </svg>
              </button>
            </td>
            <td class="text-center w-9" v-if="onSelectRow">
              <input
                type="checkbox"
                class="cursor-pointer accent-secondary"
                v-model="selectedItems"
                :value="record"
                @change="onSelectRow(newDataSelected)"
              />
            </td>
            <td
              v-for="column in columns"
              :key="column.dataIndex"
              class="py-3 px-4"
              :class="(onClickRow || onSelectSingleRow) && 'cursor-pointer'"
              :style="[
                column.width && { width: `${column.width}px` },
                column.align && { textAlign: `${column.align}` },
              ]"
              @click="
                onClickRow
                  ? onClickRow(record)
                  : onSelectSingleRow && onSelect(record)
              "
            >
              <slot
                name="bodyCell"
                :column="column"
                :record="record"
                :index="index"
              ></slot>
              <span v-if="column.dataIndex" class="text-xs lg:text-base">{{
                record[column.dataIndex] == null ||
                record[column.dataIndex] == ""
                  ? "-"
                  : record[column.dataIndex]
              }}</span>
            </td>
          </tr>
          <tr
            :class="
              record.active && 'border-b border-gray-200  dark:border-gray-800'
            "
          >
            <td :colspan="columns.length + 1">
              <AccordionTableComponet :active="record.active" class="w-full">
                <slot
                  name="expandedRowRender"
                  :record="record"
                  :index="index"
                ></slot>
              </AccordionTableComponet>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot v-if="columnFooter.length > 0">
        <tr>
          <template v-for="column in columnFooter">
            <th
              class="py-3 px-4 font-bold text-xs lg:text-base bg-gray-50 dark:bg-slate-700 dark:text-gray-200 text-gray-600"
              :style="[
                column.alignHeader
                  ? { textAlign: `${column.alignHeader}` }
                  : { textAlign: 'left' },
              ]"
            >
              <p v-if="column.label != ''">
                ${{ $n(column.label, "decimal", "en") }}
              </p>
            </th>
          </template>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { sort } from "fast-sort";
import AccordionTableComponet from "../accordion/AccordionTableComponet.vue";
import _ from "lodash";
import EmptyComponent from "@/components/empty/EmptyComponent.vue";
import { baseURL } from "@/utils/baseURL";
export default {
  components: { AccordionTableComponet, EmptyComponent },
  props: {
    columns: {
      type: Array,
      rerequired: true,
    },
    items: {
      type: Array,
      rerequired: true,
    },
    scrollX: {
      type: String,
      rerequired: false,
      default: "100%",
    },
    onClickRow: {
      type: Function,
      rerequired: false,
    },
    onSelectRow: {
      type: Function,
      rerequired: false,
    },
    onSelectSingleRow: {
      type: Function,
      rerequired: false,
    },
    expanded: {
      type: Function,
      rerequired: false,
    },
    columnFooter: {
      type: Array,
      rerequired: false,
      default: [],
    },
    sort: {
      type: Boolean,
      default: true,
    },
    onSearch: {
      type: Function,
      rerequired: false,
    },
  },
  data() {
    return {
      sortBy: null,
      sortDesc: null,
      baseURL,
      selectedItems: [],
      isAllSelected: false,
      dataItems: [],
      expandedRow: false,
      search: "",
      dataSelected: null,
    };
  },
  watch: {
    selectedItems: function (newVal) {
      if (this.onSelectRow) {
        this.isAllSelected = newVal.length === this.items.length;
      }
    },
    items: function (newItems) {
      if (this.onSelectRow) {
        if (newItems.length == 0) {
          this.isAllSelected = false;
        }
      }
    },
    sortedItems(newItems) {
      this.dataItems = newItems.map((item) => {
        return { ...item, active: false };
      });
    },
    search(newValue) {
      this.onSearch(newValue);
    },
  },
  computed: {
    sortedItems() {
      if (this.sortDesc === null) return this.items;
      if (this.sortDesc) {
        return sort(this.items).desc(this.sortBy);
      } else {
        return sort(this.items).asc(this.sortBy);
      }
    },
    newDataSelected() {
      return this.items.filter((item1) =>
        this.selectedItems.some((item2) => item1.name === item2.name)
      );
    },
    dataSearch: {
      get() {
        return this.search;
      },
      set: _.debounce(function (newValue) {
        this.search = newValue;
      }, 500),
    },
  },
  mounted() {
    this.expandedRow = Boolean(this.$slots.expandedRowRender);
  },
  methods: {
    onSelect(item) {
      this.dataSelected = item;
      this.onSelectSingleRow(item);
    },
    toggleExpandable(record) {
      this.expanded(record);
      record.active = !record.active;
    },
    setSort(key) {
      if (this.sortBy === key) {
        if (this.sortDesc === true) {
          this.sortDesc = null;
          this.sortBy = "";
        } else if (this.sortDesc === false) {
          this.sortDesc = true;
        } else {
          this.sortDesc = false;
        }
      } else {
        this.sortBy = key;
        this.sortDesc = false;
      }
    },
    toggleSelectAll() {
      this.selectedItems = [];
      if (this.isAllSelected) this.selectedItems = [];
      else this.selectedItems = this.dataItems;
      this.onSelectRow(this.newDataSelected);
    },
  },
};
</script>
