<template>
  <div class="w-full px-5">
    <div class="flex justify-between items-center">
      <div class="mt-4 mb-6 leading-9">
        <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
          Close Shift
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'table' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'close-shift' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >Close Shift</router-link
                >
              </div>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span
                  class="text-gray-400 ml-1 md:ml-2 text-sm font-medium dark:text-gray-500"
                  >{{ $route.params.id }}</span
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        @click="!loading && print()"
        class="inline-flex items-center text-sm p-2 lg:p-[10px] bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5 mr-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
          />
        </svg>

        Print
      </button>
    </div>
    <div
      class="w-full flex justify-center overflow-y-auto h-[calc(100vh_-_130px)] lg:h-[calc(100vh_-_156px)] dark:text-gray-100"
    >
      <CloseShiftDetailContentView v-if="!loading" :data="data" />
    </div>
    <PrintComponent>
      <CloseShiftDetailContentView v-if="!loading" :data="data" />
    </PrintComponent>
  </div>
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import { print, formatDate } from "@/utils/globleFunction";
import Tr from "@/i18n/translation";
import PrintComponent from "@/components/print/PrintComponent.vue";
import CloseShiftDetailContentView from "./CloseShiftDetailContentView.vue";

export default {
  components: {
    SpinComponent,
    PrintComponent,
    CloseShiftDetailContentView,
  },
  data() {
    return {
      Tr,
      print,
      data: null,
      loading: false,
    };
  },
  created() {
    this.getClosingDetail();
  },
  methods: {
    getClosingDetail() {
      this.loading = true;
      dataService
        .get("get_closing_detail", `key=${this.$route.params.id}`)
        .then((res) => {
          this.data = res.data.message;
          this.loading = false;
        });
    },
  },
};
</script>
