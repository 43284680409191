<template>
  <div class="border-b border-gray-200 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px" role="tablist">
      <li class="mr-2" role="presentation">
        <router-link
          :to="Tr.i18nRoute({ name: 'live-comments' })"
          class="inline-flex items-center text-gray-600 dark:text-gray-400 hover:text-secondary hover:border-secondary dark:hover:text-primary dark:hover:border-primary rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b"
          :class="
            currentRoute == 'live-comments'
              ? 'text-secondary border-secondary dark:text-primary dark:border-primary'
              : 'border-transparent'
          "
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
              />
            </svg>
          </span>
          Comments
        </router-link>
      </li>
      <li class="mr-2" role="presentation">
        <router-link
          :to="Tr.i18nRoute({ name: 'live-customer' })"
          class="inline-flex items-center text-gray-600 dark:text-gray-400 hover:text-secondary hover:border-secondary dark:hover:text-primary dark:hover:border-primary rounded-t-lg py-4 px-4 text-sm font-medium text-center border-b"
          :class="
            currentRoute == 'live-customer'
              ? 'text-secondary border-secondary dark:text-primary dark:border-primary'
              : 'border-transparent'
          "
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
              />
            </svg>
          </span>
          Customer
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";

export default {
  data() {
    return {
      Tr,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>
