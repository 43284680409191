import axios from "axios";
import { baseURL } from "@/utils/baseURL";
export const apiUrl = `${baseURL}/api/method/`;

export const basePath = "dpos.api.posapp.";

export const baseAPI = axios.create({
  withCredentials: true,
  mode: "cors",
  baseURL: `${apiUrl}`,
  headers: {
    "Content-Type": "application/json",
  },
});
