export default class User {
  constructor(
    first_name,
    last_name,
    email,
    username,
    password,
    confirm_password,
    role_profile_name,
    user_image,
    enabled
  ) {
    this.username = username;
    this.password = password;
    this.confirm_password = confirm_password;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.role_profile_name = role_profile_name;
    this.user_image = user_image;
    this.enabled = enabled;
  }
}
