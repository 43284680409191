import { dispatchNotification } from "@/components/notification";
import { baseAPI, basePath } from "./baseAPI";

class AuthService {
  async login(user) {
    try {
      const response = await baseAPI.post(basePath + "login", {
        username: user.username,
        password: user.password,
      });
      const message = response.data.message;
      return message;
    } catch (err) {
      dispatchNotification({
        content: err.response.data.message
          ? err.response.data.message
          : JSON.parse(JSON.parse(err.response.data._server_messages)[0])
              .message,
        type: "warning",
      });
    }
  }
  async logout() {
    const response = await baseAPI.post("logout");
    const message = response.data.message;
    return message;
  }
  async verifyToken() {
    const response = await baseAPI.get(basePath + "verify_token");
    const message = response.data.message;
    return message;
  }
  async getUserProfile() {
    const response = await baseAPI.get(basePath + "get_pos_profile");
    const message = response.data.message;
    return message;
  }
  async getModeOfPayment() {
    const response = await baseAPI.get(basePath + "get_opening_data");
    const message = response.data.message;
    return message;
  }
  async createOpening(data, uid) {
    try {
      const response = await baseAPI.post(basePath + "make_opening", data);
      const message = response.data.message;
      return message;
    } catch (err) {
      let error = err.response.data._server_messages;
      if (error)
        dispatchNotification({
          content: JSON.parse(JSON.parse(error)[0]).message,
          type: "warning",
        });
    }
  }
  async createClosing(data) {
    try {
      const response = await baseAPI.post(
        basePath + "submit_closing_shift",
        data
      );
      const message = response.data.message;
      return message;
    } catch (err) {
      let error = err.response.data._server_messages;
      if (error)
        dispatchNotification({
          content: JSON.parse(JSON.parse(error)[0]).message,
          type: "warning",
        });
    }
  }
}

export default new AuthService();
