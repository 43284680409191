import dataService from "@/services/data.service";
import Tr from "@/i18n/translation";
import router from "@/router";
import { dispatchNotification } from "@/components/notification";
import { print, formatDate } from "@/utils/globleFunction";
import { v4 as uuidv4 } from "uuid";
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";
import store from "@/store";

// state
const state = {
  restaurant: {
    name: "",
    table: null,
    items: [],
    items_remove: [],
    number_customer: 0,
    customer: null,
    discount: 0,
    discount_amount: 0,
    creation: null,
    receiveUSD: 0,
    receiveKHR: 0,
    invoice_no: null,
    waiting_number: 0,
    remarks: "",
    status_invoice: null,
  },
  loading: false,
};

// getters
const getters = {
  getInvoiceName: (state) => state.restaurant.name,
  getStatusInvoice: (state) => state.restaurant.status_invoice,
  getRemarks: (state) => state.restaurant.remarks,
  getLoading: (state) => state.loading,
  getCartItems: (state, getters, rootState) => {
    return state.restaurant.items.map((item) => {
      return {
        ...item,
        amount:
          item.price_exchange - item.price_exchange * (item.discount / 100),
      };
    });
  },
  getCartItemsDiscount: (state, getters, rootState) => {
    return getters.getCartItems.map((item) => {
      if (getters.getStatusItemDiscount)
        if (item.discount >= state.restaurant.discount)
          return {
            ...item,
            discount: item.discount,
          };
        else
          return {
            ...item,
            discount: state.restaurant.discount,
            amount:
              item.price_exchange -
              item.price_exchange * (state.restaurant.discount / 100),
          };
      else
        return {
          ...item,
          discount: 0,
        };
    });
  },
  getCartTotalQty: (state, getters) => {
    return getters.getCartItems.reduce((total, item) => {
      return total + item.qty;
    }, 0);
  },
  getCartSubTotal: (state, getters) => {
    if (!getters.getStatusItemDiscount)
      return getters.getCartItems.reduce((total, item) => {
        return total + item.amount * item.qty;
      }, 0);
    else
      return getters.getCartItemsDiscount.reduce((total, item) => {
        return total + item.amount * item.qty;
      }, 0);
  },
  getCartSubTotalOrg: (state, getters) => {
    return getters.getCartItems.reduce((total, item) => {
      return total + item.price_exchange * item.qty;
    }, 0);
  },
  getTotalAmountTaxCharge:
    (state, getters) =>
    (value = 0) => {
      let x = getters.getCartSubTotalOrg * (value / 100);
      return x >= 0 ? x : 0;
    },
  getCartTotal:
    (state, getters) =>
    (tax = 0, charge = 0) => {
      return (
        Number(
          (
            getters.getSubtotalItemNotDiscount + getters.getSubtotalItemDiscount
          ).toFixed(2)
        ) -
        Number(getters.getTotalDiscountAmount.toFixed(2)) +
        Number(tax.toFixed(2)) +
        Number(charge.toFixed(2))
      );
    },
  getItemNotDiscount: (state, getters) => {
    return getters.getCartItems.filter(
      (x) => x.discount <= Number(state.restaurant.discount)
    );
  },
  getSubtotalItemNotDiscount: (state, getters) => {
    return getters.getItemNotDiscount.reduce(
      (total, item) => total + item.price_exchange * item.qty,
      0
    );
  },
  getItemDiscount: (state, getters) => {
    return getters.getCartItems.filter(
      (x) => x.discount > Number(state.restaurant.discount)
    );
  },
  getStatusItemDiscount: (state, getters) => {
    return getters.getCartItems.find((x) => x.discount > 0);
  },
  getSubtotalItemDiscount: (state, getters) => {
    return getters.getItemDiscount.reduce(
      (total, item) => total + item.amount * item.qty,
      0
    );
  },
  getNumberCustomer: (state, getters) => {
    return state.restaurant.number_customer;
  },
  getCustomer: (state, getters) => {
    return state.restaurant.customer;
  },
  getDiscount: (state, getters) => {
    return Number(state.restaurant.discount);
  },
  getTotalAfterDiscountPer: (state, getters) => {
    return getters.getSubtotalItemNotDiscount - getters.getDiscountPerAmount;
  },
  getDiscountAmount: (state, getters) => {
    return Number(state.restaurant.discount_amount);
  },
  getDiscountPerAmount: (state, getters) => {
    return (
      (Number(state.restaurant.discount) / 100) *
      getters.getSubtotalItemNotDiscount
    );
  },
  getTotalDiscountAmount: (state, getters) => {
    return getters.getDiscountPerAmount + getters.getDiscountAmount;
  },
  getDataTable: (state, getters) => {
    return state.restaurant.table;
  },
  getDateCreation: (state, getters) => {
    return state.restaurant.creation;
  },
  getReceiveUSD: (state, getters) => {
    return state.restaurant.receiveUSD;
  },
  getReceiveKHR: (state, getters) => {
    return state.restaurant.receiveKHR;
  },
  getInvoiceNo: (state) => {
    return state.restaurant.invoice_no;
  },
  getWaitingNumber: (state) => state.restaurant.waiting_number,
};

// actions
const actions = {
  setNewCart({ commit }, data) {
    commit("setNewCart", data);
  },
  onChageDiscountItem({ commit }, data) {
    commit("onChageDiscountItem", data);
  },
  addItemRemove({ commit }, item) {
    commit("addItemRemove", item);
  },
  clearCartItem({ commit }) {
    commit("clearCartItem");
  },
  setRemarks({ commit }, value) {
    commit("setRemarks", value);
  },
  getDataSalesInvoice({ commit }, data) {
    dataService
      .get("get_sales_invoice", data)
      .then((res) => {
        const item = res.data.message.data;
        let discount = item.taxes.find(
          (item) => item.dpos_type === "Discount Percentage"
        )?.dpos_rate;
        let discount_amount = item.taxes.find(
          (item) => item.dpos_type === "Discount Amount"
        )?.amount;

        item.creation = formatDate(item.creation);
        item.receiveUSD = 0;
        item.receiveKHR = 0;
        item.items_remove = [];
        item.discount = discount ? discount : 0;
        item.discount_amount = discount_amount ? discount_amount : 0;
        item.items = item.items.map((i) => {
          return {
            ...i,
            id: uuidv4(),
          };
        });
        commit("setItemToCart", item);
      })
      .catch((err) => {
        router.replace({
          ...Tr.i18nRoute({ name: "table" }),
        });
        let error = err.response.data._server_messages;
        if (error)
          dispatchNotification({
            content: JSON.parse(JSON.parse(error)[0]).message,
            type: "warning",
          });
      });
  },
  setReceiveUSD({ state, commit }, receive) {
    commit("setReceiveUSD", receive);
  },
  setReceiveKHR({ state, commit }, receive) {
    commit("setReceiveKHR", receive);
  },
  setNumberCustomer({ state, commit }, numberCustomer) {
    commit("setNumberCustomer", numberCustomer);
  },
  setDiscount({ state, commit }, discount) {
    commit("setDiscount", discount);
  },
  setDiscountAmount({ state, commit }, discount) {
    commit("setDiscountAmount", discount);
  },
  setCustomer({ state, commit }, customer) {
    commit("setCustomer", customer);
  },
  clearCustomer({ state, commit }) {
    commit("clearCustomer");
  },
  setDataTable({ state, commit }, data) {
    commit("setDataTable", data);
  },
  setCartItem(
    { state, commit, getters },
    { table, key, total, pos_profile, reasons, pos_entry }
  ) {
    commit("setLoading", true);
    let data = {
      name: state.restaurant.name,
      table: table?.name,
      number_customer: getters.getNumberCustomer,
      total: total,
      total_qty: getters.getCartTotalQty,
      reasons: reasons,
      items_remove: state.restaurant.items_remove,
      pos_entry: pos_entry,
      items: state.restaurant.items.map((item) => {
        return {
          item_code: item.item_code,
          item_name: item.item_name,
          qty: item.qty,
          discount_percentage: item.discount,
          take_note: item.take_note,
          name: item.name,
          tag: item.tag,
          uom: item.uom,
        };
      }),
    };
    dataService
      .post("save_sales_invoice", data)
      .then((res) => {
        const item = res.data?.message;
        if (key == "confirm") {
          dispatchNotification({
            content: "Your action was successfully confirmed",
            type: "success",
          });
          router.replace({
            ...Tr.i18nRoute({ name: "table" }),
          });
        } else {
          router.push({
            ...Tr.i18nRoute({ name: "checkout" }),
            query: {
              sales_invoice: item.name,
            },
          });
        }
        if (store.getters["auth/isUsePrinter"]) socket.emit("printOrder", item);
        socket.emit("send", {
          pos_profile: pos_profile,
          type: type.refT,
        });
        socket.emit("send", {
          pos_profile: pos_profile,
          type: type.refM,
          table: table?.name,
        });
        socket.emit("send", {
          pos_profile: pos_profile,
          type: type.refCS,
          table: table?.name,
        });
        socket.emit("send", {
          pos_profile: pos_profile,
          type: type.refP,
          table: table?.name,
        });
        if (store.getters["auth/isUseKDS"]) {
          let serviceRemove = item.items_remove;
          socket.emit("send", {
            data: serviceRemove,
            type: type.SRS,
            pos_profile: pos_profile,
          });

          let serviceAdd = item.items;
          socket.emit("send", {
            data: serviceAdd,
            type: type.SS,
            pos_profile: pos_profile,
          });
        }
        commit("setLoading", false);
      })
      .catch((err) => {
        let error = err.response?.data._server_messages;
        if (error)
          dispatchNotification({
            content: JSON.parse(JSON.parse(error)[0]).message,
            type: "warning",
          });
        commit("setLoading", false);
      });
  },
  submitCartItem(
    { state, commit, getters },
    { grandTotal, key, taxes, mode_of_payment, pos_profile }
  ) {
    commit("setLoading", true);
    let data = {
      key: key,
      name: state.restaurant.name,
      table: state.restaurant.table,
      customer: getters.getCustomer.name,
      grand_total: grandTotal,
      sub_total: getters.getCartSubTotal,
      remarks: getters.getRemarks,
      items: getters.getCartItems.map((item) => {
        if (getters.getStatusItemDiscount)
          if (item.discount >= state.restaurant.discount)
            return {
              ...item,
              discount_percentage: item.discount,
            };
          else
            return {
              ...item,
              discount_percentage: state.restaurant.discount,
            };
        else
          return {
            ...item,
            discount_percentage: 0,
          };
      }),
      taxes: taxes.map((item) => {
        if (item.dpos_type === "Tax") {
          return {
            ...item,
            amount: getters.getTotalAmountTaxCharge(item.dpos_rate),
          };
        } else if (item.dpos_type === "Discount Amount") {
          return {
            ...item,
            dpos_rate: 0,
            amount: !getters.getStatusItemDiscount
              ? getters.getDiscountAmount
              : 0,
          };
        } else if (item.dpos_type === "Discount Percentage") {
          return {
            ...item,
            dpos_rate: !getters.getStatusItemDiscount ? getters.getDiscount : 0,
            amount: !getters.getStatusItemDiscount
              ? getters.getDiscountPerAmount
              : 0,
          };
        } else if (item.dpos_type === "Service Charge") {
          return {
            ...item,
            amount: getters.getTotalAmountTaxCharge(item.dpos_rate),
          };
        }
        return item;
      }),
      mode_of_payment: mode_of_payment,
    };
    if (state.restaurant.invoice_no == null || key == "receipt")
      dataService
        .post("submit_sales_invoice", data)
        .then((res) => {
          dispatchNotification({
            content: `Your action was successfully ${res.data.message.message}!`,
            type: "success",
          });
          socket.emit("send", {
            pos_profile: pos_profile,
            type: type.refT,
          });
          socket.emit("send", {
            pos_profile: pos_profile,
            type: type.refP,
            table: state.restaurant.table.name,
          });
          if (key == "receipt") {
            let invoices = res.data.message.invoices;
            if (invoices)
              if (invoices.length > 0)
                router.push({
                  ...Tr.i18nRoute({ name: "checkout" }),
                  query: {
                    sales_invoice: invoices[0].name,
                  },
                });
              else
                router.push({
                  ...Tr.i18nRoute({ name: "table" }),
                });
            else
              router.push({
                ...Tr.i18nRoute({ name: "menu" }),
              });
            print();
            commit("setLoading", false);
            socket.emit("send", {
              pos_profile: pos_profile,
              type: type.refCS,
              table: state.restaurant.table.name,
            });
          } else {
            const item = res.data.message.data;
            const items = {
              name: item.name,
              number_customer: item.number_customer,
              customer: getters.getCustomer,
              creation: formatDate(item?.creation),
              remarks: item.remarks,
              table: item.table,
              receiveUSD: 0,
              receiveKHR: 0,
              status_invoice: item.status_invoice,
              discount: Math.abs(
                Number(
                  item.taxes.find(
                    (item) => item.dpos_type === "Discount Percentage"
                  ).dpos_rate
                )
              ),
              discount_amount: item.taxes.find(
                (item) => item.dpos_type === "Discount Amount"
              ).amount,
              invoice_no: item.invoice_no,
              items: item.items.map((i) => {
                return {
                  id: uuidv4(),
                  ...i,
                };
              }),
            };
            commit("setItemToCart", items);
            setTimeout(() => {
              print();
              commit("setLoading", false);
            }, 100);
          }
        })
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
          setTimeout(() => {
            commit("setLoading", false);
          }, 100);
        });
    else {
      setTimeout(() => {
        print();
        commit("setLoading", false);
      }, 100);
    }
  },
  addItemToCart({ state, commit }, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (!cartItem) {
      commit("addItemToCart", item);
    } else {
      commit("incrementqtyByCartItem", item);
    }
  },
  removeItemFromCart({ state, commit, rootState }, item) {
    commit("removeItemFromCart", item);
  },
  clearCart({ commit }) {
    commit("clearCart");
  },
  setOptionByCartItem({ state, commit, rootState }, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      commit("setOptionByCartItem", item);
      // commit("items/decrementInventoryByItem", { item_code: itemId }, { root: true });
    }
  },
  incrementqtyByCartItem({ state, commit, rootState }, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      commit("incrementqtyByCartItem", item);
      // commit("items/decrementInventoryByItem", { item_code: itemId }, { root: true });
    }
  },
  decrementqtyByCartItem({ state, commit, rootState }, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      commit("decrementqtyByCartItem", item);
      // commit("items/incrementInventoryByItem", { item_code: itemId }, { root: true });
    }
  },
};

// mutations
const mutations = {
  onChageDiscountItem(state, data) {
    const cartItem = state.restaurant.items.find((res) => res.id === data.id);
    if (cartItem) {
      cartItem.discount = data.value ? data.value : 0;
    }
    state.restaurant.discount = 0;
  },
  setNewCart(state, data) {
    state.restaurant.items = data;
  },
  addItemRemove(state, item) {
    state.restaurant.items_remove.push(item);
  },
  setRemarks(state, value) {
    state.restaurant.remarks = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  clearCartItem(state) {
    state.restaurant.items = [];
  },
  setReceiveUSD(state, receive) {
    state.restaurant.receiveUSD = receive;
  },
  setReceiveKHR(state, receive) {
    state.restaurant.receiveKHR = receive;
  },
  setDiscount(state, discount) {
    state.restaurant.discount = discount;
  },
  setDiscountAmount(state, discount) {
    state.restaurant.discount_amount = discount;
  },
  setDataTable(state, table) {
    state.restaurant.table = table;
  },
  setCustomer(state, customer) {
    state.restaurant.customer = customer;
  },
  clearCustomer(state) {
    state.restaurant.customer = null;
  },
  setNumberCustomer(state, numberCustomer) {
    state.restaurant.number_customer = numberCustomer;
  },
  setItemToCart(state, data) {
    state.restaurant = data;
  },
  addItemToCart(state, item) {
    state.restaurant.items.splice(0, 0, {
      ...item,
      qty: 1,
      discount: 0,
      take_note: "",
    });
  },
  removeItemFromCart(state, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      state.restaurant.items.splice(
        state.restaurant.items.indexOf(cartItem),
        1
      );
    }
  },
  clearCart(state) {
    state.restaurant = {
      name: "",
      table: null,
      items: [],
      items_remove: [],
      number_customer: 0,
      customer: null,
      discount: 0,
      discount_amount: 0,
      creation: null,
      receiveUSD: 0,
      receiveKHR: 0,
      invoice_no: null,
      waiting_number: 0,
      remarks: "",
      status_invoice: null,
    };
  },
  setOptionByCartItem(state, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      if (
        cartItem.discount != item.discount ||
        cartItem.take_note != item.take_note
      )
        cartItem.id = uuidv4();
      cartItem.qty = item.qty;
      cartItem.discount = item.discount;
      cartItem.take_note = item.take_note;
    }
  },
  incrementqtyByCartItem(state, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      cartItem.qty++;
    }
  },

  decrementqtyByCartItem(state, item) {
    const cartItem = state.restaurant.items.find((res) => res.id === item.id);
    if (cartItem) {
      cartItem.qty--;
      if (cartItem.qty === 0) {
        state.restaurant.items.splice(
          state.restaurant.items.indexOf(cartItem),
          1
        );
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
