export const print = () => {
  const modal = document.getElementById("modal-print");
  const cloned = modal.cloneNode(true);
  let section = document.getElementById("print");

  if (!section) {
    section = document.createElement("div");
    section.id = "print";
    document.body.appendChild(section);
  }

  section.innerHTML = "";
  section.appendChild(cloned);
  window.print();
};

// dd-mm-yyyy HH:mm
export const formatDateTime = (date) => {
  const parsedDateTime = new Date(date);
  const day = String(parsedDateTime.getDate()).padStart(2, "0");
  const month = String(parsedDateTime.getMonth() + 1).padStart(2, "0");
  const year = parsedDateTime.getFullYear();

  const hours = String(parsedDateTime.getHours()).padStart(2, "0");
  const minutes = String(parsedDateTime.getMinutes()).padStart(2, "0");

  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}`;

  return formattedDateTime;
};

// Form the desired date format 'DD-MM-YYYY'
export const formatDate = (date) => {
  const dateObject = new Date(date);

  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = dateObject.getFullYear().toString();

  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

export const formatDateNow = (now) => {
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = now.getFullYear();

  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

export const renderItems = (items) => {
  let i = 1;
  return items.map((item) => {
    return { ...item, no: i++ };
  });
};

export const formatTime = (time) => {
  const timeComponents = time.split(":");

  const hours = timeComponents[0];
  const minutes = timeComponents[1];
  const seconds = Math.floor(parseFloat(timeComponents[2]));

  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  return formattedTime;
};

export const updateDarkMode = (isDarkMode) => {
  if (isDarkMode) {
    document.documentElement.classList.add("dark");
    localStorage.setItem("darkMode", "true");
  } else {
    document.documentElement.classList.remove("dark");
    localStorage.setItem("darkMode", "false");
  }
};

export const toggleFullScreen = () => {
  const doc = window.document;
  const docEl = doc.documentElement;

  const requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  const exitFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    // Go fullscreen
    if (requestFullScreen) {
      requestFullScreen.call(docEl);
    }
  } else {
    // Exit fullscreen
    if (exitFullScreen) {
      exitFullScreen.call(doc);
    }
  }
};

export const formatString = (value, type = "format") => {
  return type === "format"
    ? value.replace(/%20/g, " ")
    : value.replace(/ /g, "%20");
};

export const convertToZeroPaddedString = (number, desiredLength) => {
  const numberString = number.toString();
  const paddedNumberString = numberString.padStart(desiredLength, "0");
  return paddedNumberString;
};

import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const exportToExcel = (items) => {
  const ws = XLSX.utils.json_to_sheet(items);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  XLSX.writeFile(wb, "exported_data.xlsx");
};
export const exportToPDF = () => {
  const doc = new jsPDF();
  doc.autoTable({ html: "table" });
  doc.save("exported_data.pdf");
};

export const addRequired = (id, status = true) => {
  const inputElement = document.getElementById(id);
  if (inputElement) {
    if (!status) {
      inputElement.removeAttribute("required");
    } else {
      inputElement.setAttribute("required", "true");
    }
  }
};

// Format the date to "YYYY-MM-DD"
const currentTimestamp = Date.now();
const currentDate = new Date(currentTimestamp);
export const formattedDateNow = `${currentDate.getFullYear()}-${padZero(
  currentDate.getMonth() + 1
)}-${padZero(currentDate.getDate())}`;
function padZero(num) {
  return num.toString().padStart(2, "0");
}
const currency_precision = 2;
export const formtCurrency = (value) => {
  value = parseFloat(value);
  return value.toFixed(currency_precision).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

import dataService from "@/services/data.service";
export const passwordStrength = async (val) => {
  return await dataService
    .postPublic("test_pwd_strength", { pwd: val })
    .then((res) => {
      let data = res.data.message;
      if (data) {
        let score = data.score;
        var strength = {
          red: ["Weak", "#ff0e0e", 25],
          orange: ["Average", "#ffcc00", 50],
          blue: ["Strong", "#007bff", 75],
          green: ["Excellent", "#28a745", 100],
        };
        var indicators = ["red", "red", "orange", "blue", "green"];
        return {
          score: score,
          text: strength[indicators[score]][0],
          color: strength[indicators[score]][1],
          percent: strength[indicators[score]][2],
        };
      } else return null;
    });
};
