<template>
  <TransitionRoot as="template" :show="isModalOpen">
    <Dialog as="div" class="relative z-20" @click="() => {
        if (freeSpaceClose) onToggle();
      }
      ">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full justify-center text-center items-center md:px-2 lg:px-4">
          <TransitionChild class=" w-full" as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-0 scale-95"
            enter-to="opacity-100 translate-y-0 scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 scale-100" leave-to="opacity-0 translate-y-0 scale-95">
            <DialogPanel :style="{ maxWidth: `${width}px` }" class="flex transform text-left text-base transition">
              <div class="w-full p-5 mx-auto my-auto rounded-xl shadow-lg bg-white dark:bg-slate-800 relative">
                <div class="text-[20px] flex items-center mb-4 space-x-1">
                  <h1 class="text-gray-700 dark:text-gray-100">
                    {{ title }}
                  </h1>
                  <p class="text-gray-600 dark:text-gray-200 underline underline-offset-1 ellipsis w-28">
                    {{ subtitle }}
                  </p>
                </div>
                <button type="button" class="absolute right-2 top-2 text-gray-400 hover:text-gray-500" @click="onToggle">
                  <span class="sr-only">Close</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                    <path fill-rule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clip-rule="evenodd" />
                  </svg>
                </button>
                <slot></slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    isModalOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    onToggle: { type: Function, required: true },
    width: {
      type: Number,
      default: "auto",
      required: false,
    },
    freeSpaceClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
