<template>
  <div class="flex space-x-6">
    <div class="search_customer relative w-full">
      <span
        class="absolute top-1.5 left-1.5 text-gray-600 dark:text-gray-100"
        :class="isOpen ? 'z-[51]' : 'z-10'"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-7 h-7"
        >
          <path
            d="M10 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.465 14.493a1.23 1.23 0 0 0 .41 1.412A9.957 9.957 0 0 0 10 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 0 0-13.074.003Z"
          />
        </svg>
      </span>

      <multiselect
        v-model="customer"
        :options="customers"
        placeholder=""
        @open="isOpen = true"
        @close="isOpen = false"
        :disabled="readonly"
      />

      <button
        type="button"
        @click="handleAddView"
        :disabled="readonly"
        class="absolute top-2 right-2.5 bg-white dark:bg-slate-700 text-gray-600 dark:text-gray-100"
        :class="isOpen ? 'z-[51]' : 'z-0'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      </button>
    </div>
    <!-- <div class="flex space-x-6">
      <button
        class="w-24 font-bold text-gray-600 dark:text-gray-200 rounded-md hover:bg-slate-200 dark:hover:bg-slate-700 hover:text-gray-800"
        type="button"
        @click="onShowCoupons"
      >
        0 Coupons
      </button>
      <button
        class="w-40 font-bold text-gray-600 dark:text-gray-200 rounded-md hover:bg-slate-200 dark:hover:bg-slate-700 hover:text-gray-800"
        type="button"
      >
        0 Offers : 0 Applied
      </button>
    </div> -->
    <ModalComponent
      title="New Customer"
      :isModalOpen="isAddView"
      :onToggle="() => (this.isAddView = false)"
      :width="578"
      :freeSpaceClose="true"
    >
      <form
        class="p-2 grid grid-cols-1 gap-y-2"
        name="form"
        @submit.prevent="handleSaveCustomer"
      >
        <div>
          <InputComponent
            id="customer_name"
            label="Customer Name"
            v-model="dataForm.customer_name"
            required
          />
        </div>
        <div>
          <label
            for="customer_group"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Customer Group
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <multiselect
              id="customer_group"
              v-model="dataForm.customer_group"
              placeholder=""
              label="name"
              track-by="name"
              :options="dataCustomerGroup"
            />
          </div>
        </div>
        <div>
          <label
            for="mobile_number"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Phone Number</label
          >
          <div class="mt-1">
            <input
              id="mobile_number"
              type="tel"
              v-model="dataForm.mobile_no"
              class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
            />
          </div>
        </div>
        <div class="flex justify-end pt-2 lg:pt-4 gap-4">
          <button
            type="button"
            @click="isAddView = false"
            class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
          >
            Save
          </button>
        </div>
      </form>
    </ModalComponent>
  </div>
  <SpinComponent :show="loading" />
</template>

<script>
import dataService from "@/services/data.service";
import { mapActions, mapGetters } from "vuex";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import Customer from "@/models/customer";
import { dispatchNotification } from "@/components/notification";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import InputComponent from "@/components/input/InputComponent.vue";

export default {
  components: {
    ModalComponent,
    SpinComponent,
    InputComponent,
  },
  data() {
    return {
      customers: [],
      customer: "",
      readonly: false,
      loading: false,
      isAddView: false,
      isOpen: false,
      dataForm: new Customer("", null, "", "", "", "", false),
      dataCustomerGroup: [],
    };
  },
  created() {
    this.getCustomerNames();
    this.getCustomerGroup();
    this.emitter.on("setSustomer", (customer) => {
      this.customer = customer;
    });
    this.emitter.on("setCustomerReadonly", (value) => {
      this.readonly = value;
    });
  },
  computed: {
    ...mapGetters("retail", {
      userProfile: "userProfile",
    }),
    pos_profile() {
      return this.userProfile.pos_profile;
    },
  },
  mounted() {
    this.customer = this.pos_profile.customer;
  },
  watch: {
    customer() {
      this.emitter.emit("updateCustomer", this.customer);
    },
  },
  methods: {
    show_offers() {
      this.emitter.emit("show_offers", "true");
    },
    onShowCoupons() {
      this.emitter.emit("onShowCoupons", true);
    },
    handleSaveCustomer() {
      this.loading = true;
      dataService
        .post("create_customer", {
          ...this.dataForm,
          customer_group: this.dataForm.customer_group?.name
            ? this.dataForm.customer_group.name
            : null,
        })
        .then((res) => {
          let data = res.data.message;
          this.customers.push(data.name);
          this.customer = data.name;
          (this.dataForm = new Customer("", null, "", "", "", "", false)),
            dispatchNotification({
              content: "Create Customer successfully!",
              type: "success",
            });
          this.loading = false;
          this.isAddView = false;
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.loading = false;
        });
    },
    handleAddView() {
      this.isAddView = true;
    },
    getCustomerGroup() {
      dataService.get("get_customer_group").then((res) => {
        this.dataCustomerGroup = res.data.message;
        this.dataForm.customer_group = this.dataCustomerGroup.find(
          (item) => item.old_parent == ""
        );
      });
    },
    getCustomerNames() {
      if (
        this.pos_profile.custom_use_browser_local_storage &&
        localStorage.customer_storage
      ) {
        this.customers = JSON.parse(localStorage.getItem("customer_storage"));
      }
      dataService
        .post("get_customer_names", {
          pos_profile: this.pos_profile,
        })
        .then((res) => {
          let data = res.data.message;
          if (data) {
            this.customers = data.map((item) => item.name);
            if (this.pos_profile.custom_use_browser_local_storage) {
              localStorage.setItem("customer_storage", "");
              localStorage.setItem("customer_storage", JSON.stringify(data));
            }
          }
        });
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.customer_name
        ? item.customer_name.toLowerCase()
        : "";
      const textTwo = item.tax_id ? item.tax_id.toLowerCase() : "";
      const textThree = item.email_id ? item.email_id.toLowerCase() : "";
      const textFour = item.mobile_no ? item.mobile_no.toLowerCase() : "";
      const textFifth = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1 ||
        textFour.indexOf(searchText) > -1 ||
        textFifth.indexOf(searchText) > -1
      );
    },
  },
};
</script>
