<template>
  <div class=" w-full">
    <div class="p-2 lg:p-4 grid grid-cols-1 md:grid-cols-6">
      <div class="col-span-4 w-full">
        <div class="relative mb-2 flex justify-between">
          <div class="w-full md:w-1/2 relative">
              <input type="text" v-model="onSearch" @input="handleSearch"
                class="w-full px-9 py-1.5 border rounded-full dark:text-gray-200 bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 focus:duration-0 focus:outline-none focus:ring-transparent"
                placeholder="Search..." /><span class="absolute py-[9px] px-3 top-0 left-0 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
              </span><span v-if="searchFilter != ''" @click="handleSearchClear"
                class="absolute top-[5px] right-3 lg:top-[7.5px]  text-gray-500 dark:text-gray-400 cursor-pointer"><svg
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </div>
        </div>
        <div class="flex items-center justify-between space-x-2 w-full">
          <div class="flex items-center space-x-2 w-full">
            <span v-if="menuChild.child.length !== 0" @click="handleCloseMenuChild"
              class="bg-primary shadow rounded-full p-0.5 text-white cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>
            <nav
              class="grid grid-flow-col text-center max-w-full md:max-w-[75%] overflow-x-auto no-scrollbar  text-gray-600 bg-gray-200 dark:text-gray-100 dark:bg-slate-700 rounded-full">
              <a v-if="menuChild.child.length === 0" v-for="(item, index) in menu" :key="item.name">
                <span @click="() => handleSelectMenu(item, index)"
                  class="flex justify-center cursor-pointer text-sm lg py-1 lg:py-2 w-24 lg:w-40" :class="[
                    item.name === selectMenu &&
                    'bg-primary rounded-full shadow text-white',
                  ]">{{ item.label }}</span>
              </a>
              <a v-else>
                <span @click="() => handleSelectMenu(menuChild, 0)"
                  class="flex justify-center cursor-pointer text-sm lg py-1 lg:py-2 w-24 lg:w-40" :class="[
                    menuChild.name === selectMenu &&
                    'bg-secondary rounded-full shadow text-white',
                  ]">{{ menuChild.label }}</span>
              </a>
              <a v-if="menuChild.child.length !== 0" v-for="(itemChild, index) in menuChild.child" :key="itemChild.name">
                <span @click="() => handleSelectMenu(itemChild, index)"
                  class="flex justify-center cursor-pointer text-sm lg py-1 lg:py-2 w-24 lg:w-40" :class="[
                    itemChild.name === selectMenu &&
                    'bg-secondary rounded-full shadow text-white',
                  ]">{{ itemChild.label }}</span>
              </a>
            </nav>
          </div>
        </div>
        <div class="relative">
          <SpinComponent :show="loadItem" />
          <EmptyComponent :show="dataItems?.length == 0 && !loadItem" />
          <div id="infinite-list"
            class="relative mt-2 overflow-auto h-[calc(100vh_-_138px)] sm:h-[calc(100vh_-_137px)] lg:h-[calc(100vh_-_187px)] dir-rtl pr-2">
            <div class="grid gap-2 lg:gap-4 grid-cols-4 lg:grid-cols-5">
              <div v-for="item in dataItems" :key="item.name" class="group cursor-pointer" @click="
                getNumberCustomer != 0
                  ? addItemToCart(item)
                  : handleBeforeGuests(item)
                ">
                <div
                  class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 lg:aspect-h-8 lg:aspect-w-7">
                  <img :src="item.image ? baseURL + item.image : imageEmpty" :alt="item?.imageAlt"
                    class="h-full w-full object-cover object-center group-hover:opacity-75" />
                </div>
                <h3
                  class="mt-3 text-1xs lg:text-sm  text-gray-700 dark:text-gray-100 ellipsis-line line-clamp-2">
                  {{ item.item_name }}
                </h3>
                <p class="mt-1 text-1xs lg:text-sm font-medium  text-gray-900 dark:text-gray-100">
                  {{
                    item.currency_symbol +
                    " " +
                    $n(
                      item.price_list_rate,
                      "decimal",
                      `${item.currency === "USD" ? "en" : "kh"}`
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-3 md:col-span-2 hidden md:block">
        <div class="p-2 leading-4 lg:leading-10">
          <div
            class="flex justify-between border-b-2  dark:border-slate-600 text-gray-900 dark:text-gray-100">
            <div class="flex items-center text-1xs lg:text-[16px] space-x-1"
              @click="() => (this.isModalOpenGuests = true)">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="hidden lg:block w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>
              </div>
              <p class="flex items-center space-x-1">
              <p>Guests:</p>
              <p class="text-primary lg:text-[20px]">{{
                getNumberCustomer
              }}</p>
              </p>
            </div>
            <div v-if="getBusinessType !== 'Fast Food'" class="text-1xs lg:text-[16px] cursor-pointer"
              @click="handleOpenTable">
              <p class="w-48 text-right ellipsis text-primary">
                <span class="text-slate-900  dark:text-gray-50 relative"><svg
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="hidden lg:block w-6 h-6 absolute -bottom-[3px] left-0">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                  </svg>
                  <span class="ml-[26px]">Tables:</span>
                </span>
                <span v-if="dataTable?.name != undefined" class="lg:text-[20px] ml-1">
                  {{ dataTable.label }}
                </span>
                <span v-else>N/A</span>
              </p>
            </div>
          </div>
        </div>
        <div class="pl-2 lg:pl-4 pr-0 overflow-hidden relative">
          <EmptyComponent :show="getCartItems?.length == 0" />

          <ul role="list"
            class="divide-y  divide-gray-100 dark:divide-gray-600 flex flex-col overflow-auto pr-2 rounded-lg h-[calc(100vh_-_275px)] lg:h-[calc(100vh_-_443px)]">
            <li v-for="item in getCartItems" :key="item.name" class="flex py-2 lg:py-4">
              <div
                class="h-16 lg:h-24 w-16 lg:w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 bg-white"
                :class="!item.parent && 'cursor-pointer'" @click="handleOpenOption(item)">
                <img :src="item.image ? baseURL + item.image : imageEmpty" :alt="item?.imageAlt"
                  class="h-full w-full object-cover object-center" />
              </div>

              <div class="ml-4 flex flex-1 flex-col">
                <div>
                  <div class="flex justify-between  text-gray-900 dark:text-gray-100 items-start">
                    <div class="w-3/4">
                      <div class="w-full text-1xs lg:text-sm font-medium flex items-center">
                        <h1 class="ellipsis-line line-clamp-1">{{ item.item_name }}</h1>
                        <div>
                          <svg v-if="item.parent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                            fill="currentColor" class="w-4 h-4   text-secondary dark:text-primary">
                            <path fill-rule="evenodd"
                              d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                              clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                      <p v-if="item.take_note != ''" class="text-sm  text-gray-600 dark:text-gray-400">
                        {{ item.take_note }}
                      </p>
                    </div>
                    <div class="text-right">
                      <p class="text-1xs lg:text-[16px]">
                        {{
                          item.currency_symbol +
                          " " +
                          $n(
                            item.price_list_rate,
                            "decimal",
                            `${item.currency === "USD" ? "en" : "kh"}`
                          )
                        }}
                      </p>
                      <p v-if="item.discount != 0"
                        class="text-1xs lg:text-[16px]  text-gray-500 dark:text-gray-400">
                        {{ item.discount }}% off
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex flex-1 items-end justify-between text-sm">
                  <span v-if="item.parent" class="mx-2  dark:text-gray-100">Qty: {{ item.qty }}</span>
                  <div v-else
                    class="flex items-end bg-gray-100 rounded-md border border-gray-100 mb-1   dark:bg-slate-700 dark:border-gray-700">
                    <button class="p-[2px]  bg-white text-gray-600 dark:bg-slate-800 dark:text-white" :class="item.qty == 1
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                      " :disabled="item.qty == 1" @click="decrementqtyByCartItem(item)">
                      <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                      </svg>
                    </button>
                    <span class="mx-2  dark:text-gray-100">{{ item.qty }}</span>
                    <button class="p-[2px]   bg-white text-gray-600 dark:bg-slate-800 dark:text-white"
                      @click="incrementqtyByCartItem(item)">
                      <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                      </svg>
                    </button>
                  </div>
                  <div class="flex">
                    <button v-if="item.dpos_status !== 'Done'" @click="handleRemoveItemFromCart(item)" type="button"
                      class="font-medium text-red-700 text-1xs lg:text-sm">
                      Remove
                    </button>
                    <div v-else class="font-medium text-primary text-1xs lg:text-sm">
                      Done
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="p-2 lg:pl-4">
          <div class="border-dashed border rounded-lg   dark:text-white dark:border-slate-600">
            <div class="p-2 lg:p-4 pb-2 text-1xs lg:text-[16px]">
              <div class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Subtotal</p>
                <p class="font-medium">
                  $ {{ $n(getCartSubTotal, "decimal", "en") }}
                </p>
              </div>

              <div class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Tax {{ getTax }}%</p>
                <p class="font-medium">
                  $ {{ $n(getTotalAmountTaxCharge(getTax), "decimal", "en") }}
                </p>
              </div>
              <div class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Service Charge {{ getCharge }}%</p>
                <p class="font-medium">
                  $
                  {{ $n(getTotalAmountTaxCharge(getCharge), "decimal", "en") }}
                </p>
              </div>
            </div>
            <div class="border-dotted border-t h-1 w-full  dark:border-slate-600"></div>
            <div class="p-2 lg:p-4 flex justify-between leading-6 lg:leading-10 items-center">
              <h1 class="text-[16px] lg:text-[20px]">TOTAL</h1>
              <p class="text-1xs lg:text-[20px] font-medium">
                $ {{ $n(total, "decimal", "en") }}
              </p>
            </div>
          </div>
        </div>
        <div class="pr-2 pl-2 lg:pl-4">
          <div class="grid grid-cols-8 gap-1.5 lg:gap-5">
            <div class="col-span-2">
              <button v-if="getBusinessType !== 'Fast Food'"
                class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-gray-600 text-white font-medium rounded-md w-full justify-center"
                @click="cancelOrder">
                Cancel
              </button>
              <button v-else
                class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-gray-600 text-white font-medium rounded-md w-full justify-center"
                @click="clearCartItem">
                Clear
              </button>
            </div>
            <div v-show="!userRoleCashier" class="col-span-3"></div>
            <div class="col-span-3">
              <button v-if="getBusinessType !== 'Fast Food'" @click="saveCartItem('confirm')"
                class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white font-medium rounded-md w-full justify-center">
                Confirm
              </button>
            </div>
            <div class="col-span-3" v-if="userRoleCashier">
              <button @click="saveCartItem('pay')"
                class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-primary text-white font-medium rounded-md w-full justify-center">
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DrawerComponent :isDrawerOpen="onDrawerOpenOrder" :isDrawerClose="() => this.onDrawerOpenOrder = false">
      <div class="w-full">
        <div class="p-2 leading-4 lg:leading-10">
          <div
            class="flex justify-between border-b-2   dark:border-slate-600 text-gray-900 dark:text-gray-100">
            <div class="flex items-center text-1xs lg:text-[16px] space-x-1 cursor-pointer"
              @click="() => this.isModalOpenGuests = true">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="hidden lg:block w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>
              </div>
              <p class="flex items-center space-x-1">
              <p>Guests:</p>
              <p class="text-primary lg:text-[20px]">{{
                getNumberCustomer
              }}</p>
              </p>
            </div>
            <div v-if="getBusinessType !== 'Fast Food'" class="text-1xs lg:text-[16px] cursor-pointer"
              @click="handleOpenTable">
              <p class="w-48 text-right ellipsis text-primary">
                <span class="text-slate-900 dark:text-gray-50 relative"><svg
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="hidden lg:block w-6 h-6 absolute -bottom-[3px] left-0">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                  </svg>
                  <span class="ml-[26px]">Tables:</span>
                </span>
                <span v-if="dataTable?.name != undefined" class="lg:text-[20px] ml-1">
                  {{ dataTable.label }}
                </span>
                <span v-else>N/A</span>
              </p>
            </div>
          </div>
        </div>
        <div class="pl-2 lg:pl-4 pr-0 overflow-hidden relative">
          <EmptyComponent :show="getCartItems?.length == 0" />

          <ul role="list"
            class=" divide-y divide-gray-100 dark:divide-gray-600 flex flex-col overflow-auto pr-2 rounded-lg h-[calc(100vh_-_275px)] lg:h-[calc(100vh_-_443px)]">
            <li v-for="item in getCartItems" :key="item.name" class="flex py-2 lg:py-4">
              <div
                class="h-16 lg:h-24 w-16 lg:w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 bg-white"
                :class="!item.parent && 'cursor-pointer'" @click="handleOpenOption(item)">
                <img :src="item.image ? baseURL + item.image : imageEmpty" :alt="item?.imageAlt"
                  class="h-full w-full object-cover object-center" />
              </div>

              <div class="ml-4 flex flex-1 flex-col">
                <div>
                  <div class="flex justify-between  text-gray-900 dark:text-gray-100 items-start">
                    <div class="w-3/4">
                      <div class="w-full text-1xs lg:text-sm font-medium flex items-center">
                        <h1 class="ellipsis-line line-clamp-1">{{ item.item_name }}</h1>
                        <div>
                          <svg v-if="item.parent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                            fill="currentColor" class="w-4 h-4  text-secondary dark:text-primary">
                            <path fill-rule="evenodd"
                              d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                              clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                      <p v-if="item.take_note != ''" class="text-sm  text-gray-600 dark:text-gray-400">
                        {{ item.take_note }}
                      </p>
                    </div>
                    <div class="text-right">
                      <p class="text-1xs lg:text-[16px]">
                        {{
                          item.currency_symbol +
                          " " +
                          $n(
                            item.price_list_rate,
                            "decimal",
                            `${item.currency === "USD" ? "en" : "kh"}`
                          )
                        }}
                      </p>
                      <p v-if="item.discount != 0"
                        class="text-1xs lg:text-[16px]  text-gray-500 dark:text-gray-400">
                        {{ item.discount }}% off
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex flex-1 items-end justify-between text-sm">
                  <span v-if="item.parent" class="mx-2  dark:text-gray-100">Qty: {{ item.qty }}</span>
                  <div v-else
                    class="flex items-end bg-gray-100 rounded-md border border-gray-100 mb-1  dark:bg-slate-700 dark:border-gray-700">
                    <button class="p-[2px]  bg-white text-gray-600 dark:bg-slate-800 dark:text-white" :class="item.qty == 1
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                      " :disabled="item.qty == 1" @click="decrementqtyByCartItem(item)">
                      <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                      </svg>
                    </button>
                    <span class="mx-2  dark:text-gray-100">{{ item.qty }}</span>
                    <button class="p-[2px]  bg-white text-gray-600 dark:bg-slate-800 dark:text-white"
                      @click="incrementqtyByCartItem(item)">
                      <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                      </svg>
                    </button>
                  </div>
                  <div class="flex">
                    <button v-if="item.dpos_status !== 'Done'" @click="handleRemoveItemFromCart(item)" type="button"
                      class="font-medium text-red-700 text-1xs lg:text-sm">
                      Remove
                    </button>
                    <div v-else class="font-medium text-primary text-1xs lg:text-sm">
                      Done
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="p-2 lg:pl-4">
          <div class="border-dashed border rounded-lg  dark:text-white dark:border-slate-600">
            <div class="p-2 lg:p-4 pb-2 text-1xs lg:text-[16px]">
              <div class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Subtotal</p>
                <p class="font-medium">
                  $ {{ $n(getCartSubTotal, "decimal", "en") }}
                </p>
              </div>

              <div class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Tax {{ getTax }}%</p>
                <p class="font-medium">
                  $ {{ $n(getTotalAmountTaxCharge(getTax), "decimal", "en") }}
                </p>
              </div>
              <div class="flex justify-between leading-6 lg:leading-10 items-center">
                <p>Service Charge {{ getCharge }}%</p>
                <p class="font-medium">
                  $
                  {{ $n(getTotalAmountTaxCharge(getCharge), "decimal", "en") }}
                </p>
              </div>
            </div>
            <div class="border-dotted border-t h-1 w-full  dark:border-slate-600"></div>
            <div class="p-2 lg:p-4 flex justify-between leading-6 lg:leading-10 items-center">
              <h1 class="text-[16px] lg:text-[20px]">TOTAL</h1>
              <p class="text-1xs lg:text-[20px] font-medium">
                $ {{ $n(total, "decimal", "en") }}
              </p>
            </div>
          </div>
        </div>
        <div class="pr-2 pl-2 lg:pl-4">
          <div class="grid grid-cols-8 gap-1.5 lg:gap-5">
            <div class="col-span-2">
              <button v-if="getBusinessType !== 'Fast Food'"
                class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-gray-600 text-white font-medium rounded-md w-full justify-center"
                @click="cancelOrder">
                Cancel
              </button>
              <button v-else
                class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-gray-600 text-white font-medium rounded-md w-full justify-center"
                @click="clearCartItem">
                Clear
              </button>
            </div>
            <div v-show="!userRoleCashier" class="col-span-3"></div>
            <div class="col-span-3">
              <button v-if="getBusinessType !== 'Fast Food'" @click="saveCartItem('confirm')"
                class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-secondary text-white font-medium rounded-md w-full justify-center">
                Confirm
              </button>
            </div>
            <div class="col-span-3" v-if="userRoleCashier">
              <button @click="saveCartItem('pay')"
                class="inline-flex items-center text-sm py-2 lg:py-[10px] bg-primary text-white font-medium rounded-md w-full justify-center">
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </DrawerComponent>
    <ModalComponent :title="selectItemCart?.item_name" :isModalOpen="isModalOpenOption" :onToggle="handleCloseOption"
      :width="578">
      <div class="pb-2">
        <label class="text-sm lg:text-sm flex space-x-2 items-center  dark:text-gray-200">Modifier
        </label>
        <div v-if="!isAddTakeNote" class="flex space-x-2 lg:space-x-4 items-center">
          <div class="w-full ">
            <multiselect v-model="selectedTakeNote" label="description" track-by="description" placeholder=""
              :options="dataTakeNote" :multiple="true" />
          </div>
          <div>
            <button
              class=" dark:text-gray-200 underline underline-offset-2 inline-flex items-center text-sm py-2 lg:py-[10px] bg-transparent text-gray-600 hover:border-secondary/80 hover:text-secondary font-medium rounded-md justify-center whitespace-nowrap"
              @click="this.isAddTakeNote = !this.isAddTakeNote">
              + New
            </button>
          </div>
          <div class="text-red-500 cursor-pointer" @click="deleteDataTakeNote">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
          </div>
        </div>
        <div v-else class="flex space-x-2 lg:space-x-4 items-center">
            <InputComponent id="reason" v-model="takeNote" />
          <div>
            <button @click="postDataTakeNote"
              class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center">
              Save
            </button>
          </div>
          <div class="text-gray-600 cursor-pointer  dark:text-gray-200"
            @click="this.isAddTakeNote = !this.isAddTakeNote">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="py-2 grid grid-cols-2 gap-2 lg:gap-4">
        <div>
          <label
            class="text-sm lg:text-sm flex space-x-2 items-center  dark:text-gray-200"><span>Quantity</span>
          </label>
          <div class="relative">
            <input :value="qty" @input="onInput" type="number"
              class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600  rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
               />

            <div class="absolute inset-y-0 right-2 flex items-center text-gray-300 hover:text-gray-400"
              @click="handleShowKeypad('qty')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="py-2">
        <label class="text-sm flex space-x-2 items-center  dark:text-gray-200"><span>Discount</span>
          <div class="text-gray-400 hover:text-gray-600 cursor-pointer" @click="this.switchDis = !this.switchDis">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
            </svg>
          </div>
        </label>
        <div v-if="switchDis" class="grid grid-cols-2 gap-2 lg:gap-4">
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-600  dark:text-gray-200 sm:text-sm">$</span>
            </div>
            <VueNumber v-model="discountUSD" v-number="{ min: 0, max: selectItemCart?.price_exchange }"
              class="w-full px-5 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600  rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent" />

            <div class="absolute inset-y-0 right-2 flex items-center text-gray-300 hover:text-gray-400"
              @click="handleShowKeypad('receive')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
              </svg>
            </div>
          </div>
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span class="text-gray-600  dark:text-gray-200 sm:text-sm">៛</span>
            </div>
            <VueNumber v-model="discountKHR" v-number="{ min: 0, max: maxDiscountKHR }"
              class="w-full px-5 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600  rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent" />
            <div class="absolute inset-y-0 right-2 flex items-center text-gray-300 hover:text-gray-400"
              @click="handleShowKeypad('receive')">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
              </svg>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="relative">
            <multiselect v-model="selectedDiscount" :options="getDiscountRate" label="value" placeholder=""
              :searchable="false" track-by="value" />
          </div>
        </div>
      </div>
      <div class="flex justify-between pt-2 lg:pt-4">
        <div class="flex space-x-4">
          <button @click="handleRemoveCartFromOption"
            class="flex items-center text-sm bg-transparent p-0 border-none text-red-700 font-medium rounded-md underline underline-offset-2">
            Remove
          </button>
        </div>
        <button @click="handleSaveOption"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center">
          Save
        </button>
      </div>
    </ModalComponent>
    <ModalComponent title="How many guests?" :isModalOpen="isModalOpenGuests" :onToggle="() => {
      this.isModalOpenGuests = false;
      this.itemBeforeGuests = null;
    }
      " :width="500">
      <KeypadComponent :handleKeypadOk="handleKeypadOk" defaultValue="1" />
    </ModalComponent>
    <ModalComponent title="Select a table" :isModalOpen="isModalOpenTable" :onToggle="() => this.isModalOpenTable = false"
      :width="500">
      <div class="w-full">
        <label class="text-4x flex space-x-2 items-center  dark:text-gray-200">Table
        </label>
        <div class="relative rounded-md shadow-sm ">
          <multiselect v-model="selectedTableOpen" track-by="name" label="label" placeholder=""
            :options="dataTablesOpen" />
        </div>
        <div class="flex justify-end pt-2 lg:pt-4">
          <button @click="handleSaveTable"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center">
            Save
          </button>
        </div>
      </div>
    </ModalComponent>
    <ModalComponent title="Why you want to cancel this order?" :isModalOpen="isCancelOrder"
      :onToggle="() => (this.isCancelOrder = false)" :width="500">
      <div class="w-full">
        <div class="my-2">
          <label
            class="text-sm lg:text-sm flex space-x-2 items-center  dark:text-gray-200"><span>Reason</span>
          </label>
          <div v-if="!isAddReason" class="flex space-x-2 lg:space-x-4 items-center">
            <div class="w-full ">
              <multiselect v-model="selectedReason" label="description" track-by="description" placeholder=""
                :options="dataReason" />
            </div>
            <div>
              <button
                class="  dark:text-gray-200 underline underline-offset-2 inline-flex items-center text-sm py-2 lg:py-[10px] bg-transparent text-gray-600 hover:border-secondary/80 hover:text-secondary font-medium rounded-md justify-center whitespace-nowrap"
                @click="this.isAddReason = !this.isAddReason">
                + New
              </button>
            </div>
            <div class="text-red-500 cursor-pointer" @click="deleteDataReason">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
            </div>
          </div>
          <div v-else class="flex space-x-2 lg:space-x-4 items-center">
            <InputComponent id="reason" v-model="reason" />
            <div>
              <button @click="postDataReason"
                class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center">
                Save
              </button>
            </div>
            <div class="text-gray-600 cursor-pointer  dark:text-gray-200"
              @click="this.isAddReason = !this.isAddReason">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex justify-end pt-2 lg:pt-4">
          <div class="flex space-x-4">
            <button @click="this.isCancelOrder = false"
              class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary  dark:text-gray-300 font-medium">
              Cancel
            </button>
            <button @click="handleYesCancel"
              class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>

    <ModalComponent :title="titleDeleteItem" :isModalOpen="isDeleteItem" :onToggle="() => (this.isDeleteItem = false)"
      :width="500">
      <div class="w-full">
        <div class="my-2">
          <label
            class="text-sm lg:text-sm flex space-x-2 items-center  dark:text-gray-200"><span>Reason</span>
          </label>
          <div v-if="!isAddReason" class="flex space-x-2 lg:space-x-4 items-center">
            <div class="w-full ">
              <multiselect v-model="selectedReason" label="description" track-by="description" placeholder=""
                :options="dataReason" />
            </div>
            <div>
              <button
                class="  dark:text-gray-200 underline underline-offset-2 inline-flex items-center text-sm py-2 lg:py-[10px] bg-transparent text-gray-600 hover:border-secondary/80 hover:text-secondary font-medium rounded-md justify-center whitespace-nowrap"
                @click="this.isAddReason = !this.isAddReason">
                + New
              </button>
            </div>
            <div class="text-red-500 cursor-pointer" @click="deleteDataReason">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
            </div>
          </div>
          <div v-else class="flex space-x-2 lg:space-x-4 items-center">
            <InputComponent id="reason" v-model="reason" 
              />
            <div>
              <button @click="postDataReason"
                class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center">
                Save
              </button>
            </div>
            <div class="text-gray-600 cursor-pointer  dark:text-gray-200"
              @click="this.isAddReason = !this.isAddReason">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex justify-end pt-2 lg:pt-4">
          <div class="flex space-x-4">
            <button @click="this.isDeleteItem = false"
              class="flex items-center text-sm bg-transparent p-0 border-none text-secondary  dark:text-gray-300 font-medium">
              Cancel
            </button>
            <button @click="handleYesDelete"
              class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center">
              Save
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
    <button v-show="getCartTotalQty > 0" @click="() => (this.onDrawerOpenOrder = !this.onDrawerOpenOrder)" type="button"
      class="block md:hidden fixed bg-secondary  dark:bg-slate-300 text-white dark:text-black  shadow-md z-10 bottom-10 right-10 p-2.5 rounded-full">
      <div class=" flex items-center justify-center rounded-md p-1  relative">
        <span class="sr-only">Close menu</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
        </svg>
        <span
          class="absolute animate-bounce -top-1 -right-3 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full"
          v-show="getCartTotalQty > 0">{{ getCartTotalQty > 100 ? '+99' : getCartTotalQty }}</span>
      </div>
    </button>
    <KeypadDialogComponent :show="showKeypad" :handleKeypadDialogOk="handleKeypadDialogOk" :keyKeypad="keyKeypad"
      :onToggle="() => (this.showKeypad = false)" />
    <SpinComponent :show="loading || getLoading" />
  </div>
</template>

<script>
import ModalComponent from "@/components/modal/ModalComponent.vue";
import KeypadDialogComponent from "@/components/keypad/KeypadDialogComponent.vue";
import KeypadComponent from "@/components/keypad/KeypadComponent.vue";
import DrawerComponent from "@/components/drawer/DrawerComponent.vue";
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import dataService from "@/services/data.service";
import imageEmpty from "@/assets/image/empty.jpg";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import EmptyComponent from "@/components/empty/EmptyComponent.vue";
import { mapActions, mapGetters } from "vuex";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";
import _ from 'lodash';
import InputComponent from "@/components/input/InputComponent.vue";
import { baseURL } from "@/utils/baseURL";

export default {
  name: "Menu",
  components: {
    ModalComponent,
    KeypadDialogComponent,
    KeypadComponent,
    DrawerComponent,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    EmptyComponent,
    SpinComponent,
    InputComponent
  },
  data() {
    return {
      imageEmpty,
      menu: [{ label: "All", name: "", index_no: 0, child: [] }],
      menuChild: { child: [] },
      selectMenu: "",
      showKeypad: false,
      person: "",
      keyKeypad: "",
      isModalOpenVariant: false,
      isModalOpenOption: false,
      isModalOpenGuests: false,
      isModalOpenTable: false,
      onDrawerOpenOrder: false,
      selectedTableOpen: [],
      dataTablesOpen: [],
      dataTablesDifferent: [],
      selectedTableDifferent: [],
      switchDis: false,
      loading: false,
      selectItemCart: null,
      qty: "",
      isAddTakeNote: false,
      dataTakeNote: [],
      selectedTakeNote: [],
      takeNote: "",
      isAddReason: false,
      dataReason: [],
      selectedReason: null,
      reason: "",
      selectedDiscount: { value: 0 },
      deleteItemReason: "",
      searchFilter: "",
      limit: 30,
      page: 1,
      isModalOpenYes: false,
      itemBeforeGuests: null,
      itemBeforeRemove: null,
      isRemoveItemSaved: false,
      isCancelOrder: false,
      isDeleteItem: false,
      scrollTop: 0,
      discountUSD: 0,
      discountKHR: 0,
      discountAmount: 0,
      baseURL,
    };
  },
  computed: {
    ...mapGetters("items_restaurant", {
      getItems: "getItems",
      loadItem: "loadItem",
    }),
    ...mapGetters("auth", {
      getTax: "getTax",
      getCharge: "getCharge",
      currencyExchange: "getCurrencyExchange",
      getPOSProfile: "getPOSProfile",
      getGuestCount: "getGuestCount",
      getBusinessType: "getBusinessType",
      getDiscountRate: "getDiscountRate",
      getUserRoles: "getUserRoles",
      getPOSEntry: "getPOSEntry",
    }),
    ...mapGetters("restaurant", {
      dataTable: "getDataTable",
      getCartTotal: "getCartTotal",
      getCartItems: "getCartItems",
      getCartSubTotal: "getCartSubTotal",
      getCartTotalQty: "getCartTotalQty",
      getNumberCustomer: "getNumberCustomer",
      getCartSubTotalOrg: "getCartSubTotalOrg",
      getLoading: "getLoading",
      getTotalAmountTaxCharge: "getTotalAmountTaxCharge",
    }),
    userRoleCashier() {
      return this.getUserRoles?.includes("Cashier User");
    },
    titleDeleteItem() {
      return `Why you want to remove ${this.itemBeforeRemove?.qty} of ${this.itemBeforeRemove?.item_name} item?`
    },
    total() {
      return this.getCartTotal(
        this.getTotalAmountTaxCharge(this.getTax),
        this.getTotalAmountTaxCharge(this.getCharge)
      );
    },
    maxDiscountKHR() {
      return (
        Math.round(
          ((this.selectItemCart?.price_exchange - Number(this.discountUSD)) *
            this.currencyExchange) /
          100
        ) * 100
      );
    },
    filteredItems() {
      return this.getItems.filter(item =>
        (!this.selectMenu || item.group_name === this.selectMenu) &&
        (!this.searchFilter || 
        item.item_name.toLowerCase().includes(this.searchFilter.toLowerCase()) || 
        item.item_code.toLowerCase().includes(this.searchFilter.toLowerCase()))
      );
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.limit);
    },
    dataItems() {
      const index = this.page * this.limit;
      return this.filteredItems.slice(0, index);
    },
    onSearch: {
      get() {
        return this.searchFilter;
      },
      set: _.debounce(function (newValue) {
        this.searchFilter = newValue;
      }, 200),
    },
  },
  mounted() {
    socket.on(type.refM, (message) => {
      if (message.pos_profile === this.getPOSProfile && message.table === this.dataTable?.name) {
        this.getDataTable()
      }
    });
    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", (e) => {
      this.scrollTop = listElm.scrollTop;
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        this.getItems.length > this.dataItems.length
      ) {
        this.page++;
      }
    });
  },
  created() {
    this.clearCart();
    this.getDataCheckout()
    this.setItems()
    this.getDataMenu();
    this.getDataReason();
    this.getDataTable();
    this.getDataTakeNote();
    this.getDataTablesOpen();
    this.getDataTablesDifferent();
    this.setCheckNumberCustomer()
  },
  watch: {
    selectedDiscount(newDiscount) {
      if (!newDiscount)
        this.selectedDiscount = { value: 0 };
    },
    getCartItems() {
      socket.emit("send", {
        pos_profile: this.getPOSProfile,
        type: type.refCFD,
        data: this.getCartItems
      });
    },
    switchDis() {
      this.selectedDiscount = { value: 0 };
      this.discountUSD = 0;
    },
    discountUSD(newData) {
      if (Number(newData) <= this.selectItemCart?.price_exchange) {
        this.discountAmount = Number(newData);
        this.discountUSD = Number(newData);
      } else {
        this.discountAmount = this.selectItemCart?.price_exchange;
        this.discountUSD = this.selectItemCart?.price_exchange;
      }
      this.discountKHR = 0;
    },
    discountKHR(newData) {
      if (Number(newData) <= this.maxDiscountKHR) {
        this.discountAmount =
          this.discountUSD + Number(newData) / this.currencyExchange;
      } else {
        this.discountAmount =
          this.discountUSD + this.maxDiscountKHR / this.currencyExchange;
      }
    },
  },
  methods: {
    handleYesDelete() {
      if (this.selectedReason != null) {
        this.isDeleteItem = false
        this.removeItemFromCart(this.itemBeforeRemove);
        this.addItemRemove({ name: this.itemBeforeRemove.name, item_code: this.itemBeforeRemove.item_code, qty: this.itemBeforeRemove.qty, reasons: this.selectedReason?.description })
        this.selectedReason = null
      }
      else dispatchNotification({
        content: "Please input reason!",
        type: "warning",
      });
    },
    handleRemoveItemFromCart(item) {
      if (this.userRoleCashier)
        if (item.parent) {
          this.isDeleteItem = true
          this.itemBeforeRemove = item;
        } else this.removeItemFromCart(item);
      else dispatchNotification({
        content: "No permission!",
        type: "warning",
      });
    },
    getDataCheckout() {
      if (this.getBusinessType === "Fast Food" && this.$route.query.sales_invoice) {
        this.getDataSalesInvoice(`key=${this.$route.query.sales_invoice}`);
      }
    },
    setCheckNumberCustomer() {
      if (!this.getGuestCount)
        this.setNumberCustomer(1)
    },
    handleYesCancel() {
      this.loading = true;
      if (this.dataTable.sales_invoice != "") {
        if (this.selectedReason !== null)
          dataService
            .post("cancel_sales_invoice", {
              name: this.dataTable.sales_invoice,
              table: this.dataTable.name,
              reasons: this.selectedReason?.description
            })
            .then(() => {
              this.$router.replace({
                ...Tr.i18nRoute({ name: "table" }),
              });
              this.loading = false;
              socket.emit("send", {
                pos_profile: this.getPOSProfile,
                type: type.refT,
              });
              socket.emit("send", {
                pos_profile: this.getPOSProfile,
                type: type.refM,
                table: this.dataTable.name,
              });
              socket.emit("send", {
                pos_profile: this.getPOSProfile,
                type: type.refCS,
                table: this.dataTable.name,
              });
              socket.emit("send", {
                pos_profile: this.getPOSProfile,
                type: type.refS,
              });
              dispatchNotification({
                content: "Your action was successfully order cancelled",
                type: "success",
              });
              this.isCancelOrder = false;
            });
        else {
          dispatchNotification({
            content: "Please input reason!",
            type: "warning",
          });
          this.loading = false;
        }
      } else {
        this.loading = false;
        this.$router.go(-1);
      }
    },
    onInput(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.qty = value;
      }
      this.$forceUpdate();
    },
    cancelOrder() {
      if (this.dataTable.sales_invoice != null && this.userRoleCashier) this.isCancelOrder = true;
      else this.$router.go(-1);
    },

    handleBeforeGuests(item) {
      this.isModalOpenGuests = true;
      this.itemBeforeGuests = item;
    },
    queryData() {
      let exit = this.dataTable.invoices_split.find(item => !item.status)?.name
      if (exit) {
        this.getDataSalesInvoice(`key=${exit}`);
      } else {
        this.clearCart();
      }
    },
    saveCartItem(key) {
      if (
        this.getCartItems.length != 0 ||
        this.dataTable?.sales_invoice
      )
        if (this.dataTable != null || this.getBusinessType === 'Fast Food') {
          this.setCartItem({
            reasons: this.deleteItemReason,
            table: this.dataTable,
            key: key,
            total: this.total,
            pos_profile: this.getPOSProfile,
            pos_entry: this.getPOSEntry
          });
        } else {
          dispatchNotification({
            content: "Please Select a Table!",
            type: "warning",
          });
        }
      else {
        dispatchNotification({
          content: "Please choose items!",
          type: "warning",
        });
      }
    },
    getDataTablesDifferent() {
      dataService
        .get("get_tables_different", `key=${this.$route.query?.name}`)
        .then((res) => {
          this.dataTablesDifferent = res.data.message;
        });
    },
    getDataTablesOpen() {
      dataService.get("get_tables_open").then((res) => {
        this.dataTablesOpen = res.data.message;
      });
    },
    getDataTable() {
      if (this.$route.query?.name)
        dataService
          .get("get_table", `key=${this.$route.query?.name}`)
          .then((res) => {
            let table = res.data.message.data
            this.setDataTable(table);
            if (!table) this.$router.replace(Tr.i18nRoute({ name: "table" }));
            if (this.$route.query?.sales_invoice) this.getDataSalesInvoice(`key=${this.$route.query?.sales_invoice}`);
            else if (table.sales_invoice) this.queryData()
          }
          );
    },
    // Take note
    postDataTakeNote() {
      this.loading = true;
      dataService
        .post("create_take_note", { description: this.takeNote })
        .then((res) => {
          this.selectedTakeNote.push(res.data.message);
          this.takeNote = "";
          this.isAddTakeNote = false;
          this.loading = false;
          this.getDataTakeNote();
        });
    },
    getDataTakeNote() {
      dataService.get("get_take_note").then((res) => {
        this.dataTakeNote = res.data.message;
      });
    },

    deleteDataTakeNote() {
      dataService
        .delete("delete_take_note", { take_notes: this.selectedTakeNote })
        .then((res) => {
          this.selectedTakeNote = [];
          this.getDataTakeNote();
        });
    },
    //Reason 
    postDataReason() {
      this.loading = true;
      dataService
        .post("create_reason", { description: this.reason })
        .then((res) => {
          this.selectedReason = res.data.message
          this.reason = "";
          this.isAddReason = false;
          this.loading = false;
          this.getDataReason();
        });
    },
    getDataReason() {
      dataService.get("get_reason").then((res) => {
        this.dataReason = res.data.message;
      });
    },
    deleteDataReason() {
      dataService
        .delete("delete_reason", { reason: this.selectedReason })
        .then((res) => {
          this.selectedReason = null;
          this.getDataReason();
        });
    },
    ...mapActions("restaurant", {
      clearCart: "clearCart",
      setCartItem: "setCartItem",
      setDataTable: "setDataTable",
      clearCartItem: "clearCartItem",
      addItemToCart: "addItemToCart",
      addItemRemove: "addItemRemove",
      setNumberCustomer: "setNumberCustomer",
      removeItemFromCart: "removeItemFromCart",
      setOptionByCartItem: "setOptionByCartItem",
      getDataSalesInvoice: "getDataSalesInvoice",
      incrementqtyByCartItem: "incrementqtyByCartItem",
      decrementqtyByCartItem: "decrementqtyByCartItem",
    }),
    ...mapActions("items_restaurant", {
      setItems: "setItems",
      setItemEmpty: "setItemEmpty",
      setItemsSearch: "setItemsSearch",
    }),
    handleSearch() {
      this.page = 1;
    },
    handleSearchClear() {
      this.searchFilter = "";
    },
    getDataMenu() {
      dataService.get("get_menus").then((res) => {
        this.menu = [...this.menu, ...res.data.message];
        this.selectMenu = this.menu[0].name;
      });
    },
    handleSelectMenu(item, index) {
      const nav = document.querySelector('nav');
      const link = document.querySelectorAll('nav a')[index];

      const scrollPosition = link.offsetLeft - (nav.offsetWidth - link.offsetWidth) / 2;

      nav.scroll({
        left: scrollPosition,
        behavior: 'smooth',
      });
      if (!this.loadItem) {
        this.selectMenu = item.name;
        this.page = this.getItems?.page ? this.getItems?.page : 1;
        this.query = this.getItems?.query ? this.getItems?.query : "";
      }
      if (item.child?.length > 0) {
        this.menuChild = item
      }
    },
    handleCloseMenuChild() {
      if (this.scrollTop > 0) this.page = 0;
      else this.page = 1;
      this.menuChild = { child: [] };
      this.selectMenu = "";
    },
    handleShowKeypad(key) {
      this.keyKeypad = key;
      this.showKeypad = true;
    },
    handleKeypadDialogOk(value, key) {
      if (value != 0)
        if (key == "qty") {
          this.qty = value;
        }
      this.showKeypad = false;
    },
    handleRemoveCartFromOption() {
      this.removeItemFromCart(this.selectItemCart);
      this.isModalOpenOption = !this.isModalOpenOption;
      this.showKeypad = false;
    },
    handleSaveOption() {
      this.loading = true;
      if (this.qty != 0 && this.qty != "") {

        this.setOptionByCartItem({
          ...this.selectItemCart,
          qty: this.qty,
          discount:
            this.discountAmount === 0
              ? this.selectedDiscount.value
              : (this.discountAmount / this.selectItemCart?.price_exchange) *
              100,
          take_note:
            this.selectedTakeNote.length == 0
              ? ""
              : this.selectedTakeNote
                ?.map((item) => item.description)
                .join(", "),
        });
        this.isModalOpenOption = !this.isModalOpenOption;
        this.selectedDiscount = { value: 0 };
        this.discountUSD = 0;
        this.discountKHR = 0;
        this.showKeypad = false;
        this.loading = false;
        this.switchDis = false;
      } else {
        dispatchNotification({
          content: "Quantity must be at least 1!",
          type: "warning",
        });
        this.loading = false;
      }
    },
    handleOpenOption(item) {
      if (!item.parent) {
        this.selectItemCart = item;
        this.qty = item.qty;
        this.selectedDiscount = { value: item.discount };
        this.selectedTakeNote =
          item.take_note != ""
            ? item.take_note?.split(", ").map((description, index) => ({
              name: description.trim(),
              description: description.trim(),
            }))
            : [];
        this.isModalOpenOption = true;
      }
    },
    handleCloseOption() {
      this.discountUSD = 0;
      this.discountKHR = 0;
      this.isModalOpenOption = false;
      this.showKeypad = false;
      this.switchDis = false;
    },
    handleKeypadOk(value) {
      if (value != 0) {
        this.setNumberCustomer(Number(value));
        if (this.itemBeforeGuests != null) {
          this.addItemToCart(this.itemBeforeGuests);
          this.itemBeforeGuests = null;
        }
        this.isModalOpenGuests = false;
      } else
        dispatchNotification({
          content: "Guests must be at least 1!",
          type: "warning",
        });
    },
    handleOpenTable() {
      this.selectedTableOpen = this.dataTable;
      this.isModalOpenTable = true;
    },
    handleSaveTable() {
      this.setDataTable(this.selectedTableOpen);
      let sales_invoice = this.$route.query?.sales_invoice;
      if (
        sales_invoice != undefined &&
        sales_invoice != "" &&
        this.selectedTableOpen != null
      )
        this.saveCartItem("confirm");
      this.isModalOpenTable = false;
    },
  },
};
</script>