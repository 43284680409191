<template>
  <TransitionRoot as="template" :show="isDrawerOpen">
    <Dialog as="div" class="relative z-20" @click="isDrawerClose">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 flex max-w-full ${placement}-0" :class="placement == 'left'
              ? `pr-10 ${placement}-0`
              : `pl-10 ${placement}-0`
            ">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500"
              :enter-from="valuePlacement" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500"
              leave-from="translate-x-0" :leave-to="valuePlacement">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-xs">
                <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0"
                  enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                  <div>
                    <div
                      class="absolute left-0 top-0 -ml-8 pr-2 pt-4 h-screen text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      @click.stop="isDrawerClose">
                      <span class="absolute -inset-2.5" />
                      <span class="sr-only">Close panel</span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>
                    <div class="absolute right-0 top-0 -mr-8 flex pl-2 pt-4">
                      <button type="button"
                        class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        @click="isDrawerClose">
                        <span class="absolute -inset-2.5" />
                        <span class="sr-only">Close panel</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </TransitionChild>
                <div class="h-full bg-white dark:bg-slate-800 py-6 shadow-xl ">
                  <slot></slot>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
export default {
  props: {
    isDrawerOpen: {
      type: Boolean,
      rerequired: true,
    },
    isDrawerClose: {
      type: Function,
      rerequired: true,
    },
    placement: {
      type: String,
      rerequired: false,
      default: "right",
    },
  },
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },
  computed: {
    valuePlacement() {
      let value;
      switch (this.placement) {
        case "right":
          value = "translate-x-full";
          break;
        case "left":
          value = "-translate-x-full";
          break;

        default:
          value = "translate-x-full";
          break;
      }
      return value;
    },
  },
};
</script>
