export default class Setting {
  constructor(
    name,
    custom_display_name,
    is_transaction,
    warehouse,
    custom_footer_invoice1,
    custom_footer_invoice2,
    custom_footer_invoice3,
    customer,
    custom_logo,
    custom_tax_types,
    custom_vat_tin,
    custom_discounts,
    custom_business_types,
    custom_guest_count,
    custom_start_number,
    custom_end_number,
    start_reciept_no,
    start_invoice_no,
    customer_groups,
    email_id,
    phone,
    address_line1,
    city,
    country,
    custom_qr_code,
    custom_use_kds,
    custom_use_printer,
    exchange_rate,
    custom_allow_user_to_edit_additional_discount,
    custom_use_percentage_discount,
    custom_max_discount_percentage_allowed,
    custom_allow_user_to_edit_item_discount
  ) {
    this.name = name;
    this.custom_display_name = custom_display_name;
    this.is_transaction = is_transaction;
    this.warehouse = warehouse;
    this.custom_footer_invoice1 = custom_footer_invoice1;
    this.custom_footer_invoice2 = custom_footer_invoice2;
    this.custom_footer_invoice3 = custom_footer_invoice3;
    this.customer = customer;
    this.custom_logo = custom_logo;
    this.custom_tax_types = custom_tax_types;
    this.custom_vat_tin = custom_vat_tin;
    this.custom_discounts = custom_discounts;
    this.custom_business_types = custom_business_types;
    this.custom_guest_count = custom_guest_count;
    this.custom_start_number = custom_start_number;
    this.custom_end_number = custom_end_number;
    this.start_reciept_no = start_reciept_no;
    this.start_invoice_no = start_invoice_no;
    this.customer_groups = customer_groups;
    this.email_id = email_id;
    this.phone = phone;
    this.address_line1 = address_line1;
    this.city = city;
    this.country = country;
    this.custom_qr_code = custom_qr_code;
    this.custom_use_kds = custom_use_kds;
    this.custom_use_printer = custom_use_printer;
    this.exchange_rate = exchange_rate;
    this.custom_allow_user_to_edit_additional_discount = custom_allow_user_to_edit_additional_discount;
    this.custom_use_percentage_discount = custom_use_percentage_discount;
    this.custom_max_discount_percentage_allowed = custom_max_discount_percentage_allowed;
    this.custom_allow_user_to_edit_item_discount = custom_allow_user_to_edit_item_discount;
  }
}
