<template>
  <!-- <div>
    <v-card
      class="selection mx-auto grey lighten-5"
      style="max-height: 80vh; height: 80vh"
    >
      <v-card-title>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="6">
            <span class="text-h6 primary--text">{{ __('Coupons') }}</span>
          </v-col>
          <v-col cols="4">
            <v-text-field
              dense
              outlined
              color="primary"
              :label="frappe._('Coupon')"
              background-color="white"
              hide-details
              v-model="newCoupon"
              class="mr-4"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              class="pa-1"
              color="success"
              dark
              @click="add_coupon(newCoupon)"
              >{{ __('add') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <div class="my-0 py-0 overflow-y-auto" style="max-height: 75vh">
        <template @mouseover="style = 'cursor: pointer'">
          <v-data-table
            :headers="items_headers"
            :items="dataCoupons"
            :single-expand="singleExpand"
            item-key="coupon"
            class="elevation-1"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:item.applied="{ item }">
              <v-simple-checkbox
                v-model="item.applied"
                disabled
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </template>
      </div>
    </v-card>

    <v-card
      flat
      style="max-height: 11vh; height: 11vh"
      class="cards mb-0 mt-3 py-0"
    >
      <v-row align="start" no-gutters>
        <v-col cols="12">
          <v-btn
            block
            class="pa-1"
            large
            color="warning"
            dark
            @click="back_to_invoice"
            >{{ __('Back') }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </div> -->
  <div class="w-full space-y-4">
    <div class="relative w-full">
      <input
        type="text"
        v-model="newCoupon"
        ref="newCoupon"
        class="w-full px-9 py-[7px] border dark:text-gray-300 bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparente"
        placeholder="Search code coupon..."
      />
      <div class="absolute py-[9px] px-3 top-0 left-0 text-gray-400">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </div>
      <div
        v-if="newCoupon != ''"
        @click="newCoupon = ''"
        class="absolute right-3 top-2.5 text-gray-500 dark:text-gray-400 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-5 h-5"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="relative">
      <SpinComponent :show="loading" />
      <div
        id="infinite-list"
        class="relative mt-2 overflow-auto h-[calc(100vh_-_139px)] sm:h-[calc(100vh_-_167px)] lg:h-[calc(100vh_-_209px)]"
      >
        <div class="w-full">
          <TableComponent :columns="columns" :items="renderItems(dataCoupons)">
            <template #bodyCell="{ column, record }"> </template>
          </TableComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    key: "item_name",
    label: "Name",
    dataIndex: "item_name",
    sort: true,
  },
  {
    key: "custom_item_code",
    label: "Item Code",
    dataIndex: "custom_item_code",
    sort: true,
  },
  {
    key: "rate",
    label: "Rate",
    alignHeader: "end",
    align: "center",
    sort: true,
  },
  {
    key: "actual_qty",
    label: "Available QTY",
    alignHeader: "end",
    align: "center",
    sort: true,
  },
  {
    key: "stock_uom",
    label: "UOM",
    dataIndex: "stock_uom",
    alignHeader: "end",
    align: "center",
    sort: true,
  },
];
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import TableComponent from "@/components/table/TableComponent.vue";
import { renderItems } from "@/utils/globleFunction";
import InputComponent from "@/components/input/InputComponent.vue";
import { baseURL } from "@/utils/baseURL";

export default {
  components: {
    SpinComponent,
    TableComponent,
    InputComponent,
  },
  data: () => ({
    columns,
    renderItems,
    loading: false,
    pos_profile: "",
    customer: "",
    dataCoupons: [],
    newCoupon: null,
    itemsPerPage: 1000,
    singleExpand: true,
  }),

  computed: {
    couponsCount() {
      return this.dataCoupons.length;
    },
    appliedCouponsCount() {
      return this.dataCoupons.filter((el) => !!el.applied).length;
    },
  },

  methods: {
    back_to_invoice() {
      this.emitter.emit("show_coupons", "false");
    },
    add_coupon(newCoupon) {
      if (!this.customer || !newCoupon) return;
      const exist = this.dataCoupons.find((el) => el.coupon_code == newCoupon);
      if (exist) {
        this.emitter.emit("show_mesage", {
          text: __("This coupon already used !"),
          color: "error",
        });
        return;
      }
      const vm = this;
      frappe.call({
        method: "posawesome.posawesome.api.posapp.get_pos_coupon",
        args: {
          coupon: newCoupon,
          customer: vm.customer,
          company: vm.pos_profile.company,
        },
        callback: function (r) {
          if (r.message) {
            const res = r.message;
            if (res.msg != "Apply" || !res.coupon) {
              this.emitter.emit("show_mesage", {
                text: res.msg,
                color: "error",
              });
            } else {
              vm.newCoupon = null;
              const coupon = res.coupon;
              vm.dataCoupons.push({
                coupon: coupon.name,
                coupon_code: coupon.coupon_code,
                type: coupon.coupon_type,
                applied: 0,
                pos_offer: coupon.pos_offer,
                customer: coupon.customer || vm.customer,
              });
            }
          }
        },
      });
    },
    setActiveGiftCoupons() {
      if (!this.customer) return;
      const vm = this;
      frappe.call({
        method: "posawesome.posawesome.api.posapp.get_active_gift_coupons",
        args: {
          customer: vm.customer,
          company: vm.pos_profile.company,
        },
        callback: function (r) {
          if (r.message) {
            const coupons = r.message;
            coupons.forEach((coupon_code) => {
              vm.add_coupon(coupon_code);
            });
          }
        },
      });
    },

    updatePosCoupons(offers) {
      this.dataCoupons.forEach((coupon) => {
        const offer = offers.find(
          (el) => el.offer_applied && el.coupon == coupon.coupon
        );
        if (offer) {
          coupon.applied = 1;
        } else {
          coupon.applied = 0;
        }
      });
    },

    removeCoupon(reomove_list) {
      this.dataCoupons = this.dataCoupons.filter(
        (coupon) => !reomove_list.includes(coupon.coupon)
      );
    },
    updateInvoice() {
      this.emitter.emit("update_invoice_coupons", this.dataCoupons);
    },
    updateCounters() {
      this.emitter.emit("update_coupons_counters", {
        couponsCount: this.couponsCount,
        appliedCouponsCount: this.appliedCouponsCount,
      });
    },
  },

  watch: {
    dataCoupons: {
      deep: true,
      handler() {
        this.updateInvoice();
        this.updateCounters();
      },
    },
  },

  created: function () {
    this.$nextTick(function () {
      this.emitter.on("register_pos_profile", (data) => {
        this.pos_profile = data.pos_profile;
      });
    });
    this.emitter.on("update_customer", (customer) => {
      if (this.customer != customer) {
        const to_remove = [];
        this.dataCoupons.forEach((el) => {
          if (el.type == "Promotional") {
            el.customer = customer;
          } else {
            to_remove.push(el.coupon);
          }
        });
        this.customer = customer;
        if (to_remove.length) {
          this.removeCoupon(to_remove);
        }
      }
      this.setActiveGiftCoupons();
    });
    this.emitter.on("update_pos_coupons", (data) => {
      this.updatePosCoupons(data);
    });
    this.emitter.on("set_pos_coupons", (data) => {
      this.dataCoupons = data;
    });
  },
};
</script>
