<template>
  <form class="px-5" name="form" @submit.prevent="handleSaveCustomer">
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1
          class="text-sm sm:text-xl font-semibold  dark:text-gray-100"
        >
          New Customer
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center  dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'customers' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium  dark:text-gray-400 dark:hover:text-white"
                  >Customers</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'create-customer' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium  dark:text-gray-400 dark:hover:text-white"
                  >New Customer</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        type="submit"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          class="h-5 w-5 mr-1"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
          />
          <polyline points="17 21 17 13 7 13 7 21" />
          <polyline points="7 3 7 8 15 8" />
        </svg>
        Save
      </button>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-6 md:grid-cols-3 gap-2 sm:gap-6">
      <div class="hidden sm:block col-span-1" />
      <div
        class="sm:col-span-4 md:col-span-1 grid grid-cols-1 gap-2 sm:gap-6 w-full"
      >
        <div>
          <label
            for="customer_name"
            class="block text-sm font-medium leading-6 text-gray-900  dark:text-gray-100"
            >Customer Name <span class="text-red-400">*</span></label
          >
          <div class="mt-1">
            <input
              id="customer_name"
              type="text"
              v-model="dataForm.customer_name"
              required
              class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600  rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
            />
          </div>
        </div>
        <div>
          <label
            for="customer_group"
            class="block text-sm font-medium leading-6 text-gray-900  dark:text-gray-100"
            >Customer Group <span class="text-red-400">*</span>
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <multiselect
              id="customer_group"
              v-model="dataForm.customer_group"
              placeholder=""
              label="name"
              track-by="name"
              :options="dataCustomerGroup"
              :allow-empty="false"
              deselect-label="Can't remove this value"
            />
          </div>
        </div>
        <div>
          <label
            for="mobile_number"
            class="block text-sm font-medium leading-6 text-gray-900  dark:text-gray-100"
            >Phone Number</label
          >
          <div class="mt-1">
            <input
              id="mobile_number"
              type="tel"
              v-model="dataForm.mobile_no"
              class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600  rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
            />
          </div>
        </div>
        <div>
          <div class="flex space-x-2">
            <input
              id="disabled"
              name="disabled"
              type="checkbox"
              v-model="dataForm.disabled"
              class="accent-secondary"
            />
            <label
              for="disabled"
              class="text-sm font-medium text-gray-900  dark:text-gray-100"
              >Disabled</label
            >
          </div>
        </div>
        <div class="flex items-center space-x-2">
          <Switch
            v-model="isAddress"
            :class="isAddress ? 'bg-teal-700' : 'bg-teal-900'"
            class="relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
          >
            <span class="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              :class="isAddress ? 'translate-x-4' : 'translate-x-0'"
              class="pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
            />
          </Switch>
          <span
            class="block text-sm font-medium leading-6 text-gray-900  dark:text-gray-100"
            >Do you want to add address?</span
          >
        </div>
        <div v-if="isAddress" class="grid grid-cols-1 gap-2 sm:gap-6">
          <div>
            <label
              for="address_line1"
              class="block text-sm font-medium leading-6 text-gray-900  dark:text-gray-100"
              >Address <span class="text-red-400">*</span></label
            >
            <div class="mt-1">
              <input
                id="address_line1"
                type="text"
                v-model="dataForm.address_line1"
                required
                class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600  rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
              />
            </div>
          </div>
          <div>
            <label
              for="city"
              class="block text-sm font-medium leading-6 text-gray-900  dark:text-gray-100"
              >City <span class="text-red-400">*</span></label
            >
            <div class="mt-1">
              <input
                id="city"
                type="text"
                v-model="dataForm.city"
                required
                class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600  rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
              />
            </div>
          </div>
          <div>
            <label
              for="country"
              class="block text-sm font-medium leading-6 text-gray-900  dark:text-gray-100"
              >Country <span class="text-red-400">*</span></label
            >
            <div class="mt-1">
              <input
                id="country"
                type="text"
                v-model="dataForm.country"
                required
                class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600  rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="hidden sm:block col-span-1" />
    </div>
  </form>
  <SpinComponent :show="loading" />
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import Customer from "@/models/customer";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import UploadImageComponent from "@/components/upload/UploadImageComponent.vue";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import { Switch } from "@headlessui/vue";
import { addRequired } from "@/utils/globleFunction";

export default {
  name: "Create Customer",
  components: {
    SpinComponent,
    ModalComponent,
    UploadImageComponent,
    InputCurrencyComponent,
    Switch,
  },
  data() {
    return {
      Tr,
      dataForm: new Customer("", null, "", "", "", "", false),
      loading: false,
      dataCustomerGroup: [],
      isAddress: false,
    };
  },
  created() {
    this.getCustomerGroup();
  },
  watch: {
    "dataForm.customer_group"(newData) {
      if (newData) addRequired("customer_group", false);
      else addRequired("customer_group");
    },
  },
  mounted() {
    addRequired("customer_group");
  },
  methods: {
    async handleSaveCustomer() {
      this.loading = true;
      await dataService
        .post("create_customer", {
          ...this.dataForm,
          customer_group: this.dataForm.customer_group.name,
        })
        .then((res) => {
          dispatchNotification({
            content: "Create Customer successfully!",
            type: "success",
          });
          this.loading = false;
          this.$router.go(-1);
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.loading = false;
        });
    },
    getCustomerGroup() {
      dataService.get("get_customer_group").then((res) => {
        this.dataCustomerGroup = res.data.message;
        this.dataForm.customer_group = this.dataCustomerGroup.find(
          (item) => item.old_parent == ""
        );
      });
    },
  },
};
</script>
<style>
.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}
</style>
