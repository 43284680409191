export default {
  en: {
    currency: {
      style: "currency",
      currency: "USD",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  kh: {
    currency: {
      style: "currency",
      currency: "KHR",
    },

    decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
};
