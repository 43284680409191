<template>
  <form class="px-5" name="form" @submit.prevent="handleSaveUser">
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
          New User
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'user' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >Users</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'create-user' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >New User</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        type="submit"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          class="h-5 w-5 mr-1"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
          />
          <polyline points="17 21 17 13 7 13 7 21" />
          <polyline points="7 3 7 8 15 8" />
        </svg>
        Save
      </button>
    </div>
    <div class="space-y-4">
      <AccordionComponet :title="`<h1 class='uppercase'>Basic Info</h1>`">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-y-3 sm:gap-6 items-start">
          <div class="col-span-1">
            <label
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Profile</label
            >
            <UploadImageComponent
              :dataImage="(e) => (dataForm.user_image = e)"
            />
          </div>
          <div class="col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-6">
            <InputComponent
              id="first_name"
              label="First Name"
              v-model="dataForm.first_name"
              required
            />
            <InputComponent
              id="last_name"
              label="Last Name"
              v-model="dataForm.last_name"
            />
            <div>
              <label
                for="full_name"
                class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >Full Name</label
              >
              <div>
                <input
                  id="full_name"
                  type="text"
                  :value="`${dataForm.first_name ? dataForm.first_name : ''} ${
                    dataForm.last_name ? dataForm.last_name : ''
                  }`"
                  readonly
                  class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                />
              </div>
            </div>

            <InputComponent
              id="email"
              type="email"
              label="Email"
              v-model="dataForm.email"
              required
            />
            <InputComponent
              id="username"
              label="Username"
              v-model="dataForm.username"
            />
            <div>
              <label
                for="role_profile_name"
                class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >Role Profile <span class="text-red-400">*</span>
              </label>
              <div>
                <multiselect
                  id="role_profile_name"
                  v-model="dataForm.role_profile_name"
                  placeholder=""
                  :options="dataRoleProfile"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                />
              </div>
            </div>
            <div>
              <div class="flex space-x-2">
                <input
                  id="enabled"
                  name="enabled"
                  type="checkbox"
                  v-model="dataForm.enabled"
                  class="accent-secondary"
                />
                <label
                  for="enabled"
                  class="text-sm font-medium text-gray-900 dark:text-gray-100"
                  >Enabled</label
                >
              </div>
            </div>
          </div>
        </div>
      </AccordionComponet>
      <AccordionComponet :title="`<h1 class='uppercase'>Password</h1>`">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-y-3 sm:gap-6">
          <div />
          <div class="grid grid-cols-1 gap-y-3 sm:gap-6">
            <div>
              <div class="relative">
                <div v-if="pwdStrength" class="absolute top-1 right-0 w-1/4">
                  <div class="flex items-center space-x-2">
                    <span
                      class="text-[13px]"
                      :style="`color: ${pwdStrength?.color}`"
                      >{{ pwdStrength?.text }}</span
                    >
                    <div
                      class="bg-gray-100 dark:bg-slate-600 relative h-1 w-full rounded-2xl"
                    >
                      <div
                        class="absolute bg-black top-0 left-0 h-full w-1/2 rounded-2xl"
                        :style="`width: ${pwdStrength?.percent}%; background-color: ${pwdStrength?.color}`"
                      ></div>
                    </div>
                  </div>
                </div>
                <InputComponent
                  id="password"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  v-model="password"
                  required
                />

                <div class="absolute bottom-3 right-4 cursor-pointer">
                  <svg
                    class="h-4 text-gray-700 dark:text-gray-100"
                    fill="none"
                    @click="showPassword = !showPassword"
                    :class="!showPassword ? 'block' : 'hidden'"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                    ></path>
                  </svg>
                  <svg
                    class="h-4 text-gray-700 dark:text-gray-100"
                    fill="none"
                    @click="showPassword = !showPassword"
                    :class="showPassword ? 'block' : 'hidden'"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                    ></path>
                  </svg>
                </div>
              </div>
              <span
                v-if="pwdStrength && pwdStrength?.score < 4"
                class="text-[14px] text-gray-500 dark:text-gray-400"
                >Include symbols, numbers and capital letters in the
                password</span
              >
            </div>
            <div class="relative">
              <InputComponent
                id="confirm_password"
                :type="showConfirmPassword ? 'text' : 'password'"
                label="Confirm Password"
                v-model="dataForm.confirm_password"
                required
              />
              <div class="absolute bottom-3 right-4 cursor-pointer">
                <svg
                  class="h-4 text-gray-700 dark:text-gray-100"
                  fill="none"
                  @click="showConfirmPassword = !showConfirmPassword"
                  :class="!showConfirmPassword ? 'block' : 'hidden'"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                  ></path>
                </svg>
                <svg
                  class="h-4 text-gray-700 dark:text-gray-100"
                  fill="none"
                  @click="showConfirmPassword = !showConfirmPassword"
                  :class="showConfirmPassword ? 'block' : 'hidden'"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="currentColor"
                    d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div />
        </div>
      </AccordionComponet>
    </div>
  </form>
  <SpinComponent :show="loading" />
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import User from "@/models/user";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import AccordionComponet from "@/components/accordion/AccordionComponet.vue";
import UploadImageComponent from "@/components/upload/UploadImageComponent.vue";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import { addRequired, passwordStrength } from "@/utils/globleFunction";
import InputComponent from "@/components/input/InputComponent.vue";
import _ from "lodash";

export default {
  name: "Create User",
  components: {
    SpinComponent,
    ModalComponent,
    UploadImageComponent,
    InputCurrencyComponent,
    AccordionComponet,
    InputComponent,
  },
  data() {
    return {
      Tr,
      dataForm: new User("", "", "", "", "", "", "", "", true),
      loading: false,
      dataRoleProfile: [],
      showPassword: false,
      showConfirmPassword: false,
      pwdStrength: null,
    };
  },
  created() {
    this.getRoleProfile();
  },
  watch: {
    "dataForm.role_profile_name"(newData) {
      if (newData) addRequired("role_profile_name", false);
      else addRequired("role_profile_name");
    },
  },
  mounted() {
    addRequired("role_profile_name");
  },
  computed: {
    password: {
      get() {
        return this.dataForm.password;
      },
      set: _.debounce(async function (newValue) {
        this.dataForm.password = newValue;
        this.pwdStrength = await passwordStrength(newValue);
        console.log(this.pwdStrength)
      }, 100),
    },
  },
  methods: {
    async handleSaveUser() {
      this.loading = true;
      if (this.dataForm.password === this.dataForm.confirm_password) {
        let iamge = "";
        if (this.dataForm.user_image)
          iamge = await dataService
            .postFile("upload_image", this.dataForm.user_image)
            .then((res) => res.data.message);

        await dataService
          .post("create_user", {
            ...this.dataForm,
            user_image:
              typeof this.dataForm.user_image == "string"
                ? this.dataForm.user_image
                : iamge,
          })
          .then((res) => {
            dispatchNotification({
              content: "Create user successfully",
              type: "success",
            });
            this.loading = false;
            this.$router.go(-1);
          })
          .catch((err) => {
            dispatchNotification({
              content: JSON.parse(
                JSON.parse(err.response.data._server_messages)[0]
              ).message,
              type: "warning",
            });
            this.loading = false;
          });
      } else {
        dispatchNotification({
          content: "Password not match!",
          type: "warning",
        });
        this.loading = false;
      }
    },
    getRoleProfile() {
      dataService.get("get_role_profile").then((res) => {
        this.dataRoleProfile = res.data.message;
      });
    },
  },
};
</script>
<style>
.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}
</style>
