<template>
  <div>
    <ModalComponent
      title="Received"
      :isModalOpen="true"
      :onToggle="handleBack"
      :width="478"
      :freeSpaceClose="false"
    >
      <div class="dark:text-gray-200">
        We've received your account deletion request. Our team is working on it,
        and you'll be notified once it's completed. Thanks for your patience!
      </div>
      <div class="flex justify-end pt-2 lg:pt-4">
        <button
          @click="handleBack"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Back
        </button>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import ModalComponent from "@/components/modal/ModalComponent.vue";

export default {
  name: "Delete",
  components: {
    ModalComponent,
  },
  data() {
    return {
      Tr,
    };
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>
