export default class Supplier {
  constructor(
    supplier_name,
    supplier_group,
    supplier_type,
    mobile_no,
    address_line1,  
    city,
    country,
    disabled
  ) {
    this.supplier_name = supplier_name;
    this.supplier_group = supplier_group;
    this.supplier_type = supplier_type;
    this.mobile_no = mobile_no;
    this.address_line1 = address_line1;
    this.city = city;
    this.country = country;
    this.disabled = disabled;
  }
}
