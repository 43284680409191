<template>
    <div class="animated-counter">
        <span class="counter" :data-count="targetValue">${{ $n(animatedValue, "decimal", "en") }}</span>
    </div>
</template>
  
<script>
export default {
    props: {
        startValue: {
            type: Number,
            required: true,
        },
        endValue: {
            type: Number,
            required: true,
        },
        duration: {
            type: Number,
            default: 2000,
        },
    },
    data() {
        return {
            targetValue: this.endValue,
            animatedValue: this.startValue,
        };
    },
    mounted() {
        this.startAnimation();
    },
    methods: {
        startAnimation() {
            let startTime;
            const animate = (timestamp) => {
                if (!startTime) {
                    startTime = timestamp;
                }

                const progress = timestamp - startTime;
                if (progress < this.duration) {
                    this.animatedValue = this.easeInOutQuad(
                        progress,
                        this.startValue,
                        this.endValue - this.startValue,
                        this.duration
                    );
                    requestAnimationFrame(animate);
                } else {
                    this.animatedValue = this.endValue;
                }
            };

            requestAnimationFrame(animate);
        },
        easeInOutQuad(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        },
    },
};
</script>
  
<style scoped>
.animated-counter {
    text-align: center;
}

.counter {
    animation: countup-animation 2s ease-in-out;
}

@keyframes countup-animation {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
  