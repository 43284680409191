<template>
  <form class="px-2 md:px-5" name="form" @submit.prevent="handleSaveSetting">
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
          Company Settings
        </h1>
      </div>
      <button
        type="submit"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          class="h-5 w-5 mr-1.5"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
          />
          <polyline points="17 21 17 13 7 13 7 21" />
          <polyline points="7 3 7 8 15 8" />
        </svg>
        Save
      </button>
    </div>
    <div
      class="w-full flex flex-nowrap space-x-0 md:space-x-4 h-[calc(100vh_-_140px)]"
    >
      <div class="w-96 uppercase dark:text-gray-100 hidden md:block">
        <div
          v-for="item in dataMenus"
          :key="item.id"
          @click="scrollToElement(item.id)"
          class="p-4 hover:bg-gray-200 dark:hover:bg-slate-900/90 cursor-pointer"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="w-full space-y-4 overflow-y-auto h-[calc(100vh_-_140px)]">
        <AccordionComponet
          id="general_info"
          title="
        <h1 class='uppercase dark:text-gray-100'>General Info</h1>
        "
        >
          <div
            class="mt-4 grid grid-cols-1 md:grid-cols-3 gap-y-2 md:gap-6 items-start"
          >
            <div class="col-span-1">
              <label
                class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >Logo</label
              >
              <UploadImageComponent
                :src="dataForm.custom_logo"
                :dataImage="(e) => (dataForm.custom_logo = e)"
              />
            </div>

            <div
              class="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-6 items-start"
            >
              <InputComponent
                id="name"
                label="POS Profile Name"
                v-model="dataForm.name"
                readonly
              />
              <InputComponent
                id="custom_display_name"
                label="Display Name"
                v-model="dataForm.custom_display_name"
                required
              />
              <div>
                <label
                  for="warehouse"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >Warehouse <span class="text-red-400">*</span></label
                >
                <div>
                  <multiselect
                    id="warehouse"
                    type="text"
                    v-model="dataForm.warehouse"
                    placeholder=""
                    :options="dataWarehouses"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                  />
                </div>
              </div>
              <div>
                <label
                  for="customer"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >Default Customer <span class="text-red-400">*</span></label
                >
                <div>
                  <multiselect
                    id="customer"
                    v-model="dataForm.customer"
                    placeholder=""
                    :options="dataCustomers"
                    label="customer_name"
                    track-by="name"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                  />
                </div>
              </div>
              <InputComponent
                id="start_invoice_no"
                label="Prefix Invoice No"
                v-model="dataForm.start_invoice_no"
                required
              />
              <div v-if="!dataForm.is_transaction && getBC == 'Restaurant'">
                <label
                  for="custom_business_types"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >Business Types <span class="text-red-400">*</span>
                </label>
                <div>
                  <multiselect
                    id="custom_business_types"
                    v-model="dataForm.custom_business_types"
                    :searchable="false"
                    placeholder=""
                    :options="['Fine Dining', 'Fast Food']"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                  />
                </div>
              </div>
              <InputComponent
                v-else-if="getBC == 'Restaurant'"
                id="custom_business_types"
                label="Business Types"
                v-model="dataForm.custom_business_types"
                readonly
              />
              <div
                v-if="
                  dataForm.custom_business_types === 'Fast Food' &&
                  getBC == 'Restaurant'
                "
              >
                <label
                  for="waitig_number"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >Wating Number <span class="text-red-400">*</span></label
                >
                <div class="grid grid-cols-11 gap-2 items-center">
                  <input
                    id="waitig_number"
                    type="number"
                    v-model="dataForm.custom_start_number"
                    required
                    class="w-full col-span-5 px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:border-primary dark:focus:border-primary focus:ring-transparent"
                  />
                  <div class="col-span-1 text-center dark:text-gray-100">
                    To
                  </div>
                  <input
                    id="waitig_number"
                    type="number"
                    v-model="dataForm.custom_end_number"
                    required
                    class="w-full col-span-5 px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:border-primary dark:focus:border-primary focus:ring-transparent"
                  />
                </div>
              </div>
              <div
                v-if="
                  dataForm.custom_business_types !== 'Fast Food' &&
                  getBC == 'Restaurant'
                "
              >
                <label
                  for="custom_guest_count"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >Guest Count <span class="text-red-400">*</span>
                </label>
                <div>
                  <multiselect
                    id="custom_guest_count"
                    v-model="dataForm.custom_guest_count"
                    :searchable="false"
                    placeholder=""
                    :options="['Active', 'Inactive']"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                  />
                </div>
              </div>
              <div v-if="getBC == 'Restaurant'" class="space-y-2">
                <div class="mt-1 flex items-center space-x-2">
                  <input
                    id="custom_use_kds"
                    type="checkbox"
                    v-model="dataForm.custom_use_kds"
                    class="h-4 w-4 cursor-pointer accent-secondary"
                  /><label
                    for="custom_use_kds"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                    >Use KDS</label
                  >
                </div>
                <div class="mt-1 flex items-center space-x-2">
                  <input
                    id="custom_use_printer"
                    type="checkbox"
                    v-model="dataForm.custom_use_printer"
                    class="h-4 w-4 cursor-pointer accent-secondary"
                  /><label
                    for="custom_use_printer"
                    class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                    >Use Printer</label
                  >
                </div>
              </div>
              <InputComponent
                v-if="getBC != 'Restaurant'"
                id="custom_max_discount_percentage_allowed"
                type="number"
                label="Max Discount Percentage Allowed"
                v-model="dataForm.custom_max_discount_percentage_allowed"
              />
              <div
                v-if="getBC != 'Restaurant'"
                class="mt-1 flex items-center space-x-2"
              >
                <input
                  id="custom_allow_user_to_edit_additional_discount"
                  type="checkbox"
                  v-model="
                    dataForm.custom_allow_user_to_edit_additional_discount
                  "
                  class="h-4 w-4 cursor-pointer accent-secondary"
                /><label
                  for="custom_allow_user_to_edit_additional_discount"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >Allow user to edit Additional Discount</label
                >
              </div>
              <div
                v-if="
                  dataForm.custom_allow_user_to_edit_additional_discount &&
                  getBC != 'Restaurant'
                "
                class="mt-1 flex items-center space-x-2"
              >
                <input
                  id="custom_use_percentage_discount"
                  type="checkbox"
                  v-model="dataForm.custom_use_percentage_discount"
                  class="h-4 w-4 cursor-pointer accent-secondary"
                /><label
                  for="custom_use_percentage_discount"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >Use Percentage Discount</label
                >
              </div>
              <div
                v-if="getBC != 'Restaurant'"
                class="mt-1 flex items-center space-x-2"
              >
                <input
                  id="custom_allow_user_to_edit_item_discount"
                  type="checkbox"
                  v-model="dataForm.custom_allow_user_to_edit_item_discount"
                  class="h-4 w-4 cursor-pointer accent-secondary"
                /><label
                  for="custom_allow_user_to_edit_item_discount"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >Allow User to Edit Item Discount</label
                >
              </div>
            </div>
          </div>
        </AccordionComponet>
        <AccordionComponet
          id="tax_registration_number"
          title="<h1 class='uppercase dark:text-gray-100'>Tax Registration Number</h1>"
        >
          <div
            class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6 items-start"
          >
            <div v-if="!dataForm.is_transaction">
              <label
                for="custom_tax_types"
                class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >VAT Types <span class="text-red-400">*</span>
              </label>
              <div>
                <multiselect
                  id="custom_tax_types"
                  v-model="dataForm.custom_tax_types"
                  :searchable="false"
                  placeholder=""
                  :options="['Tax 10%', 'Tax Included', 'Tax Exempt']"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                />
              </div>
            </div>
            <InputComponent
              v-else
              id="custom_tax_types"
              label="VAT Types"
              v-model="dataForm.custom_tax_types"
              readonly
            />
            <InputComponent
              v-show="dataForm.custom_tax_types == 'Tax 10%'"
              id="custom_vat_tin"
              label="VAT TIN"
              v-model="dataForm.custom_vat_tin"
            />
          </div>
        </AccordionComponet>
        <AccordionComponet
          id="discount_rate"
          title="<h1 class='uppercase dark:text-gray-100'>Discount Rate</h1>"
        >
          <div class="mt-4 w-1/2">
            <div
              v-for="(field, index) in dataForm.custom_discounts"
              :key="index"
              class="flex space-x-6 items-end"
            >
              <div class="w-full">
                <label
                  :for="'custom_discounts' + index"
                  class="block text-sm font-medium leading-6 mb-1 text-gray-900 dark:text-gray-100"
                  >{{ index == 0 ? "Discounts" : "" }}
                </label>
                <div class="relative">
                  <input
                    :id="'custom_discounts' + index"
                    type="number"
                    v-model="dataForm.custom_discounts[index].discount_rate"
                    required
                    class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                  />
                  <div
                    class="pointer-events-none absolute inset-y-0 right-4 flex items-center pl-3 dark:text-gray-200"
                  >
                    %
                  </div>
                </div>
              </div>
              <button
                type="button"
                @click="dataForm.custom_discounts.splice(index, 1)"
                class="m-2 hover:text-red-500 dark:text-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
            <button
              type="button"
              @click="dataForm.custom_discounts.push({ discount_rate: 0 })"
              class="inline-flex items-center text-sm font-medium rounded-md justify-center mt-4 text-gray-800 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v12m6-6H6"
                />
              </svg>

              Add Field
            </button>
          </div>
        </AccordionComponet>
        <AccordionComponet
          id="exchange_rate"
          title="
        <h1 class='uppercase dark:text-gray-100'>Exchange Rate</h1>
        "
        >
          <div
            class="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-6 items-start"
          >
            <InputCurrencyComponent
              id="exchange_rate"
              label="Exchange Rate (1 USD = [?] KHR)"
              prefix="៛"
              v-model="dataForm.exchange_rate"
              required
            />
          </div>
        </AccordionComponet>
        <AccordionComponet
          id="address"
          title="
        <h1 class='uppercase dark:text-gray-100'>Address</h1>
        "
        >
          <div
            class="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-6 items-start"
          >
            <div class="col-span-2">
              <label
                for="address_line1"
                class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >Address <span class="text-red-400">*</span></label
              >
              <div>
                <input
                  id="address_line1"
                  type="text"
                  v-model="dataForm.address_line1"
                  required
                  class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                />
              </div>
            </div>
            <div class="col-span-1 hidden md:block"></div>
            <div class="col-span-1">
              <label
                for="city"
                class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >City <span class="text-red-400">*</span></label
              >
              <div>
                <input
                  id="city"
                  type="text"
                  v-model="dataForm.city"
                  required
                  class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                />
              </div>
            </div>
            <div class="col-span-1">
              <label
                for="country"
                class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >Country
                <span class="text-red-400">*</span>
              </label>
              <div>
                <multiselect
                  id="country"
                  v-model="dataForm.country"
                  placeholder=""
                  :options="dataCountries"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                />
              </div>
            </div>
          </div>
        </AccordionComponet>
        <AccordionComponet
          id="contact_details"
          title="<h1 class='uppercase dark:text-gray-100'>Contact Details</h1>"
        >
          <div
            class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6 items-start"
          >
            <InputComponent
              id="phone"
              type="number"
              label="Phone"
              v-model="dataForm.phone"
              required
            />
            <InputComponent
              id="email_id"
              type="email"
              label="Email"
              v-model="dataForm.email_id"
            />
          </div>
        </AccordionComponet>
        <AccordionComponet
          id="footer_invoice"
          title="<h1 class='uppercase dark:text-gray-100'>Footer Invoice</h1>"
        >
          <div
            class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6 items-start"
          >
            <div class="col-span-1 space-y-2">
              <UploadImageComponent
                :src="dataForm.custom_qr_code"
                :dataImage="(e) => (dataForm.custom_qr_code = e)"
              />

              <InputComponent
                id="custom_footer_invoice1"
                v-model="dataForm.custom_footer_invoice1"
              />
              <InputComponent
                id="custom_footer_invoice2"
                v-model="dataForm.custom_footer_invoice2"
              />
              <InputComponent
                id="custom_footer_invoice3"
                v-model="dataForm.custom_footer_invoice3"
              />
            </div>
          </div>
        </AccordionComponet>
      </div>
    </div>
  </form>
  <SpinComponent :show="loading" />
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import Setting from "@/models/setting";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import UploadImageComponent from "@/components/upload/UploadImageComponent.vue";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import { Switch } from "@headlessui/vue";
import InputComponent from "@/components/input/InputComponent.vue";
import AccordionComponet from "@/components/accordion/AccordionComponet.vue";
import { mapActions, mapGetters } from "vuex";

const dataMenus = [
  {
    id: "general_info",
    name: "General Info",
  },
  {
    id: "tax_registration_number",
    name: "Tax Registration Number",
  },
  {
    id: "discount_rate",
    name: "Discount Rate",
  },
  {
    id: "exchange_rate",
    name: "Exchange Rate",
  },
  {
    id: "address",
    name: "Address",
  },
  {
    id: "contact_details",
    name: "Contact Details",
  },
  {
    id: "footer_invoice",
    name: "Footer Invoice",
  },
];
export default {
  name: "Create Setting",
  components: {
    SpinComponent,
    ModalComponent,
    UploadImageComponent,
    InputCurrencyComponent,
    Switch,
    InputComponent,
    AccordionComponet,
  },
  data() {
    return {
      loading: false,
      dataMenus,
      dataForm: new Setting(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        [],
        "",
        "",
        1,
        100,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        false,
        null,
        false,
        false,
        0,
        false
      ),
      dataWarehouses: [],
      dataCustomers: [],
      dataCountries: [],
    };
  },
  created() {
    this.getCompanySetting();
    this.getCustomers();
    this.getCountries();
    this.getWarehouses();
  },
  computed: {
    ...mapGetters("auth", {
      getBC: "getBC",
    }),
  },
    methods: {
        ...mapActions("auth", {
      getUserProfile: "getUserProfile",
    }),
    scrollToElement(id) {
      const container = document.getElementById(id);
      container.scrollIntoView({ behavior: "smooth" });
    },
    async handleSaveSetting() {
      this.loading = true;
      if (this.dataForm.customer != "") {
        let logo = "";
        if (
          typeof this.dataForm.custom_logo != "string" &&
          this.dataForm.custom_logo
        ) {
          logo = await dataService
            .postFile("upload_image", this.dataForm.custom_logo)
            .then((res) => res.data.message);
          this.loading = false;
        }
        let qr = "";
        if (
          typeof this.dataForm.custom_qr_code != "string" &&
          this.dataForm.custom_qr_code
        ) {
          qr = await dataService
            .postFile("upload_image", this.dataForm.custom_qr_code)
            .then((res) => res.data.message);
          this.loading = false;
        }
        await dataService
          .post("save_company_setting", {
            ...this.dataForm,
            custom_logo:
              typeof this.dataForm.custom_logo == "string"
                ? this.dataForm.custom_logo
                : logo,
            custom_qr_code:
              typeof this.dataForm.custom_qr_code == "string"
                ? this.dataForm.custom_qr_code
                : qr,
          })
          .then((res) => {
            dispatchNotification({
              content: "Save setting successfully",
              type: "success",
            });
            this.getCompanySetting();
            this.loading = false;
            this.getUserProfile();
          })
          .catch((err) => {
            dispatchNotification({
              content: JSON.parse(
                JSON.parse(err.response.data._server_messages)[0]
              ).message,
              type: "warning",
            });
            this.loading = false;
          });
      } else {
        dispatchNotification({
          content: "Customer is not empty!",
          type: "warning",
        });
        this.loading = false;
      }
    },
    getCompanySetting() {
      this.loading = true;
      dataService.get("get_company_setting").then((res) => {
        let data = res.data.message;
        this.dataForm = {
          ...data,
          customer: { name: data.customer, customer_name: data.customer },
        };
        this.loading = false;
      });
    },
    getCustomers() {
      dataService.get("get_customers").then((res) => {
        this.dataCustomers = res.data.message;
      });
    },
    getWarehouses() {
      dataService.get("get_warehouses").then((res) => {
        this.dataWarehouses = res.data.message;
      });
    },
    getCountries() {
      dataService.get("get_countries").then((res) => {
        this.dataCountries = res.data.message;
      });
    },
  },
};
</script>
