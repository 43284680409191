<template>
  <form class="px-5" name="form" @submit.prevent="handleSaveOffer">
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
          {{ dataForm.name }}
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'offer' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >Offer</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'view-offer' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >{{ dataForm.name }}</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        type="submit"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          class="h-5 w-5 mr-1"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
          />
          <polyline points="17 21 17 13 7 13 7 21" />
          <polyline points="7 3 7 8 15 8" />
        </svg>
        Save
      </button>
    </div>
    <div class="overflow-y-auto h-[calc(100vh_-_150px)] space-y-6">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <InputComponent
          id="title"
          label="Title"
          v-model="dataForm.title"
          required
        />
        <div class="flex items-center space-x-2">
          <input
            id="disable"
            type="checkbox"
            v-model="dataForm.disable"
            class="h-4 w-4 cursor-pointer accent-secondary"
          /><label
            for="disable"
            class="text-sm font-medium text-gray-900 dark:text-gray-100"
            >Disable</label
          >
        </div>
        <InputComponent
          id="description"
          type="textarea"
          label="Description"
          v-model="dataForm.description"
          required
        />
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div class="space-y-6">
          <div>
            <label
              for="apply_on"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Qualifying Transaction / Item
              <span class="text-red-400">*</span></label
            >
            <div class="relative rounded-md shadow-sm">
              <multiselect
                id="apply_on"
                v-model="dataForm.apply_on"
                placeholder=""
                :allow-empty="false"
                deselect-label="Can't remove this value"
                @select="(value) => controllersForm()"
                :options="['Item Code', 'Item Group', 'Transaction']"
              />
            </div>
          </div>
          <div>
            <label
              for="offer"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Promo Type <span class="text-red-400">*</span></label
            >
            <div class="relative rounded-md shadow-sm">
              <multiselect
                id="offer"
                v-model="dataForm.offer"
                placeholder=""
                :allow-empty="false"
                deselect-label="Can't remove this value"
                :options="offer_option"
                @select="(value) => controllersForm()"
              />
            </div>
          </div>
        </div>
        <div class="space-y-6">
          <div v-if="dataForm.apply_on == 'Item Code'">
            <label
              for="item"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Apply Rule On Item Code
              <span class="text-red-400">*</span></label
            >
            <div class="relative rounded-md shadow-sm">
              <multiselect
                id="item"
                v-model="dataForm.item"
                placeholder=""
                :options="dataItemCode"
                @input="onSearchItemCode"
              />
            </div>
          </div>
          <div v-if="dataForm.apply_on == 'Item Group'">
            <label
              for="item_group"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Apply Rule On Item Group
              <span class="text-red-400">*</span></label
            >
            <div class="relative rounded-md shadow-sm">
              <multiselect
                id="item_group"
                v-model="dataForm.item_group"
                placeholder=""
                :options="dataItemGroup"
                @input="onSearchItemGroup"
              />
            </div>
          </div>
          <div v-if="dataForm.apply_on == 'Brand'">
            <label
              for="brand"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Apply Rule On Brand <span class="text-red-400">*</span></label
            >
            <div class="relative rounded-md shadow-sm">
              <multiselect
                id="brand"
                v-model="dataForm.brand"
                placeholder=""
                :options="dataBrand"
                @input="onSearchBrand"
              />
            </div>
          </div>

          <div>
            <label
              for="valid_from"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Valid From <span class="text-red-400">*</span></label
            >
            <div class="relative rounded-md shadow-sm">
              <vue-tailwind-datepicker
                v-model="dataForm.valid_from"
                :formatter="{
                  date: 'DD-MM-YYYY',
                  month: 'MMM',
                }"
                as-single
              />
            </div>
          </div>
          <div>
            <label
              for="valid_upto"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Valid Upto</label
            >
            <div class="relative rounded-md shadow-sm">
              <vue-tailwind-datepicker
                v-model="dataForm.valid_upto"
                :formatter="{
                  date: 'DD-MM-YYYY',
                  month: 'MMM',
                }"
                as-single
              />
            </div>
          </div>

          <div class="flex items-center space-x-2">
            <input
              id="coupon_based"
              type="checkbox"
              v-model="dataForm.coupon_based"
              class="h-4 w-4 cursor-pointer accent-secondary"
            /><label
              for="coupon_based"
              class="text-sm font-medium text-gray-900 dark:text-gray-100"
              >Coupon Code Based</label
            >
          </div>
          <div class="flex items-center space-x-2">
            <input
              id="auto"
              type="checkbox"
              v-model="dataForm.auto"
              class="h-4 w-4 cursor-pointer accent-secondary"
            /><label
              for="auto"
              class="text-sm font-medium text-gray-900 dark:text-gray-100"
              >Auto Apply</label
            >
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <InputComponent
          id="min_qty"
          type="number"
          label="Min Qty"
          v-model="dataForm.min_qty"
        />
        <InputCurrencyComponent
          id="min_amt"
          label="Min Amount"
          v-model="dataForm.min_amt"
          :required="dataForm.apply_on == 'Transaction'"
        />
        <InputComponent
          id="max_qty"
          type="number"
          label="Max Qty"
          v-model="dataForm.max_qty"
        />

        <InputCurrencyComponent
          id="max_amt"
          label="Max Amount"
          v-model="dataForm.max_amt"
        />
      </div>
      <div
        class="grid grid-cols-1 lg:grid-cols-2 gap-6"
        v-if="dataForm.offer === 'Give Product'"
      >
        <div class="space-y-6">
          <div>
            <label
              for="apply_type"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Apply Type <span class="text-red-400">*</span></label
            >
            <div class="relative rounded-md shadow-sm">
              <multiselect
                id="apply_type"
                v-model="dataForm.apply_type"
                placeholder=""
                :options="['Item Code', 'Item Group']"
                @select="(value) => controllersForm()"
              />
            </div>
          </div>
          <div
            v-if="
              dataForm.apply_on === 'Item Code' &&
              dataForm.offer === 'Give Product' &&
              dataForm.apply_type === 'Item Code'
            "
            class="flex items-center space-x-2"
          >
            <input
              id="replace_item"
              type="checkbox"
              v-model="dataForm.replace_item"
              class="h-4 w-4 cursor-pointer accent-secondary"
            /><label
              for="replace_item"
              class="text-sm font-medium text-gray-900 dark:text-gray-100"
              >Replace Same Item</label
            >
          </div>
          <div
            v-if="
              dataForm.apply_on === 'Item Group' &&
              dataForm.offer === 'Give Product' &&
              dataForm.apply_type === 'Item Group'
            "
            class="flex items-center space-x-2"
          >
            <input
              id="replace_cheapest_item"
              type="checkbox"
              v-model="dataForm.replace_cheapest_item"
              class="h-4 w-4 cursor-pointer accent-secondary"
            /><label
              for="replace_cheapest_item"
              class="text-sm font-medium text-gray-900 dark:text-gray-100"
              >Replace Cheapest Item</label
            >
          </div>
        </div>
        <div class="space-y-6">
          <div
            v-if="dataForm.apply_type === 'Item Code' && !dataForm.replace_item"
          >
            <label
              for="apply_item_code"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Item Code <span class="text-red-400">*</span></label
            >
            <div class="relative rounded-md shadow-sm">
              <multiselect
                id="apply_item_code"
                v-model="dataForm.apply_item_code"
                placeholder=""
                :options="dataItemCode"
                @input="onSearchItemCode"
              />
            </div>
          </div>
          <div
            v-if="
              dataForm.apply_type === 'Item Group' &&
              !dataForm.replace_cheapest_item
            "
          >
            <label
              for="apply_item_group"
              class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
              >Item Group <span class="text-red-400">*</span></label
            >
            <div class="relative rounded-md shadow-sm">
              <multiselect
                id="apply_item_group"
                v-model="dataForm.apply_item_group"
                placeholder=""
                :options="dataItemGroup"
                @input="onSearchItemGroup"
              />
            </div>
          </div>
          <InputCurrencyComponent
            v-if="
              dataForm.apply_type === 'Item Group' &&
              !dataForm.replace_cheapest_item
            "
            id="less_then"
            label="Item Rate Should Less Then"
            v-model="dataForm.less_then"
          />
        </div>
      </div>
      <div v-if="dataForm.offer === 'Give Product'">
        <InputCurrencyComponent
          id="given_qty"
          label="Given Quantity"
          v-model="dataForm.given_qty"
        />
      </div>
      <div
        v-if="dataForm.offer !== 'Loyalty Point'"
        class="grid grid-cols-1 lg:grid-cols-2 gap-6"
      >
        <div>
          <label
            for="discount_type"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Discount Type <span class="text-red-400">*</span></label
          >
          <div class="relative rounded-md shadow-sm">
            <multiselect
              id="discount_type"
              v-model="dataForm.discount_type"
              placeholder=""
              :options="discount_type_option"
            />
          </div>
        </div>
        <div class="space-y-6">
          <InputCurrencyComponent
            v-if="dataForm.discount_type === 'Rate'"
            id="Rate"
            label="Rate"
            v-model="dataForm.Rate"
            required
          />
          <InputCurrencyComponent
            v-if="dataForm.discount_type === 'Discount Amount'"
            id="discount_amount"
            label="Discount Amount"
            v-model="dataForm.discount_amount"
            required
          />
          <InputCurrencyComponent
            v-if="dataForm.discount_type === 'Discount Percentage'"
            id="discount_percentage"
            label="Discount Percentage"
            v-model="dataForm.discount_percentage"
            required
          />
        </div>
      </div>
      <div
        v-if="dataForm.offer == 'Loyalty Point'"
        class="grid grid-cols-1 lg:grid-cols-2 gap-6"
      >
        <div>
          <label
            for="loyalty_program"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Default Loyalty Program <span class="text-red-400">*</span></label
          >
          <div class="relative rounded-md shadow-sm">
            <multiselect
              id="loyalty_program"
              v-model="dataForm.loyalty_program"
              placeholder=""
              :options="[]"
            />
          </div>
        </div>
        <InputComponent
          id="loyalty_points"
          type="number"
          label="Loyalty Points"
          v-model="dataForm.loyalty_points"
        />
      </div>
    </div>
  </form>
  <SpinComponent :show="loading" />
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import Offer from "@/models/offer";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import InputComponent from "@/components/input/InputComponent.vue";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import { mapGetters } from "vuex";
import { formatDate } from "@/utils/globleFunction";

export default {
  name: "Create Offer",
  components: {
    SpinComponent,
    InputComponent,
    InputCurrencyComponent,
  },
  data() {
    return {
      Tr,
      dataForm: new Offer(
        "",
        "",
        false,
        "Item Code",
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        0,
        0,
        0,
        "",
        true,
        true,
        false,
        false,
        "",
        "",
        0,
        0,
        "",
        0,
        0,
        0,
        "",
        0
      ),
      loading: false,
      discount_type_option: [],
      offer_option: [
        "Item Price",
        "Give Product",
        "Grand Total",
        "Loyalty Point",
      ],
      dataItemCode: [],
      dataItemGroup: [],
      dataBrand: [],
    };
  },
  created() {
    this.getItemCode();
    this.getItemGroup();
    this.getDataOffer();
  },
  computed: {
    ...mapGetters("auth", {
      isUseKDS: "isUseKDS",
      isUsePrinter: "isUsePrinter",
      getFrappeVersion: "getFrappeVersion",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getDataOffer() {
      this.loading = true;
      dataService.get("get_offer", `key=${this.id}`).then((res) => {
        let data = res.data.message;
        this.dataForm = {
          ...data,
          valid_from: formatDate(data.valid_from),
          valid_upto: data.valid_upto ? formatDate(data.valid_upto) : "",
          coupon_based: Boolean(data.coupon_based),
          disable: Boolean(data.disable),
          auto: Boolean(data.auto),
          replace_item: Boolean(data.replace_item),
          replace_cheapest_item: Boolean(data.replace_cheapest_item),
        };
        this.loading = false;
      });
    },
    controllersForm() {
      if (this.dataForm.apply_on === "Transaction") {
        this.offer_option = ["Give Product", "Grand Total", "Loyalty Point"];
      } else {
        this.offer_option = [
          "Item Price",
          "Give Product",
          "Grand Total",
          "Loyalty Point",
        ];
      }
      if (this.dataForm.offer === "Grand Total") {
        this.discount_type_option = ["Discount Percentage"];
        this.dataForm.discount_type = this.discount_type_option[0];
      } else {
        this.discount_type_option = [
          "Rate",
          "Discount Percentage",
          "Discount Amount",
        ];
      }
      if (
        this.dataForm.apply_type === "Item Group" &&
        this.dataForm.offer === "Give Product" &&
        !this.dataForm.replace_item &&
        !this.dataForm.replace_cheapest_item
      ) {
        this.dataForm.auto = false;
      }
      if (
        this.dataForm.apply_on !== "Item Code" ||
        this.dataForm.offer !== "Give Product" ||
        this.dataForm.apply_type !== "Item Code"
      ) {
        this.dataForm.replace_item = false;
      }
      if (
        this.dataForm.apply_on !== "Item Group" ||
        this.dataForm.offer !== "Give Product" ||
        this.dataForm.apply_type !== "Item Group"
      ) {
        this.dataForm.replace_cheapest_item = false;
      }
    },
    handleSaveOffer() {
      this.loading = true;
      dataService
        .post("update_offer", this.dataForm)
        .then((res) => {
          dispatchNotification({
            content: "Update offer successfully",
            type: "success",
          });
          this.loading = false;
          this.$router.go(-1);
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.loading = false;
        });
    },
    onSearchItemCode(event) {
      this.getItemCode(event.target.value);
    },
    getItemCode(query = "") {
      dataService.get("search_offer_item", `query=${query}`).then((res) => {
        if (this.getFrappeVersion.split(".")[0] == "14")
          this.dataItemCode = res.data.results.map((item) => item.value);
        else this.dataItemCode = res.data.message.map((item) => item.value);
      });
    },
    onSearchItemGroup(event) {
      this.getItemGroup(event.target.value);
    },
    getItemGroup(query = "") {
      dataService
        .get("search_offer_item_group", `query=${query}`)
        .then((res) => {
          if (this.getFrappeVersion.split(".")[0] == "14")
            this.dataItemGroup = res.data.results.map((item) => item.value);
          else this.dataItemGroup = res.data.message.map((item) => item.value);
        });
    },
    onSearchBrand(event) {
      this.getBrand(event.target.value);
    },
    getBrand(query = "") {
      dataService.get("search_offer_brand", `query=${query}`).then((res) => {
        if (this.getFrappeVersion.split(".")[0] == "14")
          this.dataBrand = res.data.results.map((item) => item.value);
        else this.dataBrand = res.data.message.map((item) => item.value);
      });
    },
  },
};
</script>
<style>
.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}
</style>
