<template>
  <form class="px-5" name="form" @submit.prevent="handleSavePurchase">
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold dark:text-gray-100">
          New Purchase Invoice
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'purchase-invoice' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white ellipsis-line line-clamp-1"
                  >Purchase Invoice</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'create-purchase-invoice' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white ellipsis-line line-clamp-1"
                  >New Purchase Invoice</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        type="submit"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          class="h-5 w-5 mr-1"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
          />
          <polyline points="17 21 17 13 7 13 7 21" />
          <polyline points="7 3 7 8 15 8" />
        </svg>
        Save
      </button>
    </div>
    <div class="overflow-y-auto h-[calc(100vh_-_150px)]">
      <div class="grid grid-cols-2 sm:grid-cols-4 gap-3 sm:gap-6">
        <div class="col-span-2">
          <label
            for="supplier"
            class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Supplier <span class="text-red-400">*</span
            ><span
              class="ml-2 cursor-pointer text-secondary dark:text-primary"
              @click="isAddSupplier = true"
              >+ Add</span
            ></label
          >
          <div class="relative rounded-md shadow-sm">
            <multiselect
              id="supplier"
              v-model="dataForm.supplier"
              placeholder=""
              :options="dataSuppliers"
              label="supplier_name"
              track-by="name"
              :allow-empty="false"
              deselect-label="Can't remove this value"
              @input="onSearchSupplier"
              @select="onSelectSupplier"
              ><template v-slot:option="{ option }">
                <div class="leading-5">
                  <h3>{{ option.supplier_name }}</h3>
                  <p class="text-gray-400">{{ option.description }}</p>
                </div>
              </template></multiselect
            >
          </div>
        </div>
        <div class="col-span-2">
          <label
            for="supplier"
            class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Posting Date <span class="text-red-400">*</span></label
          >
          <div class="relative rounded-md shadow-sm">
            <vue-tailwind-datepicker
              v-model="dataForm.posting_date"
              :formatter="{
                date: 'DD-MM-YYYY HH:mm:ss',
                month: 'MMM',
              }"
              as-single
              :disabled="!dataForm.set_posting_date"
            />
          </div>
          <div class="mt-2">
            <div class="flex items-center space-x-2">
              <input
                id="set_posting_date"
                type="checkbox"
                v-model="dataForm.set_posting_date"
                class="h-4 w-4 cursor-pointer accent-secondary"
              /><label
                for="set_posting_date"
                class="text-sm font-medium text-gray-900 dark:text-gray-100"
              >
                Edit Posting Date and Time</label
              >
            </div>
          </div>
        </div>
        <div class="col-span-4 space-y-4">
          <h1 class="text-sm font-bold text-gray-900 dark:text-gray-100">
            Items
          </h1>
          <div
            v-for="(field, index) in dataForm.items"
            class="flex space-x-4 items-end"
          >
            <div
              class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 gap-3 sm:gap-6 w-full"
            >
              <div>
                <label
                  for="item_code"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >{{ index == 0 ? "Item Code" : "" }}
                  <span v-show="index == 0" class="text-red-400">*</span></label
                >
                <div class="relative">
                  <multiselect
                    id="item_code"
                    v-model="dataForm.items[index]"
                    placeholder=""
                    :options="dataItems"
                    label="item_code_label"
                    track-by="item_code"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    @input="onSearchItem"
                    @select="(value) => onSelectItem(value, index)"
                  >
                    <template v-slot:option="{ option }">
                      <div class="leading-5">
                        <h3>{{ option.item_code_label }}</h3>
                        <p class="text-gray-400">{{ option.description }}</p>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
              <InputComponent
                id="description"
                :label="index == 0 ? 'Item Name' : ''"
                v-model="dataForm.items[index].description"
                readonly
              />
              <div>
                <label
                  for="item_code"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >{{ index == 0 ? "UOM" : "" }}
                  <span v-show="index == 0" class="text-red-400">*</span></label
                >
                <div class="relative">
                  <multiselect
                    id="uom"
                    v-model="dataForm.items[index].uom"
                    placeholder=""
                    :options="dataUOMs"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                  />
                </div>
              </div>
              <InputComponent
                id="qty"
                type="Number"
                :label="index == 0 ? 'Qty' : ''"
                required
                v-model="dataForm.items[index].qty"
              />
              <InputCurrencyComponent
                id="rate"
                prefix="$"
                :label="index == 0 ? 'Rate' : ''"
                required
                v-model="dataForm.items[index].rate"
              />
              <div>
                <label
                  for="amount"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                  >{{ index == 0 ? "Amount" : "" }}
                  <span v-show="index == 0" class="text-red-400">*</span></label
                >
                <div>
                  <input
                    id="amount"
                    :value="`$ ${
                      dataForm.items[index].qty * dataForm.items[index].rate
                        ? (
                            dataForm.items[index].qty *
                            dataForm.items[index].rate
                          ).toLocaleString()
                        : 0
                    }`"
                    readonly
                    class="w-full px-3 py-[7px] dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-600 rounded-lg focus:duration-0 focus:outline-none focus:ring-transparent"
                  />
                </div>
              </div>
            </div>
            <button
              type="button"
              @click="dataForm.items.splice(index, 1)"
              class="m-2 dark:text-gray-100 hover:text-red-500 dark:hover:text-red-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>
          <div class="flex space-x-4">
            <button
              type="button"
              @click="handleShowMultiItem"
              class="inline-flex items-center text-sm font-medium rounded-md justify-center text-gray-800 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v12m6-6H6"
                />
              </svg>

              Add Multiple Row
            </button>
            <button
              type="button"
              @click="
                dataForm.items.push({
                  item_code: '',
                  uom: '',
                  qyt: '',
                  description: '',
                  rate: null,
                })
              "
              class="inline-flex items-center text-sm font-medium rounded-md justify-center text-gray-800 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v12m6-6H6"
                />
              </svg>

              Add Row
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ModalComponent
    title="Select Item"
    :isModalOpen="isSelectMulti"
    :onToggle="() => (this.isSelectMulti = false)"
    :width="678"
    :freeSpaceClose="true"
  >
    <form name="form" @submit.prevent="handleSearchItem">
      <div class="w-full">
        <InputComponent
          id="search_item"
          label="Beginning with"
          v-model="dataSearchItem"
        />
        <div class="mt-2 overflow-y-auto h-[calc(100vh_-_40vh)]">
          <div
            v-for="item in dataMultiItems"
            @click="handleSelectItem(item[0])"
            class="grid grid-cols-2 py-2 border-b cursor-pointer dark:text-gray-200 dark:border-slate-700"
          >
            <h3>{{ item[0].split(" - ").slice(-1)[0] }}</h3>
            <p>{{ item[1] }}</p>
          </div>
          <button
            v-if="dataMultiItems.length == start + 20"
            type="button"
            @click="
              () => {
                start += 20;
                getMultiItems();
              }
            "
            class="px-2 border rounded-md mt-2 dark:text-gray-200 dark:border-slate-700"
          >
            More
          </button>
        </div>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          type="submit"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Search
        </button>
      </div>
    </form>
  </ModalComponent>
  <ModalComponent
    title="Set Quantity"
    :isModalOpen="isSelectSetQty"
    :onToggle="
      () => {
        this.isSelectSetQty = false;
        qty = 1;
      }
    "
    :width="478"
    :freeSpaceClose="true"
  >
    <form name="form" @submit.prevent="handleSaveQty">
      <div class="w-full">
        <InputComponent
          id="qty"
          type="Number"
          label="Qty"
          v-model="qty"
          required
        />
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          type="submit"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Save
        </button>
      </div>
    </form>
  </ModalComponent>
  <ModalComponent
    title="New Supplier"
    :isModalOpen="isAddSupplier"
    :onToggle="
      () => {
        this.isAddSupplier = false;
      }
    "
    :width="578"
    :freeSpaceClose="true"
  >
    <form name="form" @submit.prevent="handleSaveSupplier">
      <div class="space-y-4">
        <InputComponent
          id="supplier_name"
          label="Supplier Name"
          v-model="dataFormSupplier.supplier_name"
          required
        />
        <div>
          <label
            for="Supplier_group"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Supplier Group <span class="text-red-400">*</span>
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <multiselect
              id="Supplier_group"
              v-model="dataFormSupplier.supplier_group"
              placeholder=""
              label="name"
              track-by="name"
              :options="dataSupplierGroup"
              :allow-empty="false"
              deselect-label="Can't remove this value"
            />
          </div>
        </div>
        <div>
          <label
            for="supplier_type"
            class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
            >Supplier Type <span class="text-red-400">*</span>
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <multiselect
              id="supplier_type"
              v-model="dataFormSupplier.supplier_type"
              placeholder=""
              :options="[
                'Company',
                'Individual',
                'Proprietorship',
                'Partnership',
              ]"
              :allow-empty="false"
              deselect-label="Can't remove this value"
            />
          </div>
        </div>
        <InputComponent
          id="mobile_no"
          type="tel"
          label="Phone Number"
          v-model="dataFormSupplier.mobile_no"
          required
        />
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          type="submit"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Save
        </button>
      </div>
    </form>
  </ModalComponent>
  <SpinComponent :show="loading" />
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import PurchaseInvoice from "@/models/purchase_invoice";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import InputCurrencyComponent from "@/components/input/InputCurrencyComponent.vue";
import InputComponent from "@/components/input/InputComponent.vue";
import { formatDate, formatTime, formatDateNow } from "@/utils/globleFunction";
import { addRequired } from "@/utils/globleFunction";
import Supplier from "@/models/supplier";
import { mapGetters } from "vuex";

export default {
  name: "Create Purchase Invoice",
  components: {
    SpinComponent,
    ModalComponent,
    InputCurrencyComponent,
    InputComponent,
  },
  data() {
    return {
      Tr,
      dataForm: new PurchaseInvoice(
        null,
        [`${formatDateNow(new Date())}`],
        "",
        false,
        [
          {
            item_code: "",
            qyt: "",
            description: "",
            rate: null,
          },
        ]
      ),
      dataSearchItem: "",
      loading: false,
      isSelectMulti: false,
      isSelectSetQty: false,
      dataItems: [],
      dataSuppliers: [],
      dataMultiItems: [],
      start: 0,
      qty: 1,
      dataUOMs: [],
      isAddSupplier: false,
      dataFormSupplier: new Supplier(
        "",
        null,
        "Company",
        "",
        "",
        "",
        "",
        false
      ),
      dataSupplierGroup: [],
    };
  },
  created() {
    this.getItems();
    this.getSuppliers();
    this.getUOMs();
    if (this.$route.query.name) this.getPurchaseInvoiceCancel();
    this.getSupplierGroup();
  },
  computed: {
    ...mapGetters("auth", {
      getFrappeVersion: "getFrappeVersion",
    }),
  },
  watch: {
    "dataForm.supplier"(newData) {
      if (newData) addRequired("supplier", false);
      else addRequired("supplier");
    },
  },
  mounted() {
    addRequired("supplier");
  },
  methods: {
    async handleSaveSupplier() {
      this.loading = true;
      await dataService
        .post("create_supplier", {
          ...this.dataFormSupplier,
          supplier_group: this.dataFormSupplier.supplier_group.name,
        })
        .then((res) => {
          let data = res.data.message.data;
          this.dataForm.supplier = data;
          dispatchNotification({
            content: "Create supplier successfully!",
            type: "success",
          });
          this.getSuppliers();
          this.dataFormSupplier = new Supplier(
            "",
            this.dataSupplierGroup.find((item) => item.old_parent == ""),
            "Company",
            "",
            "",
            "",
            "",
            false
          );
          this.loading = false;
          this.isAddSupplier = false;
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.loading = false;
        });
    },
    getSupplierGroup() {
      dataService.get("get_supplier_group").then((res) => {
        this.dataSupplierGroup = res.data.message;
        this.dataFormSupplier.supplier_group = this.dataSupplierGroup.find(
          (item) => item.old_parent == ""
        );
      });
    },
    getPurchaseInvoiceCancel() {
      this.loading = true;
      dataService
        .get("get_purchase_invoice_Cancel", `key=${this.$route.query.name}`)
        .then((res) => {
          let data = res.data.message;
          this.dataForm = {
            ...this.dataForm,
            ...data,
            items: data.items.map((item) => {
              return {
                ...item,
                item_code_label: item.item_code.split(" - ").slice(-1)[0],
              };
            }),
          };
          this.loading = false;
        });
    },
    handleShowMultiItem() {
      this.getMultiItems();
      this.isSelectMulti = true;
    },
    async handleSavePurchase() {
      this.loading = true;
      await dataService
        .post("create_purchase_invoice", {
          ...this.dataForm,
          posting_date: this.dataForm.posting_date[0].split(" ")[0],
          posting_time: this.dataForm.posting_date[0].split(" ")[1],
          amended_from: this.$route.query.name,
        })
        .then((res) => {
          dispatchNotification({
            content: "Create purchase successfully",
            type: "success",
          });
          this.loading = false;
          this.$router.go(-1);
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.loading = false;
        });
    },
    onSearchSupplier(event) {
      this.getSuppliers(event.target.value);
    },
    getSuppliers(query = "") {
      dataService.get("search_supplier", `query=${query}`).then((res) => {
        if (this.getFrappeVersion.split(".")[0] == "14")
          this.dataSuppliers = res.data.results.map((item) => {
            return {
              ...item,
              name: item.value,
              supplier_name: item.value.split(" - ").slice(-1)[0],
            };
          });
        else
          this.dataSuppliers = res.data.message.map((item) => {
            return {
              ...item,
              name: item.value,
              supplier_name: item.value.split(" - ").slice(-1)[0],
            };
          });
      });
    },
    onSelectSupplier(value) {
      dataService.get("select_supplier", `name=${value.value}`).then((res) => {
        let data = res.data.message;
        this.dataSuppliers = [];
        this.dataForm.supplier = data;
        this.getSuppliers();
      });
    },
    onSearchItem(event) {
      this.getItems(event.target.value);
    },
    getItems(query = "") {
      dataService.get("search_purchase_item", `query=${query}`).then((res) => {
        if (this.getFrappeVersion.split(".")[0] == "14")
          this.dataItems = res.data.results.map((item) => {
            return {
              ...item,
              item_code: item.value,
              item_code_label: item.value.split(" - ").slice(-1)[0],
            };
          });
        else
          this.dataItems = res.data.message.map((item) => {
            return {
              ...item,
              item_code: item.value,
              item_code_label: item.value.split(" - ").slice(-1)[0],
            };
          });
      });
    },
    handleSearchItem() {
      this.start = 0;
      this.dataMultiItems = [];
      this.getMultiItems();
    },
    getMultiItems() {
      dataService
        .get(
          "search_purchase_items",
          `query=${this.dataSearchItem}&start=${this.start}`
        )
        .then((res) => {
          this.dataMultiItems = [...this.dataMultiItems, ...res.data.values];
        });
    },
    onSelectItem(value, index) {
      dataService
        .get("select_purchase_item", `name=${value.value}`)
        .then((res) => {
          let data = res.data.message;
          this.dataItems = [];
          this.dataForm.items[index] = {
            item_code: data.name,
            item_code_label: data.name.split(" - ").slice(-1)[0],
            qty: 1,
            rate: null,
            description: data.description,
            uom: data.uom,
          };
          this.getItems();
        });
    },
    handleSelectItem(value) {
      this.itemSelected = value;
      this.isSelectSetQty = true;
    },
    handleSaveQty() {
      dataService
        .get("select_purchase_item", `name=${this.itemSelected}`)
        .then((res) => {
          let data = res.data.message;
          this.dataForm.items.push({
            item_code: data.name,
            item_code_label: data.name.split(" - ").slice(-1)[0],
            qty: this.qty,
            rate: null,
            description: data.description,
          });
          this.isSelectSetQty = false;
          this.itemSelected = "";
        });
    },
    getUOMs() {
      dataService.get("get_uom").then((res) => {
        this.dataUOMs = res.data.message.map((item) => item.name);
      });
    },
  },
};
</script>
