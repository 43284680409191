import { createStore } from "vuex";
import authModule from "./modules/auth.module";
import restaurant from "./modules/restaurant.module";
import retail from "./modules/retail.module";
import restaurantCustomer from "./modules/restaurantCustomer.module";
import transfer from "./modules/transfer.module";
import items_restaurant from "./modules/items.restaurant.module";
import itemsCustomer from "./modules/itemsCustomer.module";

const store = createStore({
  modules: {
    auth: authModule,
    restaurant,
    retail,
    items_restaurant,
    restaurantCustomer,
    itemsCustomer,
    transfer
  },
});

export default store;
