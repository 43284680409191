<template>
  <div class="keypad-container">
    <div class="w-full">
      <div class="flex">
        <input
          :value="valueKeypad"
          min="0"
          type="number"
          @input="onchange"
          class="w-full pl-4 py-2 text-[16px] lg:text-[20px] border border-gray-200 hover:border-gray-300  dark:text-gray-200 dark:bg-slate-700 dark:border-slate-500 focus:outline-none focus:ring-transparent"
          placeholder="0"
        />
        <button
          class="inline-flex items-center py-[10px] px-4  bg-secondary dark:bg-primary text-white text-sm font-medium justify-center"
          @click="handleKeypadOk(valueKeypad)"
        >
          Ok
        </button>
      </div>
    </div>
    <div class="grid grid-cols-3">
      <div v-for="n in number" :key="n">
        <div
          class="w-full h-full text-center text-gray-500 bg-gray-100 border border-gray-300 p-4 text-lg  dark:text-gray-200 dark:bg-slate-900/80 dark:border-slate-500"
          v-if="n == 10 && onReset"
          @click="onReset"
        >
          C
        </div>
        <div
          class="w-full h-full text-center text-gray-500 bg-gray-100 border border-gray-300 p-4 text-lg  dark:text-gray-200 dark:bg-slate-900/70 dark:border-slate-500"
          :ripple="true"
          v-if="n != 10 && n != 12"
          @click="onInput(n === 11 ? 0 : n)"
        >
          <div v-if="n < 10">{{ n }}</div>
          <div v-if="n == 11">0</div>
        </div>
        <div
          class="w-full h-full text-centerborder border-gray-300 p-4 text-lg text-gray-200 bg-gray-400  dark:bg-gray-600 flex justify-center items-center"
          v-if="n == 12 && onDelete"
          @click="onDelete(n)"
        >
          <div v-if="n == 12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "numeric-keypad",
  props: {
    handleKeypadOk: { type: Function, required: false },
    defaultValue: { type: String, required: false, defalut: "" },
  },
  data: () => ({
    n: 0,
    valueKeypad: "",
    number: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  }),
  created() {
    this.valueKeypad = this.defaultValue;
  },
  computed: {
    ...mapGetters("restaurant", {
      getNumberCustomer: "getNumberCustomer",
    }),
  },
  methods: {
    onchange(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.valueKeypad = Math.abs(value);
      }
      this.$forceUpdate();
    },
    onInput(key) {
      if (String(this.valueKeypad).length < 3)
        this.valueKeypad = (String(this.valueKeypad) + key).slice(0, this.maxLength);
    },
    onDelete() {
      this.valueKeypad = this.valueKeypad.slice(0, this.valueKeypad.length - 1);
    },
    onReset() {
      this.valueKeypad = "";
    },
  },
};
</script>
