<template>
  <div>
    <div
      class="bg-white shadow-sm dark:bg-slate-900 grid grid-cols-3 items-center justify-between h-11 sm:h-[38px] lg:h-16"
    >
      <div class="w-[38px] lg:w-16">
        <router-link :to="Tr.i18nRoute({ name: 'dashboard' })">
          <img
            v-if="getLogo"
            :src="baseURL + getLogo"
            alt="Logo"
            class="cursor-pointer h-[38px] lg:h-16"
          />
          <img
            v-else
            src="@/assets/image/Logo.png"
            alt="Logo"
            class="cursor-pointer h-[38px] lg:h-16"
          />
        </router-link>
      </div>
      <div class="text-center leading-5">
        <h1 class="text-sm lg:text-xl font-bold dark:text-gray-200">
          {{ dposName }}
        </h1>
      </div>
      <div class="flex justify-end">
        <div
          class="flex items-center space-x-4 mr-2 text-gray-700 dark:text-gray-100"
        >
          <span class="hidden sm:block">
            <span class="flex items-center space-x-1"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 hidden lg:block"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <span class="text-1xs lg:text-sm">{{ $d(date, "long") }}</span>
            </span>
          </span>
          <Menu as="div" class="relative ml-3">
            <MenuButton
              class="relative rounded-full mt-1.5 text-gray-400"
              @click="isOpenNoti = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 lg:w-8 lg:h-8"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
              <span
                v-if="dataItems.length !== 0"
                class="absolute animate-bounce -top-1 -right-3 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full"
                >{{ dataItems.length }}</span
              >
            </MenuButton>
            <transition
              v-show="isOpenNoti"
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                static
                class="absolute -right-10 z-10 mt-2 min-w-max origin-top-right divide-y divide-gray-100 dark:divide-gray-500 rounded-md bg-white dark:bg-slate-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div>
                  <div
                    class="flex justify-between p-2 items-start min-w-[250px]"
                  >
                    <div class="space-x-1 p-2">
                      <h1
                        class="text-sm lg:text-lg text-gray-700 dark:text-gray-100"
                      >
                        List Items (Done)
                      </h1>
                    </div>
                    <button
                      type="button"
                      class="text-gray-400 hover:text-gray-500"
                      @click="isOpenNoti = false"
                    >
                      <span class="sr-only">Close</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <MenuItem>
                    <div
                      v-if="dataItems.length == 0"
                      class="relative min-h-[200px]"
                    >
                      <EmptyComponent v-show="true" />
                    </div>
                    <ul
                      v-else
                      role="list"
                      class="divide-y overflow-y-auto divide-gray-100 dark:divide-gray-500 max-h-[70vh]"
                    >
                      <li
                        v-for="(item, index) in dataItems"
                        :key="index"
                        class="flex justify-between items-center gap-x-6 py-3 cursor-pointer px-4 b"
                      >
                        <div class="flex gap-x-4">
                          <div class="flex-auto">
                            <p
                              class="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200"
                            >
                              {{ item.item_name }} ({{ item.qty }})
                            </p>
                          </div>
                        </div>
                        <div class="hidden sm:flex sm:flex-col sm:items-end">
                          <p
                            class="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200"
                          >
                            {{ item.table }}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton class="flex max-w-xs items-center rounded-full bg-gray-800 text-sm">
                <div v-if="locale === 'en'" class="inline-block w-7 lg:w-8 h-auto cursor-pointer">
                  <img src="@/assets/image/en.png" alt="en" class="w-full h-auto" />
                </div>
                <div v-else class="inline-block w-7 lg:w-8 h-auto cursor-pointer">
                  <img src="@/assets/image/kh.png" alt="en" class="w-full h-auto" />
                </div>
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-gray-500 rounded-md bg-white dark:bg-slate-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem v-for="sLocale in supportedLocales" :key="`locale-${sLocale}`">
                  <div @click="switchLanguage(sLocale)"
                    class="text-gray-700 dark:text-gray-100 px-4 py-2 text-sm" :class="[
                      locale === sLocale
                        ? 'bg-gray-100 text-gray-900 dark:bg-gray-800'
                        : 'text-gray-700',
                      'px-4 py-2 text-sm cursor-pointer',
                    ]">
                    {{ t(`locale.${sLocale}`) }}
                  </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton
                class="flex items-center justify-center rounded-full bg-secondary text-xs lg:text-xl h-7 w-7 lg:w-11 lg:h-11 text-white overflow-hidden"
              >
                <span v-if="!getUserImage">{{
                  fullName
                    ?.split(" ")
                    .map((word) => word.charAt(0))
                    .join("")
                }}</span>
                <img v-else :src="baseURL + getUserImage" alt="profile" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-gray-500 rounded-md bg-white dark:bg-slate-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="Tr.i18nRoute({ name: 'profile' })"
                      class="text-gray-800 dark:text-gray-100 block px-4 py-2 text-md font-bold"
                    >
                      {{ fullName }}
                    </router-link>
                  </MenuItem>
                </div>
                <div
                  class="py-1"
                  v-if="userRoleCashier && routeRestaurant == 'restaurant'"
                >
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="Tr.i18nRoute({ name: 'service-kitchen-drink' })"
                      class="text-gray-800 dark:text-gray-100 block px-4 py-2 w-full text-left"
                    >
                      Baristar/Kitchen
                    </router-link>
                  </MenuItem>
                </div>
                <div
                  class="py-1"
                  v-if="
                    userRoleCashier &&
                    (routeRestaurant == 'restaurant' ||
                      routeRestaurant == 'retail')
                  "
                >
                  <MenuItem v-slot="{ active }">
                    <button
                      @click="handleDisplay"
                      class="ellipsis text-gray-800 dark:text-gray-100 block px-4 py-2 w-full text-left"
                      target="_blank"
                    >
                      Customer Facing Display
                    </button>
                  </MenuItem>
                </div>
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <button
                      @click="refreshPage"
                      class="text-gray-700 dark:text-gray-100 px-4 py-2 text-sm w-full text-left flex justify-between items-center"
                    >
                      Refresh
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                      </svg>
                    </button>
                  </MenuItem>
                </div>
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <button
                      @click="toggleFullScreen"
                      class="text-gray-700 dark:text-gray-100 px-4 py-2 text-sm w-full text-left flex justify-between items-center"
                    >
                      {{ $t("nav.full_screen") }}
                      <svg
                        class="h-6 w-6"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
                        <path d="M4 16v2a2 2 0 0 0 2 2h2" />
                        <path d="M16 4h2a2 2 0 0 1 2 2v2" />
                        <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
                      </svg>
                    </button>
                  </MenuItem>
                </div>
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <div
                      class="flex justify-between text-gray-700 dark:text-gray-100 px-4 py-2 text-sm"
                    >
                      {{ $t("nav.dark_mode") }}
                      <div
                        class="relative inline-block w-12 align-middle select-none transition duration-200 ease-in"
                      >
                        <input
                          type="checkbox"
                          name="toggle"
                          id="toggle"
                          v-model="isDarkMode"
                          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-2 appearance-none cursor-pointer"
                          @change="updateDarkMode(isDarkMode)"
                        />
                        <label
                          for="toggle"
                          class="toggle-label block overflow-hidden h-6 rounded-full bg-slate-500 cursor-pointer"
                        >
                          <div
                            class="w-full flex justify-between p-1 text-white"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              class="w-4 h-4"
                            >
                              <path
                                d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              class="w-4 h-4"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                        </label>
                      </div>
                    </div>
                  </MenuItem>
                </div>
                <div class="py-1" v-if="!userRoleCashier">
                  <MenuItem v-slot="{ active }">
                    <button
                      @click="logout"
                      class="text-gray-700 dark:text-gray-100 px-4 py-2 text-sm w-full text-left flex justify-between items-center"
                    >
                      {{ $t("nav.logout") }}
                      <span class="flex flex-col items-center">
                        <svg
                          class="h-6 w-6"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                          <polyline points="16 17 21 12 16 7" />
                          <line x1="21" y1="12" x2="9" y2="12" />
                        </svg>
                      </span>
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import Tr from "@/i18n/translation";
import store from "@/store";
import { updateDarkMode, toggleFullScreen } from "@/utils/globleFunction";
import { baseURL } from "@/utils/baseURL";
import { mapGetters, mapActions } from "vuex";
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";
import EmptyComponent from "@/components/empty/EmptyComponent.vue";

export default {
  name: "Header Layout",
  setup() {
    const { t, locale } = useI18n();

    const supportedLocales = Tr.supportedLocales;

    const router = useRouter();

    const switchLanguage = async (event) => {
      const newLocale = event;

      await Tr.switchLanguage(newLocale);

      try {
        await router.replace({ params: { locale: newLocale } });
      } catch (e) {
        router.push("/");
      }
    };

    return { t, locale, supportedLocales, switchLanguage };
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    EmptyComponent,
  },
  data() {
    return {
      userNavigation,
      language: true,
      Tr,
      date: new Date(),
      isDarkMode: false,
      updateDarkMode,
      toggleFullScreen,
      dataItems: [],
      isOpenNoti: false,
      baseURL,
    };
  },
  watch: {
    isOpenNoti(newData) {
      if (!newData) {
        this.dataItems = [];
        localStorage.removeItem("itmesNoti");
      }
    },
  },
  created() {
    this.dataItems = JSON.parse(localStorage.getItem("itmesNoti"))
      ? JSON.parse(localStorage.getItem("itmesNoti"))
      : [];
  },
  computed: {
    ...mapGetters("auth", {
      getPOSProfile: "getPOSProfile",
      getUserRoles: "getUserRoles",
      getLogo: "getLogo",
      getUserImage: "getUserImage",
    }),
    userRoleCashier() {
      return this.getUserRoles?.includes("Cashier User");
    },
    userRoleService() {
      return this.getUserRoles?.includes("Service User");
    },
    routeRestaurant() {
      return this.$route.path.split("/")[2];
    },
    user() {
      return store.state?.auth?.user;
    },
    fullName() {
      return this.user?.full_name.replace(/\b\w/g, (match) =>
        match.toUpperCase()
      );
    },
    dposName() {
      return store.state?.auth?.user?.dpos_name;
    },
  },
  mounted() {
    setInterval(() => {
      this.date = new Date();
    }, 1000);
    const storedDarkMode = localStorage.getItem("darkMode");
    this.isDarkMode = storedDarkMode === "true";

    socket.on(type.sendNotification, (message) => {
      if (message.pos_profile === this.getPOSProfile) {
        this.dataItems.push(message.item);
        localStorage.setItem("itmesNoti", JSON.stringify(this.dataItems));
      }
    });
  },
  methods: {
    ...mapActions("auth", {
      logout: "logout",
    }),
    refreshPage() {
      window.location.reload();
    },
    handleDisplay() {
      window.open(
        this.$router.resolve({
          ...Tr.i18nRoute({
            name:
              this.routeRestaurant == "restaurant"
                ? "customer-facing-display"
                : "customer-facing-display-retail",
          }),
        }).href,
        "New Window",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, fullscreen=yes`
      );
    },
  },
};
</script>
