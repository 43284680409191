<template>
  <div
    id="infinite-list"
    class="w-full px-5 overflow-x-auto"
    :class="
      userRoles
        ? 'h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]'
        : 'h-[100vh]'
    "
  >
    <div class="py-4 flex justify-between items-center">
      <h1 class="text-lg lg:text-[20px] font-medium dark:text-gray-100 w-1/3">
        List Ordered
      </h1>
      <div v-if="!userRoles" class="w-1/3 flex justify-end">
        <div class="flex items-center space-x-2 lg:space-x-4">
          <div
            class="relative inline-block w-13 align-middle select-none transition duration-200 ease-in"
          >
            <input
              type="checkbox"
              name="toggle"
              id="toggle"
              v-model="isDarkMode"
              class="toggle-checkbox absolute block w-5 h-5 rounded-full bg-white border-2 appearance-none cursor-pointer"
              @change="updateDarkMode(isDarkMode)"
            />
            <label
              for="toggle"
              class="toggle-label block overflow-hidden h-5 rounded-full bg-slate-500 cursor-pointer"
            >
              <div class="w-full flex justify-between px-1 py-0.5 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </label>
          </div>
          <button
            @click="toggleFullScreen"
            class="text-gray-700 dark:text-gray-100 text-sm"
          >
            <svg
              class="h-6 w-6"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
              <path d="M4 16v2a2 2 0 0 0 2 2h2" />
              <path d="M16 4h2a2 2 0 0 1 2 2v2" />
              <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
            </svg>
          </button>
          <button
            @click="logout"
            class="text-gray-700 dark:text-gray-100 text-sm"
          >
            <svg
              class="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
              <polyline points="16 17 21 12 16 7" />
              <line x1="21" y1="12" x2="9" y2="12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="inline-block overflow-x-scroll w-full">
      <TableComponent
        :columns="columns"
        :items="renderItems(items)"
        scrollX="35rem"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'image'">
            <img
              :src="record.image ? baseURL + record.image : imageEmpty"
              alt="food"
            />
          </template>
          <template v-if="column.key === 'item_name'">
            <p class="font-medium">{{ record.item_name }}</p>
            <p class="text-gray-500 dark:text-gray-400">
              {{ record.take_note }}
            </p>
          </template>
          <template v-if="column.key === 'action'">
            <div class="flex justify-center">
              <button
                @click="onSelectDone(record)"
                class="inline-flex items-center text-sm p-2 lg:p-[10px] bg-secondary text-white font-medium rounded-md justify-center"
              >
                Done
              </button>
            </div>
          </template>
        </template>
      </TableComponent>
    </div>
    <ModalComponent
      title="Confirmation"
      :isModalOpen="isDoneItem"
      :onToggle="() => (this.isDoneItem = false)"
      :width="500"
    >
      <div class="w-full">
        <div>
          <p class="dark:text-gray-200">
            Are you sure want to done of {{ selectedItem?.item_name }}?
          </p>
        </div>
        <div class="flex justify-end pt-2 lg:pt-4">
          <div class="flex space-x-4">
            <button
              @click="this.isDoneItem = false"
              class="flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
            >
              Cancel
            </button>
            <button
              @click="handleConfirmDone"
              class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
const columns = [
  {
    key: "no",
    label: "#",
    dataIndex: "no",
    width: 20,
    align: "center",
    alignHeader: "center",
  },
  {
    key: "table",
    label: "Table",
    dataIndex: "table",
    align: "center",
    alignHeader: "center",
  },
  { key: "image", label: "Image", width: 120, alignHeader: "center" },
  { key: "item_name", label: "Name", align: "center", alignHeader: "center" },
  {
    key: "qty",
    label: "Qty",
    dataIndex: "qty",
    align: "center",
    alignHeader: "center",
  },
  {
    key: "uom",
    label: "UOM",
    dataIndex: "uom",
    align: "center",
    alignHeader: "center",
  },
  {
    key: "time",
    label: "Time",
    dataIndex: "time",
    align: "center",
    alignHeader: "center",
    width: 120,
  },
  { key: "action", label: "Action", alignHeader: "center" },
];
import TableComponent from "@/components/table/TableComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import imageEmpty from "@/assets/image/empty.jpg";
import { mapActions, mapGetters } from "vuex";
import { dispatchNotification } from "@/components/notification";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { baseURL } from "@/utils/baseURL";
import {
  updateDarkMode,
  toggleFullScreen,
  convertToZeroPaddedString,
  renderItems,
} from "@/utils/globleFunction";
import Tr from "@/i18n/translation";
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";

export default {
  name: "Bar",
  components: {
    TableComponent,
    SpinComponent,
    ModalComponent,
  },
  data() {
    return {
      Tr,
      renderItems,
      columns,
      limit: 10,
      page: 1,
      items: [],
      imageEmpty,
      loading: false,
      totalItems: 0,
      selectedItem: null,
      isDoneItem: false,
      isDarkMode: false,
      updateDarkMode,
      toggleFullScreen,
      baseURL,
    };
  },
  created() {
    this.setName();
    this.getItems();

    setInterval(() => {
      this.items.forEach((item) => {
        const currentTime = new Date();
        const elapsedMilliseconds =
          currentTime - new Date(item.dpos_order_time);
        const hours = Math.floor(elapsedMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor(
          (elapsedMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((elapsedMilliseconds % (1000 * 60)) / 1000);
        item.time = `${convertToZeroPaddedString(
          hours,
          2
        )}:${convertToZeroPaddedString(minutes, 2)}:${convertToZeroPaddedString(
          seconds,
          2
        )}`;
      });
    }, 1000);
  },

  computed: {
    ...mapGetters("auth", {
      getPOSProfile: "getPOSProfile",
      getUserRoles: "getUserRoles",
      getUserServices: "getUserServices",
    }),
    userRoles() {
      return this.getUserRoles?.includes("Cashier User");
    },
  },
  mounted() {
    const storedDarkMode = localStorage.getItem("darkMode");
    this.isDarkMode = storedDarkMode === "true";

    socket.on(type.SS, (message) => {
      let exit = message.data.filter((i) =>
        this.getUserServices.some((j) => j === i.kitchen_drink.name)
      );
      if (message.pos_profile === this.getPOSProfile && exit)
        this.items.push(...exit);
    });

    socket.on(type.SRS, (message) => {
      let exit = message.data.filter((i) =>
        this.getUserServices.some((j) => j === i.kitchen_drink.name)
      );
      if (message.pos_profile === this.getPOSProfile) {
        let itemRemove = exit.map((item) => item.name);
        this.items = this.items.filter(
          (item) => !itemRemove.includes(item.name)
        );
      }
    });

    socket.on(type.refS, (message) => {
      if (message.pos_profile === this.getPOSProfile) this.onRefreshItems();
    });

    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", (e) => {
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        this.totalItems > this.items.length
      ) {
        this.page++;
        this.getItems();
      }
    });
  },
  methods: {
    ...mapActions("auth", {
      logout: "logout",
    }),
    setName() {
      this.name = this.$route.params.name;
    },
    getApi() {
      return dataService
        .get("get_items_bar_kitchen", `limit=${this.limit}&&page=${this.page}`)
        .then((res) => res.data.message);
    },
    async getItems() {
      this.loading = true;
      let { items, total } = await this.getApi();
      this.items.push(...items);
      this.totalItems = total;
      this.loading = false;
    },
    handleConfirmDone() {
      this.loading = true;
      dataService
        .post(
          `confirm_done?key=${this.selectedItem.name}&&time=${this.selectedItem.time}`
        )
        .then(async (res) => {
          dispatchNotification({
            content: "Your action was successfully item done",
            type: "success",
          });
          this.isDoneItem = false;
          this.page = 1;
          let { items, total } = await this.getApi();
          this.items = items;
          this.loading = false;

          socket.emit("send", {
            pos_profile: this.getPOSProfile,
            type: type.refM,
            table: this.selectedItem.table_name,
          });
          socket.emit("send", {
            pos_profile: this.getPOSProfile,
            type: type.refCS,
            table: this.selectedItem.table_name,
          });
          socket.emit("send", {
            pos_profile: this.getPOSProfile,
            type: type.sendNotification,
            item: {
              item_name: this.selectedItem.item_name,
              table: this.selectedItem.table,
              qty: this.selectedItem.qty,
            },
          });
          this.selectedItem = null;
        });
    },
    onRefreshItems() {
      this.items = [];
      this.page = 1;
      this.getItems();
    },
    onSelectDone(item) {
      this.selectedItem = item;
      this.isDoneItem = true;
    },
  },
};
</script>
