<template>
  <div>
    <div class="grid grid-cols-5">
      <div class="col-span-2 overflow-hidden h-screen">
        <img :src="getCartItems[0]?.image
          ? baseURL + getCartItems[0]?.image
          : imageEmpty
          " alt="" class="w-full object-cover" />
      </div>
      <div class="col-span-3 px-4">
        <div
          class="w-full flex items-center justify-center py-2 border-b  border-gray-900 dark:border-gray-400">
          <h1 class="uppercase text-lg font-bold  dark:text-gray-100">
            Order Summary
          </h1>
        </div>
        <div class="flex w-full  dark:text-gray-100 text-sm my-2 font-bold">
          <h1 class="w-[50%]">Items</h1>
          <h1 class="w-[10%] text-center">Qty</h1>
          <h1 class="w-[20%] text-center">Price</h1>
          <h1 class="text-right w-[20%]  dark:text-gray-100 pr-2">
            Amount
          </h1>
        </div>
        <div class="overflow-auto h-[calc(100vh_-_185px)]">
          <div v-for="item in getCartItems"
            class="flex w-full  dark:text-gray-100 text-sm py-2.5 border-b border-gray-300 dark:border-slate-600">
            <div class="w-[50%]">
              {{ item.item_name }}
              <p v-if="item.take_note != ''" class="text-base  text-gray-600 dark:text-gray-400">
                {{ item.take_note }}
              </p>
            </div>
            <div class="w-[10%] text-center">{{ item.qty }}</div>
            <div class="w-[20%] text-center">{{ item.currency_symbol +
              " " +
              $n(
                item.price_list_rate,
                "decimal",
                `${item.currency === "USD" ? "en" : "kh"}`
              ) }}</div>
            <div class="text-right w-[20%]  dark:text-gray-100 font-bold pr-2">
              <p>
                {{
                  item.currency_symbol +
                  " " +
                  $n(
                    item.price_list_rate * item.qty,
                    "decimal",
                    `${item.currency === "USD" ? "en" : "kh"}`
                  )
                }}
              </p>
              <p v-if="item.discount != 0" class=" text-gray-500 dark:text-gray-400">
                {{ item.discount }}% off
              </p>
            </div>
          </div>
        </div>
        <div class="border-b  border-gray-900 dark:border-gray-400 my-2"></div>
        <div class="flex items-center">
          <div class="flex  dark:text-gray-100 text-sm leading-7 w-1/2">
            <div class="w-1/2">
              <p>Subtotal</p>
              <p>Tax</p>
              <p>Discount</p>
              <p v-show="getCharge > 0" class="ellipsis">Service Change</p>
            </div>
            <div class="w-1/2 text-right font-bold  dark:text-gray-100">
              <p>${{ $n(getCartSubTotal, "decimal", "en") }}</p>
              <p>${{ $n(getTotalAmountTaxCharge(getTax), "decimal", "en") }}</p>
              <p>${{ $n(getTotalDiscountAmount, "decimal", "en") }}</p>
              <p v-show="getCharge > 0">
                ${{ $n(getTotalAmountTaxCharge(getCharge), "decimal", "en") }}
              </p>
            </div>
          </div>
          <div class="text-right w-1/2 uppercase leading-tight">
            <span class="text-base  dark:text-gray-100">Total</span>
            <div class="text-primary text-[25px] font-bold">
              <p>${{ $n(total, "decimal", "en") }}</p>
              <p>៛{{ $n(totalKHR, "decimal", "kh") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";
import { mapActions, mapGetters } from "vuex";
import imageEmpty from "@/assets/image/ad.jpg";
import { baseURL } from "@/utils/baseURL";

export default {
  data() {
    return {
      imageEmpty,
      baseURL,
    };
  },
  mounted() {
    socket.on(type.refCFD, (message) => {
      if (message.pos_profile === this.getPOSProfile) {
        this.setNewCart(message.data);
      }
    });
  },
  computed: {
    ...mapGetters("auth", {
      getTax: "getTax",
      getCharge: "getCharge",
      getPOSProfile: "getPOSProfile",
      getDiscountRate: "getDiscountRate",
      currencyExchange: "getCurrencyExchange",
    }),
    ...mapGetters("restaurant", {
      getCartTotal: "getCartTotal",
      getCartItems: "getCartItems",
      getCartSubTotal: "getCartSubTotal",
      getCartTotalQty: "getCartTotalQty",
      getNumberCustomer: "getNumberCustomer",
      getCartSubTotalOrg: "getCartSubTotalOrg",
      getTotalDiscountAmount: "getTotalDiscountAmount",
      getTotalAmountTaxCharge: "getTotalAmountTaxCharge",
    }),
    total() {
      return this.getCartTotal(
        this.getTotalAmountTaxCharge(this.getTax),
        this.getTotalAmountTaxCharge(this.getCharge)
      );
    },
    totalKHR() {
      return Math.round((this.total * this.currencyExchange) / 100) * 100;
    },
  },
  methods: {
    ...mapActions("restaurant", {
      setNewCart: "setNewCart",
    }),
  },
};
</script>
