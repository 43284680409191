<template>
  <div
    class="w-full px-5 overflow-x-auto h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]"
  >
    <div class="mt-2 mb-6 leading-9">
      <h1 class="text-[20px] font-medium dark:text-gray-100">Qty On Hand</h1>
      <nav class="flex text-gray-700" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <router-link
              :to="Tr.i18nRoute({ name: 'table' })"
              class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                ></path>
              </svg>
              Home
            </router-link>
          </li>
          <li>
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <router-link
                :to="Tr.i18nRoute({ name: 'qty-on-hand' })"
                class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                >Qty On Hand</router-link
              >
            </div>
          </li>
        </ol>
      </nav>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 my-3">
      <vue-tailwind-datepicker
        :formatter="{
          date: 'DD-MM-YYYY',
          month: 'MMM',
        }"
        v-model="date"
        as-single
        use-range
      />
      <multiselect
        id="warehouse"
        type="text"
        v-model="warehouse"
        placeholder=""
        :options="dataWarehouses"
      />
    </div>
    <div ref="table"></div>
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import Tr from "@/i18n/translation";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import { formatDate } from "@/utils/globleFunction";

export default {
  name: "Qty On Hand",
  components: {
    SpinComponent,
  },
  data() {
    return {
      tabulator: null,
      table: null,
      Tr,
      loading: false,
      groupBy: "Warehouse",
      date: [],
      formatDate,
      dataWarehouses: [],
      warehouse: "",
    };
  },
  created() {
    this.getDefaultDateValue();
    this.getWarehouses();
  },
  watch: {
    date(newData) {
      if (newData.length === 0) {
        this.getDefaultDateValue();
      } else {
        this.getData();
      }
    },
    warehouse(newData) {
      this.getData();
    },
  },
  mounted() {
    this.tabulator = new Tabulator(this.$refs.table, {
      data: [],
      height: "calc(100vh - 225px)",
      layout: "fitColumns",
      columns: [],
    });
  },
  methods: {
    getWarehouses() {
      dataService.get("get_warehouses").then((res) => {
        this.dataWarehouses = res.data.message;
      });
    },
    getData() {
      this.loading = true;
      dataService
        .get(
          "get_qty_on_hand",
          `from_date=${this.date[0]}&to_date=${this.date[1]}&warehouse=${
            this.warehouse ? this.warehouse : ""
          }`
        )
        .then((res) => {
          let data = res.data.message;
          this.tabulator.setData(data[1]);
          this.tabulator.setColumns([
            {
              formatter: "rownum",
              hozAlign: "center",
              width: 40,
              headerSort: false,
            },
            ...data[0],
          ]);
          this.loading = false;
        });
    },
    getDefaultDateValue() {
      var currentDate = new Date();
      var sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 7);
      var currentDateStr = this.formatDate(currentDate);
      var sevenDaysAgoStr = this.formatDate(sevenDaysAgo);
      this.date = [sevenDaysAgoStr, currentDateStr];
    },
  },
};
</script>

<style lang="scss">
@import "https://unpkg.com/tabulator-tables@5.3.1/dist/css/tabulator_simple.min.css";
</style>
