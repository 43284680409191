<template>
  <div
    class="w-full px-5 overflow-x-auto h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]"
  >
    <div class="mt-2 mb-6 leading-9">
      <h1 class="text-[20px] font-medium dark:text-gray-100">
        Sale Transactions
      </h1>
      <nav class="flex text-gray-700" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <router-link
              :to="Tr.i18nRoute({ name: 'table' })"
              class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                ></path>
              </svg>
              Home
            </router-link>
          </li>
          <li>
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <router-link
                :to="Tr.i18nRoute({ name: 'sale-transactions' })"
                class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                >Sale Transactions</router-link
              >
            </div>
          </li>
        </ol>
      </nav>
    </div>
    <div class="grid grid-cols-2 md:grid-cols-4 gap-2 my-3">
      <vue-tailwind-datepicker
        :formatter="{
          date: 'DD/MM/YYYY',
          month: 'MMM',
        }"
        v-model="dateTransaction"
        as-single
        use-range
      />
      <div>
        <multiselect
          v-model="typeTransaction"
          :options="['Summary', 'Detail']"
          :searchable="false"
        />
      </div>
    </div>
    <div
      class="inline-block overflow-x-scroll w-full sm:w-[calc(100vw_-_78px)] lg:w-[calc(100vw_-_104px)]"
    >
      <TableComponent
        :columns="columns"
        :items="renderItems(items)"
        :columnFooter="columnFooter"
        id="infinite-list"
        scrollX="120rem"
      >
        <template #bodyCell="{ column, record }">
          <template
            v-if="column.key === 'currency' && typeTransaction === 'Summary'"
          >
            {{ record.currency }}
          </template>
          <template
            v-if="
              column.key === 'total_transaction' &&
              typeTransaction === 'Summary'
            "
          >
            {{ record.total_transaction }}
          </template>
          <template
            v-if="
              column.key === 'custom_item_code' && typeTransaction === 'Detail'
            "
          >
            {{ record.custom_item_code }}
          </template>
          <template
            v-if="column.key === 'item_name' && typeTransaction === 'Detail'"
          >
            {{ record.item_name }}
          </template>
          <template v-if="column.key === 'uom' && typeTransaction === 'Detail'">
            {{ record.uom }}
          </template>
          <template
            v-if="
              column.key === 'price_list_rate' && typeTransaction === 'Detail'
            "
          >
            ${{ $n(record.price_list_rate, "decimal", "en") }}
          </template>
          <template v-if="column.key === 'discount_amount'">
            ${{ $n(record.discount_amount, "decimal", "en") }}
          </template>
          <template v-if="column.key === 'sub_amount'">
            ${{ $n(record.sub_amount, "decimal", "en") }}
          </template>
          <template v-if="column.key === 'inv_dis'">
            ${{ $n(record.inv_dis, "decimal", "en") }}
          </template>
          <template v-if="column.key === 'ser_amount'">
            ${{ $n(record.ser_amount, "decimal", "en") }}
          </template>
          <template v-if="column.key === 'tax'">
            ${{ $n(record.tax, "decimal", "en") }}
          </template>
          <template v-if="column.key === 'total'">
            ${{ $n(record.total, "decimal", "en") }}
          </template>
          <template v-if="column.key === 'total_cost'">
            ${{ $n(record.total_cost, "decimal", "en") }}
          </template>
          <template v-if="column.key === 'total_profit'">
            ${{ $n(record.total_profit, "decimal", "en") }}
          </template>
        </template>
      </TableComponent>
    </div>
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
const columns_component = [
  { key: "discount_amount", label: "Line Discount" },
  { key: "sub_amount", label: "Sub Amount" },
  { key: "inv_dis", label: "Inv. Dis" },
  { key: "ser_amount", label: "Ser. Charge" },
  { key: "tax", label: "Tax" },
  { key: "total", label: "Total" },
  { key: "total_cost", label: "Cost" },
  { key: "total_profit", label: "Profit" },
];

const column_detail = [
  { key: "custom_item_code", label: "Item Code" },
  { key: "item_name", label: "Item Name" },
  { key: "qty", label: "Qty", dataIndex: "qty" },
  { key: "uom", label: "UMS" },
  { key: "price_list_rate", label: "Price" },
];

const column_summary = [
  { key: "currency", label: "Currency" },
  { key: "total_transaction", label: "Total Transaction" },
  { key: "qty", label: "Total Qty", dataIndex: "qty" },
];

import TableComponent from "@/components/table/TableComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { renderItems } from "@/utils/globleFunction";
import Tr from "@/i18n/translation";

export default {
  name: "Sale Summary",
  components: {
    TableComponent,
    SpinComponent,
    ModalComponent,
  },
  data() {
    return {
      Tr,
      items: [],
      loading: false,
      loading: false,
      totalItems: 0,
      limit: 100,
      page: 1,
      renderItems,
      dateValue: [],
      typeTransaction: "Summary",
      dateTransaction: [],
      columns: [],
      columnFooter: [],
    };
  },
  created() {
    this.getDefaultDateTransaction();
    this.columns = [...column_summary, ...columns_component];
  },
  watch: {
    dateTransaction(newData) {
      if (newData.length === 0) {
        this.getDefaultDateTransaction();
      } else {
        this.items = [];
        this.getSaleTransaction();
      }
    },
    typeTransaction(newData) {
      this.items = [];
      if (newData === "Summary") {
        this.columns = [...column_summary, ...columns_component];
      } else if (newData === "Detail") {
        this.columns = [...column_detail, ...columns_component];
      }
      this.getSaleTransaction();
    },
  },
  methods: {
    formatDate(date) {
      var day = date.getDate().toString().padStart(2, "0");
      var month = (date.getMonth() + 1).toString().padStart(2, "0");
      var year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    getDefaultDateTransaction() {
      var currentDate = new Date();
      var sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 7);
      var currentDateStr = this.formatDate(currentDate);
      var sevenDaysAgoStr = this.formatDate(sevenDaysAgo);
      this.dateTransaction = [sevenDaysAgoStr, currentDateStr];
    },
    getSaleTransaction() {
      this.loading = true;
      dataService
        .get(
          "get_sale_transactions",
          `limit=${this.limit}&&page=${this.page}&&start=${this.dateTransaction[0]}&end=${this.dateTransaction[1]}&&type=${this.typeTransaction}`
        )
        .then((res) => {
          let { items, total } = res.data.message;
          this.items.push(...items);
          let t = 0;
          let t_profit = 0;
          let total_cost = 0;
          for (let i = 0; i < this.items.length; i++) {
            t += this.items[i].total;
            t_profit += this.items[i].total_profit;
            total_cost += this.items[i].total_cost;
          }
          let data = this.columns.map((item) => {
            if (item.key === "total")
              return {
                ...item,
                label: t,
              };
            else if (item.key === "total_cost")
              return {
                ...item,
                label: total_cost,
              };
            else if (item.key === "total_profit")
              return {
                ...item,
                label: t_profit,
              };
            else
              return {
                ...item,
                label: "",
              };
          });

          this.columnFooter = data;
          this.totalItems = total;
          this.loading = false;
        });
    },
  },
};
</script>
