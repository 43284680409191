<template>
  <div
    id="infinite-list"
    class="w-full px-5 overflow-x-auto h-[calc(100vh_-_44px)] sm:h-[calc(100vh_-_38px)] lg:h-[calc(100vh_-_64px)]"
  >
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1
          class="text-sm sm:text-xl font-semibold  dark:text-gray-100"
        >
          Menu Group
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'setup-menu' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >Menu Group</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        v-if="dataSelectedItem.length == 0"
        @click="handleAddMenu"
        class="flex items-center text-sm py-2 lg:py-[10px] pl-3 pr-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5 mr-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v12m6-6H6"
          />
        </svg>
        Add Menu
      </button>
      <Menu v-else as="div" class="relative">
        <div>
          <MenuButton
            class="flex items-center text-sm py-2 lg:py-[10px] pl-4 pr-3 bg-secondary text-white font-medium rounded-md justify-center"
          >
            Actions<svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 ml-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
              />
            </svg>
          </MenuButton>
        </div>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-gray-500 rounded-md bg-white dark:bg-slate-950 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <button
                  @click="handleDeleteItem"
                  class="text-left text-gray-800 dark:text-gray-100 block px-4 py-2 text-md font-bold w-full"
                >
                  Delete
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <div
      class="inline-block overflow-x-scroll w-full sm:w-[calc(100vw_-_78px)] lg:w-[calc(100vw_-_111px)]"
    >
      <TableComponent
        :columns="columns"
        :items="renderItems(items)"
        scrollX="35rem"
        :onClickRow="handleViewItem"
        :onSelectRow="(data) => (dataSelectedItem = data)"
        :onSearch="
          (value) => {
            page = 1;
            limit = 20;
            items = [];
            search = value;
            getItems();
          }
        "
      >
        <template #filter>
          <div class="w-full sm:w-1/2">
            <multiselect
              v-model="status"
              :searchable="false"
              placeholder=""
              :options="['Enabled', 'Disabled']"
            />
          </div>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'parent_menu'">
            {{ record.parent_menu ? record.parent_menu.label : "-" }}
          </template>
          <template v-if="column.key === 'status'">
            <span
              v-show="record.disabled"
              class="nline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
              >Disable</span
            >
            <span
              v-show="!record.disabled"
              class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-secondary ring-1 ring-inset ring-secondary/20"
              >Enable</span
            >
          </template>
        </template>
      </TableComponent>
    </div>
    <ModalComponent
      :title="titleModal"
      :isModalOpen="isAddView"
      :onToggle="() => (this.isAddView = false)"
      :width="578"
    >
      <form
        class="p-2 grid grid-cols-1 gap-y-2"
        name="form"
        @submit.prevent="handleSaveMenu"
      >
        <InputComponent
          id="menu"
          type="text"
          label="Menu"
          v-model="dataForm.label"
          required
        />
        <InputComponent
          id="index_no"
          type="number"
          label="Index"
          v-model="dataForm.index_no"
        />
        <div class="mt-1 flex items-center space-x-2">
          <input
            id="is_parent_menu"
            type="checkbox"
            v-model="dataForm.is_parent_menu"
            @change="dataForm.parent_menu = null"
            class="h-4 w-4 cursor-pointer accent-secondary"
          /><label
            for="is_parent_menu"
            class="block text-sm font-medium leading-6 text-gray-900  dark:text-gray-100"
            >Parent Menu</label
          >
        </div>
        <div v-if="dataForm.is_parent_menu">
          <label
            for="parent_menu"
            class="block text-sm font-medium leading-6 text-gray-900  dark:text-gray-100"
          ></label>
          <div class="mt-1">
            <multiselect
              id="parent_menu"
              v-model="dataForm.parent_menu"
              placeholder=""
              label="label"
              track-by="name"
              :options="dataMenu"
              :allow-empty="false"
              deselect-label="Can't remove this value"
            />
          </div>
        </div>
        <div class="mt-1 flex items-center space-x-2">
          <input
            id="disabled"
            type="checkbox"
            v-model="dataForm.disabled"
            class="h-4 w-4 cursor-pointer accent-secondary"
          /><label
            for="disabled"
            class="block text-sm font-medium leading-6 text-gray-900  dark:text-gray-100"
            >Disabled</label
          >
        </div>
        <div
          v-if="dataForm.name"
          @click="handleShowItem"
          class="mt-1 text-sm font-medium leading-6 text-secondary dark:text-gray-100 cursor-pointer hover:underline"
        >
          + Add Item to menu
        </div>
        <div class="flex justify-end pt-2 lg:pt-4 gap-4">
          <button
            type="button"
            @click="isAddView = false"
            class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
          >
            Save
          </button>
        </div>
      </form>
    </ModalComponent>
    <ModalComponent
      title="Item"
      :isModalOpen="isShowItem"
      :onToggle="() => (this.isShowItem = false)"
      :width="678"
      :freeSpaceClose="true"
    >
      <div class="p-2">
        <div class="relative">
          <input
            type="text"
            v-model="query"
            @input="handleSearch"
            class="h-full w-full pl-9 py-2.5 lg:text-sm border pr-2  text-gray-900 dark:text-gray-100 bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-md focus:outline-none focus:border-primary focus:ring-transparent"
            placeholder="Search..."
          /><span class="absolute py-[9px] px-3 top-0 left-0 text-gray-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg> </span
          ><span
            v-if="query != ''"
            @click="handleSearchClear"
            class="absolute right-3 top-[8px]  text-gray-500 dark:text-gray-400 cursor-pointer"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          <div
            class="absolute mt-0.5 w-full shadow-lg bg-white  dark:bg-slate-700 overflow-y-auto max-h-[468px] z-50"
          >
            <div
              class="flex items-center justify-between px-4 py-2 border-b border-gray-200 dark:border-gray-800 text-gray-700 dark:text-gray-200 cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-600"
              v-for="item in newItemSearch"
              @click="handleAddItem(item)"
            >
              <div class="dark:text-gray-100">
                <div>{{ item.custom_item_code }}</div>
                <div>{{ item.item_name }}</div>
              </div>
              <div class="dark:text-gray-100">
                {{ item.uom }}
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2 overflow-y-auto h-[478px]">
          <TableComponent
            :columns="columns_item"
            :items="renderItems(menuItems)"
            scrollX="35rem"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'action'">
                <button
                  type="button"
                  class="p-1 text-red-400"
                  @click.stop="handleRemoveItem(record.item_code)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </template>
            </template>
          </TableComponent>
        </div>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          @click="isShowItem = false"
          class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
        >
          Cancel
        </button>
        <button
          @click="handleSaveItem"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Save
        </button>
      </div>
    </ModalComponent>
    <ModalComponent
      title="Confirm"
      :isModalOpen="isDeleteMessage"
      :onToggle="() => (this.isDeleteMessage = false)"
      :width="378"
      :freeSpaceClose="true"
    >
      <div class="p-2">
        <h1 class="dark:text-gray-100">Do you want to delete menu?</h1>
      </div>
      <div class="flex justify-end pt-2 lg:pt-4 gap-4">
        <button
          @click="isDeleteMessage = false"
          class="inline-flex items-center text-sm bg-transparent p-0 border-none text-secondary dark:text-gray-300 font-medium"
        >
          Cancel
        </button>
        <button
          @click="handleYesDeleteItem"
          class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
        >
          Yes
        </button>
      </div>
    </ModalComponent>
    <SpinComponent :show="loading" />
  </div>
</template>

<script>
const columns = [
  {
    key: "no",
    label: "#",
    dataIndex: "no",
    width: 20,
    align: "center",
    sort: true,
  },
  {
    key: "label",
    label: "Label",
    dataIndex: "label",
    sort: true,
  },
  {
    key: "parent_menu",
    label: "Parent Menu",
    align: "center",
    alignHeader: "center",
    sort: true,
  },
  {
    key: "index_no",
    label: "Index",
    dataIndex: "index_no",
    align: "center",
    alignHeader: "center",
    sort: true,
  },
  {
    key: "status",
    label: "Status",
    align: "center",
    alignHeader: "center",
  },
];
const columns_item = [
  {
    key: "no",
    label: "#",
    dataIndex: "no",
    width: 20,
    align: "center",
  },
  {
    key: "custom_item_code",
    label: "Item Code",
    dataIndex: "custom_item_code",
  },
  {
    key: "item_name",
    label: "Item Name",
    dataIndex: "item_name",
  },
  {
    key: "uom",
    label: "UOM",
    dataIndex: "uom",
  },
  {
    key: "action",
    label: "Action",
    width: 50,
  },
];
import TableComponent from "@/components/table/TableComponent.vue";
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import imageEmpty from "@/assets/image/empty.jpg";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { renderItems } from "@/utils/globleFunction";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";
import MenuGroup from "@/models/menuGroup";
import InputComponent from "@/components/input/InputComponent.vue";

export default {
  name: "Menu Group",
  components: {
    TableComponent,
    SpinComponent,
    ModalComponent,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    InputComponent,
  },
  data() {
    return {
      Tr,
      renderItems,
      dataForm: new MenuGroup("", false, null, 0, false),
      columns,
      columns_item,
      limit: 20,
      page: 1,
      items: [],
      imageEmpty,
      loading: false,
      isAddView: false,
      dataSelectedItem: [],
      isDeleteMessage: false,
      titleModal: "",
      dataMenu: [],
      isShowItem: false,
      query: "",
      status: "Enabled",
      search: "",
      menuItems: [],
      itemSearch: [],
    };
  },
  created() {
    this.getItems();
    this.getDataMenu();
  },
  watch: {
    status(value) {
      this.page = 1;
      this.limit = 20;
      this.items = [];
      this.getItems();
    },
  },
  mounted() {
    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", (e) => {
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        this.totalItems > this.items.length
      ) {
        this.page++;
        this.getItems();
      }
    });
  },
  computed: {
    newItemSearch() {
      return this.itemSearch.filter(
        (itemA) =>
          !this.menuItems.some((itemB) => itemA.item_code === itemB.item_code)
      );
    },
  },
  methods: {
    handleRemoveItem(value) {
      this.menuItems = this.menuItems.filter((item) => item.item_code != value);
    },
    handleAddItem(item) {
      this.menuItems.push(item);
      this.itemSearch = this.itemSearch.filter(
        (itemS) => itemS.item_code != item.item_code
      );
    },
    handleSearch(event) {
      this.query = event.target.value;
      if (this.query.length > 2)
        dataService
          .get("get_all_menu_items", `query=${this.query}`)
          .then((res) => {
            let items = res.data.message;
            this.itemSearch = items;
          });
      else this.itemSearch = [];
    },
    handleSearchClear() {
      this.query = "";
      this.itemSearch = [];
    },
    getMenuItems() {
      dataService
        .getPaginate(
          "get_list_menu_item",
          this.dataForm.name,
          this.query,
          this.limit,
          this.page
        )
        .then((res) => {
          let { items, total } = res.data.message;
          this.menuItems.push(...items);
          this.totalItems = total;
        });
    },
    handleShowItem() {
      this.menuItems = [];
      this.getMenuItems();
      this.isShowItem = true;
    },
    handleSaveItem() {
      this.loading = true;
      dataService
        .post("update_menu_item", {
          items: this.menuItems.map((item) => {
            return { item_code: item.item_code, item_name: item.item_name };
          }),
          parent: this.dataForm.name,
        })
        .then((res) => {
          dispatchNotification({
            content: "Add item successfully!",
            type: "success",
          });
          this.loading = false;
          this.isShowItem = false;
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.isDeleteMessage = false;
          this.loading = false;
        });
    },
    getDataMenu() {
      dataService.get("get_menus").then((res) => {
        this.dataMenu = res.data.message;
      });
    },
    handleAddMenu() {
      this.titleModal = "Add Menu Group";
      this.dataForm = new MenuGroup("", false, null, 0, false);
      this.isAddView = true;
    },
    handleViewItem(value) {
      this.titleModal = "View/Edit Menu";
      this.dataForm = {
        ...value,
        is_parent_menu: Boolean(value.is_parent_menu),
        disabled: Boolean(value.disabled),
      };
      this.isAddView = true;
    },
    getItems() {
      this.loading = true;
      dataService
        .getAllPaginate(
          "get_list_menu",
          this.search,
          this.limit,
          this.page,
          this.status == "Enabled" ? 0 : this.status == "Disabled" ? 1 : null
        )
        .then((res) => {
          let { items, total } = res.data.message;
          this.items.push(...items);
          this.totalItems = total;
          this.loading = false;
        });
    },
    handleDeleteItem() {
      this.isDeleteMessage = true;
    },
    handleYesDeleteItem() {
      this.loading = true;
      dataService
        .post("delete_menu", {
          items: this.dataSelectedItem.map((item) => item.name),
        })
        .then((res) => {
          dispatchNotification({
            content: "Detete item successfully!",
            type: "success",
          });
          this.dataSelectedItem = [];
          this.isDeleteMessage = false;
          this.loading = false;
          this.page = 1;
          this.items = [];
          this.getItems();
        })
        .catch((err) => {
          dispatchNotification({
            content: JSON.parse(
              JSON.parse(err.response.data._server_messages)[0]
            ).message,
            type: "warning",
          });
          this.isDeleteMessage = false;
          this.loading = false;
        });
    },

    handleSaveMenu() {
      if (this.dataForm.is_parent_menu)
        if (this.dataForm.parent_menu != null) this.saveMenu();
        else
          dispatchNotification({
            content: "Parent Menu is not empty!",
            type: "warning",
          });
      else this.saveMenu();
    },
    saveMenu() {
      this.loading = true;
      if (!this.dataForm.name)
        dataService
          .post("create_menu", this.dataForm)
          .then((res) => {
            dispatchNotification({
              content: "Create Menu successfully",
              type: "success",
            });
            this.items = [];
            this.page = 1;
            this.getItems();
            this.isAddView = false;
            this.loading = false;
          })
          .catch((err) => {
            dispatchNotification({
              content: JSON.parse(
                JSON.parse(err.response.data._server_messages)[0]
              ).message,
              type: "warning",
            });
            this.loading = false;
          });
      else {
        dataService
          .post("update_menu", this.dataForm)
          .then((res) => {
            dispatchNotification({
              content: "Update Menu successfully",
              type: "success",
            });
            this.items = [];
            this.page = 1;
            this.getItems();
            this.isAddView = false;
            this.loading = false;
          })
          .catch((err) => {
            dispatchNotification({
              content: JSON.parse(
                JSON.parse(err.response.data._server_messages)[0]
              ).message,
              type: "warning",
            });
            this.loading = false;
          });
      }
    },
  },
};
</script>
