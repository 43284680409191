<template>
  <div>
    <div class="grid grid-cols-5">
      <div class="col-span-2 overflow-hidden h-screen">
        <img
          :src="items[0]?.image ? baseURL + items[0]?.image : imageEmpty"
          alt=""
          class="w-full object-cover"
        />
      </div>
      <div class="col-span-3 px-4">
        <div
          class="w-full flex items-center justify-center py-2 border-b border-gray-900 dark:border-gray-400"
        >
          <h1 class="uppercase text-lg font-bold dark:text-gray-100">
            Order Summary
          </h1>
        </div>
        <div class="flex w-full dark:text-gray-100 text-sm my-2 font-bold">
          <h1 class="w-[50%]">Items</h1>
          <h1 class="w-[10%] text-center">Qty</h1>
          <h1 class="w-[20%] text-center">Price</h1>
          <h1 class="text-right w-[20%] dark:text-gray-100 pr-2">Amount</h1>
        </div>
        <div class="overflow-auto h-[calc(100vh_-_185px)]">
          <div
            v-for="item in items"
            class="flex w-full dark:text-gray-100 text-sm py-2.5 border-b border-gray-300 dark:border-slate-600"
          >
            <div class="w-[50%]">
              {{ item.item_name }}
              <p
                v-if="item.take_note != ''"
                class="text-base text-gray-600 dark:text-gray-400"
              >
                {{ item.take_note }}
              </p>
            </div>
            <div class="w-[10%] text-center">{{ item.qty }}</div>
            <div class="w-[20%] text-center">
              $ {{ formtCurrency(item.price_list_rate) }}
            </div>
            <div class="text-right w-[20%] dark:text-gray-100 font-bold pr-2">
              <p>$ {{ formtCurrency(item.price_list_rate * item.qty) }}</p>
              <p
                v-if="item.discount_percentage != 0"
                class="text-gray-500 dark:text-gray-400"
              >
                {{ item.discount_percentage }}% off
              </p>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-900 dark:border-gray-400 my-2"></div>
        <div class="flex items-center">
          <div class="flex dark:text-gray-100 text-sm leading-7 w-1/2">
            <div class="w-1/2">
              <p>Subtotal</p>
              <p>Tax</p>
              <p>Discount</p>
              <!-- <p v-show="getCharge > 0" class="ellipsis">Service Change</p> -->
            </div>
            <div class="w-1/2 text-right font-bold dark:text-gray-100">
              <p>${{ formtCurrency(subtotal) }}</p>
              <p>${{ formtCurrency(tax) }}</p>
              <p>${{ formtCurrency(discount_amount) }}</p>
              <!-- <p v-show="getCharge > 0">
                ${{ $n(getTotalAmountTaxCharge(getCharge), "decimal", "en") }}
              </p>  -->
            </div>
          </div>
          <div class="text-right w-1/2 uppercase leading-tight">
            <span class="text-base dark:text-gray-100">Total</span>
            <div class="text-primary text-[25px] font-bold">
              <p>${{ formtCurrency(total + tax) }}</p>
              <p>៛{{ formtCurrency((total + tax) * currencyExchange) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";
import { mapActions, mapGetters } from "vuex";
import imageEmpty from "@/assets/image/ad.jpg";
import { baseURL } from "@/utils/baseURL";
import { formtCurrency } from "@/utils/globleFunction";

export default {
  data() {
    return {
      imageEmpty,
      baseURL,
      formtCurrency,
      items: [],
      total: 0,
      discount_amount: 0,
    };
  },
  mounted() {
    socket.on(type.refCFD, (message) => {
      if (message.pos_profile === this.getPOSProfile) {
        this.items = message.data;
        this.total = message.total;
        this.discount_amount = message.discount_amount;
      }
    });
  },
  computed: {
    ...mapGetters("auth", {
      getPOSProfile: "getPOSProfile",
      currencyExchange: "getCurrencyExchange",
      getTax: "getTax",
    }),
    subtotal() {
      let sum = 0;
      this.items.forEach((item) => {
        sum += item.qty * item.rate;
      });
      return Number(sum);
    },
    tax() {
      return this.subtotal * (this.getTax / 100);
    },
  },
  methods: {},
};
</script>
