<template>
  <form class="px-5" name="form" @submit.prevent="handleSaveCustomer">
    <div class="py-4 flex justify-between items-center">
      <div class="leading-9">
        <h1 class="text-sm sm:text-xl font-semibold  dark:text-gray-100">
          New Customer
        </h1>
        <nav class="flex text-gray-700" aria-label="Breadcrumb">
          <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
              <router-link
                :to="Tr.i18nRoute({ name: 'dashboard' })"
                class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                Home
              </router-link>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'live-customer' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >Customer</router-link
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <router-link
                  :to="Tr.i18nRoute({ name: 'create-live-customer' })"
                  class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white"
                  >New Customer</router-link
                >
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <button
        type="submit"
        class="inline-flex items-center text-sm py-2 lg:py-[10px] px-4 bg-secondary text-white font-medium rounded-md justify-center"
      >
        <svg
          class="h-5 w-5 mr-1"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
          />
          <polyline points="17 21 17 13 7 13 7 21" />
          <polyline points="7 3 7 8 15 8" />
        </svg>
        Save
      </button>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-4">
      <div>
        <label
          for="first_name"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >First Name <span class="text-red-400">*</span></label
        >
        <div class="mt-1">
          <input
            name="first_name"
            type="text"
            v-model="dataForm.first_name"
            required
            class="w-full px-4 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
          />
        </div>
      </div>
      <div>
        <label
          for="last_name"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >Last Name  <span class="text-red-400">*</span></label
        >
        <div class="mt-1">
          <input
            name="last_name"
            type="text"
            v-model="dataForm.last_name"
            required
            class="w-full px-4 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
          />
        </div>
      </div>
      <div>
        <label
          for="phone"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >Phone</label
        >
        <div class="mt-1">
          <input
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            name="phone"
            type="number"
            v-model="dataForm.phone"
            class="w-full px-4 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
          />
        </div>
      </div>
      <div>
        <label
          for="email"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >Email</label
        >
        <div class="mt-1">
          <input
            name="email"
            type="email"
            v-model="dataForm.email"
            class="w-full px-4 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
          />
        </div>
      </div>
      <div>
        <label
          for="live_id"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >Live ID <span class="text-red-400">*</span></label
        >
        <div class="mt-1">
          <input
            name="live_id"
            type="number"
            v-model="dataForm.live_id"
            required
            class="w-full px-4 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
          />
        </div>
      </div>
      <div>
        <label
          for="token"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
          >Token <span class="text-red-400">*</span></label
        >
        <div class="mt-1">
          <input
            name="token"
            type="text"
            v-model="dataForm.token"
            required
            class="w-full px-4 py-2 dark:text-gray-200 border bg-white border-gray-200 dark:bg-slate-700 dark:border-slate-700 rounded-lg focus:outline-none focus:border-primary focus:ring-transparent transition-colors"
          />
        </div>
      </div>
    </div>
    <SpinComponent :show="loading" />
  </form>
</template>

<script>
import SpinComponent from "@/components/spin/SpinComponent.vue";
import dataService from "@/services/data.service";
import Customer from "@/models/live";
import Tr from "@/i18n/translation";
import { dispatchNotification } from "@/components/notification";

export default {
  name: "Bar",
  components: {
    SpinComponent,
  },
  data() {
    return {
      Tr,
      dataForm: new Customer("", "", "", "", "", ""),
      loading: false,
    };
  },

  methods: {
    handleSaveCustomer() {
      dataService.post("create_live_customer", this.dataForm).then((res) => {
        dispatchNotification({
          content: "Create customer successfully",
          type: "success",
        });
        this.$router.go(-1);
      });
    },
  },
};
</script>
