import dataService from "@/services/data.service";
import { dispatchNotification } from "@/components/notification";
import { v4 as uuidv4 } from "uuid";
import { formatDateTime } from "@/utils/globleFunction";
import { socket } from "@/services/socketio.service.js";
import { type } from "@/services/socketio.type";

// state
const state = {
  transfer: {
    isModalOpenMove: false,
    isLoading: false,
    cart_one: {
      name: "",
      table: null,
      items: [],
      number_customer: 0,
      customer: null,
      discount: 0,
      discount_amount: 0,
      creation: null,
      receive: 0,
      invoice_no: null,
    },
    cart_two: {
      name: "",
      table: null,
      items: [],
      number_customer: 0,
      customer: null,
      discount: 0,
      discount_amount: 0,
      creation: null,
      receive: 0,
      invoice_no: null,
    },
  },
};

// getters
const getters = {
  getCartItemsOne: (state, getters, rootState) => {
    return state.transfer.cart_one.items.map((item) => {
      return {
        ...item,
        amount:
          item.price_exchange - item.price_exchange * (item.discount / 100),
      };
    });
  },
  getCartItemsTwo: (state, getters, rootState) => {
    return state.transfer.cart_two.items.map((item) => {
      return {
        ...item,
        amount:
          item.price_exchange - item.price_exchange * (item.discount / 100),
      };
    });
  },
  getCartTotalOne:
    (state, getters) =>
    (tax = 0, charge = 0) => {
      return (
        Number(getters.getCartSubTotalOne.toFixed(2)) -
        Number(getters.getTotalDiscountAmountOne.toFixed(2)) +
        Number(tax.toFixed(2)) +
        Number(charge.toFixed(2))
      );
    },
  getCartSubTotalOne: (state, getters) => {
    return getters.getCartItemsOne.reduce((total, item) => {
      return total + item.amount * item.qty;
    }, 0);
  },
  getTotalDiscountAmountOne: (state, getters) => {
    return (
      (state.transfer.cart_one.discount / 100) * getters.getCartSubTotalOne
    );
  },
  getCartTotalQtyOne: (state, getters) => {
    return getters.getCartItemsOne.reduce((total, item) => {
      return total + item.qty;
    }, 0);
  },
  getCartSubTotalOrgOne: (state, getters) => {
    return getters.getCartItemsOne.reduce((total, item) => {
      return total + item.price_exchange * item.qty;
    }, 0);
  },
  getTotalAmountTaxChargeOne:
    (state, getters) =>
    (value = 0) => {
      let x = getters.getCartSubTotalOrgOne * (value / 100);
      return x >= 0 ? x : 0;
    },
  getCartTotalTwo:
    (state, getters) =>
    (tax = 0, charge = 0) => {
      return (
        Number(getters.getCartSubTotalTwo.toFixed(2)) -
        Number(getters.getTotalDiscountAmountTwo.toFixed(2)) +
        Number(tax.toFixed(2)) +
        Number(charge.toFixed(2))
      );
    },
  getCartSubTotalTwo: (state, getters) => {
    return getters.getCartItemsTwo.reduce((total, item) => {
      return total + item.amount * item.qty;
    }, 0);
  },
  getTotalDiscountAmountTwo: (state, getters) => {
    return (
      (state.transfer.cart_two.discount / 100) * getters.getCartSubTotalTwo
    );
  },
  getCartTotalQtyTwo: (state, getters) => {
    return getters.getCartItemsTwo.reduce((total, item) => {
      return total + item.qty;
    }, 0);
  },
  getIsModalOpenMove(state) {
    return state.transfer.isModalOpenMove;
  },
  getIsLoading(state) {
    return state.transfer.isLoading;
  },
  getCartSubTotalOrgTwo: (state, getters) => {
    return getters.getCartItemsTwo.reduce((total, item) => {
      return total + item.price_exchange * item.qty;
    }, 0);
  },
  getTotalAmountTaxChargeTwo:
    (state, getters) =>
    (value = 0) => {
      let x = getters.getCartSubTotalOrgTwo * (value / 100);
      return x >= 0 ? x : 0;
    },
};

// actions
const actions = {
  setIsModalOpenMove({ commit }, status) {
    commit("setIsModalOpenMove", status);
  },
  saveItemsMoved(
    { state, commit, getters },
    { totalCartOne, totalCartTwo, pos_profile }
  ) {
    const data1 = state.transfer.cart_one;
    const data2 = state.transfer.cart_two;

    if (
      (data1.name != "" || (data1.name == "" && data1.items.length != 0)) &&
      (data2.name != "" || (data2.name == "" && data2.items.length != 0))
    ) {
      commit("setIsLoading", true);
      let itme = {
        name: data1.name,
        table: data1.table.name,
        number_customer: data1.number_customer,
        items_remove: [],
        total: totalCartOne,
        total_qty: getters.getCartTotalQtyOne,
        items: data1.items.map((item) => {
          return {
            ...item,
            discount_percentage: item.discount,
            tag: item.dpos_tag,
            order_time: item.dpos_order_time,
            uom: item.uom,
            dpos_status: item.dpos_status,
          };
        }),
      };
      dataService
        .post("save_sales_invoice", itme)
        .then((res) => {
          socket.emit("send", {
            pos_profile: pos_profile,
            type: type.refM,
            table: data1.table.name,
          });
          let itme = {
            name: data2.name,
            table: data2.table.name,
            number_customer: data2.number_customer,
            items_remove: [],
            total: totalCartTwo,
            total_qty: getters.getCartTotalQtyOne,
            items: data2.items.map((item) => {
              return {
                ...item,
                discount_percentage: item.discount,
                tag: item.dpos_tag,
                order_time: item.dpos_order_time,
                uom: item.uom,
                dpos_status: item.dpos_status,
              };
            }),
          };
          dataService
            .post("save_sales_invoice", itme)
            .then((res) => {
              dispatchNotification({
                content: "Your action was successfully saved",
                type: "success",
              });
              commit("clearDataItemCartOne");
              commit("clearDataItemCartTwo");
              commit("setIsModalOpenMove", false);
              commit("setIsLoading", false);
              socket.emit("send", {
                pos_profile: pos_profile,
                type: type.refT,
              });
              socket.emit("send", {
                pos_profile: pos_profile,
                type: type.refM,
                table: data2.table.name,
              });
              socket.emit("send", {
                pos_profile: pos_profile,
                type: type.refCS,
                table: data2.table.name,
              });
              socket.emit("send", {
                pos_profile: pos_profile,
                type: type.refS,
              });
            })
            .catch((err) => {
              let error = err.response.data._server_messages;
              if (error)
                dispatchNotification({
                  content: JSON.parse(JSON.parse(error)[0]).message,
                  type: "warning",
                });
            });
        })
        .catch((err) => {
          let error = err.response.data._server_messages;
          if (error)
            dispatchNotification({
              content: JSON.parse(JSON.parse(error)[0]).message,
              type: "warning",
            });
        });
    } else {
      dispatchNotification({
        content: "Please Select item!",
        type: "warning",
      });
    }
  },
  setDataTableOne({ state, commit }, data) {
    commit("setDataTableOne", data);
  },
  setDataTableTwo({ state, commit }, data) {
    commit("setDataTableTwo", data);
  },
  getDataItemCartOne({ commit }, data) {
    dataService
      .get("get_sales_invoice", data)
      .then((res) => {
        const item = res.data.message.data;
        if (item != null) {
          let discount = item.taxes.find(
            (item) => item.dpos_type === "Discount Percentage"
          )?.dpos_rate;
          let discount_amount = item.taxes.find(
            (item) => item.dpos_type === "Discount Amount"
          )?.amount;

          item.creation = formatDateTime(item.creation);
          item.receive = 0;
          item.discount = discount ? discount : 0;
          item.discount_amount = discount_amount ? discount_amount : 0;
          item.items = item.items.map((i) => {
            return { ...i, ...{ id: uuidv4() } };
          });
          commit("getDataItemCartOne", item);
        } else commit("clearDataItemCartOne");
      })
      .catch((err) => {
        let error = err.response.data._server_messages;
        if (error)
          dispatchNotification({
            content: JSON.parse(JSON.parse(error)[0]).message,
            type: "warning",
          });
      });
  },
  getDataItemCartTwo({ commit }, data) {
    dataService
      .get("get_sales_invoice", data)
      .then(
        (res) => {
          const item = res.data.message.data;

          if (item != null) {
            let discount = item.taxes.find(
              (item) => item.dpos_type === "Discount Percentage"
            )?.dpos_rate;
            let discount_amount = item.taxes.find(
              (item) => item.dpos_type === "Discount Amount"
            )?.amount;

            item.creation = formatDateTime(item.creation);
            item.receive = 0;
            item.discount = discount ? discount : 0;
            item.discount_amount = discount_amount ? discount_amount : 0;
            item.items = item.items.map((i) => {
              return { ...i, ...{ id: uuidv4() } };
            });

            commit("getDataItemCartTwo", item);
          } else commit("clearDataItemCartTwo");
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((err) => {
        let error = err.response.data._server_messages;
        if (error)
          dispatchNotification({
            content: JSON.parse(JSON.parse(error)[0]).message,
            type: "warning",
          });
      });
  },
  moveItemToCartTwo({ state, commit }, item) {
    commit(
      "removeItemFromCartOne",
      removeItemsByQuantity(state.transfer.cart_one.items, item)
    );
    commit("moveItemToCartTwo", [...state.transfer.cart_two.items, ...item]);
  },
  moveItemToCartOne({ state, commit }, item) {
    commit(
      "removeItemFromCartTwo",
      removeItemsByQuantity(state.transfer.cart_two.items, item)
    );
    commit("moveItemToCartOne", [...state.transfer.cart_one.items, ...item]);
  },
  moveAllItemsToCart({ state, commit }, key) {
    if (
      state.transfer.cart_one.table != null &&
      state.transfer.cart_two.table != null
    )
      commit("moveAllItemsToCart", key);
    else
      dispatchNotification({
        content: "Please Select a Table!",
        type: "warning",
      });
  },
};

// mutations
const mutations = {
  moveAllItemsToCart(state, key) {
    const obj1 = state.transfer.cart_one;
    const obj2 = state.transfer.cart_two;
    if (key == 2) {
      obj1.items.push(...obj2.items);
      obj1.number_customer =
        obj1.number_customer != 0 ? obj1.number_customer : obj2.number_customer;
      obj2.items.length = 0;
    } else {
      obj2.items.push(...obj1.items);
      obj2.number_customer =
        obj2.number_customer != 0 ? obj2.number_customer : obj1.number_customer;
      obj1.items.length = 0;
    }
  },
  setIsModalOpenMove(state, status) {
    state.transfer.isModalOpenMove = status;
  },
  setIsLoading(state, status) {
    state.transfer.isLoading = status;
  },
  setDataTableOne(state, data) {
    state.transfer.cart_one.table = data;
  },
  setDataTableTwo(state, data) {
    state.transfer.cart_two.table = data;
  },
  moveItemToCartTwo(state, item) {
    state.transfer.cart_two.items = item;
  },
  removeItemFromCartOne(state, item) {
    state.transfer.cart_one.items = item;
  },
  moveItemToCartOne(state, item) {
    state.transfer.cart_one.items = item
  },
  removeItemFromCartTwo(state, item) {
    state.transfer.cart_two.items = item;
  },
  getDataItemCartOne(state, data) {
    state.transfer.cart_one = data;
  },
  clearDataItemCartOne(state, data) {
    state.transfer.cart_one = {
      name: "",
      table: state.transfer.cart_one.table,
      items: [],
      number_customer: 0,
      customer: null,
      discount: 0,
      discount_amount: 0,
      creation: null,
      receive: 0,
      invoice_no: null,
    };
  },
  clearDataItemCartTwo(state, data) {
    state.transfer.cart_two = {
      name: "",
      table: state.transfer.cart_two.table,
      items: [],
      number_customer: 0,
      customer: null,
      discount: 0,
      discount_amount: 0,
      creation: null,
      receive: 0,
      invoice_no: null,
    };
  },
  getDataItemCartTwo(state, data) {
    state.transfer.cart_two = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function removeItemsByQuantity(item1, item2) {
  const item1Map = new Map(item1.map((item) => [item.id, item]));

  item2.forEach((itemToRemove) => {
    const itemCode = itemToRemove.id;
    const quantityToRemove = itemToRemove.qty;

    if (item1Map.has(itemCode)) {
      const itemInItem1 = item1Map.get(itemCode);

      if (itemInItem1.qty >= quantityToRemove) {
        itemInItem1.qty -= quantityToRemove;

        if (itemInItem1.qty === 0) {
          item1 = item1.filter((item) => item.id !== itemCode);
        }
      } else {
        console.log(`Not enough quantity of ${itemCode} to remove.`);
      }
    } else {
      console.log(`${itemCode} not found in item1.`);
    }
  });

  return item1;
}
